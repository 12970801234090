import React from 'react';
import { Flexbox } from 'cw-ui-core';
import * as PropTypes from 'prop-types';
import css from './Selection.scss';

function Selection(props) {
  return (
    <Flexbox
      automationName="selectTag"
      className={css.container}
      onClick={tag => props.onClick(tag)}
    >
      {props.msg}
    </Flexbox>
  );
}

Selection.propTypes = {
  msg: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default Selection;
