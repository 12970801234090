export const getCustomThemeTemplate = () => {
  return `
.TicketList-row:hover {
  background-color: secondary; }
.UserAccount-menuItem:hover {
  background-color: secondary; }
.GanttChart-hoveredRow {
  background-color: secondary; }
.UserList-row:hover {
  background-color: secondary; }
.RoleDialog-selected {
  background-color: secondary; }
.LandingPage-login:hover {
  border-color: primary; }`;
};
