import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/CreateTicketSuccess/CreateTicketSuccess.scss';
import Locale from 'src/Locale';

const CreateTicketSuccess = props => {
  return (
    <core.Flexbox column className={css.container}>
      <core.ScreenMessagePanelContainer screenKey={props.drawerId} />
      <span>
        <core.Label
          className={css.titleName}
          bold
          grey7
          value={core.formatMessage(Locale.ticket_successfully_created, {
            ticketId: props.ticketId
          })}
        />
      </span>
      <div className={css.imageContainer}>
        <core.Image
          src="/images/SupportTicket/ticket_created_message.svg"
          className={css.image}
        />
      </div>
      <core.ButtonStandard
        cwId="createTicketSuccess"
        automationName="createTicketSuccess"
        className={css.viewTicket}
        value={core.formatMessage(Locale.view_ticket)}
        isStandard
        onClick={() => props.onViewTicket(props.ticketId)}
      ></core.ButtonStandard>
    </core.Flexbox>
  );
};

export default CreateTicketSuccess;
