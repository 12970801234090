import * as core from 'cw-ui-core';
import CompanyService from 'src/services/Api/HomeApi/CompanyService';

export const fetchPartnerDevelopmentManager = async () => {
    const result = await CompanyService.getPartnerDevelopmentManager();
  
    if (!result.isSuccess) {
      const error = 'No Partner Development Manager assigned to this company.';
      core.CwLog.error(error);
      core
        .Store()
        .dispatch(core.setModuleCache(['partnerDevelopmentManager'], result.isSuccess));
      return result.isSuccess;
    }
  
    core
      .Store()
      .dispatch(core.setModuleCache(['partnerDevelopmentManager'], result.response));
    return result.response;
  };

  export const fetchPartnerDevelopmentManagerPhoto = async () => {
    var imageUrl = await CompanyService.getPartnerDevelopmentManagerPhoto();
    core
      .Store()
      .dispatch(core.setModuleCache(['partnerDevelopmentManagerPhoto'], imageUrl.response));
    return imageUrl.response;
  };