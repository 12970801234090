import cx from 'classnames';
import * as core from 'cw-ui-core';
import React, { useEffect, useState } from 'react';
import PayFabricFrame from './PayFabricFrame';
import css from './Wallet.scss';

const NewCard = ({
  card,
  hasCards,
  isSaving,
  selected,
  payFabricUrlCreate,
  onSelectChange,
  onAddCard,
  onCancelEditCard,
  setEditedCard
}) => {
  const [isShowAddButton, setIsShowAddButton] = useState(true);

  const value = { id: card.ID, checked: selected, cardInWallet: card.card };

  const onAddNewCard = () => {
    setIsShowAddButton(false);
    onSelectChange(value);
    setEditedCard(value);
  };

  useEffect(() => {
    if (!hasCards) {
      onAddNewCard();
    }
    // eslint-disable-next-line
  }, [hasCards]);

  return (
    <>
      <core.Flexbox
        className={cx(
          css.newCard,
          css.creditCard,
          {
            [css.selected]: (selected && !isShowAddButton) || isSaving
          },
          { [css.rowReverse]: !isSaving }
        )}
        key={card.ID}
      >
        {isSaving ? (
          <core.Placeholder className={css.cardLoading} />
        ) : (
          <>
            {isShowAddButton && (
              <core.ButtonStandard
                cwId="addNewCard"
                automationName="addNewCard"
                title={card.Account}
                value={card.Account}
                isStandard
                isSmall={false}
                onClick={onAddNewCard}
                className={css.addNewCard}
              />
            )}
            {!isShowAddButton && (
              <PayFabricFrame
                payFabricUrl={payFabricUrlCreate}
                onAddUpdateCard={cardId => {
                  setIsShowAddButton(true);
                  onAddCard(cardId);
                }}
                onCancel={() => {
                  setIsShowAddButton(true);
                  onCancelEditCard(card.ID);
                }}
              />
            )}
          </>
        )}
      </core.Flexbox>
    </>
  );
};

export default NewCard;
