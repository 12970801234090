import BaseService from '../BaseApiService';

class ScreenConnectService extends BaseService {
  getBaseUrl() {
    return `${this.getBaseUrlFromConfig("partners")}/api/screen_connect`;
  }

  getAccountInstanceInfo() {
    return this.get(`${this.apiUrl()}/dummy/GetAccountInstanceInfo`);
  }

}

export default new ScreenConnectService();