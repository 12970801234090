import BaseService from '../BaseApiService';

class CartItemsService extends BaseService {
  getBaseUrl() {
    return `${this.getBaseUrlFromConfig('billing')}/api/buyer_cart_items`;
  }

  getCartItems(userId) {
    const params = {
      query: `{"perColConditions": {"buyerid":"${userId}"}}`
    };
    const url = this.appendParamsToUrl(this.apiUrl(), params);
    return this.get(url);
  }

  updateCartItems(currentCart) {
    // eslint-disable-next-line no-underscore-dangle
    if (!currentCart._id) {
      return this.post(this.apiUrl(), currentCart);
    }

    // eslint-disable-next-line no-underscore-dangle
    return this.put(`${this.apiUrl()}/${currentCart._id}`, currentCart);
  }

  deleteCurrentCart(id) {
    return this.delete(`${this.apiUrl()}/${id}`);
  }
}

export default new CartItemsService();
