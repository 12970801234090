import React, { useEffect, useRef } from 'react';
import * as core from 'cw-ui-core';
import css from './NewsPostDrawer.scss';
import DrawerHeader from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/DrawerHeaderContainer';
import DrawerContent from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/DrawerContent';
import DrawerFooter from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/DrawerFooter';
import { Steps } from 'src/services/NewsAdmin/NewsAdminService';
import CreatePostSuccess from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/CreatePostSuccess';
import NotificationBeforeCloseContainer from 'src/components/Drawer/NotificationBeforeCloseContainer';
import { NEWS_POST_DRAWER } from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsPostDrawerContainer';

const NewsPostDrawer = props => {
  const drawerTitle = useRef(null);
  const drawerContent = useRef(null);
  const drawerBtns = useRef(null);
  useEffect(() => {
    let titleHeight;
    let buttonsHeight;
    titleHeight = drawerTitle.current
      ? drawerTitle.current.getBoundingClientRect().height
      : 0;
    buttonsHeight = drawerBtns.current
      ? drawerBtns.current.getBoundingClientRect().height
      : 0;
    let heightToApply = window.innerHeight - (buttonsHeight + titleHeight + 48);
    drawerContent.current &&
      (drawerContent.current.style.height = `${heightToApply}px`);
  });

  const {
    getNewsPostCriteria,
    newsPostCriteria,
    mappingsReady,
    step,
    onTypeClick,
    showBreadCrumbs,
    disabledContinueButton,
    setBreadcrumb,
    onBreadcrumbClick,
    breadcrumbList,
    lastBreadcrumbName,
    setValueOnPath,
    mediaType,
    postDetails,
    disabledCreatePostButton,
    onCreatePost,
    onViewPostClick,
    onMediaTypeChange,
    onButtonTypeChange,
    onAuthorChange,
    onPriorityChange,
    onImageUrlChange,
    onVideoUrlChange,
    postType,
    onCallToActionUrlChange,
    buttonType,
    isPostEdit,
    onEditPost,
    productsList,
    marketsList,
    personasList,
    authorsList,
    onPostScheduledDateChange,
    onPostScheduledTimeChange,
    onPostScheduledChange,
    scheduledDate,
    allowPostScheduleTypeChange,
    postSchedule
  } = props;

  useEffect(() => {
    drawerContent.current && (drawerContent.current.scrollTop = 0);
  }, [step]);

  useEffect(() => {
    getNewsPostCriteria();
  }, [getNewsPostCriteria]);

  return (
    <core.Drawer
      onCloseIconClicked={props.onCloseIconClicked}
      onBeforeClose={props.onBeforeClose}
      closeOnBlur
    >
      {!props.isPostCreated ? (
        <core.Flexbox className={css.newsPostDrawer} column>
          <div ref={drawerTitle}>
            <DrawerHeader />
          </div>
          <core.ScreenMessagePanelContainer screenKey={NEWS_POST_DRAWER} />
          <div className={css.scrollableArea} ref={drawerContent}>
            <div className={css.position}>
              <NotificationBeforeCloseContainer
                drawerScreenKey={NEWS_POST_DRAWER}
              ></NotificationBeforeCloseContainer>
            </div>
            <DrawerContent
              newsPostCriteria={newsPostCriteria}
              mappingsReady={mappingsReady}
              currentStep={step}
              onTypeClick={onTypeClick}
              showBreadCrumbs={showBreadCrumbs}
              setBreadcrumb={setBreadcrumb}
              onBreadcrumbClick={onBreadcrumbClick}
              breadcrumbList={breadcrumbList}
              lastBreadcrumbName={lastBreadcrumbName}
              setValueOnPath={setValueOnPath}
              mediaType={mediaType}
              postDetails={postDetails}
              onMediaTypeChange={onMediaTypeChange}
              onButtonTypeChange={onButtonTypeChange}
              onAuthorChange={onAuthorChange}
              onPriorityChange={onPriorityChange}
              onImageUrlChange={onImageUrlChange}
              onVideoUrlChange={onVideoUrlChange}
              postType={postType}
              onCallToActionUrlChange={onCallToActionUrlChange}
              buttonType={buttonType}
              productsList={productsList}
              marketsList={marketsList}
              personasList={personasList}
              authorsList={authorsList}
              onPostScheduledDateChange={onPostScheduledDateChange}
              onPostScheduledTimeChange={onPostScheduledTimeChange}
              onPostScheduledChange={onPostScheduledChange}
              scheduledDate={scheduledDate}
              isPostEdit={isPostEdit}
              allowPostScheduleTypeChange={allowPostScheduleTypeChange}
              postSchedule={postSchedule}
            />
          </div>

          {step !== Steps.Step_1 && (
            <div ref={drawerBtns} className={css.footerContainer}>
              <DrawerFooter
                currentStep={step}
                onTypeClick={onTypeClick}
                disabledContinueButton={disabledContinueButton}
                disabledCreatePostButton={disabledCreatePostButton}
                setBreadcrumb={setBreadcrumb}
                onCreatePost={onCreatePost}
                isPostEdit={isPostEdit}
                onEditPost={onEditPost}
              />
            </div>
          )}
        </core.Flexbox>
      ) : (
        <CreatePostSuccess
          postId={props.createdPostId}
          onViewPostClick={onViewPostClick}
          isPostEdit={isPostEdit}
        ></CreatePostSuccess>
      )}
    </core.Drawer>
  );
};

export default NewsPostDrawer;
