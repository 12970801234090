import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import SecurityBulletinDrawer from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawer';
import {
  getSecurityBulletinCriteria,
  getKeyByValue,
  Steps,
  loadSecurityBulletins,
  addSecurityBulletin,
  getSecurityBulletinById,
  editSecurityBulletin
} from 'src/services/SecurityAdmin/SecurityBulletinAdminService';
import { VIEW_SECURITY_BULLETIN_DRAWER } from 'src/components/Drawer/SecurityBulletin/SecurityPageDrawer';
import Locale from 'src/Locale';
import Routes from 'src/services/Routes';

export const SECURITY_BULLETIN_DRAWER = 'SECURITY_BULLETIN_DRAWER';

const isBulletinEdited = isBulletinEdit => {
  const state = core.Store().getState();
  const updatedData = core.getScreenUpdated(
    state.screen,
    [],
    SECURITY_BULLETIN_DRAWER
  );
  return !isBulletinEdit || (isBulletinEdit && updatedData.size > 0);
};

const mapStateToProps = state => {
  let mappingsReady = false;
  let securityBulletinCriteria = core.getModuleCache(state.module, [
    'securityBulletinCriteria'
  ]);
  if (securityBulletinCriteria) {
    securityBulletinCriteria = securityBulletinCriteria.toJS();
    mappingsReady = true;
  }

  const step = core.getScreenData(
    state.screen,
    'step',
    SECURITY_BULLETIN_DRAWER
  );

  const userName = core.getUserName(state.oidc);
  const authorsList =
    securityBulletinCriteria &&
    securityBulletinCriteria.authors.map(x => ({
      id: x.name,
      name: x.name === 'Self' ? userName : x.name,
      imageUrl: x.imageUrl
    }));

  let securityBulletinDetails = core.getScreenData(
    state.screen,
    [],
    SECURITY_BULLETIN_DRAWER
  );
  securityBulletinDetails = securityBulletinDetails
    ? securityBulletinDetails.toJS()
    : null;

  let breadcrumbList = core.getScreenData(
    state.screen,
    'breadcrumbList',
    SECURITY_BULLETIN_DRAWER
  );
  breadcrumbList = breadcrumbList ? breadcrumbList.toJS() : [];

  const createdBulletinId = core.getScreenData(
    state.screen,
    ['securityBulletinId'],
    SECURITY_BULLETIN_DRAWER
  );

  const isBulletinEdit = core.getScreenData(
    state.screen,
    ['bulletinEdit'],
    SECURITY_BULLETIN_DRAWER
  );

  const securityBulletinUrl =
    createdBulletinId &&
    window.location.origin +
      Routes.SECURITY_BULLETIN_DRAWER.path.replace(
        ':securityBulletinId',
        createdBulletinId
      );

  const disabledCreateBulletinButton = !(
    securityBulletinDetails &&
    (securityBulletinDetails.title
      ? securityBulletinDetails.title.trim()
      : null) &&
    (securityBulletinDetails.content
      ? securityBulletinDetails.content.trim()
      : null) &&
    securityBulletinDetails.author &&
    isBulletinEdited(isBulletinEdit)
  );

  return {
    step,
    securityBulletinCriteria,
    mappingsReady,
    showBreadCrumbs: step !== Steps.Step_1,
    breadcrumbList,
    authorsList,
    securityBulletinDetails,
    createdBulletinId,
    disabledCreateBulletinButton,
    securityBulletinUrl,
    isBulletinEdit
  };
};

const mapDispatchToProps = dispatch => {
  const keys = Object.keys(Steps);
  return {
    onCloseIconClicked: () => {
      dispatch(core.hideDrawer(SECURITY_BULLETIN_DRAWER));
    },
    getSecurityBulletinCriteria: async () => {
      const state = core.Store().getState();
      let securityBulletinCriteria = core.getModuleCache(state.module, [
        'securityBulletinCriteria'
      ]);

      if (!securityBulletinCriteria) {
        await getSecurityBulletinCriteria();
      }
    },
    onProductClick: (desc, step) => {
      let nextStep = Steps.Step_2;
      if (step) {
        let nextIndex = keys.indexOf(getKeyByValue(Steps, step)) + 1;
        if (nextIndex > -1) {
          nextStep = Steps[keys[nextIndex]];
        }
      }

      dispatch(core.setScreenData('step', nextStep, SECURITY_BULLETIN_DRAWER));
      desc &&
        dispatch(core.setScreenData('product', desc, SECURITY_BULLETIN_DRAWER));
      const state = core.Store().getState();
      let breadcrumbList = core.getScreenData(
        state.screen,
        'breadcrumbList',
        SECURITY_BULLETIN_DRAWER
      );
      breadcrumbList = breadcrumbList ? breadcrumbList.toJS() : [];
      if (step === Steps.Step_1) {
        breadcrumbList = [
          {
            name: desc
          }
        ];
      }

      dispatch(
        core.setScreenData(
          'breadcrumbList',
          breadcrumbList,
          SECURITY_BULLETIN_DRAWER
        )
      );
    },
    onBreadcrumbClick: index => {
      const state = core.Store().getState();
      let breadcrumbList = core.getScreenData(
        state.screen,
        'breadcrumbList',
        SECURITY_BULLETIN_DRAWER
      );

      breadcrumbList = breadcrumbList ? breadcrumbList.toJS() : [];
      const step = Steps[keys[index]];
      dispatch(core.setScreenData('step', step, SECURITY_BULLETIN_DRAWER));
      const currentStepNumber = step.slice(-1) - 1;
      currentStepNumber > 0 &&
        breadcrumbList.splice(
          currentStepNumber,
          breadcrumbList.length - currentStepNumber
        );
      dispatch(
        core.setScreenData(
          'breadcrumbList',
          breadcrumbList,
          SECURITY_BULLETIN_DRAWER
        )
      );
    },
    onAuthorChange: filter => {
      dispatch(
        core.setScreenData('author', filter.name, SECURITY_BULLETIN_DRAWER)
      );
      dispatch(
        core.setScreenData(
          'authorImageUrl',
          filter.imageUrl,
          SECURITY_BULLETIN_DRAWER
        )
      );
      dispatch(
        core.setScreenData('originator', filter.id, SECURITY_BULLETIN_DRAWER)
      );
    },
    onCreateSecurityBulletinClick: async () => {
      const state = core.Store().getState();
      let securityBulletinDetails = core.getScreenData(
        state.screen,
        [],
        SECURITY_BULLETIN_DRAWER
      );
      securityBulletinDetails = securityBulletinDetails
        ? securityBulletinDetails.toJS()
        : null;
      const result = await addSecurityBulletin(securityBulletinDetails);
      if (result) {
        dispatch(
          core.setScreenData(
            ['securityBulletinId'],
            result.id,
            SECURITY_BULLETIN_DRAWER
          )
        );
        await loadSecurityBulletins();
      }
    },
    onEditSecurityBulletinClick: async () => {
      const state = core.Store().getState();
      let securityBulletinDetails = core.getScreenData(
        state.screen,
        [],
        SECURITY_BULLETIN_DRAWER
      );
      securityBulletinDetails = securityBulletinDetails
        ? securityBulletinDetails.toJS()
        : null;

      const editBulletinId = core.getScreenData(
        state.screen,
        'editBulletinId',
        SECURITY_BULLETIN_DRAWER
      );

      const result = await editSecurityBulletin(
        editBulletinId,
        securityBulletinDetails
      );
      if (result) {
        dispatch(
          core.setScreenData(
            ['securityBulletinId'],
            result.id,
            SECURITY_BULLETIN_DRAWER
          )
        );
        await loadSecurityBulletins();
      }
    },
    onViewBulletinClick: async bulletinId => {
      dispatch(core.hideDrawer(SECURITY_BULLETIN_DRAWER));
      dispatch(core.showDrawer(VIEW_SECURITY_BULLETIN_DRAWER));
      const securityBulletinDetails = await getSecurityBulletinById(bulletinId);
      if (securityBulletinDetails) {
        dispatch(
          core.setScreenData(
            ['securityBulletinDetails'],
            securityBulletinDetails,
            VIEW_SECURITY_BULLETIN_DRAWER
          )
        );
      } else {
        dispatch(
          core.setErrorScreenMessage(
            core.formatMessage(Locale.security_bulletin_access_error),
            false,
            null,
            VIEW_SECURITY_BULLETIN_DRAWER
          )
        );
      }
    }
  };
};

const SecurityBulletinDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityBulletinDrawer);

export default SecurityBulletinDrawerContainer;
