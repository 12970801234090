import React, { useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import {
  Image,
  IconPath,
  ButtonStandard,
  Flexbox,
  TextField,
  Toggle,
  Label,
  formatMessage,
  CheckBox,
  ScreenMessagePanelContainer,
  Drawer
} from 'cw-ui-core';
import cx from 'classnames';
import css from 'src/components/Drawer/UserProfileDrawer/UserProfileDrawer.scss';
import svgCss from 'src/styles/svg.scss';
import animationCss from 'src/styles/animation.scss';
import useFormInput from 'src/state/hooks/formInput';
import useNotifications from 'src/state/hooks/notification';
import {
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  BILLING_SUCCESS,
  BILLING_ERROR,
  SECURITY_SUCCESS,
  PASSWORD_SUCCESS,
  PROFILE_FIRST_NAME_BLANK_ERROR,
  PASSWORD_NOT_MATCHED,
  PASSWORD_INCORRECT,
  SECURITY_ERROR
} from 'src/components/Drawer/UserProfileDrawer/UserProfileNotifications';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import UserProfileHeader from 'src/components/Drawer/UserProfileDrawer/UserProfileHeader';
import SectionContentHOC from 'src/components/Drawer/SectionContentHOC';
import Locale from 'src/Locale';
import MfaEmailContainer from 'src/components/Drawer/UserProfileDrawer/MultiFactor/MfaEmailContainer';
import MfaAppContainer from 'src/components/Drawer/UserProfileDrawer/MultiFactor/MfaAppContainer';
import NotificationBeforeCloseContainer from 'src/components/Drawer/NotificationBeforeCloseContainer';

export const UPDATE_PROFILE_DRAWER = 'updateProfileDrawer';

const UserProfileDrawer = props => {
  const {
    form,
    onFormChange,
    isDirty,
    normalizeData,
    newForm,
    onFormChangePassword,
    isPasswordValid,
    resetForm,
    isFormUpdated
  } = useFormInput({
    firstName: { value: props.firstName, error: '' },
    lastName: { value: props.lastName, error: '' },
    currentPassword: { value: '', validationmsg: '' },
    newPassword: {
      value: '',
      validationmsg: props.validationMessage.map(msg => ({ ...msg }))
    },
    confirmPassword: {
      value: '',
      validationmsg: props.validationMessage.map(msg => ({ ...msg }))
    }
  });

  const [isBillingNotificationsEnabled, setIsBillingNotificationEnabled] =
    useState(null);

  const onSecurityToggleChange = async () => {
    const toggle = !props.isSecurityNotificationsEnabled;
    let result = await props.setSecurityNotifications(toggle);
    if (result !== null) {
      addSingleNotification(SECURITY_SUCCESS());
    } else {
      addSingleNotification(SECURITY_ERROR());
    }
  };

  const [notifications, dismissNotification, addSingleNotification] =
    useNotifications();
  const [isClosing, setIsClosing] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isMainSaveBusy, setIsMainSaveBusy] = useState(false);
  const [isCloseAccountBusy, setIsCloseAccountBusy] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const partnerRef = useRef(null);
  const { onUserProfileChange } = props;

  const MfaNotification = notification => {
    addSingleNotification(notification());
  };

  useEffect(() => {
    if (props.hasAccessToBillingNofitications) {
      const getBillingSettings = async () => {
        let result = await props.getBillingSettings();
        setIsBillingNotificationEnabled(result === true);
      };

      getBillingSettings();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isChanged = isDirty || isFormUpdated || isChecked;
    onUserProfileChange(isChanged);
  }, [onUserProfileChange, isDirty, isFormUpdated, isChecked]);

  const onClose = () => {
    setIsClosing(true);
    setTimeout(props.onClose, 500);
  };

  let containerClass = cx(css.container, animationCss.animated);

  if (isClosing) {
    containerClass = cx(containerClass, animationCss.fadeOutRight);
  } else {
    containerClass = cx(containerClass, animationCss.fadeInRight);
  }

  const onSubmitForm = async () => {
    const data = normalizeData();
    setIsMainSaveBusy(true);
    if (props.validateForm(form)) {
      const result = await props.onSubmitForm(data);
      if (result) {
        addSingleNotification(PROFILE_SUCCESS());
        newForm();
      } else {
        addSingleNotification(PROFILE_ERROR());
      }
    } else {
      addSingleNotification(PROFILE_FIRST_NAME_BLANK_ERROR());
    }

    setIsMainSaveBusy(false);
  };

  const onNotificationToggleChange = async () => {
    const toggle = !isBillingNotificationsEnabled;
    setIsBillingNotificationEnabled(toggle);
    let result = await props.setBillingNotifications(toggle);
    if (result !== null) {
      setIsBillingNotificationEnabled(result);
      addSingleNotification(BILLING_SUCCESS());
    } else {
      addSingleNotification(BILLING_ERROR());
    }
  };

  const standardButton = () => (
    <ButtonStandard
      cwId="saveUserProfile"
      automationName="saveUserProfile"
      title={formatMessage(Locale.user_profile_save_button)}
      value={formatMessage(Locale.user_profile_save_button)}
      isStandard
      isSmall
      disabled={!isDirty}
      onClick={onSubmitForm}
      className={css.profileButton}
    />
  );

  const BtnWithLoading = WithButtonLoadingIndicatorHOC(standardButton);

  const onPasswordSubmit = async () => {
    setIsBusy(true);
    const data = {
      currentPassword: form.currentPassword.value,
      password: form.confirmPassword.value
    };
    if (form.newPassword.value !== form.confirmPassword.value) {
      addSingleNotification(PASSWORD_NOT_MATCHED());
    } else {
      const result = await props.onPasswordSubmit(data);
      if (result) {
        addSingleNotification(PASSWORD_SUCCESS());
        resetForm();
      } else {
        addSingleNotification(PASSWORD_INCORRECT());
      }
    }

    setIsBusy(false);
  };

  const standardButtonPassword = () => (
    <ButtonStandard
      cwId="savePasswordProfile"
      automationName="savePasswordProfile"
      title={formatMessage(Locale.user_profile_save_button)}
      value={formatMessage(Locale.user_profile_save_button)}
      isStandard
      isSmall
      disabled={!isPasswordValid}
      onClick={onPasswordSubmit}
      className={css.profileButton}
    />
  );

  const BtnWithLoadingPassword = WithButtonLoadingIndicatorHOC(
    standardButtonPassword
  );

  const onCloseAccount = async () => {
    setIsCloseAccountBusy(true);
    await props.onCloseAccount(props.accountId);
    setIsCloseAccountBusy(false);
  };

  const standardButtonCloseAccount = () => (
    <ButtonStandard
      cwId="closeAccount"
      automationName="closeAccount"
      title={formatMessage(Locale.close)}
      value={formatMessage(Locale.close)}
      isStandard
      isSmall
      disabled={!isChecked}
      onClick={onCloseAccount}
      className={css.profileButton}
    />
  );

  const BtnWithLoadingCloseAccount = WithButtonLoadingIndicatorHOC(
    standardButtonCloseAccount
  );

  const onCheckboxChecked = value => {
    setIsChecked(value);
  };

  const onBeforeCloseDrawer = result => {
    const onBeforeCloseWhenDirty = isDirty || isFormUpdated || isChecked;
    if (onBeforeCloseWhenDirty) {
      result.isCancel = true;
      props.onBeforeClose(result);
    }
  };

  let content;
  let dynStyle = { CwThemeBackgroundColor: props.initials !== undefined };
  if (props.initials) {
    content = props.initials;
  } else if (props.src) {
    content = <Image src={props.src} />;
  }

  return (
    <Drawer
      customClass={cx(containerClass, {
        [css.mobileContainer]: props.isMobileLayout
      })}
      fadeDirection="left"
      onCloseIconClicked={onClose}
      closeOnBlur
      onBeforeClose={onBeforeCloseDrawer}
    >
      <UserProfileHeader
        mainTitle={formatMessage(Locale.profile_edit_my_profile)}
        notifications={notifications}
        dismissNotification={dismissNotification}
      />
      <ScreenMessagePanelContainer screenKey={UPDATE_PROFILE_DRAWER} />
      <NotificationBeforeCloseContainer
        drawerScreenKey={UPDATE_PROFILE_DRAWER}
      ></NotificationBeforeCloseContainer>
      <div className={css.content}>
        <SectionContentHOC
          title={formatMessage(Locale.user_profile_settings)}
          isCollapsible
          isExpandable
        >
          <div className={cx(dynStyle, css.avatar)} style={props.style}>
            {content}
          </div>
          <div className={css.area}>
            <Flexbox className={css.inputGroup}>
              <Flexbox column className={css.namesInput}>
                <Label
                  value={formatMessage(Locale.profile_first_name)}
                  className={css.label}
                />
                <TextField
                  cwId="firstName"
                  automationName="firstName"
                  placeholder={formatMessage(Locale.profile_first_name)}
                  value={form['firstName'].value}
                  onChange={value => onFormChange('firstName', value)}
                  maxLength={25}
                />
              </Flexbox>
              <Flexbox column className={css.namesInput}>
                <Label
                  value={formatMessage(Locale.profile_last_name)}
                  className={css.label}
                />
                <TextField
                  cwId="lastName"
                  automationName="lastName"
                  placeholder={formatMessage(Locale.profile_last_name)}
                  value={form['lastName'].value}
                  onChange={value => onFormChange('lastName', value)}
                  maxLength={25}
                />
              </Flexbox>
            </Flexbox>
            <div className={css.errorStyle}>{form.firstName.error}</div>
            <div className={css.errorStyle}>{form.lastName.error}</div>
            <div className={css.inputGroup}>
              <Flexbox className={css.attribute}>
                <Label className={css.label}>
                  {formatMessage(Locale.profile_email)}
                </Label>
                <TextField
                  automationName="email"
                  className={css.email}
                  readOnly
                  value={props.email}
                ></TextField>
              </Flexbox>
              <Toggle
                cwId="toggleBillingNotifications"
                automationName="toggleBillingNotifications"
                disabled={!props.hasAccessToBillingNofitications}
                onChange={onNotificationToggleChange}
                value={isBillingNotificationsEnabled}
                text={
                  isBillingNotificationsEnabled
                    ? formatMessage(Locale.billing_notifications_disabled)
                    : formatMessage(Locale.billing_notifications_enable)
                }
                className={css.billingNotificationToggle}
              />
                
                  <Toggle
                    automationName="toggleSecurityNotifications"
                    onChange={onSecurityToggleChange}
                    value={props.isSecurityNotificationsEnabled}
                    text={
                      props.isSecurityNotificationsEnabled
                        ? formatMessage(Locale.security_notifications_disabled)
                        : formatMessage(Locale.security_notifications_enable)
                    }
                    className={css.securityNotificationToggle}
                  />
            </div>
          </div>
          <div className={css.area}>
            <Flexbox className={css.detailAttribute} column>
              <Label value={formatMessage(Locale.profile_partner_id)} grey7 />
              <Flexbox className={css.partnerId}>
                <div className={css.detailValue} ref={partnerRef}>
                  {props.partnerId}
                </div>
                <Image
                  title={formatMessage(Locale.copy_to_clipboard)}
                  automationName="copyClipboard"
                  className={cx(css.copyImg, svgCss.actionState)}
                  onClick={props.copyToClipboard}
                  src={IconPath.get('Action_Copy')}
                />
              </Flexbox>
            </Flexbox>
            <Flexbox className={css.detailAttribute} column>
              <Label
                value={formatMessage(Locale.profile_connectwise_home_roles)}
                grey7
              />
              <Label className={css.detailValue} value={props.roles} />
            </Flexbox>
          </div>
          <Flexbox className={css.buttonContainer}>
            <BtnWithLoading
              isBusy={isMainSaveBusy}
              loaderStyle={{ width: '10px', height: '10px' }}
              wrapperStyle={{ height: '100%' }}
              className={css.profileButton}
            />
          </Flexbox>
        </SectionContentHOC>
        {!props.externalAuthEnabled && (
          <>
            <SectionContentHOC
              title={formatMessage(Locale.profile_mfa)}
              isCollapsible
              isExpandable
            > 
              <MfaEmailContainer mfaNotificationCallback={MfaNotification}></MfaEmailContainer>
              <MfaAppContainer mfaNotificationCallback={MfaNotification} />
            </SectionContentHOC>
            <SectionContentHOC
              title={formatMessage(Locale.user_profile_change_password)}
              isCollapsible
            >
              <Flexbox column className={css.allPasswordContainer}>
                <Flexbox className={css.passwordContainer} column>
                  <Label
                    className={css.passwordLabel}
                    value={formatMessage(Locale.profile_current_password)}
                  />
                  <TextField
                    type="password"
                    id="currentPassword"
                    automationName="currentPassword"
                    className={css.passwordInput}
                    name="currentPassword"
                    placeholder={formatMessage(
                      Locale.user_profile_current_password_placeholder
                    )}
                    value={form['currentPassword'].value}
                    onChange={e => onFormChangePassword('currentPassword', e)}
                  />
                </Flexbox>
                <Flexbox className={css.passwordContainer} column>
                  <Label
                    className={css.passwordLabel}
                    value={formatMessage(Locale.profile_new_password)}
                  />
                  <TextField
                    type="password"
                    id="newPassword"
                    automationName="newPassword"
                    className={css.passwordInput}
                    name="newPassword"
                    placeholder={formatMessage(
                      Locale.user_profile_new_password_placeholder
                    )}
                    value={form['newPassword'].value}
                    onChange={e =>
                      onFormChangePassword(
                        'newPassword',
                        e,
                        form.newPassword.validationmsg
                      )
                    }
                  />
                  <Image
                    cwId="newPasswordImage"
                    isInlineSVG
                    src={IconPath.get('Tile_Alerts')}
                    className={cx(
                      svgCss.red,
                      {
                        [css.invalidInputShow]:
                          form !== null &&
                          form.newPassword.validationmsg.filter(
                            item => item.colorCode === 'red'
                          ).length > 0
                      },
                      {
                        [css.invalidInputHide]:
                          form.newPassword.validationmsg.filter(
                            item => item.colorCode === 'red'
                          ).length === 0
                      }
                    )}
                  />
                  <div className={css.inputRequirements}>
                    {form !== null &&
                      form.newPassword.validationmsg.map((p, parentIndex) => {
                        return (
                          <div key={parentIndex} className={p.cssClass}>
                            <Image
                              cwId="newPasswordImage"
                              isInlineSVG
                              src={
                                p.imgSrc
                                  ? IconPath.get(`${p.imgSrc}`)
                                  : IconPath.get('Flag_Enabled')
                              }
                              className={cx(
                                css.validationImage,
                                p.colorCode === 'black'
                                  ? [svgCss.black]
                                  : p.colorCode === 'red'
                                  ? [svgCss.red]
                                  : [svgCss.green]
                              )}
                            />
                            <div className={css.validationMessage}>
                              {p.validationName}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Flexbox>
                <Flexbox className={css.passwordContainer} column>
                  <Label
                    className={css.passwordLabel}
                    value={formatMessage(Locale.profile_confirm_password)}
                  />
                  <TextField
                    type="password"
                    id="confirmPassword"
                    automationName="confirmPassword"
                    className={css.passwordInput}
                    name="confirmPassword"
                    placeholder={formatMessage(
                      Locale.user_profile_confirm_password_placeholder
                    )}
                    value={form['confirmPassword'].value}
                    onChange={e =>
                      onFormChangePassword(
                        'confirmPassword',
                        e,
                        form.confirmPassword.validationmsg
                      )
                    }
                  />
                  <Image
                    cwId="confirmPasswordImage"
                    isInlineSVG
                    src={IconPath.get('Tile_Alerts')}
                    className={cx(
                      svgCss.red,
                      {
                        [css.invalidInputShow]:
                          form !== null &&
                          form.confirmPassword.validationmsg.filter(
                            item => item.colorCode === 'red'
                          ).length > 0
                            ? true
                            : false
                      },
                      {
                        [css.invalidInputHide]:
                          form.confirmPassword.validationmsg.filter(
                            item => item.colorCode === 'red'
                          ).length === 0
                            ? true
                            : false
                      }
                    )}
                  />
                  <div className={css.inputConfirmRequirements}>
                    {form &&
                      form.confirmPassword.validationmsg.map(
                        (p, parentIndex) => {
                          return (
                            <div key={parentIndex} className={p.cssClass}>
                              <Image
                                cwId="confirmPasswordImage"
                                isInlineSVG
                                src={
                                  p.imgSrc
                                    ? IconPath.get(`${p.imgSrc}`)
                                    : IconPath.get('Flag_Enabled')
                                }
                                className={cx(
                                  css.validationImage,
                                  p.colorCode === 'black'
                                    ? [svgCss.black]
                                    : p.colorCode === 'red'
                                    ? [svgCss.red]
                                    : [svgCss.green]
                                )}
                              />
                              <div className={css.validationMessage}>
                                {p.validationName}
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </Flexbox>
                <Flexbox className={css.buttonContainer}>
                  <BtnWithLoadingPassword
                    isBusy={isBusy}
                    loaderStyle={{ width: '10px', height: '10px' }}
                    wrapperStyle={{ height: '100%' }}
                    className={css.profileButton}
                  />
                </Flexbox>
              </Flexbox>
            </SectionContentHOC>
          </>
        )}
        <SectionContentHOC
          title={formatMessage(Locale.close_account_header_text)}
          isCollapsible
          isExpanded={false}
        >
          <Label vlaue={formatMessage(Locale.close_account_label)} />
          <CheckBox
            className={css.closeAccountText}
            text={formatMessage(Locale.close_account_text)}
            value={isChecked}
            onChange={e => onCheckboxChecked(e)}
          ></CheckBox>
          <Flexbox className={css.buttonContainer}>
            <BtnWithLoadingCloseAccount
              isBusy={isCloseAccountBusy}
              loaderStyle={{ width: '10px', height: '10px' }}
              wrapperStyle={{ height: '100%' }}
              className={css.profileButton}
            />
          </Flexbox>
        </SectionContentHOC>
      </div>
    </Drawer>
  );
};

UserProfileDrawer.propTypes = {
  toggleMask: PropTypes.func,
  isMobileLayout: PropTypes.bool,
  avatar: PropTypes.func,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  accountId: PropTypes.string,
  partnerId: PropTypes.string,
  roles: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  isLocalIdp: PropTypes.bool
};

export default UserProfileDrawer;
