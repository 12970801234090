import React, { useState, useEffect } from 'react';
import * as core from 'cw-ui-core';
import WalletContainer from 'src/components/Wallet/WalletContainer';
import LoadingComponent from 'src/components/Loading/GenericLoadingComponent';
import FailedPaymentContainer from 'src/components/Drawer/PartnerWalletDrawer/FailedPaymentContainer';
import cx from 'classnames';
import css from 'src/components/Drawer/PartnerWalletDrawer/WalletDrawer.scss';
import Locale from 'src/Locale';
import RetryPaymentContainer from 'src/components/Drawer/PartnerWalletDrawer/RetryPaymentContainer';
import NotificationBeforeCloseContainer from 'src/components/Drawer/NotificationBeforeCloseContainer';
import { WALLET_DRAWER } from 'src/components/Drawer/PartnerWalletDrawer/WalletDrawerContainer';

const WalletLoadingComponent = () => {
  const columns = [
    {
      className: cx(css.flex, css.square),
      stackCount: 1
    }
  ];

  return (
    <LoadingComponent
      id="loading-component-1"
      columns={columns}
      parentClass={css.parentClass}
    />
  );
};

const WalletDrawer = props => {
  const { onDidMount, onCloseIconClicked } = props;
  const [processingPayment, setProcessingPayment] = useState(false);

  useEffect(() => {
    onDidMount();
  }, [onDidMount, processingPayment]);

  const setPaymentProcessing = () => {
    setProcessingPayment(true);
  };

  let isLoading = props.isLoading;
  let wallet = props.wallet ? props.wallet.toJS() : {};

  return (
    <core.Drawer
      customClass={css.mobileNavClose}
      fadeDirection="left"
      onCloseIconClicked={onCloseIconClicked}
      onBeforeClose={props.onBeforeClose}
      closeOnBlur
    >
      {processingPayment ? (
        <RetryPaymentContainer />
      ) : (
        <div className={css.container}>
          <h2 className={css.title}>
            {core.formatMessage(Locale.wallet_drawer_title)}
          </h2>
          <div className={css.message}>
            {core.formatMessage(Locale.wallet_drawer_message)}
          </div>
          <NotificationBeforeCloseContainer
            drawerScreenKey={WALLET_DRAWER}
          ></NotificationBeforeCloseContainer>
          <core.Flexbox className={css.cardContainer} column={false}>
            {isLoading ? <WalletLoadingComponent /> : <WalletContainer />}
          </core.Flexbox>
          {isLoading === false ? (
            <FailedPaymentContainer
              selectedCard={wallet.selectedCard}
              setPaymentProcessing={setPaymentProcessing}
            />
          ) : null}
        </div>
      )}
    </core.Drawer>
  );
};

export default core.MobileLayoutHOC(WalletDrawer);
