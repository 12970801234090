import { GET, getUrl } from 'src/services/ApiService';

const FeaturesService = () => {
  const apiUrl = () => getUrl('home', '/features');

  const getFeatures = async () => {
    return await GET(`${apiUrl()}/`, true);
  };

  return {
    getFeatures
  };
};

export default FeaturesService();
