import * as core from 'cw-ui-core';

export const showNotificationBeforeClose = drawerScreenKey => {
  core
    .Store()
    .dispatch(
      core.setScreenData('showNotificationBeforeClose', true, drawerScreenKey)
    );
};

export const hideNotificationBeforeClose = drawerScreenKey => {
  core
    .Store()
    .dispatch(
      core.setScreenData('showNotificationBeforeClose', false, drawerScreenKey)
    );
};
