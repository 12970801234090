import {useState} from 'react';
import {OrderedMap} from 'immutable';

export default () => {

  const [notifications, setNotifications] = useState(OrderedMap());
  
  const addSingleNotification = notification => {
    if (!notifications.has(notification.cwId)) {
      const update = notifications.clear().set(notification.cwId, notification);
      setNotifications(update);
    }
  };

  const addNotification = notification => {
    if (!notifications.has(notification.cwId)) {
      const update = notifications.set(notification.cwId, notification);
      setNotifications(update);
    }
  };

  const dismissNotification = id => {
    const sans = notifications.remove(id);
    setNotifications(sans);
  };

  return [notifications.valueSeq().toArray(), dismissNotification, addSingleNotification,addNotification];
};