import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import Routes from '../Routes';
import { Map } from 'immutable';
import { BANNER_DRAWER } from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawerContainer';
import BannerApi from 'src/services/Api/HomeApi/BannerService';
import { PUBLISH_BANNER_DIALOG} from 'src/components/Admin/NewsPage/Banner/PublishBannerDialog';
import BannerService from 'src/services/Api/HomeApi/BannerService';
import { getUser } from 'src/services/Partners/PartnersService';

export const getBanners = async () => {
    const result = await BannerService.getAllBanners();
    if (!result.isSuccess) {
      const error = result.error;
      core.CwLog.error(error);
      return;
    }

  return result.response;
};

export const getPublishedBanners = async () => {
  const result = await BannerService.getPublishedBanners();
  if (!result.isSuccess) {
    const error = result.error;
    core.CwLog.error(error);
    return;
  }

  return result.response;
};

export const getBannerById = async id => {
  const result = await BannerApi.getBannerById(id);
  let isUnauthorized = false;
  if (result && !result.isSuccess) {
    if (result.status === 401) {
      isUnauthorized = true;
    } else {
      const error = result.error;
      core.CwLog.error(error);
      return;
    }
  }

  const bannerResponse = {
    result: result.response,
    isUnauthorized
  };

  return bannerResponse;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const toObject = reduxObject => {
  return reduxObject ? reduxObject.toJS() : {};
};

export const loadBanners = async pageIndex => {
  const dispatch = core.Store().dispatch;
  dispatch(core.setScreenData(['isBannerReady'], false));
  const response = await getBanners(pageIndex);
  dispatch(core.setScreenData(['banners'], response.banners));
  dispatch(
    core.setScreenData(
      'bannerPagination',
      response.metadata
    )
  );
  dispatch(core.setScreenData(['isBannerReady'], true));
};

export const publishBanner = async bannerId => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const user = await getUser();
  const selectedItems = core.getScreenData(state.screen, [
    'regionSelection',
    'regions'
  ]);
  const regions = selectedItems ? selectedItems.toJS() : [];
  if (regions.includes(-1)) {
    const indexToRemove = regions.indexOf(-1);
    regions.splice(indexToRemove, 1);
  }

  let selectedRegions = regions.toString();

  dispatch(core.toggleMask(true));

  const postInfo = {
    status: regions.length > 0 ? 'Published' : 'Unpublished',
    region: selectedRegions,
    SubmittedBy: user.firstName + ' ' + user.lastName
  };

  const result = await BannerService.publishBanner(bannerId, postInfo);
  if (!result.isSuccess) {
    dispatch(
      core.setErrorScreenMessage(
        result.error,
        false,
        result.errorMessages,
        PUBLISH_BANNER_DIALOG
      )
    );
    dispatch(core.toggleMask(false));
    return;
  } else {
    dispatch(core.toggleMask(false));
    return result.response;
  }
};

export const addBanner = async bannerDetails => {
  const dispatch = core.Store().dispatch;
  dispatch(core.toggleMask(true));
  const state = core.Store().getState();
  const id = core.getScreenData(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'id'
  ]);
  const isToggleEnabled = core.getScreenData(
    state.screen,
    ['isToggleEnabled'],
    BANNER_DRAWER
  );
  const user = await getUser();
  const isNew = id ? false : true;
  let content = bannerDetails;
  // PATCH existing record or POST if new
  let result;
  let bannerUpdatedDetails;
  if (content) {
    bannerUpdatedDetails = {
      Title: content.bannerName,
      Headline: content.bannerCopy,
      SubHeadline: content.bannerSubHeader,
      Status: isNew ? 'Unpublished' : content.status,
      FirstBanner: content.firstBannerFileName,
      SecondBanner: content.secondBannerFileName,
      ThirdBanner: content.thirdBannerFileName,
      FourthBanner: content.fourthBannerFileName,
      ButtonText: isToggleEnabled ? content.bannerCTAButtonText : null,
      ButtonTextColor: isToggleEnabled ? content.primaryButtonTextColor : null,
      RedirectUri: isToggleEnabled ? content.bannerCTAButtonUrl : null,
      ButtonColor: isToggleEnabled ? content.primaryColor : null,
      SubmittedBy: user.firstName + ' ' + user.lastName
    };
  }

  if (isNew) {
    result = await BannerApi.addBanner(bannerUpdatedDetails);
  } else {
    result = await BannerApi.updateBanner(id, bannerUpdatedDetails);
  }

  const bannerId = result.response.id;

  // save/update firstBanner if set
  const firstBanner = core.getScreenCustom(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'firstBanner'
  ]);
  if (firstBanner && firstBanner.size > 0) {
    const file = firstBanner.get(0);
    const formData = new FormData();
    formData.append('file', file, file.name);

    result = await BannerApi.addUpdateFirstBanner(bannerId, formData);

    if (!result.isSuccess) {
      dispatch(
        core.setErrorScreenMessage(
          result.error,
          false,
          result.errorMessages,
          BANNER_DRAWER
        )
      );
      dispatch(core.toggleMask(false));
      return;
    }
  }

  // save/update secondBanner if set
  const secondBanner = core.getScreenCustom(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'secondBanner'
  ]);
  if (secondBanner && secondBanner.size > 0) {
    const file = secondBanner.get(0);
    const formData = new FormData();
    formData.append('file', file, file.name);

    result = await BannerApi.addUpdateSecondBanner(bannerId, formData);

    if (!result.isSuccess) {
      dispatch(
        core.setErrorScreenMessage(
          result.error,
          false,
          result.errorMessages,
          BANNER_DRAWER
        )
      );
      dispatch(core.toggleMask(false));
      return;
    }
  }

  // save/update thirdBanner if set
  const thirdBanner = core.getScreenCustom(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'thirdBanner'
  ]);
  if (thirdBanner && thirdBanner.size > 0) {
    const file = thirdBanner.get(0);
    const formData = new FormData();
    formData.append('file', file, file.name);

    result = await BannerApi.addUpdateThirdBanner(bannerId, formData);

    if (!result.isSuccess) {
      dispatch(
        core.setErrorScreenMessage(
          result.error,
          false,
          result.errorMessages,
          BANNER_DRAWER
        )
      );
      dispatch(core.toggleMask(false));
      return;
    }
  }

  // save/update fourthBanner if set
  const fourthBanner = core.getScreenCustom(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'fourthBanner'
  ]);
  if (fourthBanner && fourthBanner.size > 0) {
    const file = fourthBanner.get(0);
    const formData = new FormData();
    formData.append('file', file, file.name);

    result = await BannerApi.addUpdateFourthBanner(bannerId, formData);

    if (!result.isSuccess) {
      dispatch(
        core.setErrorScreenMessage(
          result.error,
          false,
          result.errorMessages,
          BANNER_DRAWER
        )
      );
      dispatch(core.toggleMask(false));
      return;
    }
  }

  if (!result.isSuccess) {
    dispatch(
      core.setErrorScreenMessage(
        result.error,
        false,
        result.errorMessages,
        BANNER_DRAWER
      )
    );
    dispatch(core.toggleMask(false));
    return;
  } else {
    dispatch(core.toggleMask(false));
    await dispatch(
      core.setSuccessfulScreenMessage(
        core.formatMessage(
          isNew
            ? Locale.banner_successfully_created
            : Locale.banner_successfully_edited
        ),
        false,
        [],
        Routes.BANNER_CONFIGURATION.id
      )
    );
    return result.response;
  }
};

export const removeScreenMessages = screenKey => {
  const dispatch = core.Store().dispatch;
  setTimeout(() => {
    dispatch(core.removeAllScreenMessages(screenKey));
  }, 5000);
};

export const deleteBanner = async bannerId => {
  const dispatch = core.Store().dispatch;
  const result = await BannerApi.deleteBanner(bannerId);
  if (!result.isSuccess) {
    const error = result.error;
    core.CwLog.error(error);
    return;
  } else {
    dispatch(core.toggleMask(false));
    await dispatch(
      core.setSuccessfulScreenMessage(
        core.formatMessage(Locale.banner_successfully_deleted),
        false,
        [],
        Routes.BANNER_CONFIGURATION.id
      )
    );
    return result.response;
  }
};

/**
 * Returns required field violations
 * @param {string} screenId 
 * @param {string[]} requiredFields 
 */
const getRequiredFieldViolations = (screenId, requiredFields) => {
  const state = core.Store().getState();
  let violations = Map();

  requiredFields.forEach(field => {
    if (core.isEmpty(core.getScreenData(state.screen, [screenId, field]))) {
      violations = violations.set(field, core.formatMessage(Locale.required));
    }
  });

  return violations;
};

const getImageFileViolations = (screenId, imageFields) => {
  const state = core.Store().getState();
  let violations = Map();

  const allowedImageExtensions = ['svg'];

  imageFields.forEach(field => {
    if (field) {
      const imageFileName = core.getScreenData(
        state.screen,
        Array.isArray(field) ? [screenId, ...field] : [screenId, field]
      );
      if (!core.isEmpty(imageFileName)) {
        const fileExtension = imageFileName.split('.').pop();
        if (!allowedImageExtensions.includes(fileExtension.toLowerCase())) {
          violations = Array.isArray(field)
            ? violations.setIn(
                field,
                core.formatMessage(Locale.invalid_image_file)
              )
            : violations.set(
                field,
                core.formatMessage(Locale.invalid_image_file)
              );
        }
      }
    }
  });

  return violations;
};

const getImageResolutionViolation = (screenId, imageFields) => {
  const state = core.Store().getState();
  let violations = Map();
  const bannerFields = ['image resolution exceeds the maximum allowed size.'];

  imageFields.forEach(field => {
    if (field) {
      const imageFileName = core.getScreenInvalid(
        state.screen,
        Array.isArray(field) ? [screenId, ...field] : [screenId, field]
      );
      if (!core.isEmpty(imageFileName)) {
        if (bannerFields.includes(imageFileName.toLowerCase())) {
          violations = Array.isArray(field)
            ? violations.setIn(
                field,
                core.formatMessage(Locale.invalid_image_resolution)
              )
            : violations.set(
                field,
                core.formatMessage(Locale.invalid_image_resolution)
              );
        }
      }
    }
  });

  return violations;
};

export const validateBannerDrawer = () => {
  let controls = [
    'bannerName',
    'firstBannerFileName',
    'secondBannerFileName',
    'thirdBannerFileName',
    'fourthBannerFileName',
    'bannerCopy'
  ];
  const state = core.Store().getState();
  const toggle = core.getScreenData(
    state.screen,
    ['isToggleEnabled'],
    BANNER_DRAWER
  );
  if (toggle) {
    controls.push('primaryColor','primaryButtonTextColor','bannerCTAButtonUrl','bannerCTAButtonText');
  }

  let violations = getRequiredFieldViolations(
    Routes.BANNER_CONFIGURATION.id,
    controls
  );

  violations = violations.merge(
    getImageFileViolations(Routes.BANNER_CONFIGURATION.id, [
      'firstBannerFileName',
      'secondBannerFileName',
      'thirdBannerFileName',
      'fourthBannerFileName'
    ])
  );

  violations = violations.merge(
    getImageResolutionViolation(Routes.BANNER_CONFIGURATION.id, [
      'firstBannerFileName',
      'secondBannerFileName',
      'thirdBannerFileName',
      'fourthBannerFileName'
    ])
  );

  return violations;
};