import { connect } from 'react-redux';
import { getScreenData } from 'cw-ui-core';
import KnownIssuesListHeaderWithSorting from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesListHeaderWithSorting';

const mapStateToProps = state => {
  let sortedColumnCheck = getScreenData(state.screen, [
    'knownIssues',
    'sortingColumnStatus'
  ]);
  const isReady = getScreenData(state.screen, ['knownIssues', 'isReady']);
  sortedColumnCheck = sortedColumnCheck ? sortedColumnCheck.toJS() : null;
  return {
    sortedColumnCheck,
    isReady
  };
};

const KnownIssuesListHeaderWithSortingContainer = connect(
  mapStateToProps,
  null
)(KnownIssuesListHeaderWithSorting);

export default KnownIssuesListHeaderWithSortingContainer;
