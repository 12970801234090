import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import css from './MultiCheckBox.scss';
import {
  CheckBox,
  formatMessage,
  IconPath,
  Image,
  TriggerField
} from 'cw-ui-core';
import Locale from 'src/Locale';

class MultiCheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayText: '',
      openCategories: [],
      showOnlySelected: false,
      showOnlyUnselected: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      ((prevProps.values.length === 0 && this.props.values.length > 0) ||
        (prevState.displayText !== '' && this.state.displayText === '')) &&
      this.props.subItemsField
    ) {
      let openCategories = [];
      this.props.items.forEach(category => {
        if (this.categoryContainsValue(category)) {
          openCategories.push(category.name);
        }
      });

      this.setState({
        openCategories
      });
    }
  }

  categoryContainsValue(category) {
    const containsValue = this.props.values.some(value => {
      const index = category[this.props.subItemsField].findIndex(subItem => {
        return subItem.name === value;
      });
      return index !== -1;
    });

    return containsValue;
  }

  containsValue(id) {
    const index = this.props.values.findIndex(item => {
      return item === id;
    });
    return index !== -1;
  }

  shouldItemRender(item, enteredText) {
    if (enteredText === '') {
      return true;
    }

    const itemName = this.props.customName
      ? this.props.customName(item)
      : item.name;
    return itemName.toLowerCase().includes(enteredText.toLowerCase());
  }

  getShowOnlySelected = () => {
    return (
      <CheckBox
        className={css.checkBox}
        key={this.props.pathKey + '-showOnlySelected'}
        text={formatMessage(Locale.showOnlySelected)}
        style={{ marginTop: 8, fontStyle: 'italic' }}
        value={this.state.showOnlySelected}
        onChange={e => {
          this.setState({
            showOnlySelected: e,
            showOnlyUnselected: false
          });
        }}
      />
    );
  };

  getShowOnlyUnselected = () => {
    return (
      <CheckBox
        className={css.checkBox}
        key={this.props.pathKey + '-showAllUnselected'}
        text={formatMessage(Locale.showOnlyUnselected)}
        style={{ fontStyle: 'italic' }}
        value={this.state.showOnlyUnselected}
        onChange={e => {
          this.setState({
            showOnlyUnselected: e,
            showOnlySelected: false
          });
        }}
      />
    );
  };

  getSelectAll = () => {
    return (
      <CheckBox
        className={css.checkBox}
        key={this.props.pathKey + '-selectAll'}
        text={formatMessage(Locale.select_all)}
        style={{ marginTop: this.props.showFilters ? 0 : 8 }}
        value={this.props.selectedAll}
        onChange={() => {
          this.props.onSelectAll(this.props.selectedAll);
        }}
      />
    );
  };

  getCategoryValues = () => {
    let categories = [];
    this.props.items.forEach(item => {
      categories.push(item.name);
    });
    return categories;
  };

  toggleCategory = (name, open) => {
    let openCategories = this.state.openCategories;

    if (open) {
      const index = openCategories.indexOf(name);
      delete openCategories[index];
    } else {
      openCategories.push(name);
    }

    this.setState({
      openCategories
    });
  };

  getCategory = (name, displayName, description, open) => {
    return (
      <div
        className={css.category}
        key={name}
        onClick={() => {
          this.toggleCategory(name, open);
        }}
      >
        <Image
          src={
            open
              ? IconPath.get('Action_Tree_Collapse')
              : IconPath.get('Action_Tree_Expand')
          }
        />
        {displayName}
        {description ? (
          <Image
            className={css.help}
            src={IconPath.get('Application_Help')}
            title={description}
          />
        ) : null}
      </div>
    );
  };

  getCategories = () => {
    if (!this.props.items) {
      return null;
    }

    let renderItems = [];
    if (this.state.displayText === '' && !this.props.disableSelectAll) {
      renderItems.push(this.getSelectAll());
    }

    this.props.items.forEach(item => {
      const isOpen = this.state.openCategories.includes(item.name);
      const subItems = this.getItems(item[this.props.subItemsField]);
      if (subItems.length > 0) {
        renderItems.push(
          this.getCategory(
            item.name,
            item.displayName,
            item.description,
            isOpen
          )
        );
      }

      if (isOpen) {
        renderItems.push(this.getItems(item[this.props.subItemsField]));
      }
    });

    return renderItems;
  };

  getItems = items => {
    if (!items) {
      return null;
    }

    const filteredItems = items.filter(item =>
      this.shouldItemRender(item, this.state.displayText)
    );

    let checkboxes = [];

    if (this.props.showFilters) {
      checkboxes.push(this.getShowOnlySelected());
      checkboxes.push(this.getShowOnlyUnselected());
    }

    if (
      !this.props.subItemsField &&
      !this.props.disableSelectAll &&
      this.state.displayText === ''
    ) {
      checkboxes.push(this.getSelectAll());
    }

    const disableValues =
      this.props.disableValues && this.props.disableValues.length > 0
        ? this.props.disableValues
        : [];

    checkboxes = checkboxes.concat(
      filteredItems.reduce((accumulator, item) => {
        let disablecheckBox = false;
        if (disableValues.includes(item.name)) {
          disablecheckBox = true;
        }

        const itemId = this.props.customId
          ? this.props.customId(item)
          : item.id;
        const itemName = this.props.customName
          ? this.props.customName(item)
          : item.name;
        if (
          (!this.state.showOnlySelected && !this.state.showOnlyUnselected) ||
          (this.state.showOnlySelected && this.containsValue(itemId)) ||
          (this.state.showOnlyUnselected && !this.containsValue(itemId))
        ) {
          accumulator.push(
            <div className={css.item} key={itemId + '-wrap'}>
              <CheckBox
                className={
                  this.props.subItemsField ? css.subItemCheckBox : css.checkBox
                }
                key={itemId}
                text={itemName}
                style={{
                  marginTop:
                    checkboxes.length === 0 && accumulator.length === 0 ? 8 : 0
                }}
                value={this.containsValue(itemId)}
                onChange={() => {
                  this.props.onChange(item);
                }}
                disabled={disablecheckBox}
              />
              {this.props.showDescriptions && item.description ? (
                <Image
                  className={css.help}
                  key={item.id + '-help'}
                  style={{
                    marginBottom:
                      checkboxes.length === 0 && accumulator.length === 0
                        ? 0
                        : 8
                  }}
                  src={IconPath.get('Application_Help')}
                  title={item.description}
                />
              ) : null}
            </div>
          );
        }

        return accumulator;
      }, [])
    );

    return checkboxes;
  };

  handleChange = enteredText => {
    this.setState({
      displayText: enteredText,
      openCategories: enteredText === '' ? [] : this.getCategoryValues()
    });
  };

  render() {
    return (
      <Fragment>
        <TriggerField
          className={css.search}
          customElement={
            <Image
              style={{ position: 'absolute', top: '-4px', left: '-6px' }}
              src={IconPath.get('Action_Search')}
            />
          }
          onChange={this.handleChange}
          value={this.state.displayText}
        />
        <div className={css.wrap} style={this.props.style}>
          {this.props.subItemsField
            ? this.getCategories()
            : this.getItems(this.props.items)}
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(MultiCheckBox);
