import * as jstz from 'jstimezonedetect';
import { GET, getUrl, POST } from 'src/services/ApiService';
import * as core from 'cw-ui-core';

const NewsService = () => {
  const getAllNews = async (
    searchuuid,
    page,
    pageSize,
    postType,
    postRole,
    postProduct,
    postIndustry,
    searchText
  ) => {
    const apiUrl = getUrl('home', '/news');
    const state = core.Store().getState();
    const timeZone = jstz.determine().name();
    const locale = core.getLocale(state.module);
    const searchTextQuery = searchText ? `&searchText=${searchText}` : '';
    return await GET(
      `${apiUrl}/posts/all?searchuuid=${searchuuid}&timeZone=${timeZone}&locale=${locale}&pageSize=${pageSize}&page=${page}&postType=${postType}&postRole=${postRole}&postProduct=${postProduct}&postIndustry=${postIndustry}${searchTextQuery}`,
      true,
      '1.0'
    );
  };

  const getBookmarkedNews = async (page, pageSize) => {
    const apiUrl = getUrl('home', '/news');
    return await GET(
      `${apiUrl}/posts/bookmarks?pageSize=${pageSize}&page=${page}`,
      true,
      '1.0'
    );
  };

  const getNewsPostCriteria = () => {
    const apiUrl = getUrl('home', '/news');
    return GET(`${apiUrl}/posts/criteria`);
  };

  const likePost = async postId => {
    const apiUrl = getUrl('home', '/news');
    return POST(`${apiUrl}/posts/${postId}/like`, null, '1.0', false);
  };

  const unlikePost = async postId => {
    const apiUrl = getUrl('home', '/news');
    return POST(`${apiUrl}/posts/${postId}/unlike`, null, '1.0', false);
  };

  const bookmarkPost = async postId => {
    const apiUrl = getUrl('home', '/news');
    return POST(`${apiUrl}/posts/${postId}/bookmark`, null, '1.0', false);
  };

  const unBookmarkPost = async postId => {
    const apiUrl = getUrl('home', '/news');
    return POST(`${apiUrl}/posts/${postId}/unbookmark`, null, '1.0', false);
  };

  return {
    getAllNews,
    getBookmarkedNews,
    getNewsPostCriteria,
    likePost,
    unlikePost,
    bookmarkPost,
    unBookmarkPost
  };
};

export default NewsService();
