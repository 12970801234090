import React, { useState } from 'react';
import * as core from 'cw-ui-core';
import { onSortClick } from 'src/services/Tickets/KnownIssuesService';
import cx from 'classnames';
import flexCss from 'src/styles/flex.scss';
import css from 'src/components/PartnerSupportPage/KnownIssues/KnownIssues.scss';

const KnownIssuesListHeaderWithSorting = props => {
  const [isSort, setIsSort] = useState(false);
  const iconPath =
    props.sortedColumnCheck &&
    props.sortedColumnCheck[props.columnOrder] &&
    isSort
      ? 'Action_CollapseUp'
      : 'Action_ExpandDown';

  return (
    <core.Flexbox
      className={cx(css.headerItem, props.customClassName)}
      onClick={() => {
        if (props.isReady) {
          if (
            props.sortedColumnCheck &&
            !props.sortedColumnCheck[props.columnOrder]
          ) {
            setIsSort(true);
          } else {
            setIsSort(!isSort);
          }

          onSortClick(
            props.columnOrder,
            props.sortColumn,
            props.sortedColumnCheck &&
              !props.sortedColumnCheck[props.columnOrder]
              ? false
              : isSort
          );
        }
      }}
    >
      <core.Label
        automationName={props.automationName}
        className={cx(css.headerItem, flexCss.one)}
        value={props.name}
      />
      {props.sortedColumnCheck &&
        props.sortedColumnCheck[props.columnOrder] && (
          <core.Image
            className={css.actionIcon}
            src={core.IconPath.get(iconPath)}
          />
        )}
    </core.Flexbox>
  );
};

export default KnownIssuesListHeaderWithSorting;
