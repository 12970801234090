import React, { Fragment, useState, useEffect } from 'react';
import {
  Flexbox,
  Image,
  IconPath,
  TextArea,
  ButtonStandard,
  Placeholder,
  FormFieldContainer,
  FileUpload,
  MobileLayoutHOC,
  formatMessage
} from 'cw-ui-core';
import { injectIntl } from 'react-intl';

import css from './EditSupportTicketDrawerSummarySection.scss';
import TicketAttachment from './TicketAttachment';
import { EDIT_VIEW_TICKET_DRAWER_ID } from '../EditSupportTicketDrawer';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import Locale from 'src/Locale';

const SupportTicketDrawerSummarySection = ({
  isMobileLayout,
  ticket,
  boardId,
  isReady,
  isArchivedTicket,
  onTicketBoardChange,
  disableSendTextBox,
  ...props
}) => {
  let textMessageField;

  const [textAreaRows, setTextAreaRows] = useState(1);

  useEffect(() => {
    const doWork = async () => {
      if (boardId > 0) {
        await onTicketBoardChange(boardId);
      }
    };

    doWork().then();
  }, [boardId, onTicketBoardChange]);

  const getAttachments = () => {
    if (!props.attachments || props.attachments.length <= 0) {
      return null;
    }

    return (
      <div className={css.attachments}>
        {props.attachments.map((attachment, index) => {
          const onClose = () => {
            // copy existing array
            let newValue = props.attachments.slice(0);
            // remove from index
            newValue.splice(index, 1);

            props.onAttachmentChanged(newValue);
          };

          return (
            <TicketAttachment
              key={attachment.name + index}
              className={css.attachment}
              file={attachment}
              onCloseClicked={onClose}
            />
          );
        })}
      </div>
    );
  };

  const onFileChanged = files => {
    let newValue;

    if (!props.attachments) {
      newValue = [];
    } else {
      newValue = props.attachments.slice(0);
    }

    for (let i = 0; i < files.length; i++) {
      newValue.push(files[i]);
    }

    props.onAttachmentChanged(newValue);
  };

  const getSendButton = () => {
    const onButtonClicked = async () => {
      await props.onSendNote();
      setTextAreaRows(1);
    };

    const button = () => (
      <ButtonStandard
        cwId="sendMessage"
        automationName="sendMessage"
        title={formatMessage(Locale.send)}
        value={formatMessage(Locale.send)}
        isSmall={isMobileLayout}
        onClick={props.canUpdateMessage ? onButtonClicked : null}
        disabled={!props.canUpdateMessage}
      />
    );
    const LoadingButton = WithButtonLoadingIndicatorHOC(button);
    return isMobileLayout ? (
      <LoadingButton
        isBusy={props.isBusy}
        wrapperStyle={{ height: '36px', width: '68px' }}
      />
    ) : (
      <LoadingButton
        isBusy={props.isBusy}
        wrapperStyle={{ height: '44px', width: '88px' }}
      />
    );
  };

  const getTextMessageField = () => {
    return (
      <div className={css.sendMessageTextFieldContainer}>
        <FormFieldContainer
          path={['text']}
          onAfterChange={updateTicketMessageFieldHeight}
          screenKey={EDIT_VIEW_TICKET_DRAWER_ID}
        >
          <TextArea
            ref={input => (textMessageField = input)}
            placeholder={
              disableSendTextBox
                ? formatMessage(Locale.send_message_disable)
                : formatMessage(Locale.send_message)
            }
            className={css.sendMessageTextField}
            rows={textAreaRows}
            disabled={disableSendTextBox}
          />
        </FormFieldContainer>
        <FileUpload
          automationName="onFileChanged"
          onFileChanged={onFileChanged}
          disabled={disableSendTextBox}
          multiple
        >
          <Image
            cwId="addAttachmentIcon"
            automationName="addAttachmentIcon"
            isInlineSvg
            src={IconPath.get('Action_Attach')}
            alt="Attach File"
            className={css.addAttachmentIcon}
          />
        </FileUpload>
      </div>
    );
  };

  const getMessageContainer = () => {
    return isMobileLayout ? (
      <div className={css.sendMessageContainerMobile}>
        {getTextMessageField()}
        {getAttachments()}
        <Flexbox className={css.sendMessageButtonContainerMobile}>
          {getSendButton()}
        </Flexbox>
      </div>
    ) : (
      <Fragment>
        <Flexbox verticleAlign className={css.sendMessageContainer}>
          {getTextMessageField()}
          <div className={css.buttonContainer}>{getSendButton()}</div>
        </Flexbox>
        {getAttachments()}
      </Fragment>
    );
  };

  const LoadingComponent = () => {
    return <Placeholder className={css.placeholder} />;
  };

  const formatDate = date => {
    const { formatDate } = props.intl;
    return formatDate(date, {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    });
  };

  const updateTicketMessageFieldHeight = () => {
    const lineheight = 20;
    const element = textMessageField.textArea.textarea;
    const computed = window.getComputedStyle(element);

    const previousRows = element.rows;
    element.rows = 1;
    const height =
      element.scrollHeight -
      parseInt(computed.getPropertyValue('padding-top'), 10) -
      parseInt(computed.getPropertyValue('padding-bottom'), 10);
    const currentRows = ~~(height / lineheight);

    if (currentRows === previousRows) {
      element.rows = currentRows;
    }

    setTextAreaRows(currentRows);
  };

  const { formatTime } = props.intl;
  return (
    <section
      className={isMobileLayout ? css.mainContainerMobile : css.mainContainer}
    >
      <div
        className={
          isMobileLayout ? css.summaryContentMobile : css.summaryContent
        }
      >
        <div className={css.summaryContainer}>
          {isReady ? (
            <span className={css.sectionSummary}>{ticket.summary}</span>
          ) : (
            <Placeholder className={css.summaryPlaceholder} />
          )}
        </div>
        <div
          className={
            isMobileLayout ? css.columnContainerMobile : css.columnContainer
          }
        >
          <Flexbox className={css.firstColumn} column>
            {isReady ? (
              <label className={css.label}>
                {formatMessage(Locale.submitted_at, {
                  date: formatDate(ticket.dateEntered, {}),
                  time: formatTime(ticket.dateEntered)
                })}
              </label>
            ) : (
              <Placeholder className={css.datePlaceholder} />
            )}
            <div
              className={
                isMobileLayout
                  ? css.mobileLabelValueFieldContainer
                  : css.bottomLabelValueFieldContainer
              }
            >
              <label className={css.label}>
                {formatMessage(Locale.contact) + ':'}
              </label>
              {isReady ? (
                <label className={css.fieldValue}>
                  {ticket.contact ? ticket.contact.name : ''}
                </label>
              ) : (
                LoadingComponent()
              )}
              {!isArchivedTicket&&<Image
                cwId="contactEditIcon"
                automationName="contactEditIcon"
                isInlineSvg
                src={IconPath.get('Action_Edit')}
                alt="Edit Contact"
                className={
                  props.canUpdateContact ? css.editIcon : css.editIconDisabled
                }
                onClick={
                  props.canUpdateContact
                    ? props.openUpdateContactDialog
                    : undefined
                }
                disabled={!props.canUpdateContact}
              />}
            </div>
          </Flexbox>
          <Flexbox
            className={
              isMobileLayout ? css.secondColumnMobile : css.secondColumn
            }
            column
          >
            <div
              className={
                isMobileLayout
                  ? css.mobileLabelValueFieldContainer
                  : css.labelValueFieldContainer
              }
            >
              <label className={css.label}>
                {formatMessage(Locale.status) + ': '}
              </label>
              {isReady ? (
                <label className={css.fieldValue}>
                  {ticket.status ? ticket.status.name : ''}
                </label>
              ) : (
                LoadingComponent()
              )}
              {!isArchivedTicket&&<Image
                cwId="statusEditIcon"
                automationName="statusEditIcon"
                isInlineSvg
                src={IconPath.get('Action_Edit')}
                alt="Edit Status"
                className={
                  props.editStatusReady ? css.editIcon : css.editIconDisabled
                }
                onClick={
                  props.editStatusReady
                    ? props.openUpdateStatusDialog
                    : undefined
                }
                disabled={!props.editStatusReady}
              />}
            </div>
            <Flexbox
              className={
                isMobileLayout
                  ? css.mobileLabelValueFieldContainer
                  : css.bottomLabelValueFieldContainer
              }
            >
              <label className={css.label}>
                {formatMessage(Locale.technician) + ':'}
              </label>
              {isReady ? (
                <label className={css.fieldValue}>
                  {ticket.owner && ticket.owner.name
                    ? ticket.owner.name
                    : formatMessage(Locale.unassigned)}
                </label>
              ) : (
                LoadingComponent()
              )}
            </Flexbox>
          </Flexbox>
        </div>
        {!isArchivedTicket&&getMessageContainer()}
      </div>
    </section>
  );
};

export default injectIntl(MobileLayoutHOC(SupportTicketDrawerSummarySection));
