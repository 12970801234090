import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const useWindowResized = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  const [debouncedHandleResize] = useDebouncedCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    debouncedHandleResize();
    return () => window.removeEventListener('resize', debouncedHandleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedHandleResize]);

  return windowSize;
};
