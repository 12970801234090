import React from 'react';
import * as core from 'cw-ui-core';
import { injectIntl } from 'react-intl';
import css from 'src/components/Admin/NewsPage/Banner/BannerPreviewDialog/BannerPreviewDialog.scss';
import Locale from 'src/Locale';
import SuccessTeamContainer from 'src/components/HomePage/SuccessTeamContainer';

const BannerPreviewDialog = props => {

  return (
    <core.Dialog
      automationName="preview"
      className={css.dialog}
      includeCloseIcon
      onCloseIconClicked={props.onClose}
    >
      <core.Flexbox className={css.wrap} column>
        <div className={css.content}>
          {props.secondBanner ? (
            <core.FileImage
              className={css.bannerImage}
              file={props.secondBanner}
            />
          ) : (
            <core.Image
              isInlineSVG={false}
              src={props.secondBannerUrl}
              className={css.bannerImage}
            />
          )}
          <div className={css.innerContent}>
            <span className={css.bannerWelcome}>
              {core.formatMessage(Locale.welcome, {
                firstName: props.firstName
              })}
            </span>
            <core.Label isHTML className={css.header} value={props.header} />
            <core.Label
              grey2
              isHTML
              className={css.subHeader}
              value={props.subHeader}
            />
            <core.Flexbox className={css.innerFlex} row>
              <div className={css.bannerButtonWrapper}>
                {props.buttonText && props.toggle && (
                  <core.ButtonStandard
                    automationName="preview_banner"
                    className={css.createBtn}
                    isSmall
                    value={props.buttonText}
                    style={{
                      borderColor: props.primaryColor,
                      backgroundColor: props.primaryColor,
                      color: props.primaryButtonTextColor
                    }}
                    onClick={props.onCTATestUrlClick}
                  />
                )}
              </div>
              <SuccessTeamContainer
                disabled
                className={css.successManagerBanner}
              />
            </core.Flexbox>
          </div>
        </div>
      </core.Flexbox>
    </core.Dialog>
  );
};

export default injectIntl(BannerPreviewDialog);