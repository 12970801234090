import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import SuccessTeamBanner from './SuccessTeamBanner';
import {
  fetchSuccessManager,
  fetchSuccessManagerPhoto,
  Steps
} from 'src/services/SuccessManagerService';
import {
  fetchAccountManager,
  fetchAccountManagerPhoto
} from 'src/services/AccountManagerService';
import { PARTNER_SUCCESS_MANAGER_DRAWER } from 'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerSuccessDrawer/PartnerSuccessManagerDrawerContainer';
import {
  fetchPartnerSalesExecutive,
  fetchPartnerSalesExecutivePhoto
} from 'src/services/PartnerSalesExecutiveService';
import {
  fetchPartnerDevelopmentManager,
  fetchPartnerDevelopmentManagerPhoto
} from 'src/services/PartnerDevelopmentManagerService';

const mapStateToProps = state => {
  const successManager = core.getModuleCache(state.module, ['successManager']);
  const successManagerPhoto = core.getModuleCache(state.module, [
    'successManagerPhoto'
  ]);
  const successManagerPhotoUrl = successManagerPhoto
    ? URL.createObjectURL(successManagerPhoto)
    : undefined;
  const accountManager = core.getModuleCache(state.module, ['accountManager']);
  const accountManagerPhoto = core.getModuleCache(state.module, [
    'accountManagerPhoto'
  ]);
  const accountManagerPhotoUrl = accountManagerPhoto
    ? URL.createObjectURL(accountManagerPhoto)
    : undefined;
  const partnerSalesExecutive = core.getModuleCache(state.module, [
    'partnerSalesExecutive'
  ]);
  const partnerDevelopmentManager = core.getModuleCache(state.module, [
    'partnerDevelopmentManager'
  ]);
  return {
    successManager,
    successManagerPhotoUrl,
    successManagerInitials:
      successManager &&
      successManager.get('fullName') &&
      !successManagerPhotoUrl
        ? core.getInitials(successManager.get('fullName'))
        : null,
    accountManager,
    accountManagerPhotoUrl,
    accountManagerInitials:
      accountManager &&
      accountManager.get('fullName') &&
      !accountManagerPhotoUrl
        ? core.getInitials(accountManager.get('fullName'))
        : null,
    partnerSalesExecutive,
    partnerDevelopmentManager
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSuccessTeam: async () => {
      var successManagerResult = await fetchSuccessManager();
      if (successManagerResult !== undefined) {
        fetchSuccessManagerPhoto();
      }

      var accountManagerResult = await fetchAccountManager();
      if (accountManagerResult !== undefined) {
        fetchAccountManagerPhoto();
      }

      var partnerSalesExecutiveResult = await fetchPartnerSalesExecutive();
      if (partnerSalesExecutiveResult !== undefined) {
        fetchPartnerSalesExecutivePhoto();
      }

      var partnerDevelopmentManagerResult = await fetchPartnerDevelopmentManager();
      if (partnerDevelopmentManagerResult !== undefined) {
        fetchPartnerDevelopmentManagerPhoto();
      }
    },
    showPartnerSuccessManagerDrawer: () => {
      dispatch(
        core.showDrawer(PARTNER_SUCCESS_MANAGER_DRAWER, {
          step: Steps.Step_1
        })
      );
    }
  };
};

const SuccessTeamContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessTeamBanner);

export default SuccessTeamContainer;
