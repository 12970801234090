import { getUrl, GET, GETFILEDOWNLOAD } from 'src/services/ApiService';

const CompanyService = () => {
  const getBaseUrl = () => getUrl('home', '/company');

  return {
    getAccountManagerPhoto: async () => {
      return GETFILEDOWNLOAD(
        `${getBaseUrl()}/companies/teams/accountManager/photo`
      );
    },
    getSuccessManagerPhoto: async () => {
      return GETFILEDOWNLOAD(
        `${getBaseUrl()}/companies/teams/successManager/photo`
      );
    },
    getPartnerSalesExecutivePhoto: async () => {
      return GETFILEDOWNLOAD(
        `${getBaseUrl()}/companies/teams/partnerSalesExecutive/photo`
      );
    },
    getPartnerDevelopmentManagerPhoto: async () => {
      return GETFILEDOWNLOAD(
        `${getBaseUrl()}/companies/teams/partnerDevelopmentManager/photo`
      );
    },
    getAccountManager: async () => {
      return GET(`${getBaseUrl()}/companies/teams/accountManager`);
    },
    getSuccessManager: async () => {
      return GET(`${getBaseUrl()}/companies/teams/successManager`);
    },
    getPartnerSalesExecutive: async () => {
      return GET(`${getBaseUrl()}/companies/teams/partnerSalesExecutive`);
    },
    getPartnerDevelopmentManager: async () => {
      return GET(`${getBaseUrl()}/companies/teams/partnerDevelopmentManager`);
    },
    getContacts: (
      conditions = '',
      childConditions = '',
      orderBy = '',
      page = 1,
      pageSize = 10
    ) => {
      return GET(
        `${getBaseUrl()}/contacts?conditions=${conditions}&childConditions=${childConditions}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}`
      );
    },
    getContactId: userId => {
      return GET(`${getBaseUrl()}/contacts/${userId}`);
    },
    lookupCompany: async () => {
      return GET(getBaseUrl());
    }
  };
};

export default CompanyService();
