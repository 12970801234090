import BaseCartItem from './BaseCartItem';

class IdentifyCartItem extends BaseCartItem {
  get isIdentify() {
    return true;
  }

  get sku() {
    return this._buyerCartItem.productPlan;
  }

  get productImageUrl() {
    return '/images/products/identify.svg';
  }

  get allowQuantityChange() {
    return false;
  }

  isForCartPrice(cartPrice) {
    return (
      this.instanceId === cartPrice.instanceId && this.sku === cartPrice.product
    );
  }
}

export default IdentifyCartItem;
