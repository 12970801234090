import React from 'react';
import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import loadConfiguration from 'src/services/ConfigurationsService';
import NotFoundPage from 'src/components/NotFoundPage/NotFoundPage';

// TODO: reference this file for the ondismisshoc
/**
 * This HoC is used to get mobile/desktop layout calculated from media query breakpoints
 */
const LoadConfigurationHOC = WrappedComponent => {

  class LoadConfigurationComponent extends React.Component {
    componentDidMount() {
      this.props.onLoadConfigurationHOCDidMount();
    }

    render() {
      if (this.props.notfound) {
        return <NotFoundPage />;
      }

      if (!this.props.isReady) {
        return <core.Loader />; // Do not render if we do not have portal configuration data loaded on store
      }

      return (
        <WrappedComponent
          {...this.props}
        />
      );
    }
  }

;

  const mapStateToProps = state => {
    const configData = core.getModuleCache(state.module, ['home_configuration']);

    return {
      notfound: core.getScreenCustom(state.screen, ['notfound']),
      isReady: configData ? true : false, // only render when we have config data
    };
  };

  const mapDispatchToProps = () => {
    return {
      onLoadConfigurationHOCDidMount: () => {
        core.runSaga(loadConfiguration);
      }
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(LoadConfigurationComponent);
};

export default LoadConfigurationHOC;
