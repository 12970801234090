import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import ResourceCard from 'src/components/Drawer/PartnerSuccessManagerDrawer/ResourceCard';
import Locale from 'src/Locale';
import {
  PARTNER_SUCCESS_MANAGER_DRAWER,
  PARTNER_SALES_EXECUTIVE_TITLE,
  PathValues
} from './PartnerSuccessDrawer/PartnerSuccessManagerDrawerContainer';
import { Steps } from 'src/services/SuccessManagerService';
import {
  FEATURES,
  isFeatureEnabled
} from 'src/services/Features/FeaturesService';

const mapStateToProps = state => {
  const partnerSalesExecutive = core.getModuleCache(state.module, ['partnerSalesExecutive']);
  const partnerSalesExecutivePhoto = core.getModuleCache(state.module, [
    'partnerSalesExecutivePhoto'
  ]);
  const showFullPSEBio = core.getScreenData(
    state.screen,
    'showFullPSEBio',
    PARTNER_SUCCESS_MANAGER_DRAWER
  );

  return {
    name: partnerSalesExecutive.get('fullName'),
    description: core.formatMessage(Locale.partner_sales_executive_description),
    initials:
    partnerSalesExecutive && partnerSalesExecutive.get('fullName')
        ? core.getInitials(partnerSalesExecutive.get('fullName'))
        : null,
    phone: partnerSalesExecutive.get('phone'),
    photo: partnerSalesExecutivePhoto,
    photoUrl: partnerSalesExecutivePhoto
      ? URL.createObjectURL(partnerSalesExecutivePhoto)
      : undefined,
    aboutMe: partnerSalesExecutive.get('notes'),
    title: core.formatMessage(Locale.partner_sales_executive),
    email: partnerSalesExecutive.get('defaultEmail'),
    showFullBio: showFullPSEBio,
    isSalesForceCaseManagement: isFeatureEnabled(FEATURES.HOME_CASE_MANAGEMENT)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClick: () => {
      const state = core.Store().getState();
      const partnerSalesExecutivePhoto = core.getModuleCache(state.module, [
        'partnerSalesExecutivePhoto'
      ]);

      // Clear form values when changing card
      Object.keys(PathValues).forEach(path => {
        dispatch(
          core.setScreenData(
            PathValues[path],
            undefined,
            PARTNER_SUCCESS_MANAGER_DRAWER
          )
        );
      });

      const partnerSalesExecutivePhotoUrl = partnerSalesExecutivePhoto
        ? URL.createObjectURL(partnerSalesExecutivePhoto)
        : undefined;
      const resourceSelected = {
        type: PARTNER_SALES_EXECUTIVE_TITLE,
        member: core.getModuleCache(state.module, ['partnerSalesExecutive']).toJS(),
        photoUrl: partnerSalesExecutivePhotoUrl,
        photoAltText:
          core.formatMessage(Locale.my_account_manager) +
          ' ' +
          core.formatMessage(Locale.photo),
        tooltip: core.formatMessage(Locale.my_account_manager)
      };
      dispatch(
        core.setScreenData(
          'resourceSelected',
          resourceSelected,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
      dispatch(
        core.setScreenData('step', Steps.Step_2, PARTNER_SUCCESS_MANAGER_DRAWER)
      );
    },
    onShowMoreClick: () => {
      dispatch(
        core.setScreenData(
          'showFullPSEBio',
          true,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
    },
    onShowLessClick: () => {
      dispatch(
        core.setScreenData(
          'showFullPSEBio',
          false,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
    }
  };
};

const PartnerSalesExecutiveResourceCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceCard);

export default PartnerSalesExecutiveResourceCardContainer;
