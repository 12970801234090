import { connect } from 'react-redux';
import {
  setScreenData,
  getScreenData,
  Store,
  setErrorScreenMessage,
  getScreenCustom
} from 'cw-ui-core';

import TicketApi from 'src/services/Api/HomeApi/TicketService';
import { downloadDocument } from 'src/services/Tickets/TicketsService';
import SupportTicketNotesSection from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/SupportTicketNotes/SupportTicketNotesSection';
import { EDIT_VIEW_TICKET_DRAWER_ID } from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawer';

const loadNotes = async () => {
  const dispatch = Store().dispatch;
  const state = Store().getState();
  const ticketId = getScreenData(
    state.screen,
    ['ticket', 'id'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );
  const isArchivedTicket = getScreenCustom(
    state.screen, ['showArchivedTicketDrawer']
  );

  if (ticketId > 0) {
    const result = isArchivedTicket ? await TicketApi.getArchivedTicketsNotesAndAttachments(ticketId):
      await TicketApi.getNotesAndAttachments(ticketId);    

    if (!result.isSuccess) {
      dispatch(
        setErrorScreenMessage(
          result.error,
          false,
          result.errorMessages,
          EDIT_VIEW_TICKET_DRAWER_ID
        )
      );
    } else {
      const notes = result.response;
      dispatch(setScreenData(['notes'], notes, EDIT_VIEW_TICKET_DRAWER_ID));
    }
  }
};

const mapStateToProps = state => {
  const notes = getScreenData(
    state.screen,
    ['notes'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );
  const notesReady = notes ? true : false;

  const contactIsReady = getScreenData(state.screen, [
    'usersContact',
    'isReady'
  ]);
  const contactId = getScreenData(state.screen, [
    'usersContact',
    'contact',
    'id'
  ]);
  let contactReady = false;
  if (contactId || contactIsReady) contactReady = true;

  const ticketId = getScreenData(
    state.screen,
    ['ticket', 'id'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );

  return {
    notes: notes ? notes.toJS() : null,
    contactId,
    ticketId,
    notesReady,
    contactReady
  };
};

const mapDispatchToProps = () => {
  return {
    loadNotes,
    downloadDocumentOnClick: async (documentId, fileName) => {
      const state = Store().getState();
      const ticketId = getScreenData(
        state.screen,
        ['ticket', 'id'],
        EDIT_VIEW_TICKET_DRAWER_ID
      );
      await downloadDocument(
        ticketId,
        documentId,
        fileName,
        EDIT_VIEW_TICKET_DRAWER_ID
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportTicketNotesSection);
