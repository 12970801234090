import * as core from 'cw-ui-core';
import UserProfileService from 'src/services/Api/UserManagementApi/UserProfileService';
import Locale from 'src/Locale';
import Routes from 'src/services/Routes';

export const preloadGlobalPreferenceUrl = async () => {
  const state = core.Store().getState();
  if (core.getModuleCache(state.module, ['marketingRedirectLink'])) {
    return;
  }

  const result = await UserProfileService.getMarketingUrl();
  if (result.isSuccess) {
    const dispatch = core.Store().dispatch;
    dispatch(
      core.setModuleCache(['marketingRedirectLink'], result.response.link)
    );
  }
};

const getGlobalPreferenceUrl = async () => {
  await preloadGlobalPreferenceUrl();

  const state = core.Store().getState();
  const marketingLink = core.getModuleCache(state.module, [
    'marketingRedirectLink'
  ]);
  return marketingLink;
};

export const openGlobalPreferenceTemplate = async openMethod => {
  const globalPreferenceLink = await getGlobalPreferenceUrl();
  if (globalPreferenceLink) {
    openMethod(globalPreferenceLink);
  } else {
    const dispatch = core.Store().dispatch;
    const state = core.Store().getState();
    const userEmail = core.getUserEmail(state.oidc);
    dispatch(
      core.setErrorScreenMessage(
        core.formatMessage(Locale.user_not_found_in_one_trust, {
          email: userEmail
        }),
        true,
        null,
        Routes.ONE_TRUST_NOTIFICATION.id
      )
    );
  }
};

export const openGlobalPreferenceHere = async () => {
  openGlobalPreferenceTemplate(globalPreferenceLink => {
    window.location = globalPreferenceLink;
  });
};

export const openGlobalPreferenceInNewTab = async () => {
  openGlobalPreferenceTemplate(globalPreferenceLink => {
    window.open(globalPreferenceLink, '_blank');
  });
};
