import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon.scss';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import cx from 'classnames';
import Locale from 'src/Locale';

export const PlanCardButton = ({
  disabled,
  buttonText,
  onCardButtonClick,
  automationName,
  planCardButtonClassName
}) => {
  return (
    <core.ButtonLight
      disabled={disabled}
      automationName={automationName}
      className={cx(css.planCardButton, planCardButtonClassName)}
      value={buttonText}
      onClick={onCardButtonClick}
    />
  );
};

export const PlanCardButtonWithLoadingIndicator = WithButtonLoadingIndicatorHOC(
  PlanCardButton
);

export const ProductHeader = props => {
  return (
    <>
      <core.ScreenMessagePanelContainer screenKey={props.drawerId} />
      <core.Flexbox className={css.productName}>
        <core.Image
          className={cx(props.productIconClassName, css.productIconHeader)}
          src={props.productImageSrc}
          alt={core.formatMessage(Locale.product)}
        />
        <core.Label className={css.textAlignLeft} value={props.productName} />
      </core.Flexbox>
      <core.Label
        className={cx(css.productDescription, props.descriptionClassName)}
        value={props.productDescription}
      />
      <core.Label
        automationName="productHeaderLearnMore"
        className={cx(css.learnMore, props.learnMoreClassName)}
        value={props.productLearnMoreText}
        isLink
        onClick={props.onUniversityLinkClick}
      />
      <div className={props.dividerLineClassName} />
    </>
  );
};
