import { GET, POST, getUrl } from 'src/services/ApiService';

const InstanceLicensesService = () => {
  return {
    getProductInfoByIntanceId: instanceId => {
      const apiUrl = getUrl('home', '/instancelicenses');
      return GET(`${apiUrl}/${instanceId}`);
    },
    provisionLicenseInfo: provisionLicenseInfo => {
      const apiUrl = getUrl('home', '/instancelicenses');
      return POST(`${apiUrl}/add`, provisionLicenseInfo);
    },
    createQuoteTicket: createTicketRequest => {
      const apiUrl = getUrl('home', '/instancelicenses');
      return POST(`${apiUrl}/createTicket`, createTicketRequest);
    }
  };
};

export default InstanceLicensesService();
