import {
  Dialog,
  ScreenMessagePanelContainer,
  Label,
  ButtonStandard,
  formatMessage,
  MobileLayoutHOC,
  Image,
  IconPath,
  Flexbox
} from 'cw-ui-core';
import css from 'src/components/Drawer/UserProfileDrawer/CompanyAccountSetting/SupportEnabledDialog/SupportEnabledDialog.scss';
import Locale from 'src/Locale';
import React from 'react';
import svgCss from 'src/styles/svg.scss';
import cx from 'classnames';

export const SUPPORT_ENABLED_DIALOG = 'SUPPORT_ENABLED_DIALOG';

const SupportEnabledDialog = props => {
  const dialogButtons = (
    <ButtonStandard
      key="close"
      automationName="close"
      className={css.closeButton}
      value={formatMessage(Locale.close)}
      onClick={props.onClose}
    />
  );

  return (
    <Dialog
      dialogId={SUPPORT_ENABLED_DIALOG}
      header={formatMessage(Locale.copy_share)}
      buttons={dialogButtons}
      className={css.container}
    >
      <ScreenMessagePanelContainer
        className={css.screenMessage}
        screenKey={SUPPORT_ENABLED_DIALOG}
      />
      <Label
        className={css.headertext}
        value={formatMessage(Locale.support_enabled)}
      />
      <Flexbox className={css.description}>
        <Label className={css.password} value={props.password} />
        <Image
          automationName="copyClipboard"
          className={cx(css.copyImg, svgCss.actionState)}
          src={IconPath.get('Action_Copy')}
          onClick={props.onCopyPassword}
          alt={formatMessage(Locale.copy)}
          title={formatMessage(Locale.copy_to_clipboard)}
        />
      </Flexbox>
    </Dialog>
  );
};

export default MobileLayoutHOC(SupportEnabledDialog);
