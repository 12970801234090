import { connect } from 'react-redux';
import SecurityBulletinItem from 'src/components/Drawer/SecurityBulletin/SecurityBulletinItem';
import * as core from 'cw-ui-core';
import { SECURITY_BULLETIN_DRAWER } from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawerContainer';
import { getAvatarPhoto } from 'src/services/Avatar/AvatarService';

const mapStateToProps = state => {
  const securityBulletinCheck = core.getScreenData(
    state.screen,
    [],
    SECURITY_BULLETIN_DRAWER
  );
  const securityBulletinItem = securityBulletinCheck
    ? securityBulletinCheck.toJS()
    : null;
  let previewSecurityBulletinItem = securityBulletinItem;
  let authorImageUrl = undefined;
  if (previewSecurityBulletinItem) {
    previewSecurityBulletinItem.content =
      previewSecurityBulletinItem.content &&
      previewSecurityBulletinItem.content.replace(/\n/g, '<br />');
    authorImageUrl =
      previewSecurityBulletinItem.authorImageUrl &&
      core.getModuleCache(state.module, [
        'avatar',
        'photos',
        previewSecurityBulletinItem.authorImageUrl
      ]);
  }

  return {
    securityBulletinItem: previewSecurityBulletinItem,
    authorImageUrl:
      authorImageUrl === 'useInitials' ? undefined : authorImageUrl
  };
};

const mapDispatchToProps = () => {
  return {
    onSecurityBulletinItemLoaded: async securityBulletinItem => {
      if (securityBulletinItem && securityBulletinItem.originatorImageUrl) {
        return await getAvatarPhoto(securityBulletinItem.originatorImageUrl);
      }
    }
  };
};

const SecurityBulletinItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityBulletinItem);

export default SecurityBulletinItemContainer;
