import BaseCartItem from './BaseCartItem';

class UniteCartItem extends BaseCartItem {
  get description() {
    return this._buyerCartItem.product;
  }

  get productImageUrl() {
    return '/images/products/unite.svg';
  }

  isForCartPrice(cartPrice) {
    return (
      this.instanceId === cartPrice.instanceId &&
      this.buyerCart.find(c => c.productPlan === cartPrice.product)
    );
  }
}

export default UniteCartItem;
