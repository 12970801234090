import * as core from 'cw-ui-core';
import WalletService from 'src/services/Api/BillingApi/WalletService';
import TokenService from 'src/services/Api/BillingApi/TokenService';
import Routes from 'src/services/Routes';
import Locale from 'src/Locale';
import { getCountryCurrency } from 'src/services/Partners/PartnersService';

export const ADDNEWCARD_ID = 'addNewCard';
export const amexNotAcceptedCurrencies = ['AUD', 'CAD'];

const getTokenData = async () => {
  const result = await TokenService.create();
  if (!result.isSuccess) {
    const error = 'Failed to get token data for payfabric. ' + result.error;
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
    return;
  }

  return result.response.msg;
};

const getPayFabricReturnUrl = () => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ':' + window.location.port : ''
  }/payfabricredirect.html`;
};

const getPayFabricUrlCreate = async () => {
  const state = core.Store().getState();
  const tokenData = await getTokenData();
  if (!tokenData) {
    return;
  }

  const zip = core.getModuleCache(state.module, ['partner', 'zip']) || '';
  const phone =
    core.getModuleCache(state.module, ['partner', 'phoneNumber']) || '';
  const payFabricUrl = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'billingInfo',
    'payfabric',
    'url'
  ]);
  const payFabricCustomerId = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'billingInfo',
    'payfabric',
    'customerId'
  ]);
  const email = core.getModuleCache(state.module, ['user', 'email']);
  return `${payFabricUrl}/Payment/Web/Wallet/Create?customer=${payFabricCustomerId}&Zip=${encodeURIComponent(
    zip
  )}&Phone=${encodeURIComponent(phone)}&Email=${encodeURIComponent(
    email
  )}&tender=CreditCard&token=${tokenData}&returnuri=${encodeURIComponent(
    getPayFabricReturnUrl()
  )}?isNew=true`;
};

const getPayFabricUrlEdit = async cardId => {
  const state = core.Store().getState();
  const tokenData = await getTokenData();
  if (!tokenData) {
    return;
  }

  const payFabricUrl = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'billingInfo',
    'payfabric',
    'url'
  ]);
  return `${payFabricUrl}/Payment/Web/Wallet/edit?card=${cardId}&token=${tokenData}&returnuri=${encodeURIComponent(
    getPayFabricReturnUrl()
  )}`;
};

export const getPayFabricUrl = async (cardId, addNewCard) => {
  let payFabricUrl;
  if (addNewCard) {
    payFabricUrl = await getPayFabricUrlCreate();
    core
      .Store()
      .dispatch(
        core.setScreenData(
          [Routes.SHOPPING_CART.id, 'card', ADDNEWCARD_ID, 'payFabricUrl'],
          payFabricUrl
        )
      );
    return;
  }

  payFabricUrl = await getPayFabricUrlEdit(cardId);
  core
    .Store()
    .dispatch(
      core.setScreenData(
        [Routes.SHOPPING_CART.id, 'card', cardId, 'payFabricUrl'],
        payFabricUrl
      )
    );
};

export const setSelectedCard = async selectedCard => {
  if (selectedCard && selectedCard.IsAddNewCard) {
    await getPayFabricUrl(selectedCard.ID, true);
  }

  core
    .Store()
    .dispatch(
      core.setScreenData(
        [Routes.SHOPPING_CART.id, 'selectedCard'],
        selectedCard
      )
    );
};

export const setSelectedCardAfterCancelEdit = editedCardId => {
  const state = core.Store().getState();
  if (editedCardId === ADDNEWCARD_ID) {
    const customerWallet = core.getScreenData(state.screen, [
      Routes.SHOPPING_CART.id,
      'customerWallet'
    ]);
    if (customerWallet) {
      const defaultCard = customerWallet.toJS().find(w => w.IsDefaultCard);
      if (defaultCard) {
        setSelectedCard(defaultCard);
        return;
      }

      if (customerWallet.length > 1) {
        setSelectedCard(customerWallet[0]);
      }
    }
  }
};

const setDefaultSelectedCard = customerWallet => {
  const state = core.Store().getState();
  let selectedCard = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'selectedCard'
  ]);
  if (selectedCard && !selectedCard.getIn(['disabled'])) {
    selectedCard = customerWallet.find(
      w => w.ID === selectedCard.getIn(['ID'])
    );
    return;
  }

  selectedCard = customerWallet.find(w => w.IsDefaultCard);
  if (selectedCard && !selectedCard.disabled) {
    setSelectedCard(selectedCard);
    return;
  }

  if (customerWallet.length > 0) {
    selectedCard = customerWallet.find(w => !w.disabled);
  }

  if (selectedCard) {
    setSelectedCard(selectedCard);
    return;
  }
};

export const setEditedCard = async (editedCard, isEditing) => {
  const state = core.Store().getState();
  isEditing && (await getPayFabricUrl(editedCard.ID, false));
  if (!isEditing) {
    core
      .Store()
      .dispatch(core.removeScreenData([Routes.SHOPPING_CART.id, 'editedCard']));
    return;
  }

  core
    .Store()
    .dispatch(
      core.setScreenData([Routes.SHOPPING_CART.id, 'editedCard'], editedCard)
    );
  const selectedCard = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'selectedCard'
  ]);
  if (
    editedCard &&
    (!selectedCard || selectedCard.getIn(['ID']) !== editedCard.ID)
  ) {
    setSelectedCard(editedCard);
  }
};

const checkForNotAcceptedCurrencies = card => {
  if (!card.Currency) {
    card.disabled = true;
    card.disabledReason = 'billing_currency_not_found';
    return;
  }

  if (
    card.CardName === 'AmericanExpress' &&
    amexNotAcceptedCurrencies.includes(card.Currency)
  ) {
    card.disabled = true;
    card.disabledReason = 'amex_currency_not_accepted';
    return;
  }

  card.disabled = false;
};

const getCreditCardCurrencies = async customerWallet => {
  const dispatch = core.Store().dispatch;
  const result = await Promise.all(
    customerWallet
      .filter(c => !c.IsAddNewCard)
      .map(c => getCountryCurrency(c.Billto.Country))
  );
  result.forEach((r, index) => {
    const card = customerWallet[index];
    if (!r) {
      const error =
        'Failed to get billing currency. ' + (r.error || r.errorMessages);
      core.CwLog.error(error);
      dispatch(core.setErrorScreenMessage(error, false, r.errorMessages));
    } else {
      card.Currency = r;
    }

    checkForNotAcceptedCurrencies(card);
  });
};

export const getCustomerWallet = async () => {
  const dispatch = core.Store().dispatch;
  const result = await WalletService.retrieve();
  if (!result.isSuccess || result.response.status === 'error') {
    const errorMessage = 'Failed to load customer wallet. ';
    const error =
      errorMessage +
      (result.error || result.response.msg);

    core.CwLog.error(error);
    dispatch(core.setErrorScreenMessage(errorMessage, false, result.errorMessages));
    return;
  }

  let customerWallet = result.response.msg;
  if (!customerWallet) {
    customerWallet = [];
  }

  await getCreditCardCurrencies(customerWallet);
  customerWallet.push({
    ID: ADDNEWCARD_ID,
    Account: core.formatMessage(Locale.wallet_add_new_card),
    IsAddNewCard: true
  });
  setDefaultSelectedCard(customerWallet);
  dispatch(
    core.setScreenData(
      [Routes.SHOPPING_CART.id, 'customerWallet'],
      customerWallet
    )
  );
  return customerWallet;
};

export const addUpdateWalletCard = async (cardId, isNew) => {
  let result = await WalletService.updateCardPartnerData(cardId, isNew);
  if (!result.isSuccess) {
    const error = 'Failed to add or update wallet. ' + result.error;
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
  }

  const state = core.Store().getState();
  const dispatch = core.Store().dispatch;
  const card = result.response.msg;
  const customerWalletCheck = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'customerWallet'
  ]);
  let customerWallet = customerWalletCheck ? customerWalletCheck.toJS() : [];
  if (isNew) {
    const insertPos =
      customerWallet.length === 0 ? 0 : customerWallet.length - 1;
    customerWallet.splice(insertPos, 0, card);
  } else {
    const updatePos = customerWallet.findIndex(c => c.ID === card.ID);
    customerWallet.splice(updatePos, 1, card);
  }

  if (card.IsDefaultCard) {
    customerWallet.forEach(c => {
      if (c.ID !== card.ID) {
        c.IsDefaultCard = false;
      }
    });
  }

  const currency = await getCountryCurrency(card.Billto.Country);
  if (!currency) {
    return;
  } else {
    card.Currency = currency;
  }

  checkForNotAcceptedCurrencies(card);
  dispatch(
    core.setScreenData(
      [Routes.SHOPPING_CART.id, 'customerWallet'],
      customerWallet
    )
  );
  await setSelectedCard(card);
  setDefaultSelectedCard(customerWallet);
};

export const removeCardFromWallet = async cardId => {
  let result = await WalletService.removeCard(cardId);
  if (!result.isSuccess) {
    const error = 'Failed to remove card from wallet.' + result.error;
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
  }

  const state = core.Store().getState();
  const dispatch = core.Store().dispatch;
  const customerWalletCheck = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'customerWallet'
  ]);
  let customerWallet = customerWalletCheck ? customerWalletCheck.toJS() : [];
  let removedCard = customerWallet.filter(x => x.ID === cardId);
  let isRemovedCardDefault = removedCard[0].IsDefaultCard;
  let updatedCustomerWallet = customerWallet.filter(x => x.ID !== cardId);
  dispatch(
    core.setScreenData(
      [Routes.SHOPPING_CART.id, 'customerWallet'],
      updatedCustomerWallet
    )
  );
  const noDefaultCard = !updatedCustomerWallet.some(x => x.IsDefaultCard);
  if (isRemovedCardDefault || noDefaultCard) {
    setSelectedCard(updatedCustomerWallet[0]);
  } else {
    let selectedCard = updatedCustomerWallet.filter(x => x.IsDefaultCard);
    setSelectedCard(selectedCard[0]);
  }
};

export const validateCVV = (cvv, isBlur = false) => {
  const isValid = (!isBlur && cvv.length === 0) || cvv.length >= 3;
  core
    .Store()
    .dispatch(
      core.setScreenData(
        [Routes.SHOPPING_CART.id, 'selectedCard', 'isValidCVV'],
        isValid
      )
    );
};
