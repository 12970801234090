import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/ProductDrawer/ControlProductDrawer.scss';
import cssCommon from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon.scss';
import cx from 'classnames';
import Locale from 'src/Locale';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import { ProductHeader } from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon';

const PlanCardButtonStandard = ({
  buttonText,
  onCardButtonClick,
  automationName,
  disabled,
  toolTip
}) => (
  <core.ButtonStandard
    automationName={automationName}
    className={css.planCardControlButton}
    value={buttonText}
    onClick={onCardButtonClick}
    disabled={disabled}
    toolTip={toolTip}
    isSmall
  />
);

const PlanCardButtonStandardWithLoadingIndicator = WithButtonLoadingIndicatorHOC(
  PlanCardButtonStandard
);

const ControlProductDrawer = props => {
  return (
    <core.Drawer
      automationName="controlDrawer"
      onCloseIconClicked={props.onClose}
      closeOnBlur
    >
      <core.Flexbox
        className={cx(css.scrollable, cssCommon.scrollable, css.container)}
        column
      >
        <ProductHeader
          {...props}
          descriptionClassName={css.productDescription}
          dividerLineClassName={css.dividerLineHeader}
        />
        <core.Label className={cssCommon.productLabel}>
          {core.formatMessage(Locale.control_label)}
        </core.Label>
        <core.Label value={props.planDescription} />
        <core.Label
          automationName="compareControlPlan"
          isLink
          className={css.comparePlan}
          value={props.planCompareText}
          onClick={props.onComparePlanLinkClick}
        />
        <core.Flexbox column={props.isMobileLayout} className={css.cards}>
          {props.plans.map(plan => (
            <core.Flexbox key={plan.name} className={css.plan} column>
              <core.Flexbox
                className={cx(css.planCard, {
                  [css.support]: plan.name === 'Support',
                  [css.access]: plan.name === 'Access'
                })}
                column
              >
                <core.Flexbox className={css.planCardName}>
                  <core.Label className={css.planCardLabel} value={plan.name} />
                  {plan.status && (
                    <core.Label
                      className={cx(css.planCardStatus, {
                        [css.productStatusRed]:
                          plan.statusKey === 'payment_past_due' ||
                          plan.statusKey === 'provisioning_failed',
                        [css.productStatusGreen]:
                          plan.statusKey === 'provisioning'
                      })}
                      value={plan.status}
                    />
                  )}
                </core.Flexbox>
                {plan.description && (
                  <core.Label
                    className={cx(css.planCardLabel, css.planCardDescription)}
                    value={plan.description}
                  />
                )}
                <core.Label
                  className={css.planCardPricingLabel}
                  value={plan.pricingQuote}
                />
                <span>
                  <core.Label
                    className={(css.planCardLabel, css.planCardPrice)}
                    value={plan.price}
                  />
                  <core.Label
                    className={css.planCardPriceInterval}
                    value={plan.priceInterval}
                  />
                </span>
                <core.Label
                  className={cx(css.planCardLabelBilling, css.planBillingType)}
                  value={plan.billingType}
                />
                <div
                  title={plan.buttonTitle}
                  className={css.disableButtonTitle}
                >
                  <PlanCardButtonStandardWithLoadingIndicator
                    automationName={`planCardLoaderButton_${plan.automationName}`}
                    buttonText={plan.buttonText}
                    className={css.loadButton}
                    onCardButtonClick={() => props.onCardButtonClick(plan)}
                    isBusy={
                      plan.name === 'Support'
                        ? props.isBusySupport
                        : props.isBusyAccess
                    }
                    wrapperStyle={{
                      height: '36px',
                      marginBottom: props.isMobileLayout ? '30px' : '45px',
                      width: '166px',
                      alignSelf: 'center',
                      fontSize: '14px'
                    }}
                    disabled={!plan.isTrialEnable}
                  />
                </div>
              </core.Flexbox>
              <core.Flexbox className={css.planCard} column>
                <core.Label
                  className={css.featureLabel}
                  value={plan.featureDescription}
                />
                {plan.features.map(f => (
                  <core.Flexbox key={f} className={css.feature}>
                    <core.Image
                      className={css.featureCheckIcon}
                      src={core.IconPath.get('Status_Success')}
                    />
                    <core.Label className={css.featureLabel} value={f} />
                  </core.Flexbox>
                ))}
                <core.Label
                  className={css.featureBottomLabel}
                  value={core.formatMessage(Locale.great_for)}
                />
                <core.Image
                  src={plan.imageSrc}
                  isInlineSVG={false}
                  className={cx(props.isMobileLayout ? css.featureImage : '')}
                  alt={core.formatMessage(Locale.control_plan)}
                />
                {plan.featuresCondition && (
                  <core.Label
                    className={css.planCardFeatureCondition}
                    value={plan.featuresCondition}
                  />
                )}
              </core.Flexbox>
            </core.Flexbox>
          ))}
        </core.Flexbox>
        <core.Label value={props.planOwnedDescription} />
        <core.Label
          automationName="compareControlPlan"
          isLink
          className={css.connectPlan}
          value={props.planOwnedText}
          onClick={props.onConnectLinkClick}
        />
      </core.Flexbox>
    </core.Drawer>
  );
};

export default core.MobileLayoutHOC(ControlProductDrawer);
