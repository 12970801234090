import * as core from 'cw-ui-core';

export const getUrl = (configKey, endpoint) => {
  const apiUrl = core.getModuleConfig(core.Store().getState().module, [
    'apiUrls',
    configKey
  ]);
  return apiUrl + endpoint;
};

export const getAuthorityUrl = endpoint => {
  const authority = core.getModuleConfig(core.Store().getState().module, [
    'authority'
  ]);
  return authority + endpoint;
};

export const getHeaders = (
  acceptJson = true,
  jsonContentType = true,
  version = '1.0'
) => {
  const headers = new Headers();

  headers.append(
    'Authorization',
    `Bearer ${core.getAccessToken(core.Store().getState().oidc)}`
  );

  if (acceptJson) {
    headers.append(
      'Accept',
      `application/vnd.connectwise.com+json;version=${version}`
    );
  }

  if (jsonContentType) {
    headers.append(
      'Content-Type',
      `application/vnd.connectwise.com+json;version=${version}`
    );
  }

  headers.append('Access-Control-Allow-Headers', '*');
  return headers;
};

/**
 * @typedef {Object} apiFetchResult
 * @property {boolean} isSuccess
 * @property {any} status,
 * @property {any} response,
 * @property {any} error,
 * @property {any} errorMessages,
 * @property {any} headers
 */

/** @returns {apiFetchResult} */
export const GET = (endpoint, acceptJson, version) => {
  const options = {
    method: 'GET',
    headers: getHeaders(acceptJson, false, version)
  };

  return core.apiFetch(endpoint, options);
};

export const GETFILE = endpoint => {
  const options = {
    method: 'GET',
    headers: getHeaders()
  };

  return core.apiFetch(endpoint, options, false);
};

export const GETFILEDOWNLOAD = endpoint => {
  const options = {
    method: 'GET',
    headers: getHeaders(false, false)
  };

  return core.apiFetch(endpoint, options, false);
};

export const PUT = (endpoint, inputModel) => {
  const options = {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(inputModel)
  };

  return core.apiFetch(endpoint, options);
};

/** @returns {apiFetchResult} */
export const POST = (endpoint, body, version, isJson = true) => {
  const options = {
    method: 'POST',
    headers: getHeaders(true, true, version ? version : '1.0'),
    body: JSON.stringify(body)
  };

  return core.apiFetch(endpoint, options, isJson);
};

export const POSTFORMDATA = (endpoint, formData) => {
  const options = {
    method: 'POST',
    headers: getHeaders(true, false),
    body: formData
  };

  return core.apiFetch(endpoint, options);
};

// changes to part of an existing resource
// Try to avoid patches and instead using input model documents with PUT
// This will help prevent the user maliciously changing a field not intended for modification
export const PATCH = (endpoint, data, op = 'replace') => {
  const patches = [];

  // convert map of data to array of patch entries
  data.forEach((value, key) => {
    patches.push({
      op,
      path: '/' + key,
      value
    });
  });

  const options = {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(patches)
  };

  return core.apiFetch(endpoint, options);
};

export const DELETE = endpoint => {
  const options = {
    method: 'DELETE',
    headers: getHeaders()
  };

  return core.apiFetch(endpoint, options);
};
