import React from 'react';
import {Image, Flexbox} from 'cw-ui-core';
import { injectIntl } from 'react-intl';

import Locale from 'src/Locale';
import css from './TicketNotAuthorized.scss';

const TicketNotAuthorized = props => {
  const {formatMessage} = props.intl;
  return (
    <div className={css.screen}>
      <Flexbox className={css.container} column>
        <div className={css.text}>{formatMessage(Locale.ticket_not_authorized, {ticketId: props.ticketId})}</div>
        <Image className={css.image} src="/images/access-denied.svg" alt="Not Authorized"/>
      </Flexbox>
    </div>
  );
};

export default injectIntl(TicketNotAuthorized);