import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import NewsPostDrawer from './NewsPostDrawer';
import {
  addNewsPost,
  loadPosts,
  Steps,
  getKeyByValue,
  mediaTypes,
  buttonTypes,
  editNewsPost,
  postTypes,
  postScheduleTypes,
  validatePostScheduledDate
} from 'src/services/NewsAdmin/NewsAdminService';
import { getNewsPostCriteria } from 'src/services/News/NewsService';
import Locale from 'src/Locale';
import { VIEW_ADMIN_NEWS_DRAWER } from 'src/components/Admin/NewsPage/NewsFeed/NewsPageDrawer/NewsPageDrawer';
import { showNotificationBeforeClose } from 'src/services/DrawerNotification/DrawerNotification';

export const NEWS_POST_DRAWER = 'NEWS_POST_DRAWER';

const isPostEdited = isPostEdit => {
  const state = core.Store().getState();
  const updatedData = core.getScreenUpdated(state.screen, [], NEWS_POST_DRAWER);
  return !isPostEdit || (isPostEdit && updatedData.size > 0);
};

const mapStateToProps = state => {
  let mappingsReady = false;
  let newsPostCriteria = core.getModuleCache(state.module, [
    'newsPostCriteria'
  ]);
  if (newsPostCriteria) {
    newsPostCriteria = newsPostCriteria.toJS();
    mappingsReady = true;
  }

  const userName = core.getUserName(state.oidc);
  const step = core.getScreenData(state.screen, 'step', NEWS_POST_DRAWER);
  const isPostEdit =
    core.getScreenData(state.screen, 'postEdit', NEWS_POST_DRAWER) || false;
  const selectedProducts = core.getScreenData(
    state.screen,
    ['checkboxes', 'selectedProducts'],
    NEWS_POST_DRAWER
  );
  const selectedPersonas = core.getScreenData(
    state.screen,
    ['checkboxes', 'selectedPersonas'],
    NEWS_POST_DRAWER
  );
  const selectedMarkets = core.getScreenData(
    state.screen,
    ['checkboxes', 'selectedMarkets'],
    NEWS_POST_DRAWER
  );
  let breadcrumbList = core.getScreenData(
    state.screen,
    'breadcrumbList',
    NEWS_POST_DRAWER
  );
  breadcrumbList = breadcrumbList ? breadcrumbList.toJS() : [];

  const lastBreadcrumbName =
    step === Steps.Step_2
      ? core.formatMessage(Locale.post_audience)
      : step === Steps.Step_3 &&
        core.formatMessage(Locale.psm_breadcrumb_details);

  const mediaType = core.getScreenData(
    state.screen,
    'mediaType',
    NEWS_POST_DRAWER
  );
  const buttonType = core.getScreenData(
    state.screen,
    'buttonType',
    NEWS_POST_DRAWER
  );
  let postDetails = core.getScreenData(state.screen, [], NEWS_POST_DRAWER);
  postDetails = postDetails ? postDetails.toJS() : null;

  const postType = core.getScreenData(
    state.screen,
    'postType',
    NEWS_POST_DRAWER
  );

  const createdPostId = core.getScreenData(
    state.screen,
    ['postId'],
    NEWS_POST_DRAWER
  );

  const invalidData = core.getScreenInvalid(state.screen, [], NEWS_POST_DRAWER);

  const productsList =
    newsPostCriteria &&
    newsPostCriteria.products.map(x => ({
      id: x,
      name: x
    }));
  const marketsList =
    newsPostCriteria &&
    newsPostCriteria.markets.map(x => ({
      id: x,
      name: x
    }));
  const personasList =
    newsPostCriteria &&
    newsPostCriteria.personas.map(x => ({
      id: x,
      name: x
    }));
  const authorsList =
    newsPostCriteria &&
    newsPostCriteria.authors.map(x => ({
      id: x.name,
      name: x.name === 'Self' ? userName : x.name,
      imageUrl: x.imageUrl
    }));
  const disabledCreatePostButton = !(
    postDetails &&
    (postDetails.title ? postDetails.title.trim() : null) &&
    (postDetails.content ? postDetails.content.trim() : null) &&
    postDetails.author &&
    postDetails.postPriority &&
    (postDetails.buttonType
      ? (postDetails.callToActionText && postDetails.ctaButtonUrl) ||
        postDetails.buttonType === buttonTypes[0].name
      : false) &&
    (postDetails.mediaType
      ? postDetails.imageUrl1 ||
        (postDetails.postimageFileObject &&
          postDetails.postimageFileObject.length > 0) ||
        postDetails.videoUrl ||
        postDetails.mediaType === mediaTypes[0].name
      : postType === postTypes.PINNED_ANNOUNCEMENT) &&
    (invalidData ? invalidData.size < 1 : true) &&
    isPostEdited(isPostEdit) &&
    (postDetails.postScheduleType
      ? postDetails.postScheduleType === postScheduleTypes[0].name ||
        (postDetails.scheduledDate && postDetails.scheduledTime)
      : false)
  );

  const scheduledDate = core.getScreenData(
    state.screen,
    'scheduledDate',
    NEWS_POST_DRAWER
  );

  const postScheduledUpdated = core.getScreenUpdated(
    state.screen,
    ['postScheduleType'],
    NEWS_POST_DRAWER
  );

  const postSchedule = core.getScreenData(
    state.screen,
    'postScheduleType',
    NEWS_POST_DRAWER
  );

  return {
    step,
    newsPostCriteria,
    mappingsReady,
    showBreadCrumbs:
      postType === postTypes.PINNED_ANNOUNCEMENT
        ? step === Steps.Step_3
        : step !== Steps.Step_1,
    disabledContinueButton:
      !(selectedProducts && selectedProducts.size !== 0) ||
      !(selectedPersonas && selectedPersonas.size !== 0) ||
      !(selectedMarkets && selectedMarkets.size !== 0),
    disabledCreatePostButton,
    breadcrumbList,
    lastBreadcrumbName,
    mediaType,
    postDetails,
    isPostCreated: createdPostId !== undefined,
    createdPostId,
    postType,
    buttonType,
    isPostEdit,
    productsList,
    marketsList,
    personasList,
    authorsList,
    scheduledDate,
    postSchedule,
    allowPostScheduleTypeChange:
      isPostEdit && scheduledDate === undefined && !postScheduledUpdated
  };
};

const mapDispatchToProps = dispatch => {
  const keys = Object.keys(Steps);
  return {
    onCloseIconClicked: () => {
      dispatch(core.hideDrawer(NEWS_POST_DRAWER));
    },
    onBeforeClose: result => {
      const state = core.Store().getState();
      const step = Steps.Step_1;
      const currentStep = core.getScreenData(
        state.screen,
        'step',
        NEWS_POST_DRAWER
      );
      const createdPostId = core.getScreenData(
        state.screen,
        ['postId'],
        NEWS_POST_DRAWER
      );
      if (!createdPostId ? step !== currentStep : false) {
        showNotificationBeforeClose(NEWS_POST_DRAWER);
        result.isCancel = true;
      }
    },
    getNewsPostCriteria: async () => {
      const state = core.Store().getState();
      let newsPostCriteria = core.getModuleCache(state.module, [
        'newsPostCriteria'
      ]);

      if (!newsPostCriteria) {
        await getNewsPostCriteria();
      }
    },
    onTypeClick: (desc, step) => {
      let nextStep = Steps.Step_2;
      if (step) {
        let nextIndex = keys.indexOf(getKeyByValue(Steps, step)) + 1;
        if (nextIndex > -1) {
          nextStep = Steps[keys[nextIndex]];
        }
      }

      dispatch(core.setScreenData('step', nextStep, NEWS_POST_DRAWER));
      desc && dispatch(core.setScreenData('type', desc, NEWS_POST_DRAWER));

      const state = core.Store().getState();
      let breadcrumbList = core.getScreenData(
        state.screen,
        'breadcrumbList',
        NEWS_POST_DRAWER
      );
      breadcrumbList = breadcrumbList ? breadcrumbList.toJS() : [];
      let postType = core.getScreenData(
        state.screen,
        'postType',
        NEWS_POST_DRAWER
      );
      if (step === Steps.Step_1) {
        breadcrumbList = [
          {
            name: desc
          }
        ];
      } else if (step === Steps.Step_2) {
        if (postType === postTypes.PINNED_ANNOUNCEMENT) {
          breadcrumbList = [
            {
              name: core.formatMessage(Locale.post_audience)
            }
          ];
        } else {
          breadcrumbList[1] = {
            name: core.formatMessage(Locale.post_audience)
          };
        }
      }

      dispatch(
        core.setScreenData('breadcrumbList', breadcrumbList, NEWS_POST_DRAWER)
      );
    },

    onBreadcrumbClick: index => {
      const state = core.Store().getState();
      let breadcrumbList = core.getScreenData(
        state.screen,
        'breadcrumbList',
        NEWS_POST_DRAWER
      );
      let postType = core.getScreenData(
        state.screen,
        'postType',
        NEWS_POST_DRAWER
      );
      breadcrumbList = breadcrumbList ? breadcrumbList.toJS() : [];
      let keys = Object.keys(Steps);
      let step = Steps[keys[index]];
      if (postType === postTypes.PINNED_ANNOUNCEMENT) {
        dispatch(
          core.setScreenData('step', Steps[keys[index + 1]], NEWS_POST_DRAWER)
        );
        dispatch(core.setScreenData('breadcrumbList', [], NEWS_POST_DRAWER));
      } else {
        dispatch(core.setScreenData('step', step, NEWS_POST_DRAWER));
        let currentStepNumber = step.slice(-1) - 1;
        currentStepNumber > 0 &&
          breadcrumbList.splice(
            currentStepNumber,
            breadcrumbList.length - currentStepNumber
          );
        dispatch(
          core.setScreenData('breadcrumbList', breadcrumbList, NEWS_POST_DRAWER)
        );
      }
    },
    onCreatePost: async () => {
      const state = core.Store().getState();
      const postDetails = core.getScreenData(
        state.screen,
        [],
        NEWS_POST_DRAWER
      );
      const postInfo = postDetails ? postDetails.toJS() : null;
      const result = await addNewsPost(postInfo);
      if (result) {
        dispatch(core.setScreenData(['postId'], result.id, NEWS_POST_DRAWER));
        await loadPosts();
      }
    },
    onEditPost: async () => {
      const state = core.Store().getState();
      const postDetails = core.getScreenData(
        state.screen,
        [],
        NEWS_POST_DRAWER
      );
      const postInfo = postDetails ? postDetails.toJS() : null;
      let postId = core.getScreenData(
        state.screen,
        'newsPostId',
        NEWS_POST_DRAWER
      );
      const result = await editNewsPost(postId, postInfo);
      if (result) {
        dispatch(core.setScreenData(['postId'], result.id, NEWS_POST_DRAWER));
        await loadPosts();
      }
    },
    onViewPostClick: postId => {
      dispatch(core.hideDrawer(NEWS_POST_DRAWER));
      dispatch(
        core.showDrawer(VIEW_ADMIN_NEWS_DRAWER, {
          postId,
          views: 0,
          clicks: 0
        })
      );
    },
    onMediaTypeChange: filter => {
      dispatch(core.removeScreenData('videoUrl', NEWS_POST_DRAWER));
      dispatch(core.removeScreenInvalid(['videoUrl'], NEWS_POST_DRAWER));
      dispatch(
        core.removeScreenInvalid(['postimageFileObject'], NEWS_POST_DRAWER)
      );
      dispatch(core.removeScreenData('postimageFileObject', NEWS_POST_DRAWER));
      dispatch(core.removeScreenData('imageUrl1', NEWS_POST_DRAWER));
      dispatch(
        core.removeScreenData(['newsPost', 'featureImage'], NEWS_POST_DRAWER)
      );
      dispatch(core.setScreenData('mediaType', filter.name, NEWS_POST_DRAWER));
    },
    onButtonTypeChange: filter => {
      dispatch(core.removeScreenData('callToActionText', NEWS_POST_DRAWER));
      dispatch(core.removeScreenData('ctaButtonUrl', NEWS_POST_DRAWER));
      dispatch(core.removeScreenInvalid(['ctaButtonUrl'], NEWS_POST_DRAWER));
      dispatch(core.setScreenData('buttonType', filter.name, NEWS_POST_DRAWER));
    },
    onAuthorChange: filter => {
      dispatch(core.setScreenData('author', filter.name, NEWS_POST_DRAWER));
      dispatch(
        core.setScreenData('authorImageUrl', filter.imageUrl, NEWS_POST_DRAWER)
      );
      dispatch(core.setScreenData('originator', filter.id, NEWS_POST_DRAWER));
    },
    onPriorityChange: filter => {
      dispatch(
        core.setScreenData('postPriority', filter.name, NEWS_POST_DRAWER)
      );
    },
    onImageUrlChange: postimage => {
      let isValidImageFormat = false;
      dispatch(
        core.removeScreenInvalid(['postimageFileObject'], NEWS_POST_DRAWER)
      );
      if (postimage && postimage[0]) {
        const fileName = postimage[0].name;
        const fileExtension = fileName
          .substr(fileName.lastIndexOf('.') + 1)
          .toLowerCase();
        isValidImageFormat = ['gif', 'png', 'jpg', 'jpeg'].includes(
          fileExtension
        )
          ? true
          : false;
      }

      if (isValidImageFormat) {
        dispatch(
          core.setScreenData(
            ['postimageFileObject'],
            postimage,
            NEWS_POST_DRAWER
          )
        );
      } else {
        dispatch(
          core.setScreenInvalid(
            ['postimageFileObject'],
            core.formatMessage(Locale.wrong_image_format),
            NEWS_POST_DRAWER
          )
        );
        dispatch(
          core.removeScreenData(['postimageFileObject'], NEWS_POST_DRAWER)
        );
      }
    },
    onVideoUrlChange: videoUrl => {
      dispatch(core.removeScreenInvalid(['videoUrl'], NEWS_POST_DRAWER));
      videoUrl &&
        !videoUrl.startsWith('https://www.youtube.com/') &&
        dispatch(
          core.setScreenInvalid(
            ['videoUrl'],
            core.formatMessage(Locale.wrong_youtube_link),
            NEWS_POST_DRAWER
          )
        );
    },
    onCallToActionUrlChange: ctaUrl => {
      dispatch(core.removeScreenInvalid(['ctaButtonUrl'], NEWS_POST_DRAWER));
      const pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
      ctaUrl &&
        !pattern.test(ctaUrl) &&
        dispatch(
          core.setScreenInvalid(
            ['ctaButtonUrl'],
            core.formatMessage(Locale.wrong_call_to_action_url),
            NEWS_POST_DRAWER
          )
        );
    },
    onPostScheduledChange: filter => {
      dispatch(core.removeScreenData('scheduledDate', NEWS_POST_DRAWER));
      dispatch(core.removeScreenData('scheduledTime', NEWS_POST_DRAWER));
      dispatch(core.removeScreenInvalid(['scheduledDate'], NEWS_POST_DRAWER));
      dispatch(
        core.setScreenData('postScheduleType', filter.name, NEWS_POST_DRAWER)
      );
    },
    onPostScheduledDateChange: date => {
      const state = core.Store().getState();
      const time = core.getScreenData(
        state.screen,
        'scheduledTime',
        NEWS_POST_DRAWER
      );
      validatePostScheduledDate(date, time);
    },
    onPostScheduledTimeChange: time => {
      const state = core.Store().getState();
      const date = core.getScreenData(
        state.screen,
        'scheduledDate',
        NEWS_POST_DRAWER
      );

      validatePostScheduledDate(date, time);
    }
  };
};

const NewsPostDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsPostDrawer);

export default NewsPostDrawerContainer;
