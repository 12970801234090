import { connect } from 'react-redux';
import SecurityBulletinItem from 'src/components/Drawer/SecurityBulletin/SecurityBulletinItem';
import * as core from 'cw-ui-core';
import { VIEW_SECURITY_BULLETIN_DRAWER } from 'src/components/Drawer/SecurityBulletin/SecurityPageDrawer';
import { getAvatarPhoto } from 'src/services/Avatar/AvatarService';
import Routes from 'src/services/Routes';
import { POLICIES } from 'src/services/Authorization';
import HasAccess from 'src/services/RoleAccess';

const mapStateToProps = state => {
  let securityBulletinItem = core.getScreenData(
    state.screen,
    ['securityBulletinDetails'],
    VIEW_SECURITY_BULLETIN_DRAWER
  );

  securityBulletinItem = securityBulletinItem.toJS().result;

  const isSecurityBulletinAdmin = HasAccess(
    [POLICIES.SECURITY_BULLETINS_ADMIN],
    null
  );

  let authorImageUrl = undefined;
  let previewSecurityBulletinItem;
  if (securityBulletinItem) {
    previewSecurityBulletinItem = {
      title: securityBulletinItem.title,
      author: securityBulletinItem.originator,
      authorImageUrl: securityBulletinItem.originatorImageUrl,
      authorTitle: securityBulletinItem.originatorTitle,
      createdDate: securityBulletinItem.published,
      content: securityBulletinItem.description,
      securityBulletinUrl:
        isSecurityBulletinAdmin &&
        `${
          window.location.origin
        }${Routes.SECURITY_BULLETIN_DRAWER.path.replace(
          ':securityBulletinId',
          securityBulletinItem.id
        )}`
    };
    authorImageUrl =
      previewSecurityBulletinItem.authorImageUrl &&
      core.getModuleCache(state.module, [
        'avatar',
        'photos',
        previewSecurityBulletinItem.authorImageUrl
      ]);
  }

  return {
    securityBulletinItem: previewSecurityBulletinItem,
    authorImageUrl:
      authorImageUrl === 'useInitials' ? undefined : authorImageUrl
  };
};

const mapDispatchToProps = () => {
  return {
    onSecurityBulletinItemLoaded: async securityBulletinItem => {
      if (securityBulletinItem && securityBulletinItem.authorImageUrl) {
        return await getAvatarPhoto(securityBulletinItem.authorImageUrl);
      }
    }
  };
};

const SecurityBulletinItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityBulletinItem);

export default SecurityBulletinItemContainer;
