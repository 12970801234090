import * as Coveo from 'coveo-search-ui';

class ResultIcon extends Coveo.Component {
  static ID = 'ResultIcon';
  constructor(element, options, bindings, result) {
    super(element, ResultIcon.ID, bindings);
    this.result = result;
    this.options = Coveo.ComponentOptions.initComponentOptions(
      element,
      ResultIcon,
      options
    );
    this.build();
  }

  build = function() {
    var value = this.getValue(this.options.field);
    this.renderIcon(value);
  };

  isPlainObject = function(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  };

  getValue = function(field) {
    var value = Coveo.Utils.getFieldValue(this.result, field);
    if (!Array.isArray(value) && this.isPlainObject(value)) {
      value = '';
    }

    if (Array.isArray(value)) {
      value = value.join(',');
    }

    return value;
  };

  renderIcon = function(value) {
    if (value) {
      var iconWrapper = Coveo.$$(
        'div',
        {
          className: 'icon-wrapper',
          style: 'width:' + this.options.size + 'px'
        },
        ResultIcon.ICON_MAPPING[value.toLocaleLowerCase()]
      );
      this.element.appendChild(iconWrapper.el);
    }
  };
}

ResultIcon.ID = 'ResultIcon';

ResultIcon.options = {
  /**
   * Specifies the field that the TenableIcon should display.
   *
   * Specifying a value for this parameter is required in order for the TenableIcon component to work.
   */
  field: Coveo.ComponentOptions.buildFieldOption({
    defaultValue: '@field',
    required: true
  }),
  /**
   * Specifies the icon size in pixel.
   */
  size: Coveo.ComponentOptions.buildNumberOption({ defaultValue: 15 })
};

ResultIcon.ICON_MAPPING = {
  videos:
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20"><defs><path id="videos-a" d="M9 .667C4.417.667.667 4.417.667 9S4.417 17.333 9 17.333s8.333-3.75 8.333-8.333S13.583.667 9 .667zm0 15c-3.667 0-6.667-3-6.667-6.667s3-6.667 6.667-6.667 6.667 3 6.667 6.667-3 6.667-6.667 6.667zM11.917 9c0 .25-.125.542-.334.667L8.667 11.75a.8.8 0 0 1-.5.167c-.125 0-.25-.042-.375-.084-.292-.125-.459-.416-.459-.75V6.917c0-.292.167-.584.459-.75.291-.125.625-.125.875.083l2.916 2.083c.209.125.334.417.334.667z"></path></defs><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><mask id="videos-b" fill="#fff"><use xlink:href="#videos-a"></use></mask><use fill="#000" fill-rule="nonzero" xlink:href="#videos-a"></use><g fill="#212121" mask="url(#videos-b)"><path d="M-1-1h20v20H-1z"></path></g></g></svg>',
  documentation:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48"><g><g fill="#010101"><path d="M18 23h12c1.1 0 2-.9 2-2s-.9-2-2-2H18c-1.1 0-2 .9-2 2s.9 2 2 2zM18 31h6c1.1 0 2-.9 2-2s-.9-2-2-2h-6c-1.1 0-2 .9-2 2s.9 2 2 2zM39.4 11.6l-7-7c-.3-.3-.6-.5-1-.5-.1 0-.3 0-.4 0H10C8.9 4 8 4.9 8 6v36c0 .5.2 1 .6 1.4s.9.6 1.4.6h14c1.1 0 2-.9 2-2s-.9-2-2-2H12V8h16v6c0 1.1.9 2 2 2h6v6c0 1.1.9 2 2 2s2-.9 2-2v-9c0-.5-.2-1-.6-1.4zM34 27c-3.3 0-6 2.7-6 6 0 2.2 1.2 4.2 3 5.2V44l3-3 3 3v-5.8c1.8-1 3-3 3-5.2 0-3.3-2.7-6-6-6z"></path></g><path fill="none" d="M0 0h48v48H0V0z"></path></g></svg>',
  university:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48"><g><g fill="#010101"><path d="M18 23h12c1.1 0 2-.9 2-2s-.9-2-2-2H18c-1.1 0-2 .9-2 2s.9 2 2 2zM18 31h6c1.1 0 2-.9 2-2s-.9-2-2-2h-6c-1.1 0-2 .9-2 2s.9 2 2 2zM39.4 11.6l-7-7c-.3-.3-.6-.5-1-.5-.1 0-.3 0-.4 0H10C8.9 4 8 4.9 8 6v36c0 .5.2 1 .6 1.4s.9.6 1.4.6h14c1.1 0 2-.9 2-2s-.9-2-2-2H12V8h16v6c0 1.1.9 2 2 2h6v6c0 1.1.9 2 2 2s2-.9 2-2v-9c0-.5-.2-1-.6-1.4zM34 27c-3.3 0-6 2.7-6 6 0 2.2 1.2 4.2 3 5.2V44l3-3 3 3v-5.8c1.8-1 3-3 3-5.2 0-3.3-2.7-6-6-6z"></path></g><path fill="none" d="M0 0h48v48H0V0z"></path></g></svg>',
  article:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48"><g><path d="M24 26c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0 3.2c-5 0-9.8 2.3-13.5 6.5-.3.4-.5.8-.5 1.3v5.1c0 1.1.9 2 2 2h24c1.1 0 2-.9 2-2v-5c0-.5-.2-1-.5-1.3-3.8-4.3-8.5-6.6-13.5-6.6zm12.8-15.6C34.9 8.3 29.9 4.5 24 4.5s-10.9 3.8-12.8 9.1c-1.7.7-3 2.4-3 4.4 0 2.6 2.1 4.7 4.7 4.7.6 0 1-.4 1-1v-6.2c1.1-4.6 5.3-8 10.2-8s9 3.4 10.2 8v6.2c0 .3.1.5.3.7-.1 2.1-1.8 3.8-3.9 3.8-.6 0-1 .4-1 1s.4 1 1 1c3.2 0 5.8-2.5 5.9-5.6 1.9-.6 3.4-2.3 3.4-4.5-.2-2.1-1.5-3.8-3.2-4.5z"></path><path fill="none" d="M0 0h48v48H0V0z"></path></g></svg>',
  lessons:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48"><path fill="#010101" d="M43.9 20.5c0-.1 0-.2.1-.3 0-.1 0-.1 0-.2s0-.1 0-.2 0-.1 0-.2 0-.1 0-.2-.1-.2-.1-.2v-.1c-.1-.1-.1-.2-.2-.3-.2-.2-.4-.4-.6-.5l-18-10c-.6-.3-1.3-.3-1.9 0l-18 10c-.2.1-.4.3-.6.5-.1.1-.2.2-.2.3v.1c0 .1-.1.2-.1.2 0 .1 0 .1 0 .2s0 .1 0 .2 0 .1 0 .2 0 .1 0 .2 0 .1 0 .2 0 .1 0 .2.1.2.1.3c0 0 0 0 0 .1.1.2.3.4.5.6.1.1.2.2.3.2l4.9 2.4V32c0 .8.4 1.5 1.1 1.8l12 6c.3.1.6.2.9.2s.6-.1.9-.2l12-6c.7-.3 1.1-1 1.1-1.8v-7.8l2-1V32c0 1.1.9 2 2 2s2-.9 2-2V21c-.1-.2-.1-.3-.2-.5zM24 12.3l13.7 7.6L24 26.8l-13.7-6.9L24 12.3zm10 18.5l-10 5-10-5v-4.5l9.1 4.6c.3.1.6.2.9.2s.6-.1.9-.2l9.1-4.6v4.5z"></path><path fill="none" d="M0 0h48v48H0V0z"></path></svg>',
  'known issues':
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48"><g><path fill="#010101" d="M36 28h2c1.1 0 2-.9 2-2s-.9-2-2-2h-2v-2c0-.7-.1-1.3-.2-2H38c1.1 0 2-.9 2-2s-.9-2-2-2h-3.6c-.9-1.6-2.1-2.9-3.6-3.9l2.7-2.7c.8-.8.8-2 0-2.8-.8-.8-2-.8-2.8 0l-3.8 3.8c-1-.3-1.9-.4-2.9-.4s-1.9.1-2.8.3l-3.8-3.8c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l2.7 2.7c-1.5 1-2.7 2.4-3.6 3.9H10c-1.1 0-2 .9-2 2s.9 2 2 2h2.2c-.1.7-.2 1.3-.2 2v2h-2c-1.1 0-2 .9-2 2s.9 2 2 2h2v2c0 .7.1 1.3.2 2H10c-1.1 0-2 .9-2 2s.9 2 2 2h3.6c2.1 3.6 5.9 6 10.4 6 4.4 0 8.3-2.4 10.4-6H38c1.1 0 2-.9 2-2s-.9-2-2-2h-2.2c.1-.7.2-1.3.2-2V28zm-4 2c0 4.4-3.6 8-8 8s-8-3.6-8-8v-8c0-4.4 3.6-8 8-8s8 3.6 8 8v8z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>',
  'discussion forums':
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48"><g><path d="M42 4H14c-1.1 0-2 .9-2 2v2H6c-1.1 0-2 .9-2 2v24c0 1.1.9 2 2 2h6v6c0 .8.5 1.5 1.2 1.8.3.2.5.2.8.2.5 0 1-.2 1.4-.6l7.4-7.4H38c1.1 0 2-.9 2-2v-6h2c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-6 28H22c-.1 0-.3 0-.4 0-.1 0-.1 0-.2.1-.1 0-.1 0-.2.1-.1 0-.1.1-.2.1 0 0-.1 0-.1.1-.1.1-.2.2-.3.3L16 37.2V34c0-1.1-.9-2-2-2H8V12h28v20zm-4-14c0 1.1-.9 2-2 2H14c-1.1 0-2-.9-2-2s.9-2 2-2h16c1.1 0 2 .9 2 2zm0 8c0 1.1-.9 2-2 2H14c-1.1 0-2-.9-2-2s.9-2 2-2h16c1.1 0 2 .9 2 2z"></path><path fill="none" d="M0 0h48v48H0V0z"></path></g></svg>',
  marketplace:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48"><g><path d="M37 36c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.8 4 4zm-19-4c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm23.9-19.5l-4 16c-.2.9-1 1.5-1.9 1.5H15c-.9 0-1.7-.6-1.9-1.5L9.2 13l-1-1H6c-1.1 0-2-.9-2-2s.9-2 2-2h3c.5 0 1 .2 1.4.6l1.4 1.4H40c.6 0 1.2.3 1.6.8.4.5.5 1.1.3 1.7zM37.4 14H13.6l3 12h17.9l2.9-12z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>'
};

export default ResultIcon;
