import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import ResourceCard from 'src/components/Drawer/PartnerSuccessManagerDrawer/ResourceCard';
import Locale from 'src/Locale';
import {
  PARTNER_SUCCESS_MANAGER_DRAWER,
  PARTNER_DEVELOPMENT_MANAGER_TITLE,
  PathValues
} from './PartnerSuccessDrawer/PartnerSuccessManagerDrawerContainer';
import { Steps } from 'src/services/SuccessManagerService';
import {
  FEATURES,
  isFeatureEnabled
} from 'src/services/Features/FeaturesService';

const mapStateToProps = state => {
  const partnerDevelopmentManager = core.getModuleCache(state.module, ['partnerDevelopmentManager']);
  const partnerDevelopmentManagerPhoto = core.getModuleCache(state.module, [
    'partnerDevelopmentManagerPhoto'
  ]);
  const showFullPDMBio = core.getScreenData(
    state.screen,
    'showFullPDMBio',
    PARTNER_SUCCESS_MANAGER_DRAWER
  );

  return {
    name: partnerDevelopmentManager.get('fullName'),
    description: core.formatMessage(Locale.partner_development_manager_description),
    initials:
    partnerDevelopmentManager && partnerDevelopmentManager.get('fullName')
        ? core.getInitials(partnerDevelopmentManager.get('fullName'))
        : null,
    phone: partnerDevelopmentManager.get('phone'),
    photo: partnerDevelopmentManagerPhoto,
    photoUrl: partnerDevelopmentManagerPhoto
      ? URL.createObjectURL(partnerDevelopmentManagerPhoto)
      : undefined,
    aboutMe: partnerDevelopmentManager.get('notes'),
    title: core.formatMessage(Locale.partner_development_manager),
    email: partnerDevelopmentManager.get('defaultEmail'),
    showFullBio: showFullPDMBio,
    isSalesForceCaseManagement: isFeatureEnabled(FEATURES.HOME_CASE_MANAGEMENT)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClick: () => {
      const state = core.Store().getState();
      const partnerDevelopmentManagerPhoto = core.getModuleCache(state.module, [
        'partnerDevelopmentManagerPhoto'
      ]);

      // Clear form values when changing card
      Object.keys(PathValues).forEach(path => {
        dispatch(
          core.setScreenData(
            PathValues[path],
            undefined,
            PARTNER_SUCCESS_MANAGER_DRAWER
          )
        );
      });

      const partnerDevelopmentManagerPhotoUrl = partnerDevelopmentManagerPhoto
        ? URL.createObjectURL(partnerDevelopmentManagerPhoto)
        : undefined;
      const resourceSelected = {
        type: PARTNER_DEVELOPMENT_MANAGER_TITLE,
        member: core.getModuleCache(state.module, ['partnerDevelopmentManager']).toJS(),
        photoUrl: partnerDevelopmentManagerPhotoUrl,
        photoAltText:
          core.formatMessage(Locale.my_account_manager) +
          ' ' +
          core.formatMessage(Locale.photo),
        tooltip: core.formatMessage(Locale.my_account_manager)
      };
      dispatch(
        core.setScreenData(
          'resourceSelected',
          resourceSelected,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
      dispatch(
        core.setScreenData('step', Steps.Step_2, PARTNER_SUCCESS_MANAGER_DRAWER)
      );
    },
    onShowMoreClick: () => {
      dispatch(
        core.setScreenData(
          'showFullPDMBio',
          true,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
    },
    onShowLessClick: () => {
      dispatch(
        core.setScreenData(
          'showFullPDMBio',
          false,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
    }
  };
};

const PartnerDevelopmentManagerResourceCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceCard);

export default PartnerDevelopmentManagerResourceCardContainer;
