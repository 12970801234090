class BaseCartItem {
  constructor(buyerCartItem) {
    this._buyerCartItem = buyerCartItem[0];
    this._groupItems = buyerCartItem;
    this._groups = buyerCartItem.map(u => u.description);
  }

  get productName() {
    return this._buyerCartItem.product;
  }

  get instanceId() {
    if (typeof this._buyerCartItem.instanceId === 'undefined') {
      return null;
    }

    return this._buyerCartItem.instanceId;
  }

  get productPlan() {
    return this._buyerCartItem.productPlan;
  }

  get description() {
    return this._buyerCartItem.description;
  }

  get groups() {
    return this._groups;
  }

  get buyerCart() {
    return this._groupItems;
  }

  get isUsageBased() {
    return this._buyerCartItem.usageBased;
  }

  get allowQuantityChange() {
    if (this._buyerCartItem.usageBased) {
      return false;
    }

    if (this._buyerCartItem.isUpgrade) {
      return false;
    }

    return true;
  }

  get restrictQuantityChange() {
    if (
      !this._buyerCartItem.isUpgrade &&
      (this._buyerCartItem.restrictQty || this._buyerCartItem.restrictQuantity)
    ) {
      return true;
    }

    return false;
  }

  get isUpgrade() {
    return this._buyerCartItem.isUpgrade;
  }

  get quantity() {
    return !this._buyerCartItem.usageBased &&
      this._buyerCartItem.useSeatsAsQuantity
      ? this._buyerCartItem.seats
      : this._buyerCartItem.quantity;
  }

  get price() {
    return this._buyerCartItem.price;
  }

  get billIntervalText() {
    let billingText;
    if (this._buyerCartItem.customBillingText) {
      billingText = this._buyerCartItem.customBillingText;
    } else {
      switch (this._buyerCartItem.billIntervalMonth) {
        case 1:
          billingText = '/month';
          break;
        case 12:
          billingText = '/year';
          break;
        case 36:
          billingText = '/3 years';
          break;
        default:
          billingText = '';
      }
    }

    return billingText;
  }

  get productImageUrl() {
    return '/images/ShoppingCart/productPlaceholder.png';
  }

  isForCartPrice(cartPrice) {
    return this.instanceId === cartPrice.instanceId;
  }
}

export default BaseCartItem;
