import * as core from 'cw-ui-core';
import Routes from 'src/services/Routes';

const getPreviewArgs = () => {
  const state = core.Store().getState();

  const header = core.getScreenData(state.screen, [Routes.BANNER_CONFIGURATION.id, 'bannerCopy']);
  const subHeader = core.getScreenData(state.screen, [Routes.BANNER_CONFIGURATION.id, 'bannerSubHeader']);
  const buttonText = core.getScreenData(state.screen, [Routes.BANNER_CONFIGURATION.id, 'bannerCTAButtonText']);
  const secondBannerImage = core.getScreenCustom(state.screen, [Routes.BANNER_CONFIGURATION.id, 'secondBanner']);
  const primaryColor = core.getScreenData(state.screen, [Routes.BANNER_CONFIGURATION.id, 'primaryColor']);
  const primaryButtonTextColor = core.getScreenData(state.screen, [Routes.BANNER_CONFIGURATION.id, 'primaryButtonTextColor']);
  const secondBanner = secondBannerImage ? secondBannerImage.toJS() : undefined;
  const secondBannerUrl = core.getScreenData(state.screen, [Routes.BANNER_CONFIGURATION.id, 'secondBannerUrl']);

  const args = {
    header,
    subHeader,
    buttonText,
    secondBanner: secondBanner && secondBanner.length > 0 ? secondBanner[0] : undefined,
    primaryColor,
    primaryButtonTextColor,
    secondBannerUrl
  };

  return args;
};

export default getPreviewArgs;
