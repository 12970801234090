import { connect } from 'react-redux';
import { onFollowKnownIssueInListClicked } from 'src/services/Tickets/KnownIssuesService';
import FollowKnownIssuesButton from 'src/components/PartnerSupportPage/KnownIssues/FollowKnownIssuesButton';
import * as core from 'cw-ui-core';
import { FOLLOW_KNOWN_ISSUES_DIALOG } from 'src/components/PartnerSupportPage/KnownIssues/FollowKnownIssuesDialog';

const mapDispatchToProps = dispatch => {
  return {
    onFollowKnownIssuesClicked: async (knownIssue, showDialog = false) => {
      if (showDialog) {
        dispatch(core.showDialog(FOLLOW_KNOWN_ISSUES_DIALOG, { knownIssue }));
      } else {
        await onFollowKnownIssueInListClicked(knownIssue);
      }
    }
  };
};

const FollowKnownIssuesButtonContainer = connect(
  undefined,
  mapDispatchToProps
)(FollowKnownIssuesButton);
export default FollowKnownIssuesButtonContainer;
