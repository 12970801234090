import React, { useEffect, useRef } from 'react';
import css from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawer.scss';
import * as core from 'cw-ui-core';
import { Steps } from 'src/services/SecurityAdmin/SecurityBulletinAdminService';
import Breadcrumbs from 'src/components/Breadcrumbs/Breadcrumbs';
import Locale from 'src/Locale';
import SecurityBulletinItemContainer from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinItemContainer';
import cx from 'classnames';

const SecurityBulletinDrawerContent = props => {
  const showFullBreadcrumb = props.currentStep === Steps.Step_5 ? false : true;

  const drawerContent = useRef(null);
  useEffect(() => {
    const heightToApply = window.innerHeight - 250;
    drawerContent.current &&
      (drawerContent.current.style.maxHeight = `${heightToApply}px`);
  });

  return (
    <>
      {props.showBreadCrumbs && (
        <Breadcrumbs
          breadcrumbs={props.breadcrumbList}
          lastBreadcrumbName={core.formatMessage(Locale.psm_breadcrumb_details)}
          showFullBreadcrumb={showFullBreadcrumb}
          onBreadcrumbClick={props.onBreadcrumbClick}
        />
      )}
      {props.currentStep === Steps.Step_1 && (
        <core.Flexbox column className={css.details}>
          {props.mappingsReady &&
            props.securityBulletinCriteria.products.map(product => (
              <core.Label
                key={`product_${product}`}
                className={css.productContainer}
                cwId={`product_${product}`}
                automationName={`product_${product}`}
                onClick={() => {
                  props.onProductClick(product, props.currentStep);
                }}
                value={product}
                grey7
              />
            ))}
        </core.Flexbox>
      )}
      {props.currentStep === Steps.Step_2 && (
        <core.Flexbox className={css.detailsAndPreviewContainer}>
          <core.Flexbox
            column
            className={cx(css.details, css.securityBulletinDetails)}
          >
            <core.Label
              className={css.labels}
              value={core.formatMessage(Locale.select_securityBulletin_author)}
            />
            <core.FormFieldContainer
              path={['author']}
              screenKey="SECURITY_BULLETIN_DRAWER"
              onAfterChange={props.onAuthorChange}
            >
              <core.ComboBox
                cwId="securityBulletinAuthor"
                automationName="securityBulletinAuthor"
                className={css.dropDown}
                items={props.authorsList}
                allowCustomEntry={false}
                expandOnClick
                readOnly
                placeholder={core.formatMessage(
                  Locale.select_author_placeHolder
                )}
              />
            </core.FormFieldContainer>
            <core.Label
              className={css.labels}
              value={core.formatMessage(Locale.enter_securityBulletin_title)}
            />
            <core.FormFieldContainer
              path={['title']}
              screenKey="SECURITY_BULLETIN_DRAWER"
            >
              <core.TextField
                className={css.textfield}
                placeholder={core.formatMessage(Locale.enter_title_placeholder)}
                maxLength={100}
              />
            </core.FormFieldContainer>
            <core.Label
              className={css.labels}
              value={core.formatMessage(Locale.enter_securityBulletin_content)}
            />
            <core.FormFieldContainer
              path={['content']}
              screenKey="SECURITY_BULLETIN_DRAWER"
            >
              <core.TextArea
                className={css.textArea}
                placeholder={core.formatMessage(
                  Locale.enter_content_placeholder
                )}
              />
            </core.FormFieldContainer>
          </core.Flexbox>

          {props.securityBulletinDetails.author ? (
            <div className={css.previewContainer} ref={drawerContent}>
              <SecurityBulletinItemContainer />
            </div>
          ) : (
            <core.Label
              className={css.labels}
              value={core.formatMessage(Locale.start_previewing_bulletin)}
            />
          )}
        </core.Flexbox>
      )}
    </>
  );
};

export default SecurityBulletinDrawerContent;
