/**
 * Utility to validate if a CC is expired. CC expiration dates are stored as 'mmYY'. Cards are valid until the end of month of expiration.
 * @param {string} expMonth
 * @param {string} expYear
 * @param {Date} currentDate
 * @returns {boolean} Returns true if expired.
 */
export const isCreditCardExpired = (expMonth, expYear, currentDate) => {
  const ccDate = new Date(2000 + parseInt(expYear), parseInt(expMonth), 0);
  return ccDate < currentDate;
};
