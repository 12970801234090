import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import css from 'src/components/Drawer/SecurityBulletin/SecurityBulletinItem.scss';
import { copyToClipboard } from 'src/services/Utils.js';
import svgCss from 'src/styles/svg.scss';
import cx from 'classnames';
import * as core from 'cw-ui-core';
import Avatar from 'src/components/Avatar/Avatar';
import Locale from 'src/Locale';

const SecurityBulletinItem = props => {
  const {
    securityBulletinItem,
    onSecurityBulletinItemLoaded,
    authorImageUrl
  } = props;
  const { formatDate } = props.intl;

  useEffect(() => {
    onSecurityBulletinItemLoaded(securityBulletinItem);
  }, [securityBulletinItem, onSecurityBulletinItemLoaded]);

  return (
    <core.Flexbox
      className={props.className}
      title={securityBulletinItem.title}
      column
    >
      <core.Label className={css.title} value={securityBulletinItem.title} />
      <div className={css.infoContainer}>
        {securityBulletinItem.authorImageUrl && (
          <core.Image
            className={cx(css.productImage, {
              [css.productImageBackground]: !securityBulletinItem.authorImageUrl
            })}
            src={core.IconPath.get('security')}
            initials={
              !authorImageUrl
                ? core.getInitials(securityBulletinItem.author)
                : undefined
            }
          />
        )}
        {!securityBulletinItem.authorImageUrl && (
          <Avatar
            className={css.authorImage}
            src={authorImageUrl}
            isInlineSVG={false}
            alt={core.formatMessage(Locale.author)}
            initials={
              !authorImageUrl
                ? core.getInitials(securityBulletinItem.author)
                : undefined
            }
            style={
              authorImageUrl
                ? undefined
                : { backgroundColor: '#e6e6e6', color: '#7d7d7d' }
            }
          />
        )}
        <core.Flexbox
          column={props.isMobileLayout}
          className={css.authorContainer}
        >
          {!securityBulletinItem.authorImageUrl && (
            <>
              {!!securityBulletinItem.author && (
                <core.Label
                  className={cx(css.authorName, {
                    CwThemeColor: securityBulletinItem.src !== null
                  })}
                  title={securityBulletinItem.author}
                  value={`${securityBulletinItem.author}${
                    securityBulletinItem.authorTitle ? ' | ' : ''
                  }`}
                />
              )}
              {!securityBulletinItem.authorTitle && (
                <core.Label
                  className={cx(css.authorTitle, 'CwThemeColor')}
                  title={securityBulletinItem.authorTitle}
                  value={securityBulletinItem.authorTitle}
                  grey2
                />
              )}
            </>
          )}
          <core.Label
            className={css.securityItemDate}
            grey3
            value={formatDate(securityBulletinItem.createdDate, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}
          />
        </core.Flexbox>
      </div>
      <div className={css.bulletinDrawerContent}>
        <core.Label
          className={css.content}
          value={securityBulletinItem.content}
          isHTML
        />
      </div>
      {securityBulletinItem.securityBulletinUrl && (
        <core.Flexbox className={css.linkBulletin}>
          <core.Label value={securityBulletinItem.securityBulletinUrl} />
          <core.Image
            automationName="copyToClipboard"
            className={cx(css.copyLink, svgCss.actionState)}
            onClick={() =>
              copyToClipboard(securityBulletinItem.securityBulletinUrl)
            }
            src={core.IconPath.get('Action_Copy')}
          />
        </core.Flexbox>
      )}
    </core.Flexbox>
  );
};

export default injectIntl(core.MobileLayoutHOC(SecurityBulletinItem));
