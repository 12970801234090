import React, { useState, useEffect, useRef } from 'react';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import css from 'src/components/Drawer/AddLicenseDrawer/LicenseDrawer.scss';
import DrawerTitle from 'src/components/Drawer/AddLicenseDrawer/DrawerTitle/DrawerTitle';
import DrawerContent from 'src/components/Drawer/AddLicenseDrawer/DrawerContent/DrawerContent';
import DrawerContentError from 'src/components/Drawer/AddLicenseDrawer/DrawerContent/DrawerContentError';
import DrawerContentUnAuthorizedError from 'src/components/Drawer/AddLicenseDrawer/DrawerContent/DrawerContentUnAuthorizedError';
import DrawerActionButtons from 'src/components/Drawer/AddLicenseDrawer/DrawerActionButtons/DrawerActionButtons';
import { Steps } from 'src/services/Licenses/LicensesService';
import CreateTicketSuccess from 'src/components/CreateTicketSuccess/CreateTicketSuccess';
import NotificationBeforeCloseContainer from 'src/components/Drawer/NotificationBeforeCloseContainer';
import cx from 'classnames';
import { ADD_LICENSE_DRAWER } from 'src/components/Drawer/AddLicenseDrawer/LicenseDrawerContainer';

const License = props => {
  const {
    currentStep,
    licenseError,
    quoteRequested,
    ticketProcessingError,
    ticketId,
    onViewTicket,
    title,
    processingError,
    onTotalQuanityChange,
    onTncClick,
    provisionLicensingInfo,
    getFormattedPriceWithCurrency,
    proratePriceToDisplay,
    onClickQuote,
    errorCode,
    onClickBack,
    onClickContinue,
    contactEmail,
    activationDays,
    licenseName,
    nextMonthName,
    currentMonthName,
    productName,
    isUnAuthorized,
    onSelectQuantity,
    quantityValue,
    onLicenseQuoteCommentChange
  } = props;
  const totalSteps = Object.keys(Steps).length;
  let currentStepNumber = currentStep.slice(-1);
  const [isChecked, setIsChecked] = useState(false);
  const setCheckBoxValue = () => {
    setIsChecked(!isChecked);
  };

  const showButtonsOnSuccess =
    !licenseError &&
    !processingError &&
    !ticketProcessingError &&
    currentStep === Steps.Step_3;
  const [isBusy, setIsBusy] = useState(false);
  const onClickContinueWithLoader = async () => {
    if (!licenseError) {
      if (currentStep === Steps.Step_1) {
        onClickContinue();
      } else {
        setIsBusy(true);
        await onClickContinue();
      }
    } else {
      setIsBusy(true);
      await onClickQuote();
    }
  };

  const titleBox = useRef(null);
  const contentBox = useRef(null);
  const actionBox = useRef(null);
  useEffect(() => {
    let titleHeight;
    let actionHeight;
    const windowHeight = window.innerHeight;

    titleHeight = titleBox.current
      ? titleBox.current.getBoundingClientRect().height
      : null;
    actionHeight = actionBox.current
      ? actionBox.current.getBoundingClientRect().height
      : null;
    const heightToApply = windowHeight - (titleHeight + actionHeight + 48);

    contentBox.current &&
      (contentBox.current.style.height = `${heightToApply}px`);
  });

  return (
    <core.Flexbox column className={css.licenseDrawer}>
      {quoteRequested ? (
        <>
          {!ticketProcessingError ? (
            <CreateTicketSuccess
              ticketId={ticketId}
              onViewTicket={onViewTicket}
            />
          ) : (
            <>
              <DrawerTitle
                title={title}
                showProgressBar={!ticketProcessingError}
              />
              <DrawerContentError
                ticketProcessingError={ticketProcessingError}
              />
              <DrawerActionButtons
                ticketProcessingError={ticketProcessingError}
              />
            </>
          )}
        </>
      ) : (
        <>
          {!isUnAuthorized ? (
            <>
              <div ref={titleBox} className={css.titleContainer}>
                <DrawerTitle
                  title={title}
                  subTitle={core.formatMessage(
                    Locale.step1_add_license_description,
                    {
                      licenseName
                    }
                  )}
                  currentStep={currentStepNumber}
                  totalSteps={totalSteps}
                  showSubTitle={currentStep === Steps.Step_1 && !licenseError}
                  showProgressBar={!licenseError && !processingError}
                />
              </div>
              <NotificationBeforeCloseContainer
                drawerScreenKey={ADD_LICENSE_DRAWER}
              ></NotificationBeforeCloseContainer>
              <div ref={contentBox} className={css.contentContainer}>
                {!licenseError &&
                !processingError ? (
                  <DrawerContent
                    onTotalQuanityChange={onTotalQuanityChange}
                    currentStep={currentStep}
                    checkBoxValue={isChecked}
                    setCheckBoxValue={setCheckBoxValue}
                    onTncClick={onTncClick}
                    provisionLicensingInfo={provisionLicensingInfo}
                    getFormattedPriceWithCurrency={
                      getFormattedPriceWithCurrency
                    }
                    proratePriceToDisplay={proratePriceToDisplay}
                    activationDays={activationDays}
                    licenseName={licenseName}
                    nextMonthName={nextMonthName}
                    currentMonthName={currentMonthName}
                    productName={productName}
                    isBusy={isBusy}
                    onSelectQuantity={onSelectQuantity}
                    quantityValue={quantityValue}
                  />
                ) : (
                  <DrawerContentError
                    onTotalQuanityChange={onTotalQuanityChange}
                    errorCode={errorCode}
                    processingError={processingError}
                    licenseName={licenseName}
                    isBusy={isBusy}
                    onSelectQuantity={onSelectQuantity}
                    quantityValue={quantityValue}
                    onLicenseQuoteCommentChange={onLicenseQuoteCommentChange}
                  />
                )}
              </div>
              <div ref={actionBox}>
                {!showButtonsOnSuccess && (
                  <DrawerActionButtons
                    currentStep={currentStep}
                    onClickBack={onClickBack}
                    onClickContinue={onClickContinueWithLoader}
                    licenseError={licenseError}
                    processingError={processingError}
                    enableConfirm={isChecked}
                    onClickQuote={onClickContinueWithLoader}
                    contactEmail={contactEmail}
                    isBusy={isBusy}
                  />
                )}
              </div>
            </>
          ) : (
            <div
              ref={contentBox}
              className={cx(
                css.contentContainer,
                css.contentUnAutohorizedContainer
              )}
            >
              <DrawerContentUnAuthorizedError licenseName={licenseName} />
            </div>
          )}
        </>
      )}
    </core.Flexbox>
  );
};

export default core.MobileLayoutHOC(License);
