import { GET, POST, getAuthorityUrl, getUrl } from 'src/services/ApiService';

const UserProfileService = () => {
  const apiAuthorityUrl = () => getAuthorityUrl('/api/users/profile');
  const apiAuthorityMfaUrl = () => getAuthorityUrl('/api/users/profile/mfa');
  const apiUrl = () => getUrl('home', '/user');

  const getMfaStatus = async () => {
    return await GET(`${apiAuthorityMfaUrl()}/TwoFactorEnabled`, true, '2.0');
  };

  const getMfaAuthUrl = async () => {
    return await POST(
      `${apiAuthorityMfaUrl()}/GenerateAuthenticatorKey`,
      true,
      '2.0'
    );
  };

  const validateMFAKey = async data => {
    const body = {
      Code: data
    };
    return await POST(
      `${apiAuthorityMfaUrl()}/EnableAuthenticator2fa`,
      body,
      '2.0'
    );
  };

  const getValidMfaProviders = async () => {
    return await GET(
      `${apiAuthorityMfaUrl()}/GetValid2faProviders`,
      true,
      '2.0'
    );
  };

  const turnOnEmailMfa = async () => {
    return await POST(`${apiAuthorityMfaUrl()}/EnableEmail2fa`, true, '2.0');
  };

  const turnOffEmailMfa = async () => {
    return await POST(`${apiAuthorityMfaUrl()}/SendEmail2faCode`, true, '2.0');
  };

  const disableEmailMfa = async data => {
    const body = {
      code: data
    };
    return await POST(`${apiAuthorityMfaUrl()}/DisableEmail2fa`, body, '2.0');
  };

  const disableMFAKey = async data => {
    const body = {
      Code: data
    };
    return await POST(
      `${apiAuthorityMfaUrl()}/DisableAuthenticator2faProvider`,
      body,
      '2.0'
    );
  };

  const updatePassword = async data => {
    return await POST(`${apiAuthorityUrl()}/ChangePassword`, data, '1.0');
  };

  const isSupportEnabled = async () => {
    return await GET(
      getAuthorityUrl('/api/users/IsSupportEnabled'),
      true,
      '1.0'
    );
  };

  const enableSupport = async () => {
    return await POST(getAuthorityUrl('/api/users/EnableSupport'), {}, '1.0');
  };

  const disableSupport = async () => {
    return await POST(getAuthorityUrl('/api/users/DisableSupport'), {}, '1.0');
  };

  const getInactivityTime = async () => {
    return await GET(
      `${apiUrl()}/sessionTime`,
      true,
      '1.0'
    );
  };

  const getMarketingUrl = async () => {
    return await GET(
      `${apiUrl()}/getGlobalPreferenceLink`,
      true,
      '1.0'
    );
  };

  return {
    getMfaStatus,
    getMfaAuthUrl,
    validateMFAKey,
    turnOnEmailMfa,
    turnOffEmailMfa,
    disableEmailMfa,
    disableMFAKey,
    updatePassword,
    getValidMfaProviders,
    isSupportEnabled,
    enableSupport,
    disableSupport,
    getMarketingUrl,
    getInactivityTime
  };
};

export default UserProfileService();
