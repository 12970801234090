import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import BannerPreviewDialog from './BannerPreviewDialog';
import Routes from 'src/services/Routes';
import { BANNER_DRAWER } from '../BannerDrawer/BannerDrawerContainer';

export const BANNER_PREVIEW_DIALOG_ID = 'BANNER_PREVIEW_DIALOG';

const mapStateToProps = state => {
  return {
    toggle:core.getScreenData(state.screen,['isToggleEnabled'], BANNER_DRAWER),
    header: core.getScreenData(state.screen, ['header'], BANNER_PREVIEW_DIALOG_ID),
    subHeader: core.getScreenData(state.screen, ['subHeader'], BANNER_PREVIEW_DIALOG_ID),
    buttonText: core.getScreenData(state.screen, ['buttonText'], BANNER_PREVIEW_DIALOG_ID),
    secondBanner: core.getScreenData(state.screen, ['secondBanner'], BANNER_PREVIEW_DIALOG_ID),
    secondBannerUrl: core.getScreenData(state.screen, ['secondBannerUrl'], BANNER_PREVIEW_DIALOG_ID),
    primaryColor: core.getScreenData(state.screen, ['primaryColor'], BANNER_PREVIEW_DIALOG_ID),
    primaryButtonTextColor: core.getScreenData(state.screen, ['primaryButtonTextColor'], BANNER_PREVIEW_DIALOG_ID),
    firstName: core.getUserFirstName(state.oidc)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(core.hideDialog(BANNER_PREVIEW_DIALOG_ID));
    },
    onCTATestUrlClick: () => {
      const state = core.Store().getState();
      const url = core.getScreenData(state.screen, [
        Routes.BANNER_CONFIGURATION.id,
        'bannerCTAButtonUrl',
      ]);
      if (url) {
        window.open(url);
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerPreviewDialog);
