import { GET, POST, POSTFORMDATA, getUrl, PUT } from 'src/services/ApiService';

const NewsAdminService = () => {
  return {
    getNewsPostsOverview: (allposts, type, page, pageSize) => {
      const apiUrl = getUrl('home', '/admin/news');
      return GET(
        `${apiUrl}/posts/overview?allposts=${allposts}&type=${type}&page=${page}&pageSize=${pageSize}`
      );
    },
    getNewsPostById: id => {
      const apiUrl = getUrl('home', '/admin/news');
      return GET(`${apiUrl}/posts/${id}`);
    },
    addPost: postInfo => {
      const apiUrl = getUrl('home', '/admin/news');
      return POST(`${apiUrl}/posts`, postInfo);
    },
    editPost: (postId, postInfo) => {
      const apiUrl = getUrl('home', '/admin/news');
      return PUT(`${apiUrl}/posts/${postId}`, postInfo);
    },
    postImage: image => {
      const apiUrl = getUrl('home', '/admin/news');
      return POSTFORMDATA(`${apiUrl}/posts/image`, image);
    },
    deletePost: postId => {
      const apiUrl = getUrl('home', '/admin/news');
      return PUT(`${apiUrl}/posts/unpublish/${postId}`, null);
    },
    updatePost: (postId, feedData) => {
      const apiUrl = getUrl('home', '/admin/news');
      return PUT(`${apiUrl}/posts/${postId}`, feedData);
    }
  };
};

export default NewsAdminService();
