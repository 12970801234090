import React from 'react';
import css from 'src/components/PartnerSupportPage/KnownIssues/KnownIssues.scss';
import * as core from 'cw-ui-core';
import { CLOSED_STATUS, ARCHIVED_STATUS, RELEASED_STATUS} from 'src/services/Tickets/KnownIssuesService';

export default function FollowKnownIssuesButton(props) {
  return (
    <>
      {props.knownIssue.isFollowedByUser ? (
        <core.ButtonStandard
          automationName="followed_by_user"
          value={`+ ${props.knownIssue.follows}`}
          className={css.issueButton}
          onClick={e => {
            props.onFollowKnownIssuesClicked(props.knownIssue, false);
            e.stopPropagation();
          }}
        />
      ) : (

        <core.ButtonLight
          automationName="follow_button"
          value={`+ ${props.knownIssue.follows}`}
          className={css.issueButton}
          disabled={props.knownIssue.status.includes(CLOSED_STATUS) || props.knownIssue.status === ARCHIVED_STATUS ||
            props.knownIssue.status === RELEASED_STATUS }
          onClick={e => {
            props.onFollowKnownIssuesClicked(props.knownIssue, true);
            e.stopPropagation();
          }}
        />
      )}
    </>
  );
}
