import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import UniteProductDrawer from 'src/components/Drawer/ProductDrawer/UniteProductDrawer';
import Locale from 'src/Locale';
import { addToCart } from 'src/services/ShoppingCart/CartService';
import {
  startTrial,
  getProductStatusLocaleKey,
  getTileButtonTextLocaleKey,
  contactSupport
} from 'src/services/Products/ProductsService';
import { WALLET_DRAWER } from 'src/components/Drawer/PartnerWalletDrawer/WalletDrawerContainer';

const SIMPLE_IT_FOR_CISCO = 'Simple IT for Cisco';
const IAAS = 'IaaS (Infrastructure as a Service)';
const PRODUCTIVITY = 'Productivity';

export const UNITE_PRODUCT_DRAWER = 'UNITE_PRODUCT_DRAWER';

const getCategoryEnabled = (categoryName, product) => {
  const category =
    product &&
    product.activeLicenses &&
    product.activeLicenses.find(l => l.skuDescription === categoryName);
  return !category || category.isBillable;
};

const mapStateToProps = state => {
  const dateFormat = { month: 'long', year: 'numeric', day: 'numeric' };
  const productChk = core.getScreenData(
    state.screen,
    ['product'],
    UNITE_PRODUCT_DRAWER
  );
  const product = productChk ? productChk.toJS() : undefined;
  const pricingPlansChk = core.getScreenData(
    state.screen,
    ['pricingPlans'],
    UNITE_PRODUCT_DRAWER
  );
  const pricingPlans = pricingPlansChk ? pricingPlansChk.toJS() : [];

  const currencyFormat = {
    style: 'currency',
    currency: pricingPlans[0].currency,
    minimumFractionDigits: 0
  };

  const tileButtonTextLocaleKey = getTileButtonTextLocaleKey(product);
  const isBusy =
    core.getScreenData(state.screen, ['isProcessingCardButtonClick']) === true;

  return {
    productName: core.formatMessage(Locale.drawer_unite_title),
    productDescription: core.formatMessage(Locale.drawer_unite_description),
    productLearnMoreText: core.formatMessage(Locale.learn_more),
    planDescription: core.formatMessage(Locale.drawer_unite_plan_description),
    isBusy,
    planBillingDescription: product.purchased
      ? `${core.formatMessage(
          Locale.unite_billing_description
        )} <strong>${core.formatNumber(pricingPlans[0].price, currencyFormat)}${
          pricingPlans[0].customBillingText
        }</strong> ${core.formatMessage(
          Locale.unite_billing_payment_method
        )} <strong>${core.formatDate(
          new Date(product.billNextDateUnixTimeSeconds * 1000),
          dateFormat
        )}</strong>.`
      : '',
    plan: product && {
      name: core.formatMessage(Locale.unite_billed_categories),
      showButton: tileButtonTextLocaleKey ? true : false,
      buttonText:
        tileButtonTextLocaleKey &&
        core.formatMessage(Locale[tileButtonTextLocaleKey]),
      automationName: `Unite_${getTileButtonTextLocaleKey(product)}`,
      price: core.formatNumber(pricingPlans[0].price, currencyFormat),
      priceInterval: `/${core.formatMessage(Locale.license_per_month)}`,
      billingType: core.formatMessage(Locale.unite_billing_type),
      status: core.formatMessage(Locale[getProductStatusLocaleKey(product)], {
        days: product.daysLeftInTrial
      }),
      billedCategories: [
        {
          name: SIMPLE_IT_FOR_CISCO,
          description: core.formatMessage(Locale.unite_category_simple),
          enabled: getCategoryEnabled(SIMPLE_IT_FOR_CISCO, product)
        },
        {
          name: IAAS,
          description: core.formatMessage(Locale.unite_category_iaas),
          enabled: getCategoryEnabled(IAAS, product)
        },
        {
          name: PRODUCTIVITY,
          description: core.formatMessage(Locale.unite_category_productivity),
          enabled: getCategoryEnabled(PRODUCTIVITY, product)
        }
      ],
      comment: '*Billed monthly',
      statusKey: getProductStatusLocaleKey(product)
    }
  };
};

const showWalletDrawer = () => {
  const dispatch = core.Store().dispatch;
  dispatch(core.hideDrawer(UNITE_PRODUCT_DRAWER));
  dispatch(core.showDrawer(WALLET_DRAWER));
};

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(core.hideDrawer(UNITE_PRODUCT_DRAWER));
      dispatch(core.setScreenData(['isProcessingCardButtonClick'], false));
    },
    onUniversityLinkClick: () => {
      window.open('https://docs.connectwise.com/ConnectWise_Unite');
    },
    onCardButtonClick: async () => {
      dispatch(core.setScreenData(['isProcessingCardButtonClick'], true));
      const state = core.Store().getState();
      const productChk = core.getScreenData(
        state.screen,
        ['product'],
        UNITE_PRODUCT_DRAWER
      );
      const product = productChk ? productChk.toJS() : undefined;
      let result;
      if (product.allowFreeTrial) {
        result = await startTrial(product);
      }

      if (product.failed) {
        contactSupport();
        return;
      }

      if (product.purchased && product.paymentPastDue) {
        result = await showWalletDrawer();
      }

      if (product.purchased) {
        const partnerId = core.getPartnerId(state.oidc);
        const user = core.getUser(state.oidc);
        const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
        const stsBaseUrl = core.getModuleConfig(state.module, ['authority']);
        if (isGuestUserLoggedIn) {
          result = window.open(
            stsBaseUrl +
              '/account/switch?clientId=cwhome-backend&accountId=' +
              partnerId +
              '&redirectUri=' +
              product.launchUrl
          );
        } else {
          result = window.open(product.launchUrl);
        }
      }

      if (product.trialExpired || product.inTrial || product.cancelled) {
        result = await addToCart(product);
      }

      dispatch(core.setScreenData(['isProcessingCardButtonClick'], false));
      return result;
    }
  };
};

const UniteProductDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UniteProductDrawer);

export default injectIntl(UniteProductDrawerContainer);
