import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/ProductDrawer/ManageProductDrawer.scss';
import cssCommon from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon.scss';
import cx from 'classnames';
import { ProductHeader } from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import Locale from 'src/Locale';

const ManageProductDrawer = props => {
  const PlanCardButton = ({
    toolTip,
    disabled,
    buttonText,
    onCardButtonClick,
    automationName
  }) => {
    return (
      <core.ButtonLight
        toolTip={toolTip}
        disabled={disabled}
        automationName={automationName}
        className={css.planCardButton}
        value={buttonText}
        onClick={onCardButtonClick}
      />
    );
  };

  const PlanCardButtonWithLoadingIndicator = WithButtonLoadingIndicatorHOC(
    PlanCardButton
  );

  return (
    <core.Drawer
      automationName="manageDrawer"
      onCloseIconClicked={props.onClose}
      closeOnBlur
    >
      <div className={cx(css.scrollable, cssCommon.scrollable)}>
        <core.Flexbox className={css.container} column>
          <ProductHeader
            {...props}
            productIconClassName={css.productIconHeader}
            descriptionClassName={css.productDescription}
            dividerLineClassName={css.dividerLineHeader}
          />
          <core.Flexbox className={css.bannerHeader} column>
            <core.Flexbox column className={css.banner}>
              <core.Label
                value={props.bannerHeader}
                className={css.bannerHeaderText}
              />
              <core.Label
                value={props.bannerDescription}
                className={css.bannerDescription}
              />
            </core.Flexbox>
            <core.Flexbox
              column={props.isMobileLayout}
              className={css.planContainer}
            >
              {props.plans.map(plan => (
                <div title={props.buttonTitle} key={plan.name}>
                  <PlanCardButtonWithLoadingIndicator
                    automationName={`planCardLoaderButton_${plan.automationName}`}
                    buttonText={plan.buttonText}
                    onCardButtonClick={() => props.onCardButtonClick(plan)}
                    isBusy={
                      plan.name === 'Demo'
                        ? props.isBusyDemo
                        : props.isBusyQuote
                    }
                    wrapperStyle={{
                      bottom: '0',
                      width: '188px',
                      alignSelf: 'center',
                      fontSize: '14px'
                    }}
                    disabled={!props.isDemoOrQuoteEnable}
                    planCardButtonClassName={css.planCardButton}
                  />
                </div>
              ))}
            </core.Flexbox>
          </core.Flexbox>
          <core.Flexbox column>
            <core.Label
              value={props.benefitHeader}
              className={css.benefitHeader}
            ></core.Label>
            <core.Flexbox column className={css.benefits}>
              {props.benefits.map(benefit => (
                <core.Flexbox
                  key={benefit.name}
                  className={css.benefit}
                  column={props.isMobileLayout}
                >
                  <core.Flexbox>
                    <core.Image
                      cwId="benefitImage"
                      isInlineSVG={false}
                      src={benefit.imageSrc}
                      className={css.benefitImage}
                      alt={core.formatMessage(Locale.manage_benefit)}
                    ></core.Image>
                    <core.Flexbox column className={css.benefitMessage}>
                      <core.Label
                        value={benefit.title}
                        className={css.textBold}
                      ></core.Label>
                      <core.Label value={benefit.description}></core.Label>
                    </core.Flexbox>
                  </core.Flexbox>
                </core.Flexbox>
              ))}
            </core.Flexbox>
          </core.Flexbox>
          <core.Label
            value={core.formatMessage(Locale.drawer_manage_product_owned)}
            className={css.textBold}
          />
          <core.Label
            automationName="manageConnectAccount"
            isLink
            className={css.connectPlan}
            value={core.formatMessage(Locale.drawer_product_owned_text)}
            onClick={props.onConnectLinkClick}
          />
        </core.Flexbox>
      </div>
    </core.Drawer>
  );
};

export default core.MobileLayoutHOC(ManageProductDrawer);
