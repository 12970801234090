import { GET, POST, DELETE, getUrl } from 'src/services/ApiService';

const KnownIssuesService = () => {
  const apiUrl = () => getUrl('home', '/knownissues');
  return {
    getknownIssues: (
      pageNumber,
      pageLimit,
      filters = {},
      orderBy = '',
      sort = ''
    ) => {
      const apiUrl = getUrl('home', '/knownissues');

      const params = new URLSearchParams({
        page: pageNumber,
        pageSize: pageLimit,
        products: JSON.stringify(filters.products ? filters.products : []),
        components: JSON.stringify(
          filters.components ? filters.components : []
        ),
        statuses: JSON.stringify(filters.statuses ? filters.statuses : []),
        fixVersions: JSON.stringify(
          filters.fixVersions ? filters.fixVersions : []
        ),
        searchBy: filters.searchBy ? filters.searchBy : '',
        isDefault:
          filters.isDefault !== undefined && filters.isDefault !== null
            ? filters.isDefault
            : true,
        orderBy: orderBy ? orderBy : '',
        sort: sort ? sort : '',
        isMyWatchedIssue:
          (filters.myWatchedIssues && filters.myWatchedIssues.length) > 0
            ? true
            : false
      });

      return GET(`${apiUrl}?${params}`);
    },
    getknownIssuesFilters: () => {
      const apiUrl = getUrl('home', '/knownissues/filterfields');
      return GET(apiUrl);
    },
    getKnownIssueById: id => {
      return GET(`${apiUrl()}/${id}`);
    },
    followKnownIssue: (jiraId, userId, notify) => {
      const followData = {
        jiraId,
        userId,
        notify
      };
      return POST(`${apiUrl()}/follow`, followData);
    },
    deleteFollowKnownIssue: (jiraId, userId) => {
      return DELETE(`${apiUrl()}/follow/${userId}/${jiraId}`);
    },
    sendEmailLink: (jiraId, email, jiraKey, jiraSummary) => {
      const emailRequestModel = {
        jiraId,
        email,
        jiraKey,
        jiraSummary
      };
      return POST(`${apiUrl()}/sendemaillink`, emailRequestModel);
    }
  };
};

export default KnownIssuesService();
