import React from 'react';
import { Image, Flexbox } from 'cw-ui-core';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import css from './DrawerContentUnAuthorizedError.scss';

const DrawerContentUnAuthorizedError = props => {
  const licenseNameLowercase = props.licenseName
    ? props.licenseName.toLowerCase()
    : null;
  return (
    <Flexbox className={css.container} column>
      <core.Label
        className={css.text}
        grey7
        value={core.formatMessage(Locale.license_unauthorized_text, {
          licenseName: licenseNameLowercase
        })}
      />
      <Image
        className={css.image}
        src="/images/unauthorized.svg"
        alt={core.formatMessage(Locale.unauthorized_text)}
      />
    </Flexbox>
  );
};

export default injectIntl(DrawerContentUnAuthorizedError);
