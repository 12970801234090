import * as core from 'cw-ui-core';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { onAddLicensesCall } from 'src/services/Licenses/LicensesService.js';
import LicenseDrawer from 'src/components/Drawer/AddLicenseDrawer/LicenseDrawer';
import { showNotificationBeforeClose } from 'src/services/DrawerNotification/DrawerNotification';
export const ADD_LICENSE_DRAWER = 'ADD_LICENSE_DRAWER';

const mapStateToProps = state => {
  const isReady = core.getScreenData(
    state.screen,
    'isReady',
    ADD_LICENSE_DRAWER
  );
  const currentStep = core.getScreenData(
    state.screen,
    'step',
    ADD_LICENSE_DRAWER
  );
  return {
    isLoading: !isReady,
    step: currentStep
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseIconClicked: () => {
      dispatch(core.hideDrawer(ADD_LICENSE_DRAWER));
    },
    onBeforeClose: result => {
      const isDrawerUpdated = core.getScreenData(
        core.Store().getState().screen,
        'isDrawerUpdated',
        ADD_LICENSE_DRAWER
      );
      if (isDrawerUpdated) {
        showNotificationBeforeClose(ADD_LICENSE_DRAWER);
      }

      result.isCancel = isDrawerUpdated;
    },
    onDidMount: async () => {
      dispatch(core.setScreenData('isReady', false, ADD_LICENSE_DRAWER));
      await onAddLicensesCall();
      dispatch(core.setScreenData('isReady', true, ADD_LICENSE_DRAWER));
    }
  };
};

const LicenseDrawerConatiner = connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseDrawer);
export default injectIntl(LicenseDrawerConatiner);
