import { defineMessages } from 'react-intl';

const Locale = defineMessages({
  logout: {
    id: 'cw-home.logout',
    defaultMessage: 'Logout'
  },
  updateHomeProfile: {
    id: 'cw-home.updateHomeProfile',
    defaultMessage: 'Update Home profile'
  },
  updateProfileAndPreferenceCenter: {
    id: 'cw-home.updateProfileAndPreferenceCenter',
    defaultMessage: 'Update profile and preference center'
  },
  home: {
    id: 'cw-home.home',
    defaultMessage: 'Home'
  },
  landing_page_copyright_message: {
    id: 'cw-home.landing_page_copyright_message',
    defaultMessage: '©{currentYear} ConnectWise, LLC'
  },
  landing_page_about: {
    id: 'cw-home.landing_page_about',
    defaultMessage: 'About'
  },
  landing_page_privacy: {
    id: 'cw-home.landing_page_privacy',
    defaultMessage: 'Privacy'
  },
  contact_us: {
    id: 'cw-home.contact_us ',
    defaultMessage: 'Contact Us'
  },
  welcome: {
    id: 'cw-home.welcome',
    defaultMessage: 'Welcome Home, {firstName}!'
  },
  settings: {
    id: 'cw-home.settings',
    defaultMessage: 'Settings'
  },
  continue: {
    id: 'cw-home.continue',
    defaultMessage: 'Continue'
  },
  shopping_cart: {
    id: 'cw-home.shopping_cart',
    defaultMessage: 'Shopping Cart'
  },
  select_payment: {
    id: 'cw-home.select_payment',
    defaultMessage: 'Select Payment'
  },
  back_to_marketplace: {
    id: 'cw-home.back_to_marketplace',
    defaultMessage: 'Back to Marketplace'
  },
  back: {
    id: 'cw-home.back',
    defaultMessage: 'Back'
  },
  back_to_shopping_cart: {
    id: 'cw-home.back_to_shopping_cart',
    defaultMessage: 'Back to Shopping Cart'
  },
  back_to_select_payment: {
    id: 'cw-home.back_to_select_payment',
    defaultMessage: 'Back to Select Payment'
  },
  payment: {
    id: 'cw-home.payment',
    defaultMessage: 'Payment'
  },
  setup: {
    id: 'cw-home.setup',
    defaultMessage: 'Setup'
  },
  done: {
    id: 'cw-home.done',
    defaultMessage: 'Done'
  },
  country: {
    id: 'cw-home.country',
    defaultMessage: 'Country'
  },
  state_province: {
    id: 'cw-home.state_province',
    defaultMessage: 'State/Province'
  },
  zip_postalcode: {
    id: 'cw-home.zip_postalcode',
    defaultMessage: 'Zip/Postal Code'
  },
  phone: {
    id: 'cw-home.phone',
    defaultMessage: 'Phone'
  },
  company_info: {
    id: 'cw-home.company_info',
    defaultMessage: 'Company Info'
  },
  remove: {
    id: 'cw-home.remove',
    defaultMessage: 'Remove'
  },
  billing: {
    id: 'cw-home.billing',
    defaultMessage: 'Billing'
  },
  invoices: {
    id: 'cw-home.invoices',
    defaultMessage: 'Invoices'
  },
  all: {
    id: 'cw-home.all',
    defaultMessage: 'All'
  },
  outstanding: {
    id: 'cw-home.outstanding',
    defaultMessage: 'Outstanding'
  },
  pay: {
    id: 'cw-home.pay',
    defaultMessage: 'Pay'
  },
  paid: {
    id: 'cw-home.paid',
    defaultMessage: 'Paid'
  },
  no_manage_invoices: {
    id: 'cw-home.no_invoices',
    defaultMessage: 'There are no ConnectWise invoices to display'
  },
  no_paid_invoices: {
    id: 'cw-home.no_paid_invoices',
    defaultMessage: 'There are no paid invoices to display'
  },
  no_outstanding_invoices: {
    id: 'cw-home.no_outstanding_invoices',
    defaultMessage: 'There are no outstanding invoices to display'
  },
  invoice_due: {
    id: 'cw-home.invoice_due',
    defaultMessage: 'Due {dueDate}'
  },
  invoice_paid: {
    id: 'cw-home.invoice_paid',
    defaultMessage: 'Paid on {paidDate}'
  },
  invoice_paid_no_date: {
    id: 'cw-home.invoice_paid_no_date',
    defaultMessage: 'Paid'
  },
  invoice_number: {
    id: 'cw-home.invoice_number',
    defaultMessage: 'Invoice #{invoiceNumber}'
  },
  view_invoice_pdf: {
    id: 'cw-home.view_invoice_pdf',
    defaultMessage: 'View invoice'
  },
  view_wallet: {
    id: 'cw-home.view_wallet',
    defaultMessage: 'View Wallet'
  },
  connectWise_wallet: {
    id: 'cw-home.connectWise_wallet',
    defaultMessage: 'ConnectWise Wallet'
  },
  wallet: {
    id: 'cw-home.wallet',
    defaultMessage: 'Wallet'
  },
  retry_payment: {
    id: 'cw-home.retry_payment',
    defaultMessage: 'Retry Payment'
  },
  filter_field: {
    id: 'cw-home.filter_field',
    defaultMessage: 'Filter...'
  },
  quantity: {
    id: 'cw-home.quantity',
    defaultMessage: 'Quantity'
  },
  connectwise_master_agreement: {
    id: 'cw-home.connectwise_master_agreement',
    defaultMessage: 'ConnectWise Master Agreement'
  },
  order_summary: {
    id: 'cw-home.order_summary',
    defaultMessage: 'Order Summary'
  },
  payment_due_today: {
    id: 'cw-home.payment_due_today',
    defaultMessage: 'Payment due today'
  },
  how_charged: {
    id: 'cw-home.how_charged',
    defaultMessage: 'How will I be charged?'
  },
  order_summary_tax_clause: {
    id: 'cw-home.order_summary_tax_clause',
    defaultMessage:
      'Taxes and other fees may apply. We reserve the right to cancel orders arising from pricing or other errors.'
  },
  order_summary_agreement_clause: {
    id: 'cw-home.order_summary_agreement_clause',
    defaultMessage:
      'By clicking this check box, I authorize a charge to my account on a recurring basis and I agree to the '
  },
  view_details: {
    id: 'cw-home.view_details',
    defaultMessage: 'View Details'
  },
  partner_code_applied: {
    id: 'cw-home.partner_code_applied',
    defaultMessage: 'Partner code applied'
  },
  first_bill: {
    id: 'cw-home.first_bill',
    defaultMessage: 'First bill'
  },
  invalid_card: {
    id: 'cw-home.invalid_card',
    defaultMessage: 'Invalid card'
  },
  invalid_card_remove_card_msg: {
    id: 'cw-home.invalid_card_remove_card_msg',
    defaultMessage:
      'For security reasons, this card has been removed from your account. Please re-enter your credit card information and try again.'
  },
  invalid_card_last_attempt_msg: {
    id: 'cw-home.invalid_card_last_attempt_msg',
    defaultMessage:
      'Verify your information is correct and try again. Your card will be removed on your next unsuccessful attempt.'
  },
  ok: {
    id: 'cw-home.ok',
    defaultMessage: 'Ok'
  },
  amex_currency_not_accepted: {
    id: 'cw-home.amex_currency_not_accepted',
    defaultMessage:
      'American Express credit cards are not accepted in your region.'
  },
  billing_currency_not_found: {
    id: 'cw-home.billing_currency_not_found',
    defaultMessage: 'Failed to get billing currency.'
  },
  usage: {
    id: 'cw-home.usage',
    defaultMessage: 'usage'
  },
  prorate: {
    id: 'cw-home.prorate',
    defaultMessage: 'Prorate'
  },
  credit: {
    id: 'cw-home.credit',
    defaultMessage: 'Credit'
  },
  vat_gst: {
    id: 'cw-home.vat_gst',
    defaultMessage: 'VAT/GST #'
  },
  tax_amount: {
    id: 'cw-home.tax_amount',
    defaultMessage: 'Tax Amount'
  },
  shopping_cart_access_denied_msg: {
    id: 'cw-home.shopping_cart_access_denied_msg',
    defaultMessage:
      'Access to the requested page is forbidden at your current permission level. Contact your account administrator.'
  },
  application_access_denied_msg: {
    id: 'cw-home.application_access_denied_msg',
    defaultMessage: 'Sorry, you do not have access to this application.'
  },
  server_not_available_msg: {
    id: 'cw-home.server_not_available_msg',
    defaultMessage:
      'Sorry, we are not able to contact the ConnectWise Home server. Please try again later.'
  },
  server_not_available_title: {
    id: 'cw-home.server_not_available_title',
    defaultMessage: 'Server not available'
  },
  access_forbidden: {
    id: 'cw-home.access_forbidden',
    defaultMessage: 'Access forbidden'
  },
  access_denied: {
    id: 'cw-home.access_denied',
    defaultMessage: 'Access denied'
  },
  optional: {
    id: 'cw-home.optional',
    defaultMessage: 'Optional'
  },
  apply: {
    id: 'cw-home.apply',
    defaultMessage: 'Apply'
  },
  update: {
    id: 'cw-home.update',
    defaultMessage: 'Update'
  },
  cancel: {
    id: 'cw-home.cancel',
    defaultMessage: 'Cancel'
  },
  remove_item_from_cart: {
    id: 'cw-home.remove_item_from_cart',
    defaultMessage: 'Remove item from cart?'
  },
  remove_item_confirmation: {
    id: 'cw-home.remove_item_confirmation',
    defaultMessage:
      'Are you sure you want to remove {cartDescription} from your cart?'
  },
  place_your_order: {
    id: 'cw-home.place_your_order',
    defaultMessage: 'Place your order'
  },
  cart_empty: {
    id: 'cw-home.cart_empty',
    defaultMessage: 'Your cart is empty.'
  },
  sku: {
    id: 'cw-home.sku',
    defaultMessage: 'SKU'
  },
  review_your_order: {
    id: 'cw-home.review_your_order',
    defaultMessage: 'Review your order'
  },
  billing_info: {
    id: 'cw-home.billing_info',
    defaultMessage: 'Billing Info'
  },
  total: {
    id: 'cw-home.total',
    defaultMessage: 'Total'
  },
  date: {
    id: 'cw-home.date',
    defaultMessage: 'Date'
  },
  order: {
    id: 'cw-home.order',
    defaultMessage: 'Order'
  },
  address: {
    id: 'cw-home.address',
    defaultMessage: 'Address'
  },
  thank_your_for_order: {
    id: 'cw-home.thank_you_for_order',
    defaultMessage: 'Thank you for placing your order!'
  },
  summary: {
    id: 'cw-home.summary',
    defaultMessage: 'Summary'
  },
  almost_done: {
    id: 'cw-home.almost_done',
    defaultMessage: 'Almost Done'
  },
  back_to_account: {
    id: 'cw-home.back_to_account',
    defaultMessage: 'Back to Account'
  },
  complete_setup_marketplace_product: {
    id: 'cw-home.complete_setup_marketplace_product',
    defaultMessage: 'Account configuration is being initiated.'
  },
  university: {
    id: 'cw-home.university',
    defaultMessage: 'University'
  },
  documentation: {
    id: 'cw-home.documentation',
    defaultMessage: 'documentation'
  },
  support: {
    id: 'cw-home.support',
    defaultMessage: 'Support'
  },
  users_menu_hover_text: {
    id: 'cw-home.users_menu_hover_text',
    defaultMessage: 'User & Application Settings'
  },
  menu: {
    id: 'cw-home.menu',
    defaultMessage: 'Menu'
  },
  close: {
    id: 'cw-home.close',
    defaultMessage: 'Close'
  },
  user_profile: {
    id: 'cw-home.user_profile',
    defaultMessage: 'User Profile'
  },
  connectwise_hub: {
    id: 'cw-home.connectwise_hub',
    defaultMessage: 'My ConnectWise Hub'
  },
  products: {
    id: 'cw-home.products',
    defaultMessage: 'Products'
  },
  distributors: {
    id: 'cw-home.distributors',
    defaultMessage: 'Distributors'
  },
  discover: {
    id: 'cw-home.discover',
    defaultMessage: 'Discover'
  },
  services: {
    id: 'cw-home.services',
    defaultMessage: 'Services'
  },
  in_trial: {
    id: 'cw-home.in_trial',
    defaultMessage: 'In trial'
  },
  try_free: {
    id: 'cw-home.try_free',
    defaultMessage: 'Try for free'
  },
  not_found_title: {
    id: 'cw-home.not_found_title',
    defaultMessage: 'Not Found'
  },
  not_found_subtitle: {
    id: 'cw-home.not_found_subtitle',
    defaultMessage: 'There was a problem'
  },
  take_me_home: {
    id: 'cw-home.take_me_home',
    defaultMessage: 'Take Me Home'
  },
  my_success_manager: {
    id: 'cw-home.my_success_manager',
    defaultMessage: 'My ConnectWise Success Manager'
  },
  success_manager_mobile: {
    id: 'cw-home.success_manager_mobile',
    defaultMessage: 'My Success Manager'
  },
  trial_expired: {
    id: 'cw-home.trial_expired',
    defaultMessage: 'Trial expired'
  },
  payment_past_due: {
    id: 'cw-home.payment_past_due',
    defaultMessage: 'Payment past due'
  },
  cancelled: {
    id: 'cw-home.cancelled',
    defaultMessage: 'Cancelled'
  },
  launch: {
    id: 'cw-home.launch',
    defaultMessage: 'Launch'
  },
  start_trial: {
    id: 'cw-home.start_trial',
    defaultMessage: 'Start a Trial'
  },
  start_30_day_trial: {
    id: 'cw-home.start_30_day_trial',
    defaultMessage: 'Start 30 Day Trial'
  },
  trial_creation_title: {
    id: 'cw-home.trial_creation_title',
    defaultMessage: `Thank you for starting your trial of {productName}!`
  },
  trial_creation_expiry_date: {
    id: 'cw-home.trial_creation_expiry_date',
    defaultMessage: 'Your trial is active until {expirationDate}. '
  },
  trial_creation_message: {
    id: 'cw-home.trial_creation_message',
    defaultMessage:
      'You can launch or purchase this product at any time from the Products tab of the ConnectWise Hub.'
  },
  add_to_cart: {
    id: 'cw-home.add_to_cart',
    defaultMessage: 'Add to Cart'
  },
  learn_more: {
    id: 'cw-home.learn_more',
    defaultMessage: 'Learn More'
  },
  purchase: {
    id: 'cw-home.purchase',
    defaultMessage: 'Purchase'
  },
  your_plan: {
    id: 'cw-home.your_plan',
    defaultMessage: 'Your plan'
  },
  days_in_trial: {
    id: 'cw-home.days_in_trial',
    defaultMessage: '{days} days left in trial'
  },
  chatbot_header_text: {
    id: 'cw-home.chatbot_header_text',
    defaultMessage: 'Need Assistance?'
  },
  newspod: {
    id: 'cw-home.newspod',
    defaultMessage: "What's New"
  },
  see_more_text: {
    id: 'cw-home.see_more_text',
    defaultMessage: 'See More'
  },
  added_to_cart: {
    id: 'cw-home.added_to_cart',
    defaultMessage: 'Added to Cart'
  },
  view_cart: {
    id: 'cw-home.view_cart',
    defaultMessage: 'View Cart'
  },
  continue_shopping: {
    id: 'cw-home.continue_shopping',
    defaultMessage: 'Continue Shopping'
  },
  step1_title: {
    id: 'cw-home.step1_title',
    defaultMessage: 'How can we help you?'
  },
  step1_description: {
    id: 'cw-home.step1_description',
    defaultMessage: 'Please select your category based on your needs'
  },
  step1_stepTitle: {
    id: 'cw-home.step1_stepTitle',
    defaultMessage: 'Step 1 of 4'
  },
  step2_title: {
    id: 'cw-home.step2_title',
    defaultMessage: 'Select your issue'
  },
  step2_description: {
    id: 'cw-home.step2_description',
    defaultMessage: 'Please select your category based on your needs'
  },
  step2_stepTitle: {
    id: 'cw-home.step2_stepTitle',
    defaultMessage: 'Step 2 of 4'
  },
  step3_stepTitle: {
    id: 'cw-home.step3_stepTitle',
    defaultMessage: 'Step 3 of 4'
  },
  step4_stepTitle: {
    id: 'cw-home.step4_stepTitle',
    defaultMessage: 'Step 4 of 4'
  },
  step3and4_title: {
    id: 'cw-home.step4_title',
    defaultMessage: 'Tell us more about your issue'
  },
  step3and4_description: {
    id: 'cw-home.step4_description',
    defaultMessage: 'Please describe the issues you are having in detail.'
  },
  send: {
    id: 'cw-home.send',
    defaultMessage: 'Send'
  },
  send_message: {
    id: 'cw-home.send_message',
    defaultMessage: 'Send Message...'
  },
  send_message_disable: {
    id: 'cw-home.send_message_disable',
    defaultMessage: 'This ticket cannot be updated. Please create a new ticket'
  },
  submitted_at: {
    id: 'cw-home.submitted_at',
    defaultMessage: 'Submitted {date} at {time}'
  },
  contact: {
    id: 'cw-home.contact',
    defaultMessage: 'Contact'
  },
  status: {
    id: 'cw-home.status',
    defaultMessage: 'Status'
  },
  technician: {
    id: 'cw-home.technician',
    defaultMessage: 'Technician'
  },
  unassigned: {
    id: 'cw-home.unassigned',
    defaultMessage: 'Unassigned'
  },
  update_ticket_status: {
    id: 'cw-home.update_ticket_status',
    defaultMessage: 'Update the status on ticket number {ticketId}'
  },
  change_status: {
    id: 'cw-home.change_status',
    defaultMessage: 'Change Status'
  },
  attachment: {
    id: 'cw-home.attachment',
    defaultMessage: 'Attachment'
  },
  billing_outstanding: {
    id: 'cw-home.billing_outstanding',
    defaultMessage: 'Outstanding'
  },
  billing_partial_payment: {
    id: 'cw-home.billing_partial_payment',
    defaultMessage: 'Partial Payment'
  },
  billing_past_due: {
    id: 'cw-home.billing_past_due',
    defaultMessage: 'Past Due'
  },
  billing_paid: {
    id: 'cw-home.billing_paid',
    defaultMessage: 'Paid'
  },
  billing_payment_failed: {
    id: 'cw-home.billing_payment_failed',
    defaultMessage: 'Payment Failed'
  },
  billing_due_date: {
    id: 'cw-home.due_date',
    defaultMessage: 'DUE DATE'
  },
  billing_invoice_number: {
    id: 'cw-home.billing_invoice_number',
    defaultMessage: 'INVOICE NUMBER'
  },
  billing_payment_type: {
    id: 'cw-home.billing_payment_type',
    defaultMessage: 'PAYMENT TYPE'
  },
  billing_invoice_amount: {
    id: 'cw-home.billing_invoice_amount',
    defaultMessage: 'INVOICE AMOUNT'
  },
  billing_current_balance: {
    id: 'cw-home.billing_current_balance',
    defaultMessage: 'AMOUNT DUE'
  },
  billing_status: {
    id: 'cw-home.billing_status',
    defaultMessage: 'STATUS'
  },
  billing_mobile_invoice_number: {
    id: 'cw-home.billing_mobile_invoice_number',
    defaultMessage: 'INVOICE #'
  },
  invoice_status_message: {
    id: 'cw-home.invoice_status_message',
    defaultMessage:
      'The invoices status can take 1 to 5 business days to sync with ePay. Please check the ePay Portal for the most up to date invoice status.'
  },
  manage_Support: {
    id: 'cw-home.manage_Support',
    defaultMessage: 'Manage Support'
  },
  automate_Support: {
    id: 'cw-home.automate_Support',
    defaultMessage: 'Automate Support'
  },
  sell_Support: {
    id: 'cw-home.sell_Support',
    defaultMessage: 'Sell Support'
  },
  control_Support: {
    id: 'cw-home.control_Support',
    defaultMessage: 'ScreenConnect Support'
  },
  identify_Support: {
    id: 'cw-home.identify_Support',
    defaultMessage: 'Identify Support'
  },
  unite_Support: {
    id: 'cw-home.unite_Support',
    defaultMessage: 'Unite Support'
  },
  need_Help: {
    id: 'cw-home.need_Help',
    defaultMessage: 'Need Help'
  },
  help: {
    id: 'cw-home.help',
    defaultMessage: 'Help'
  },
  reset: {
    id: 'cw-home.reset',
    defaultMessage: 'Reset'
  },
  security: {
    id: 'cw-home.security',
    defaultMessage: 'Security'
  },
  dashboard: {
    id: 'cw-home.dashboard',
    defaultMessage: 'Dashboard'
  },
  quotes: {
    id: 'cw-home.quotes',
    defaultMessage: 'Quotes'
  },
  success_List: {
    id: 'cw-home.success_List',
    defaultMessage: 'Success List'
  },
  achievements: {
    id: 'cw-home.achievements',
    defaultMessage: 'Achievements'
  },
  desktop_Admin: {
    id: 'cw-home.desktop_Admin',
    defaultMessage: 'Desktop Admin'
  },
  risk_Assessments: {
    id: 'cw-home.risk_Assessments',
    defaultMessage: 'Risk Assessments'
  },
  dashboards: {
    id: 'cw-home.dashboards',
    defaultMessage: 'Dashboards'
  },
  billings: {
    id: 'cw-home.billings',
    defaultMessage: 'Billings'
  },
  events: {
    id: 'cw-home.events',
    defaultMessage: 'Events'
  },
  addAttachment: {
    id: 'cw-home.addAttachment',
    defaultMessage: 'Add attachments here'
  },
  field_is_required: {
    id: 'cw-home.field_is_required',
    defaultMessage: '{field} is required.'
  },
  change_contact: {
    id: 'cw-home.change_contact',
    defaultMessage: 'Change Contact'
  },
  change_contact_on_ticket: {
    id: 'cw-home.change_contact_on_ticket',
    defaultMessage: 'Change the contact on ticket number #{ticketId}'
  },
  profile_first_name: {
    id: 'cw-home.profile_first_name',
    defaultMessage: 'First name'
  },
  profile_last_name: {
    id: 'cw-home.profile_last_name',
    defaultMessage: 'Last name'
  },
  profile_email: {
    id: 'cw-home.profile_email',
    defaultMessage: 'Email'
  },
  profile_partner_id: {
    id: 'cw-home.profile_partner_id',
    defaultMessage: 'Partner ID'
  },
  profile_connectwise_home_roles: {
    id: 'cw-home.profile_connectwise_home_roles',
    defaultMessage: 'ConnectWise Home Roles'
  },
  profile_mfa: {
    id: 'cw-home.profile_mfa',
    defaultMessage: 'Multi-Factor Authentication'
  },
  profile_mfa_turned_on: {
    id: 'cw-home.profile_mfa_turned_on',
    defaultMessage: 'Turned on'
  },
  profile_mfa_turned_off: {
    id: 'cw-home.profile_mfa_turned_off',
    defaultMessage: 'Turned off'
  },
  profile_edit_my_profile: {
    id: 'cw-home.profile_edit_my_profile',
    defaultMessage: ' Edit my profile'
  },
  profile_current_password: {
    id: 'cw-home.profile_current_password',
    defaultMessage: 'Current Password'
  },
  profile_new_password: {
    id: 'cw-home.profile_new_password',
    defaultMessage: 'New Password'
  },
  profile_confirm_password: {
    id: 'cw-home.profile_confirm_password',
    defaultMessage: 'Confirm Password'
  },
  sign_up_for: {
    id: 'cw-home.sign_up_for',
    defaultMessage: 'Sign up for {productName}'
  },
  sign_up_for_screenconnect: {
    id: 'cw-home.sign_up_for_screenconnect',
    defaultMessage: 'Sign up for ScreenConnect'
  },
  morning: {
    id: 'cw-home.morning',
    defaultMessage: 'Morning'
  },
  afternoon: {
    id: 'cw-home.afternoon',
    defaultMessage: 'Afternoon'
  },
  after_hours: {
    id: 'cw-home.after_hours',
    defaultMessage: 'After hours'
  },
  anytime: {
    id: 'cw-home.anytime',
    defaultMessage: 'Anytime'
  },
  evening: {
    id: 'cw-home.evening',
    defaultMessage: 'Evening'
  },
  enter_your_issue: {
    id: 'cw-home.enter_your_issue',
    defaultMessage: 'Please enter your issue'
  },
  enter_details: {
    id: 'cw-home.enter_details',
    defaultMessage: 'Please enter your details…'
  },
  create_ticket: {
    id: 'cw-home.create_ticket',
    defaultMessage: 'Create Ticket'
  },
  close_account_label: {
    id: 'cw-home.close_account_label',
    defaultMessage:
      'Closing the account will delete all trial instances and will remove account access.'
  },
  close_account_text: {
    id: 'cw-home.close_account_text',
    defaultMessage: 'Would you like to close your account?'
  },
  close_account_header_text: {
    id: 'cw-home.close_account_header_text',
    defaultMessage: 'Close Account'
  },
  okay: {
    id: 'cw-home.okay',
    defaultMessage: 'Okay'
  },
  close_account_error_message: {
    id: 'cw-home.close_account_error_message',
    defaultMessage: 'There is some Error, Please Contact Admin.'
  },
  close_account_error_header: {
    id: 'cw-home.close_account_error_header',
    defaultMessage: 'Error Closing Account'
  },
  close_account_error_acknowledge: {
    id: 'cw-home.close_account_error_acknowledge',
    defaultMessage: 'Acknowledge'
  },
  change_password_save: {
    id: 'cw-home.change_password_save',
    defaultMessage: 'Save'
  },
  change_password_cancel: {
    id: 'cw-home.change_password_cancel',
    defaultMessage: 'Cancel'
  },
  change_password_header_text: {
    id: 'cw-home.change_password_header_text',
    defaultMessage: 'Change Password'
  },
  change_password_criteria_1: {
    id: 'cw-home.change_password_criteria_1',
    defaultMessage: 'Note: Password must contain:'
  },
  change_password_criteria_2: {
    id: 'cw-home.change_password_criteria_2',
    defaultMessage: 'At least 9 characters'
  },
  change_password_criteria_3: {
    id: 'cw-home.change_password_criteria_3',
    defaultMessage: 'At least one must be a symbol'
  },
  change_password_criteria_4: {
    id: 'cw-home.change_password_criteria_4',
    defaultMessage: 'At least one must be a number'
  },
  change_password_criteria_5: {
    id: 'cw-home.change_password_criteria_5',
    defaultMessage: 'At least one must be a capital letter'
  },
  change_password_criteria_6: {
    id: 'cw-home.change_password_criteria_6',
    defaultMessage: 'At least one must be a lower case letter'
  },
  change_password_forminput: {
    id: 'cw-home.change_password_forminput',
    defaultMessage: 'Not a valid password!'
  },
  change_password_match: {
    id: 'cw-home.change_password_match',
    defaultMessage: 'Must match new password!'
  },
  add_another_person_effected: {
    id: 'cw-home.add_another_person_effected',
    defaultMessage: 'Add another person affected'
  },
  impactedPlaceHolder: {
    id: 'cw-home.impactedPlaceHolder',
    defaultMessage: 'Please select an option'
  },
  impactedTimePlaceHolder: {
    id: 'cw-home.impactedTimePlaceHolder',
    defaultMessage: 'Please select a time'
  },
  add_licenses: {
    id: 'cw-home.add_licenses',
    defaultMessage: 'Add Licenses'
  },
  got_your_info: {
    id: 'cw-home.got_your_info',
    defaultMessage: "We've got your info!"
  },
  request_for_quote_confirmation: {
    id: 'cw-home.request_for_quote_confirmation',
    defaultMessage:
      'Thank you, a sales professional will reach out to you shortly with a custom quote.'
  },
  user_profile_save_button: {
    id: 'cw-home.user_profile_save_button',
    defaultMessage: 'Save'
  },
  user_profile_change_password: {
    id: 'cw-home.user_profile_change_password',
    defaultMessage: 'Change Password'
  },
  user_profile_current_password_placeholder: {
    id: 'cw-home.user_profile_current_password_placeholder',
    defaultMessage: 'Enter current password'
  },
  user_profile_new_password_placeholder: {
    id: 'cw-home.user_profile_new_password_placeholder',
    defaultMessage: 'Enter new password'
  },
  user_profile_confirm_password_placeholder: {
    id: 'cw-home.user_profile_confirm_password_placeholder',
    defaultMessage: 'Enter password'
  },
  user_profile_settings: {
    id: 'cw-home.user_profile_settings',
    defaultMessage: 'Profile Settings'
  },
  user_profile_my_profile: {
    id: 'cw-home.user_profile_my_profile',
    defaultMessage: 'My profile'
  },
  profile_validation_schema: {
    id: 'cw-home.profile_validation_schema',
    defaultMessage: 'First name cannot be empty'
  },
  contactDropDown_EnterName: {
    id: 'cw-home.contactDropDown_EnterName',
    defaultMessage: 'Enter name'
  },
  submitted: {
    id: 'cw-home.submitted',
    defaultMessage: 'Submitted'
  },
  ticket: {
    id: 'cw-home.ticket',
    defaultMessage: 'Ticket#'
  },
  createTicket: {
    id: 'cw-home.createTicket',
    defaultMessage: 'Create a new ticket'
  },
  supportChat: {
    id: 'cw-home.supportChat',
    defaultMessage: 'Start a support chat'
  },
  access_denied_title: {
    id: 'cw-home.access_denied_title',
    defaultMessage: 'Oops. Looks like you don’t have access to this page'
  },
  ticket_id: {
    id: 'cw-home.ticket_id',
    defaultMessage: 'Ticket #{ticketId}'
  },
  ticket_not_authorized: {
    id: 'cw-home.ticket_not_authorized',
    defaultMessage: 'Sorry, you do not have access to view this ticket'
  },
  make_a_payment: {
    id: 'cw-home.make_a_payment',
    defaultMessage: 'Make a Payment'
  },
  au_portal: {
    id: 'cw-home.au_portal',
    defaultMessage: 'Launch AU Portal'
  },
  epay_portal: {
    id: 'cw-home.epay_portal',
    defaultMessage: 'ePay Portal'
  },
  ca_portal: {
    id: 'cw-home.ca_portal',
    defaultMessage: 'Launch CA Portal'
  },
  eu_portal: {
    id: 'cw-home.eu_portal',
    defaultMessage: 'Launch EU Portal'
  },
  other_portal: {
    id: 'cw-home.other_portal',
    defaultMessage: 'Launch US Portal'
  },
  items_count: {
    id: 'cw-home.items_count',
    defaultMessage: '{lowerIndex} - {upperIndex} of {totalCount} items'
  },
  due: {
    id: 'cw-home.due',
    defaultMessage: 'Due'
  },
  search_invoice_number: {
    id: 'cw-home.search_invoice_number',
    defaultMessage: 'Search invoice number'
  },
  search_ticket_number: {
    id: 'cw-home.search_ticket_number',
    defaultMessage: 'Search ticket # or summary'
  },
  view_ticket: {
    id: 'cw-home.view_ticket',
    defaultMessage: 'View ticket'
  },
  ticket_successfully_created: {
    id: 'cw-home.ticket_successfully_created',
    defaultMessage: 'Your ticket #{ticketId} has been successfully created!'
  },
  ticket_required_field_violation: {
    id: 'cw-home.ticket_required_field_violation',
    defaultMessage: 'Required field violation in creating ticket'
  },
  failed_download_attachment: {
    id: 'cw-home.failed_download_attachment',
    defaultMessage: 'Failed to download attachment. #{resultError}'
  },
  sso_management: {
    id: 'cw-home.sso_management',
    defaultMessage: 'SSO Management'
  },
  partner_support: {
    id: 'cw-home.partner_support',
    defaultMessage: 'Partner Support'
  },
  compliance: {
    id: 'cw-home.compliance',
    defaultMessage: 'Compliance'
  },
  content: {
    id: 'cw-home.content',
    defaultMessage: 'Content'
  },
  core: {
    id: 'cw-home.core',
    defaultMessage: 'Core'
  },
  emergency_response: {
    id: 'cw-home.emergency_response',
    defaultMessage: 'Emergency Response'
  },
  billing_notifications_enable: {
    id: 'cw-home.billing_notifications_enable',
    defaultMessage: 'Enable billing notifications'
  },
  billing_notifications_disabled: {
    id: 'cw-home.billing_notifications_disabled',
    defaultMessage: 'Disable billing notifications'
  },
  security_notifications_enable: {
    id: 'cw-home.security_notifications_enable',
    defaultMessage: 'Enable security notifications'
  },
  security_notifications_disabled: {
    id: 'cw-home.security_notifications_disabled',
    defaultMessage: 'Disable security notifications'
  },
  dots_noproducts: {
    id: 'cw-home.dots_noproducts',
    defaultMessage: 'You are not subscribed to any products.'
  },
  profile_no_access: {
    id: 'cw-home.profile_no_access',
    defaultMessage: 'Sorry, you do not have access to view this profile'
  },
  mfa_email_header_detailtext: {
    id: 'cw-home.mfa_email_header_detailtext',
    defaultMessage:
      'The email address below has been configured as a multi-factor authentication option for your account.'
  },
  mfa_app_header: {
    id: 'cw-home.mfa_app_header',
    defaultMessage: 'Authenticator App Multi-Factor Authentication'
  },
  mfa_app_header_detailtext: {
    id: 'cw-home.mfa_app_header_detailtext',
    defaultMessage:
      'The authenticator app below has been configured as a multi-factor authentication option for your account.'
  },
  mfa_authsetup_header_token: {
    id: 'cw-home.mfa_authsetup_header_token',
    defaultMessage: 'Multi-Factor Authentication Token'
  },
  mfa_auth_toggle: {
    id: 'cw-home.mfa_auth_toggle',
    defaultMessage: 'Auth App'
  },
  submit: {
    id: 'cw-home.submit',
    defaultMessage: 'Submit'
  },
  sso_email_association_error: {
    id: 'cw-home.sso_email_association_error',
    defaultMessage:
      'For security purposes, your ConnectWise SSO email address must be associated to your company\'s account. Please contact your ConnectWise Admin to update or add your contact information from user management and try again.<br/><br/> If you need additional assistance, please reach out to our <a href="mailto:help@connectwise.com?subject=Unable%20to%20Verify%20Company%20in%20ConnectWise%20Home">ConnectWise Support</a> team.'
  },
  not_authorized_to_submit_payment: {
    id: 'cw-home.not_authorized_to_submit_payment',
    defaultMessage: 'Not authorized to submit a payment.'
  },
  launch_the_ePay_portal: {
    id: 'cw-home.launch_the_ePay_portal',
    defaultMessage: 'Launch the ePay portal'
  },
  cc: {
    id: 'cw-home.cc',
    defaultMessage: 'CC'
  },
  contactName: {
    id: 'cw-home.contactName',
    defaultMessage: 'Contact Name'
  },
  email_verification_header: {
    id: 'cw-home.email_verification_header',
    defaultMessage: 'Email activation required'
  },
  email_verification_body: {
    id: 'cw-home.email_verification_body',
    defaultMessage:
      'Please check your inbox for a ConnectWise account activation email. Select the button below to resend the email if you cannot locate it.'
  },
  resend_verification_mail: {
    id: 'cw-home.resend_verification_mail',
    defaultMessage: 'Resend Email'
  },
  resend_message: {
    id: 'cw-home.resend_message',
    defaultMessage: 'Resend'
  },
  thank_you_message: {
    id: 'cw-home.thank_you_message',
    defaultMessage: 'Thank you!'
  },
  confirm: {
    id: 'cw-home.confirm',
    defaultMessage: 'Confirm'
  },
  mfa_email_header: {
    id: 'cw-home.mfa_email_header',
    defaultMessage: 'Email Multi-Factor Authentication'
  },
  mfa_email_header_text: {
    id: 'cw-home.mfa_email_header_text',
    defaultMessage:
      'The email address below has been configured as a multi-factor authentication option for your account. '
  },
  mfa_email_setup_button: {
    id: 'cw-home.mfa_email_setup_button',
    defaultMessage: 'Setup'
  },
  mfa_email_setup_header: {
    id: 'cw-home.mfa_email_setup_header',
    defaultMessage: 'Email Multi-Factor Authentication'
  },
  mfa_email_setup_header_text: {
    id: 'cw-home.mfa_email_setup_header_text',
    defaultMessage:
      'Receive MFA codes to the email address associated with your ConnectWise SSO account.'
  },
  mfa_setup_email: {
    id: 'cw-home.setup_mfa_email',
    defaultMessage: 'Setup MFA with your Email'
  },
  mfa_setup_email_body: {
    id: 'cw-home.setup_mfa_email_body',
    defaultMessage:
      'We have sent a 6 digit verification code to the email address associated with your account. Please check your mailbox or spam folder for the MFA verification email.'
  },
  mfa_setup_email_verify: {
    id: 'cw-home.setup_mfa_email_verify',
    defaultMessage: 'Please verify that your email is correct:'
  },
  mfa_email_turn_off_verify: {
    id: 'cw-home.mfa_email_turn_off_verify',
    defaultMessage:
      'Are you sure you want to remove email MFA from your account?'
  },
  mfa_email_turn_off_verify_code_error: {
    id: 'cw-home.mfa_email_turn_off_verify_code_error',
    defaultMessage:
      'The authentication code you entered was not valid. Please try again.'
  },
  mfa_email_turn_on_verify: {
    id: 'cw-home.mfa_email_turn_on_verify',
    defaultMessage:
      'Turning on email MFA will require you to enter in a code sent to {emailaddress} next time you login.'
  },
  mfa_turn_off: {
    id: 'cw-home.mfa_turn_off',
    defaultMessage: 'Turn Off'
  },
  mfa_email_turn_on: {
    id: 'cw-home.mfa_email_turn_on',
    defaultMessage: 'Turn On'
  },
  mfa_email_resend_code: {
    id: 'cw-home.mfa_email_resend_code',
    defaultMessage: 'Resend Code'
  },
  mfa_secret_key: {
    id: 'cw-home.mfa_secret_key',
    defaultMessage: 'Secret Key'
  },
  copy: {
    id: 'cw-home.copy',
    defaultMessage: 'Copy'
  },
  view: {
    id: 'cw-home.view',
    defaultMessage: 'VIEW'
  },
  mfa_app_token_input_placeholder: {
    id: 'cw-home.mfa_app_token_input_placeholder',
    defaultMessage: 'Please Enter Token'
  },
  mfa_app_sub_header_text: {
    id: 'cw-home.mfa_app_sub_header_text',
    defaultMessage: 'Use an authenticator app to generate MFA codes.'
  },
  delete: {
    id: 'cw-home.delete',
    defaultMessage: 'Delete'
  },
  mfa_verify_token_placeholder: {
    id: 'cw-home.mfa_verify_token_placeholder',
    defaultMessage: 'Enter 6 digit code'
  },
  mfa_app_token_invalid: {
    id: 'cw-home.mfa_app_token_invalid',
    defaultMessage: 'Incorrect code, please try again.'
  },
  mfa_app_header_mobile: {
    id: 'cw-home.mfa_app_header_mobile',
    defaultMessage: 'Authenticator App MFA'
  },
  mfa_email_setup_header_mobile: {
    id: 'cw-home.mfa_email_setup_header_mobile',
    defaultMessage: 'Email MFA'
  },
  attachment_file_size_limit: {
    id: 'cw-home.attachment_file_size_limit',
    defaultMessage: 'exceeds the maximum size limit of 10 MB per file.'
  },
  attachment_total_file_size_limit: {
    id: 'cw-home.attachment_total_file_size_limit',
    defaultMessage:
      'The total attachment size exceeds the 50 MB limit for this site.'
  },
  wallet_drawer_title: {
    id: 'cw-home.wallet-drawer.title',
    defaultMessage: 'ConnectWise Wallet'
  },
  wallet_drawer_message: {
    id: 'cw-home.wallet-drawer.message',
    defaultMessage:
      'Manage your credit cards for your Unite, Identify, and Marketplace purchases.'
  },
  wallet_drawer_retry_payment_title: {
    id: 'cw-home.wallet-drawer.retry_payment_title',
    defaultMessage: 'Failed Payments'
  },
  wallet_drawer_retry_payment_message: {
    id: 'cw-home.wallet-drawer.retry_payment_message',
    defaultMessage:
      'Payment failed for the below invoices. Please update your payment method above and try again.'
  },
  wallet_drawer_info_notification_retry_payment: {
    id: 'cw-home.wallet-drawer.info_notification_retry_payment',
    defaultMessage:
      'Your Payment has been submitted, this could take up to 5 minutes to update.'
  },
  wallet_drawer_error_notification_retry_payment: {
    id: 'cw-home.wallet-drawer.not_authorized_to_submit_payment',
    defaultMessage:
      'There was an error processing your payment, a ticket has been create to resolve this issue.'
  },
  retry_payment_title: {
    id: 'cw-home.retry_payment_title',
    defaultMessage: 'Your payment is being processed'
  },
  retry_payment_message: {
    id: 'cw-home.retry_payment_message',
    defaultMessage:
      'Once your payment finishes processing, you will recieve an email confirmation shortly.'
  },
  failed_payment_image: {
    id: 'cw-home.failed_payment_image',
    defaultMessage: 'Failed Payment Image'
  },
  wallet_card_expiration: {
    id: 'cw-home.wallet-drawer.expiration_date',
    defaultMessage: 'Exp. {expDate}'
  },
  create_case_with_psm: {
    id: 'cw-home.create_case_with_psm',
    defaultMessage: 'Click here to create a case with PSM.'
  },
  create_case_with_am: {
    id: 'cw-home.create_case_with_am',
    defaultMessage: 'Click here to create a case with AM.'
  },
  wallet_drawer_invoice_status_payment_pending: {
    id: 'cw-home.wallet-drawer.invoice_status_payment_pending',
    defaultMessage: 'Payment Pending'
  },
  wallet_drawer_invoice_status_payment_failed: {
    id: 'cw-home.wallet-drawer.invoice_status_payment_failed',
    defaultMessage: 'Payment Failed'
  },
  wallet_drawer_invoice_status_invoice_error: {
    id: 'cw-home.wallet-drawer.invoice_status_invoice_error',
    defaultMessage: 'Invoice Error'
  },
  add_agents: {
    id: 'cw-home.add_agents',
    defaultMessage: 'Add Agents'
  },
  ticket_duplicate_affected_person: {
    id: 'cw-home.ticket_duplicate_affected_person',
    defaultMessage: 'Impacted persons list cannot have duplicate entries'
  },
  confirm_close_account: {
    id: 'cw-home.confirm_close_account',
    defaultMessage: 'Please confirm you would like to close your account.'
  },
  user_close_account_unauthorized_error: {
    id: 'cw-home.user_close_account_unauthorized_error',
    defaultMessage:
      'This user does not have the permissions required to close this account'
  },
  no_tickets: {
    id: 'cw-home.no_tickets',
    defaultMessage: 'There are no support tickets to display'
  },
  add_to_cart_unauthorized_error: {
    id: 'cw-home.add_to_cart_unauthorized_error',
    defaultMessage: 'You do not have permission to perform this action'
  },
  wallet_remove_card_info: {
    id: 'cw-home.wallet_remove_card_info',
    defaultMessage: 'Remove Credit Card ••••{cardNumber}'
  },
  wallet_remove_card_message: {
    id: 'cw-home.wallet_remove_card_message',
    defaultMessage:
      'Any pending payments on this card will be processed even after you remove it. Are you sure you want to remove your Credit Card ••••{cardNumber} from your account?'
  },
  wallet_remove_expired_card_message: {
    id: 'cw-home.wallet_remove_expired_card_message',
    defaultMessage:
      'This card has expired. Pending payments on this card cannot be processed. Please remove this card.'
  },
  wallet_credit_card_info: {
    id: 'cw-home.wallet_credit_card_info',
    defaultMessage: 'Credit ••••{cardNumber}'
  },
  wallet_card_is_expired: {
    id: 'cw-home.wallet_card_is_expired',
    defaultMessage: 'The credit card ••••{cardInfo} is expired'
  },
  wallet_card_is_expired_message: {
    id: 'cw-home.wallet_card_is_expired_message',
    defaultMessage:
      'This card has expired. Pending payments on this card cannot be processed. Please update your card and retry payment.'
  },
  wallet_card_default: {
    id: 'cw-home.wallet_card_default',
    defaultMessage: 'Default'
  },
  wallet_card_edit: {
    id: 'cw-home.wallet_card_edit',
    defaultMessage: 'Edit Card'
  },
  wallet_card_remove: {
    id: 'cw-home.wallet_card_remove',
    defaultMessage: 'Remove Card'
  },
  wallet_add_new_card: {
    id: 'cw-home.wallet_add_new_card',
    defaultMessage: `Add New Card`
  },
  wallet_no_card: {
    id: 'cw-home.wallet_no_card',
    defaultMessage: `No cards on file`
  },
  identify_billing_type: {
    id: 'cw-home.identify_billing_type',
    defaultMessage: '*Billed Monthly'
  },
  control_support_billing_type: {
    id: 'cw-home.control_support_billing_type',
    defaultMessage: 'Per technician when billed annually.'
  },
  control_access_billing_type: {
    id: 'cw-home.control_access_billing_type',
    defaultMessage: 'For 25 unattended access agents when billed annually.'
  },
  identify_label: {
    id: 'cw-home.identify_label',
    defaultMessage: "What's Included"
  },
  control_label: {
    id: 'cw-home.control_label',
    defaultMessage: 'Solutions tailored to you'
  },
  billing_pricing_quote: {
    id: 'cw-home.billing_pricing_quote',
    defaultMessage: 'Starting at'
  },
  deflection_search_box_hint: {
    id: 'cw-home.deflection_search_box_hint',
    defaultMessage: 'Start typing to find relevant articles'
  },
  search_placeholder: {
    id: 'cw-home.search_placeholder',
    defaultMessage: 'Search the University'
  },
  product_hub_empty_state: {
    id: 'cw-home.product_hub_empty_state',
    defaultMessage: 'To see your products, enable ConnectWise SSO.'
  },
  product_hub_empty_state_button: {
    id: 'cw-home.product_hub_empty_state_button',
    defaultMessage: 'Get started'
  },
  license_name: {
    id: 'cw-home.license_name',
    defaultMessage: 'ConnectWise {productName} {licenseName}'
  },
  step1_add_license_description: {
    id: 'cw-home.step1_add_license_description',
    defaultMessage:
      'Please select the number of {licenseName} you would like to add'
  },
  add_license_quantity: {
    id: 'cw-home.add_license_quantity',
    defaultMessage: 'How many {licenseName} would you like to add?'
  },
  additional_monthly_charge: {
    id: 'cw-home.additional_monthly_charge',
    defaultMessage: 'Estimated additional monthly charge'
  },
  license_puchase_summary: {
    id: 'cw-home.license_puchase_summary',
    defaultMessage: `You will be sent an invoice in the estimated amount of {amount} for the prorated number of days left in the month.`
  },
  additional_charges: {
    id: 'cw-home.additional_charges',
    defaultMessage: 'Estimated Additional Monthly Charge'
  },
  license_terms_condition: {
    id: 'cw-home.license_terms_condition',
    defaultMessage:
      'Taxes and other fees may apply. We reserve the right to cancel orders arising from pricing or other errors'
  },
  license_agreement_statement: {
    id: 'cw-home.license_agreement_statement',
    defaultMessage: 'I agree to the ConnectWise Master'
  },
  license_agreement: {
    id: 'cw-home.license_agreement',
    defaultMessage: 'Agreement'
  },
  license_purchase_response: {
    id: 'cw-home.license_purchase_response',
    defaultMessage: 'Thank you for your order!'
  },
  license_activation_duration: {
    id: 'cw-home.license_activation_duration',
    defaultMessage: `You will receive an order confirmation email shortly. Once you receieve this email, please allow up to {days} for your licenses to become available.`
  },
  add_license_error: {
    id: 'cw-home.add_license_error',
    defaultMessage: `Sorry, we are unable to add additional {licenseType} at this time`
  },
  add_license_quote: {
    id: 'cw-home.add_license_quote',
    defaultMessage: `Don't worry, let us know how many {licenseName} you would like to purchase by creating a case and your account manager can send you a quote.`
  },
  add_license_comment: {
    id: 'cw-home.add_license_comment',
    defaultMessage: `Would you like to leave a comment?`
  },
  add_license_comment_placeholder: {
    id: 'cw-home.add_license_comment_placeholder',
    defaultMessage: `Enter comment here....`
  },
  license_request_quote: {
    id: 'cw-home.license_request_quote',
    defaultMessage: 'Request Quote'
  },
  license_contact_manager: {
    id: 'cw-home.license_contact_manager',
    defaultMessage: 'Contact your account manager'
  },
  license_something_wrong: {
    id: 'cw-home.license_something_wrong',
    defaultMessage: 'Oh no, something went wrong!'
  },
  license_not_purchased: {
    id: 'cw-home.license_not_purchased',
    defaultMessage: `We were not able to update your {licenseName}. Please contact your account manager to figure out what went wrong.`
  },
  add_license_success: {
    id: 'cw-home.add_license_success',
    defaultMessage: 'Order Submitted'
  },
  add_license_confirm: {
    id: 'cw-home.add_license_confirm',
    defaultMessage: 'Please confirm the changes'
  },
  ticket_not_created: {
    id: 'cw-home.ticket_not_created',
    defaultMessage:
      'There was an issue creating your ticket. To proceed with your request, please use the button below to contact your account manager'
  },
  license_puchase_summary_two_months: {
    id: 'cw-home.license_puchase_summary_two_months',
    defaultMessage: `An invoice will be generated now in the amount of {amount} for the prorated number of days left in the month and the full month of {nextMonthName}.`
  },
  license_per_month: {
    id: 'cw-home.license_per_month',
    defaultMessage: 'month'
  },
  license_charge_summary_no_prorate: {
    id: 'cw-home.license_charge_summary_no_prorate',
    defaultMessage: `Starting on your next regular ConnectWise invoice, you will see a new line item in the amount of {totalAmount} for your newly purchased {licenseName}.`
  },
  license_charge_summary_one_month_prorate: {
    id: 'cw-home.license_charge_summary_one_month_prorate',
    defaultMessage: `You will be charged an estimated pro-rated amount of {prorateAmount} for the remaining days in {currentMonthName}. Following that, you will see a new line item on your regular ConnectWise invoice in the amount of {totalAmount} for your newly purchased {licenseName}.`
  },
  license_charge_summary_two_month_prorate: {
    id: 'cw-home.license_charge_summary_two_month_prorate',
    defaultMessage: `You will be charged an estimated pro-rated amount of {prorateAmount} for the remaining days in {currentMonthName} and the full month of {nextMonth}. Following that, you will see a new line item on your regular ConnectWise invoice in the amount of {totalAmount} for your newly purchased {licenseName}.`
  },
  member_image: {
    id: 'cw-home.member_image',
    defaultMessage: 'Member Image'
  },
  contact_manager: {
    id: 'cw-home.contact_manager',
    defaultMessage: 'Contact {managerName}'
  },
  contact_partner_success_team: {
    id: 'cw-home.contact_partner_success_team',
    defaultMessage: 'Contact Partner Success Team'
  },
  psm_help_text: {
    id: 'cw-home.psm_help_text ',
    defaultMessage: 'How can I help you?'
  },
  select_an_option: {
    id: 'cw-home.select_an_option',
    defaultMessage: 'Select an option to get started'
  },
  product_mentoring: {
    id: 'cw-home.product_mentoring',
    defaultMessage: 'Product Mentoring'
  },
  product_mentoring_details: {
    id: 'cw-home.product_mentoring_details',
    defaultMessage:
      'Connect with your Partner Success Manager to ensure you are fully maximizing your ConnectWise products. Discuss best practices, processes, integrations, and more!'
  },
  product_expansion: {
    id: 'cw-home.product_expansion',
    defaultMessage: 'Product Expansion'
  },
  product_expansion_details: {
    id: 'cw-home.product_expansion_details',
    defaultMessage:
      'Drive your success by discovering how additional ConnectWise products and integrations can supplement your business processes.'
  },
  product_support: {
    id: 'cw-home.product_support',
    defaultMessage: 'Looking for product support?'
  },
  product_select_messsage: {
    id: 'cw-home.product_select_messsage',
    defaultMessage: 'Select a product'
  },
  product_area_select_messsage: {
    id: 'cw-home.product_area_select_messsage',
    defaultMessage: 'Select a product area'
  },
  product_feature_select_messsage: {
    id: 'cw-home.product_feature_select_messsage',
    defaultMessage: 'Select a feature'
  },
  select_a_method: {
    id: 'cw-home.select_a_method',
    defaultMessage: 'Select a method'
  },
  please_select_a_method: {
    id: 'cw-home.please_select_a_method',
    defaultMessage: 'Please select a method'
  },
  psm_breadcrumb_type: {
    id: 'cw-home.psm_breadcrumb_type',
    defaultMessage: 'Type'
  },
  psm_breadcrumb_details: {
    id: 'cw-home.psm_breadcrumb_details',
    defaultMessage: 'Details'
  },
  psm_breadcrumb_product: {
    id: 'cw-home.psm_breadcrumb_product',
    defaultMessage: 'Product'
  },
  psm_breadcrumb_product_area: {
    id: 'cw-home.psm_breadcrumb_product_area',
    defaultMessage: 'Product Area'
  },
  psm_breadcrumb_feature: {
    id: 'cw-home.psm_breadcrumb_feature',
    defaultMessage: 'Feature'
  },
  breadcrumb_details: {
    id: 'cw-home.breadcrumb_details',
    defaultMessage: 'Details'
  },
  breadcrumb_issue: {
    id: 'cw-home.breadcrumb_issue',
    defaultMessage: 'Issue'
  },
  breadcrumb_problem: {
    id: 'cw-home.breadcrumb_problem',
    defaultMessage: 'Problem'
  },
  service_categories: {
    id: 'cw-home.service_categories',
    defaultMessage: 'Service Categories'
  },
  order_confirmation_text: {
    id: 'cw-home.order_confirmation_text',
    defaultMessage:
      'You will receive an email confirmation when your order has finished processing'
  },
  license_unauthorized_text: {
    id: 'cw-home.license_unauthorized_text',
    defaultMessage: `Sorry, you do not have access to add {licenseName}`
  },
  unauthorized_text: {
    id: 'cw-home.unauthorized_text',
    defaultMessage: `Not Authorized`
  },
  company_account_setting: {
    id: 'cw-home.company_account_setting',
    defaultMessage: `Company account settings`
  },
  company_account_text: {
    id: 'cw-home.company_account_text',
    defaultMessage: `Company Account`
  },
  company_account_support_text: {
    id: 'cw-home.company_account_support_text',
    defaultMessage: `ConnectWise Support`
  },
  company_account_support_description: {
    id: 'cw-home.company_account_support_description',
    defaultMessage: `Allowing support access will grant the ConnectWise support team the ability to access ConnectWise Home, Unite and Identify.`
  },
  copy_share: {
    id: 'cw-home.copy_share',
    defaultMessage: 'Copy and Share Link  '
  },
  password_copied: {
    id: 'cw-home.password_copied',
    defaultMessage: 'Password copied'
  },
  support_enabled: {
    id: 'cw-home.support_enabled',
    defaultMessage:
      'Our support team can now access your account. Please update your support ticket with the following password:'
  },
  support_access: {
    id: 'cw-home.support_access',
    defaultMessage: 'Support Access'
  },
  license_error_text: {
    id: 'cw-home.license_error_text',
    defaultMessage: `No License or Agent is available for this instance`
  },
  great_for: {
    id: 'cw-home.great_for',
    defaultMessage: `Great for:`
  },
  connectwise_control_support: {
    id: 'cw-home.connectwise_control_support',
    defaultMessage: 'ConnectWise ScreenConnect Support'
  },
  connectwise_control_access: {
    id: 'cw-home.connectwise_control_access',
    defaultMessage: 'ConnectWise ScreenConnect Access'
  },
  remote_access: {
    id: 'cw-home.remote_access',
    defaultMessage: 'Remote Access'
  },
  remote_support: {
    id: 'cw-home.remote_support',
    defaultMessage: 'Remote Support'
  },
  remote_meeting: {
    id: 'cw-home.remote_meeting',
    defaultMessage: 'Remote Meeting'
  },
  remote_app: {
    id: 'cw-home.remote_app',
    defaultMessage: 'Mobile App'
  },
  video_auditing: {
    id: 'cw-home.video_auditing',
    defaultMessage: 'Video Auditing*'
  },
  connectwise_view: {
    id: 'cw-home.connectwise_view',
    defaultMessage: 'ConnectWise View*'
  },
  unlimited_concurrent_technicians: {
    id: 'cw-home.unlimited_concurrent_technicians',
    defaultMessage: 'Unlimited Concurrent Technicians'
  },
  agent_deployer: {
    id: 'cw-home.agent_deployer',
    defaultMessage: 'Agent Deployer'
  },
  windows_updates: {
    id: 'cw-home.windows_updates',
    defaultMessage: 'Windows Updates'
  },
  mobile_app: {
    id: 'cw-home.mobile_app',
    defaultMessage: 'Mobile App'
  },
  preconfigured_reports: {
    id: 'cw-home.preconfigured_reports',
    defaultMessage: 'Preconfigured Reports'
  },
  assessments_level: {
    id: 'cw-home.assessments_level',
    defaultMessage: 'Choose From 2 Assessment Levels'
  },
  unlimited_assessments: {
    id: 'cw-home.unlimited_assessments',
    defaultMessage: 'Unlimited Assessments'
  },
  brandable_assessment_report: {
    id: 'cw-home.brandable_assessment_report',
    defaultMessage: 'Brandable Assessment Report'
  },
  remediation_steps: {
    id: 'cw-home.remediation_steps',
    defaultMessage: 'Remediation Steps'
  },
  single_portal_access: {
    id: 'cw-home.single_portal_access',
    defaultMessage: 'Single Portal Access'
  },
  manage_intergration: {
    id: 'cw-home.manage_intergration',
    defaultMessage: 'ConnectWise Manage® Integration'
  },
  reporting: {
    id: 'cw-home.reporting',
    defaultMessage: 'Reporting'
  },
  industry_comparisons: {
    id: 'cw-home.industry_comparisons',
    defaultMessage: 'Industry Comparisons'
  },
  unite_billing_description: {
    id: 'cw-home.unite_billing_description',
    defaultMessage: 'Your next billing date of'
  },
  unite_billing_payment_method: {
    id: 'cw-home.unite_billing_payment_method',
    defaultMessage: 'will be applied to your payment method on'
  },
  unite_billed_categories: {
    id: 'cw-home.unite_billed_categories',
    defaultMessage: 'Billed Categories'
  },
  unite_billing_type: {
    id: 'cw-home.unite_billing_type',
    defaultMessage: 'Per company for each category'
  },
  unite_category_simple: {
    id: 'cw-home.unite_category_simple',
    defaultMessage: 'Meraki, Spark, StealthWatch Cloud, and Umbrella'
  },
  unite_category_iaas: {
    id: 'cw-home.unite_category_iaas',
    defaultMessage: 'Amazon Web Services (AWS), Microsoft Azure'
  },
  unite_category_productivity: {
    id: 'cw-home.unite_category_productivity',
    defaultMessage: 'Microsoft Office 365'
  },
  compare_plan: {
    id: 'cw-home.compare_plan',
    defaultMessage: 'Compare Plans'
  },
  control_onwned_description: {
    id: 'cw-home.control_onwned_description',
    defaultMessage: 'Already own ConnectWise ScreenConnect?'
  },
  control_onwned_text: {
    id: 'cw-home.control_onwned_text',
    defaultMessage: 'Connect your account now!'
  },
  drawer_automate_title: {
    id: 'cw-home.drawer_automate_title',
    defaultMessage: 'ConnectWise Automate'
  },
  drawer_control_title: {
    id: 'cw-home.drawer_control_title',
    defaultMessage: 'ConnectWise ScreenConnect™'
  },
  drawer_control_description: {
    id: 'cw-home.product_drawer_control_description',
    defaultMessage:
      'ConnectWise ScreenConnect allows you to instantly connect to and remotely control devices anytime, anywhere. Exceed service delivery expectations with fast, reliable remote support and unattended access with easy-to-use machine monitoring and management features.'
  },
  drawer_control_plan_description: {
    id: 'cw-home.drawer_control_plan_description',
    defaultMessage:
      'Pick the ConnectWise ScreenConnect trial that’s right for you and start advancing your service delivery.'
  },
  drawer_control_support_feature: {
    id: 'cw-home.drawer_control_support_feature',
    defaultMessage:
      'Full remote control capabilities designed for help-desk style services, delivering on-demand remote issue resolution.'
  },
  drawer_control_support_feature_condition: {
    id: 'cw-home.drawer_control_support_feature_condition',
    defaultMessage: '*Available in ConnectWise ScreenConnect Premium'
  },
  drawer_control_access_feature: {
    id: 'cw-home.drawer_control_access_feature',
    defaultMessage:
      'Unattended access and machine management capabilities to help you save time and provide superior service delivery.'
  },
  drawer_identify_title: {
    id: 'cw-home.drawer_identify_title',
    defaultMessage: 'ConnectWise Identify Assessment™'
  },
  drawer_identify_description: {
    id: 'cw-home.drawer_identify_description',
    defaultMessage:
      "ConnectWise Identify Assessment™ is a cybersecurity risk assessment tool that helps you generate security revenue identifying your customers' critical risks and outlining a plan to remediate them."
  },
  drawer_identify_plan_description: {
    id: 'cw-home.drawer_identify_plan_description',
    defaultMessage:
      'Essentials and Comprehensive NIST Cybersecurity Framework risk assessments to evaluate risk posture.'
  },
  drawer_unite_title: {
    id: 'cw-home.drawer_unite_title',
    defaultMessage: 'Cloud Billing'
  },
  drawer_unite_description: {
    id: 'cw-home.drawer_unite_description',
    defaultMessage:
      "Cloud Billing is an easy-to-use solution that connects XaaS (Anything-as-a-Service) billing from vendors, for client invoicing, and provides common management functionality so your help desk can resolve common requests without the need to escalate to a senior resource or directly access a vendor's portal."
  },
  drawer_unite_plan_description: {
    id: 'cw-home.drawer_unite_plan_description',
    defaultMessage:
      'A company is added to a service category if it uses one or more product within it.'
  },
  drawer_manage_title: {
    id: 'cw-home.drawer_manage_title',
    defaultMessage: 'ConnectWise PSA™'
  },
  drawer_sell_title: {
    id: 'cw-home.drawer_sell_title',
    defaultMessage: 'ConnectWise CPQ™'
  },
  learn_more_university: {
    id: 'cw-home.learn_more_university',
    defaultMessage: 'Learn more with University!'
  },
  drawer_manage_description: {
    id: 'cw-home.drawer_manage_description',
    defaultMessage:
      'ConnectWise PSA™ gives you one central hub, delivering one application to enable excellent service delivery every time. In other words, we’re in the business of making YOUR business easier. Recently named the Best PSA Vendor in ChannelPro’s 2019 Readers’ Choice Awards.'
  },
  drawer_manage_banner_header: {
    id: 'cw-home.drawer_manage_banner_header',
    defaultMessage:
      'See How ConnectWise Manage Connects Your Business in a Single Tool.'
  },
  drawer_manage_banner_description: {
    id: 'cw-home.drawer_manage_banner_description',
    defaultMessage:
      'ConnectWise PSA™ brings all areas of your business—from help desk to project management to billing and finance—into a single pane of glass,providing you the insight, collaboration and capabilities you need to support more endpoints, increase employee accountability and grow your revenue.'
  },
  request_demo_button: {
    id: 'cw-home.request_demo_button',
    defaultMessage: 'Request a Demo'
  },
  request_quote_button: {
    id: 'cw-home.request_quote_button',
    defaultMessage: 'Request a Quote'
  },
  drawer_manage_benefit_header: {
    id: 'cw-home.drawer_manage_benefit_header',
    defaultMessage: 'Discover the Benefits of ConnectWise PSA™'
  },
  drawer_manage_benefit_delivery_title: {
    id: 'cw-home.drawer_manage_benefit_delivery',
    defaultMessage: 'Superior Service Delivery'
  },
  drawer_manage_benefit_delivery_text: {
    id: 'cw-home.drawer_manage_benefit_delivery_text',
    defaultMessage:
      'Keep clients in the loop with clear communication and documentation on all service requests'
  },
  drawer_manage_benefit_dashboard_title: {
    id: 'cw-home.drawer_manage_benefit_dashboard_title',
    defaultMessage: 'Better Business Insight'
  },
  drawer_manage_benefit_dashboard_text: {
    id: 'cw-home.drawer_manage_benefit_dashboard_text',
    defaultMessage:
      'Data-driven reports give you the right information to make strategic business decisions to grow your business'
  },
  drawer_manage_benefit_efficiency_title: {
    id: 'cw-home.drawer_manage_benefit_efficiency_title',
    defaultMessage: 'Accurate Time Tracking'
  },
  drawer_manage_benefit_efficiency_text: {
    id: 'cw-home.drawer_manage_benefit_efficiency_text',
    defaultMessage:
      'See exactly how much time is spent on a ticket or project, capture every billable minute, and never miss out on earned income again'
  },
  drawer_manage_benefit_billing_title: {
    id: 'cw-home.drawer_manage_benefit_billing_title',
    defaultMessage: 'Automated & Accurate Billing'
  },
  drawer_manage_benefit_billing_text: {
    id: 'cw-home.drawer_manage_benefit_billing_text',
    defaultMessage:
      'With every minute of work accounted for, or new services added, automatically deliver accurate invoices on time, every time'
  },
  drawer_manage_product_owned: {
    id: 'cw-home.drawer_manage_product_owned',
    defaultMessage: 'Already own ConnectWise PSA™?'
  },
  drawer_product_owned_text: {
    id: 'cw-home.drawer_product_owned_text',
    defaultMessage: 'Connect your account now!'
  },
  unauthorize_disable_button_title_text: {
    id: 'cw-home.unauthorize_disable_button_title_text',
    defaultMessage: 'You do not have permission to start a trial.'
  },
  trial_started_Title: {
    id: 'cw-home.trial_started_Title',
    defaultMessage: 'You can only have one trial.'
  },
  order_summary_button_title: {
    id: 'cw-home.order_summary_button_title',
    defaultMessage: 'Please enter your CVV to continue'
  },
  failed_get_board_status: {
    id: 'cw-home.failed_get_board_status',
    defaultMessage: `Failed to get the board status`
  },
  failed_status_count: {
    id: 'cw-home.failed_status-count',
    defaultMessage: `Failed to get the board status count`
  },
  postTitle: {
    id: 'cw-home.postTitle',
    defaultMessage: 'Post Title'
  },
  postType: {
    id: 'cw-home.postType',
    defaultMessage: 'Post Type'
  },
  views: {
    id: 'cw-home.views',
    defaultMessage: 'Views'
  },
  clicks: {
    id: 'cw-home.clicks',
    defaultMessage: 'Clicks'
  },
  helpful: {
    id: 'cw-home.helpful',
    defaultMessage: 'Helpful'
  },
  createPost: {
    id: 'cw-home.createPost',
    defaultMessage: 'Create Post'
  },
  create_case: {
    id: 'cw-home.create_case',
    defaultMessage: 'Create Case'
  },
  searchPost: {
    id: 'cw-home.searchPost',
    defaultMessage: 'Search Post'
  },
  standardPost: {
    id: 'cw-home.standardPost',
    defaultMessage: 'Standard Post'
  },
  pinnedAnnouncement: {
    id: 'cw-home.pinnedAnnouncement',
    defaultMessage: 'Pinned Announcement'
  },
  editPost: {
    id: 'cw-home.editPost',
    defaultMessage: 'Edit Post'
  },
  deletePost: {
    id: 'cw-home.deletePost',
    defaultMessage: 'Delete Post'
  },
  news_admin: {
    id: 'cw-home.news_admin',
    defaultMessage: 'News Admin'
  },
  select_product_label: {
    id: 'cw-home.select_product_label',
    defaultMessage: 'Select which products this post applies to.'
  },
  select_all: {
    id: 'cw-home.select_all',
    defaultMessage: 'Select All'
  },
  select_personas_label: {
    id: 'cw-home.select_personas_label',
    defaultMessage: 'Select which personas this post is targeting.'
  },
  select_markets_label: {
    id: 'cw-home.select_markets_label',
    defaultMessage: 'Select which markets this post is targeting.'
  },
  post_type_description: {
    id: 'cw-home.post_type_description',
    defaultMessage: 'Please select the type of post this is'
  },
  post_audience: {
    id: 'cw-home.post_audience',
    defaultMessage: 'Post Audience'
  },
  post_audience_description: {
    id: 'cw-home.post_audience_description',
    defaultMessage: 'Please select who this post is targeting'
  },
  post_details_description: {
    id: 'cw-home.post_details_description',
    defaultMessage: 'Please add details of post'
  },
  post_details: {
    id: 'cw-home.post_details',
    defaultMessage: 'Post Details'
  },
  author: {
    id: 'cw-home.author',
    defaultMessage: 'Author:'
  },
  select_post_author: {
    id: 'cw-home.select_post_author',
    defaultMessage: 'Select who the post author is'
  },
  select_media: {
    id: 'cw-home.select_media',
    defaultMessage: 'Select media type'
  },
  select_author_placeHolder: {
    id: 'cw-home.select_author_placeHolder',
    defaultMessage: 'Select author'
  },
  enter_post_title: {
    id: 'cw-home.enter_post_title',
    defaultMessage: 'Enter the title of post'
  },
  enter_title_placeholder: {
    id: 'cw-home.enter_title_placeholder',
    defaultMessage: 'Enter title'
  },
  enter_post_content: {
    id: 'cw-home.enter_post_content',
    defaultMessage: 'Enter content of the post'
  },
  enter_content_placeholder: {
    id: 'cw-home.enter_content_placeholder',
    defaultMessage: 'Enter content'
  },
  enter_video_url: {
    id: 'cw-home.enter_video_url',
    defaultMessage: 'Enter video URL'
  },
  post_button: {
    id: 'cw-home.post_button',
    defaultMessage: 'Select button for post'
  },
  call_to_action_text: {
    id: 'cw-home.call_to_action_text',
    defaultMessage: 'Enter Call to Action button text'
  },
  call_to_action_url: {
    id: 'cw-home.call_to_action_url',
    defaultMessage: 'Enter Call to Action button URL'
  },
  post_priority: {
    id: 'cw-home.post_priority',
    defaultMessage: 'Select priority for post'
  },
  start_previewing: {
    id: 'cw-home.start_previewing',
    defaultMessage: 'Please select author to start previewing post'
  },
  post_successfully_created: {
    id: 'cw-home.post_successfully_created',
    defaultMessage: 'Your post has been successfully created!'
  },
  post_successfully_edited: {
    id: 'cw-home.post_successfully_edited',
    defaultMessage: 'Your post has been successfully edited!'
  },
  view_post_button: {
    id: 'cw-home.view_post_button',
    defaultMessage: 'View post'
  },
  wrong_image_format: {
    id: 'cw-home.wrong_image_format',
    defaultMessage:
      'Please upload an image file with one of the extensions gif, png, jpg, jpeg'
  },
  wrong_youtube_link: {
    id: 'cw-home.wrong_youtube_link',
    defaultMessage: 'Please enter a valid youtube video link'
  },
  wrong_call_to_action_url: {
    id: 'cw-home.wrong_call_to_action_url',
    defaultMessage: 'Please enter a valid URL'
  },
  delete_post_message: {
    id: 'cw-home.delete_post_message',
    defaultMessage: 'Are you sure you want to delete this post?'
  },
  delete_post_header: {
    id: 'cw-home.delete_post_header',
    defaultMessage: 'Delete Post'
  },
  provisioning: {
    id: 'cw-home.provisioining',
    defaultMessage: 'Provisioning'
  },
  provisioning_failed: {
    id: 'cwh-home.provisioning_failed',
    defaultMessage: 'Provisioning failed'
  },
  contact_support: {
    id: 'cw-home.contact_support',
    defaultMessage: 'Contact Support'
  },
  contact_sales: {
    id: 'cw-home.contact_sales',
    defaultMessage: 'Contact Sales'
  },
  unpin_post_header: {
    id: 'cw-home.unpin_post_header',
    defaultMessage: 'Unpin Post'
  },
  unpin_post_combo_placeholder: {
    id: 'cw-home.unpin_post_combo_placeholder',
    defaultMessage: 'Select post type'
  },
  pin_post: {
    id: 'cw-home.pin_post',
    defaultMessage: 'Pin Post'
  },
  buy_now: {
    id: 'cw-home.buy_now',
    defaultMessage: 'Buy Now'
  },
  learn_more_tile: {
    id: 'cw-home.learn_more_tile',
    defaultMessage: 'Learn more'
  },
  product_tile_provisioning_message_first_line: {
    id: 'cw-home.product_tile_provisioning_message_first_line',
    defaultMessage:
      "Your trial is ready when the status changes to ''{trialStatus}''."
  },
  product_tile_provisioning_message_second_line: {
    id: 'cw-home.product_tile_provisioning_message_second_line',
    defaultMessage: 'Please allow up to five minutes for completion.'
  },
  company_account_verification_error: {
    id: 'cw-home.company_account_verification_error',
    defaultMessage:
      "Your user account seems to have been setup separately from your {entity}. This won't affect the functionality of your licensed products, but it will affect your ability to access Support tickets from ConnectWise Home.<br/><br/> Please contact {connectWiseSupport} if you need additional assistance."
  },
  email_resend_verification_body: {
    id: 'cw-home.email_resend_verification_body',
    defaultMessage: `We have sent you an email from 'DoNotReply@ConnectWise.com'. If you cannot find the email, please check your spam folder or click the button below to resend it.`
  },
  connectwise_invoices: {
    id: 'cw-home.connectwise_invoices',
    defaultMessage: 'ConnectWise Invoices'
  },
  ePay_invoices: {
    id: 'cw-home.ePay_invoices',
    defaultMessage: 'ePay Invoices'
  },
  no_epay_invoices: {
    id: 'cw-home.no_epay_invoices',
    defaultMessage: 'There are no ePay invoices to display'
  },
  register: {
    id: 'cw-home.register',
    defaultMessage: 'Register'
  },
  register_another: {
    id: 'cw-home.register_another',
    defaultMessage: 'Register Another'
  },
  event_date_with_time: {
    id: 'cw-home.event_date_with_time',
    defaultMessage: '{eventDate} at {eventTime}'
  },
  event_date_with_time_on_earlier_today: {
    id: 'cw-home.event_date_with_time_on_earlier_today',
    defaultMessage: 'Started today at {eventTime}'
  },
  event_date_with_time_on_today: {
    id: 'cw-home.event_date_with_time_on_today',
    defaultMessage: 'Starts today at {eventTime}'
  },
  event_date_with_time_on_tommorow: {
    id: 'cw-home.event_date_with_time_on_tommorow',
    defaultMessage: 'Starts tomorrow at {eventTime}'
  },
  event_date_with_time_on_this_week: {
    id: 'cw-home.event_date_with_time_on_this_week',
    defaultMessage: 'Starts this {eventDay} at {eventTime}'
  },
  event_date_with_time_on_next_week: {
    id: 'cw-home.event_date_with_time_on_next_week',
    defaultMessage: 'Starts next {eventDay} at {eventTime}'
  },
  oh_no: {
    id: 'cw-home.oh_no',
    defaultMessage: 'Uh oh!'
  },
  no_search_result_label: {
    id: 'cw-home.no_search_result_label',
    defaultMessage: 'Looks like there are no search results found.'
  },
  event_duration: {
    id: 'cw-home.event_duration',
    defaultMessage: '{eventStartDate} - {eventEndDate}'
  },
  recommended_for: {
    id: 'cw-home.recommended_for',
    defaultMessage: ' Event recommended for {items}'
  },
  view_agenda: {
    id: 'cw-home.view_agenda',
    defaultMessage: 'View Agenda'
  },
  show_more: {
    id: 'cw-home.show_more',
    defaultMessage: 'Show More'
  },
  under_billing_maintenance: {
    id: 'cw-home.under_billing_maintenance ',
    defaultMessage:
      'This page is currently down for maintenance. We apologize for the inconvenience. Please contact your account manager if you need assistance.'
  },
  duplicate_attachments_error_msg: {
    id: 'cw-home.duplicate_attachments_error_msg',
    defaultMessage: 'Duplicate attached files are not allowed'
  },
  wallet_not_found_message: {
    id: 'cw-home.wallet_not_found_message',
    defaultMessage:
      'Your account has not been setup for purchasing. Please contact us to complete the setup.'
  },
  wrong_scheduled_date: {
    id: 'cw-home.wrong_scheduled_date',
    defaultMessage:
      'Post cannot be scheduled in the past or more than 60 days in the future.'
  },
  select_date_time: {
    id: 'cw-home.select_date_time',
    defaultMessage: 'Select date/time for post to be published'
  },
  select_date: {
    id: 'cw-home.select_date',
    defaultMessage: 'Select date'
  },
  select_time: {
    id: 'cw-home.select_time',
    defaultMessage: 'Enter time (local time)'
  },
  no_media: {
    id: 'cw-home.no_media',
    defaultMessage: 'No media'
  },
  image: {
    id: 'cw-home.image',
    defaultMessage: 'Image'
  },
  video: {
    id: 'cw-home.video',
    defaultMessage: 'Video'
  },
  no_button: {
    id: 'cw-home.no_button',
    defaultMessage: 'No Button'
  },
  cta_button: {
    id: 'cw-home.cta_button',
    defaultMessage: 'Call to Action Button'
  },
  low: {
    id: 'cw-home.low',
    defaultMessage: 'Low'
  },
  medium: {
    id: 'cw-home.medium',
    defaultMessage: 'Medium'
  },
  high: {
    id: 'cw-home.high',
    defaultMessage: 'High'
  },
  post_now: {
    id: 'cw-home.post_now',
    defaultMessage: 'Post now'
  },
  post_sehedule: {
    id: 'cw-home.post_sehedule',
    defaultMessage: 'Schedule for later'
  },
  event_registered_text: {
    id: 'cw-home.event_registered_text',
    defaultMessage: 'You are registered for this event'
  },
  event_registered_mobile_text: {
    id: 'cw-home.event_registered_mobile_text',
    defaultMessage: 'Registered'
  },
  news_feed: {
    id: 'cw-home.news_feed',
    defaultMessage: 'News Feed'
  },
  security_bulletin: {
    id: 'cw-home.security_bulletin',
    defaultMessage: 'Security Bulletin'
  },
  product: {
    id: 'cw-home.product',
    defaultMessage: 'Product'
  },
  security_bulletin_access_error: {
    id: 'cw-home.security_bulletin_access_error',
    defaultMessage:
      'Sorry, you do not have access to view this bulletin or the bulletin does not exist.'
  },
  create_bulletin: {
    id: 'cw-home.create_bulletin',
    defaultMessage: 'Create Bulletin'
  },
  select_product: {
    id: 'cw-home.select_product',
    defaultMessage: 'Select which product this bulletin is for'
  },
  helpfulText: {
    id: 'cw-home.helpfulText',
    defaultMessage: 'This was helpful'
  },
  wasHelpfulText: {
    id: 'cw-home.wasHelpfulText',
    defaultMessage: 'Was this helpful?'
  },
  registration_closed: {
    id: 'cw-home.registration_closed',
    defaultMessage: 'Registration has been closed for this event'
  },
  select_securityBulletin_author: {
    id: 'cw-home.select_securityBulletin_author',
    defaultMessage: 'Select who the security bulletin author is'
  },
  enter_securityBulletin_title: {
    id: 'cw-home.enter_securityBulletin_title',
    defaultMessage: 'Enter the title of security bulletin'
  },
  enter_securityBulletin_content: {
    id: 'cw-home.enter_securityBulletin_content',
    defaultMessage: 'Enter content of the security bulletin'
  },
  security_bulletin_details: {
    id: 'cw-home.security_bulletin_details',
    defaultMessage: 'Security Bulletin Details'
  },
  bulletin_details_description: {
    id: 'cw-home.bulletin_details_description',
    defaultMessage: 'Please add details of bulletin'
  },
  bulletin_successfully_created: {
    id: 'cw-home.bulletin_successfully_created',
    defaultMessage: 'Your bulletin has been successfully created!'
  },
  bulletin_successfully_edited: {
    id: 'cw-home.bulletin_successfully_edited',
    defaultMessage: 'Your bulletin has been successfully edited!'
  },
  view_bulletin_button: {
    id: 'cw-home.view_bulletin_button',
    defaultMessage: 'View bulletin'
  },
  start_previewing_bulletin: {
    id: 'cw-home.start_previewing_bulletin',
    defaultMessage: 'Please select author to start previewing bulletin'
  },
  bulletin_title: {
    id: 'cw-home.bulletin_title',
    defaultMessage: 'Bulletin Title'
  },
  edit_bulletin: {
    id: 'cw-home.edit_bulletin',
    defaultMessage: 'Edit Bulletin'
  },
  save: {
    id: 'cw-home.save',
    defaultMessage: 'Save'
  },
  copy_to_clipboard: {
    id: 'cw-home.copy_to_clipboard',
    defaultMessage: 'Copy to clipboard'
  },
  copy_link: {
    id: 'cw-home.copy_link',
    defaultMessage: 'Copy Link'
  },
  search_for_events: {
    id: 'cw-home.search_for_events',
    defaultMessage: 'Search for events'
  },
  select_event_product: {
    id: 'cw-home.select_event_product',
    defaultMessage: 'Select product'
  },
  select_event_start_date: {
    id: 'cw-home.select_event_start_date',
    defaultMessage: 'Select start date'
  },
  select_event_end_date: {
    id: 'cw-home.select_event_end_date',
    defaultMessage: 'Select end date'
  },
  drawer_sell_description: {
    id: 'cw-home.drawer_sell_description',
    defaultMessage:
      'Discover the better way to create quotes and proposals. With ConnectWise CPQ™ you can grow your business by establishing a mature sales process and leveraging professional templates that reduce mistakes and increase efficiencies to maximize every sales opportunities.'
  },
  drawer_sell_banner_header: {
    id: 'cw-home.drawer_sell_banner_header',
    defaultMessage:
      'See for yourself what ConnectWise CPQ™ can do to boost your business in this quick overview.'
  },
  drawer_sell_banner_description_first: {
    id: 'cw-home.drawer_sell_banner_description_first',
    defaultMessage: `If you’re still manually creating quotes & proposals with spreadsheets, and spending hours gathering product information from multiple sources, it’s time for a better way.
ConnectWise CPQ™ is a powerful quote and proposal automation solution that enables you to sell more, close faster and increase profits.`
  },
  drawer_sell_banner_description_second: {
    id: 'cw-home.drawer_sell_banner_description_second',
    defaultMessage: `With outstanding CRM and operations integration, single login support, and multiple deployment options, ConnectWise CPQ™ is the unparalleled solution for guiding your sales team to success. Remotely monitor and manage systems, expertly handle patching, and keep user’s productivity high without adding headcount.`
  },
  drawer_sell_benefit_header: {
    id: 'cw-home.drawer_sell_benefit_header',
    defaultMessage:
      'See ConnectWise CPQ™ in action and discover features including:'
  },
  drawer_sell_template_title: {
    id: 'cw-home.drawer_sell_template_title',
    defaultMessage: 'Template Library'
  },
  drawer_sell_template_text: {
    id: 'cw-home.drawer_sell_template_text',
    defaultMessage:
      'Create professional quotes and proposals from customizable templates.'
  },
  drawer_sell_search_title: {
    id: 'cw-home.drawer_manage_search_title',
    defaultMessage: 'Easy Product Search'
  },
  drawer_sell_search_text: {
    id: 'cw-home.drawer_manage_search_text',
    defaultMessage:
      'Populate templates with product and price info from top distributors.'
  },
  drawer_sell_flexible_title: {
    id: 'cw-home.drawer_sell_flexible_title',
    defaultMessage: 'Flexible Functionality'
  },
  drawer_sell_flexible_text: {
    id: 'cw-home.drawer_sell_flexible_text',
    defaultMessage:
      'Drag and drop products, headers, and terms & conditions into templates.'
  },
  drawer_sell_quotes_title: {
    id: 'cw-home.drawer_sell_quotes_title',
    defaultMessage: 'Online Quotes'
  },
  drawer_sell_quotes_text: {
    id: 'cw-home.drawer_sell_quotes_text',
    defaultMessage:
      'Publish online via an interactive portal, allowing clients to select upsell options.'
  },
  drawer_sell_cloud_title: {
    id: 'cw-home.drawer_sell_cloud_title',
    defaultMessage: 'Cloud-Based Software'
  },
  drawer_sell_cloud_text: {
    id: 'cw-home.drawer_sell_cloud_text',
    defaultMessage: 'Allows the flexibility to quote at anytime from anywhere.'
  },
  drawer_sell_intelligence_title: {
    id: 'cw-home.drawer_sell_intelligence_title',
    defaultMessage: 'Business Intelligence'
  },
  drawer_sell_intelligence_text: {
    id: 'cw-home.drawer_sell_intelligence_text',
    defaultMessage:
      'Gain insight into your business like never before with custom templates and reports.'
  },
  drawer_sell_product_owned: {
    id: 'cw-home.drawer_sell_product_owned',
    defaultMessage: 'Already own ConnectWise CPQ™?'
  },
  resources: {
    id: 'cw-home.resources',
    defaultMessage: 'Resources'
  },
  no_resources: {
    id: 'cw-home.no_resources',
    defaultMessage: 'There are no resources to show!!'
  },
  add_license_request_error: {
    id: 'cw-home.add_license_request_error',
    defaultMessage:
      'There was an issue with your request to add licenses. Please contact your account manager for assistance.'
  },
  events_search_placeholder: {
    id: 'cw-home.events_search_placeholder',
    defaultMessage: 'Search Events'
  },
  news_feed_search_placeholder: {
    id: 'cw-home.news_feed_search_placeholder',
    defaultMessage: "Search What's New"
  },
  mfa_app_active: {
    id: 'cw-home.mfa_app_active',
    defaultMessage: 'Authentication App Active'
  },
  mfa_app_header_setup: {
    id: 'cw-home.mfa_app_header_setup',
    defaultMessage: 'Setup MFA with an Authenticator App'
  },
  mfa_app_turn_off_confirmation: {
    id: 'cw-home.mfa_app_turn_off_confirmation',
    defaultMessage: 'Are you sure you want to remove auth MFA?'
  },
  mfa_app_turned_off: {
    id: 'cw-home.mfa_app_turned_off',
    defaultMessage: 'Authentication App Turned Off'
  },
  mfa_app_setup: {
    id: 'cw-home.mfa_app_setup',
    defaultMessage: 'Setup Auth App'
  },
  issue: {
    id: 'cw-home.issue',
    defaultMessage: 'ISSUE#'
  },
  work_around: {
    id: 'cw-home.work_around',
    defaultMessage: 'Workaround'
  },
  reported: {
    id: 'cw-home.reported',
    defaultMessage: 'Reported'
  },
  version: {
    id: 'cw-home.version',
    defaultMessage: 'Fix Version'
  },
  updated: {
    id: 'cw-home.updated',
    defaultMessage: 'updated'
  },
  add_issue: {
    id: 'cw-home.add_issue',
    defaultMessage: 'Add Issue'
  },
  my_cases: {
    id: 'cw-home.my_cases',
    defaultMessage: 'My Cases'
  },
  my_historical_cases: {
    id: 'cw-home.my_historical_cases',
    defaultMessage: 'My Historical Cases'
  },
  my_historical_cases_text: {
    id: 'cw-home.my_historical_cases_text',
    defaultMessage:
      'All tickets closed on and before 1st of Jan 2022 are listed here. Please note you cannot perform any actions on these tickets.'
  },
  issues: {
    id: 'cw-home.issues',
    defaultMessage: 'Issues'
  },
  edit: {
    id: 'cw-home.edit',
    defaultMessage: 'Edit'
  },
  add_resources: {
    id: 'cw-home.add_resources',
    defaultMessage: 'Add More Resources'
  },
  add_default_resources: {
    id: 'cw-home.add_default_resources',
    defaultMessage: 'Add Default Resources'
  },
  add_resource: {
    id: 'cw-home.add_resource',
    defaultMessage: 'Add Resource'
  },
  edit_resource: {
    id: 'cw-home.edit_resource',
    defaultMessage: 'Edit Resource'
  },
  link_name: {
    id: 'cw-home.link_name',
    defaultMessage: 'Link Name'
  },
  url: {
    id: 'cw-home.url',
    defaultMessage: 'URL'
  },
  description: {
    id: 'cw-home.description',
    defaultMessage: 'Description'
  },
  link_name_placeholder: {
    id: 'cw-home.link_name_placeholder',
    defaultMessage: 'Enter Link Name'
  },
  url_placeholder: {
    id: 'cw-home.url_placeholder',
    defaultMessage: 'Enter URL'
  },
  description_placeholder: {
    id: 'cw-home.description_placeholder',
    defaultMessage: 'Enter Description'
  },
  confirm_delete_resource: {
    id: 'cw-home.confirm_delete_resource',
    defaultMessage: 'Please confirm you would like to delete resource.'
  },
  delete_resource_header_text: {
    id: 'cw-home.delete_resource_header_text',
    defaultMessage: 'Delete Resource'
  },
  confirm_add_resource_header_text: {
    id: 'cw-home.confirm_add_resource_header_text',
    defaultMessage: 'Confirm Add Resource'
  },
  confirm_edit_resource_header_text: {
    id: 'cw-home.confirm_edit_resource_header_text',
    defaultMessage: 'Confirm Edit Resource'
  },
  confirm_add_resource_text: {
    id: 'cw-home.confirm_add_resource_text',
    defaultMessage:
      'You are creating a new default resource that will show for ALL ConnectWise partners, are you sure you want to continue?'
  },
  confirm_edit_resource_text: {
    id: 'cw-home.confirm_edit_resource_text',
    defaultMessage:
      'You are editing a default resource these changes will show for ALL ConnectWise partners, are you sure you want to continue?'
  },
  test_url: {
    id: 'cw-home.test_url',
    defaultMessage: 'TEST URL'
  },
  stay: {
    id: 'cw-home.stay',
    defaultMessage: 'Stay'
  },
  close_drawer_title: {
    id: 'cw-home.close_drawer_title',
    defaultMessage: 'Close drawer?'
  },
  close_drawer_body: {
    id: 'cw-home.close_drawer_body',
    defaultMessage: 'The changes you made will not be saved.'
  },
  on_before_close_notification: {
    id: 'on_before_close_notification',
    defaultMessage:
      'Closing this drawer will result in a loss of unsaved changes.'
  },
  access_denied_image: {
    id: 'cw-home.access_denied_image',
    defaultMessage: 'Access Denied Image'
  },
  product_image: {
    id: 'cw-home.product_image',
    defaultMessage: 'Product Image'
  },
  license_success_image: {
    id: 'cw-home.license_success_image',
    defaultMessage: 'License Success Image'
  },
  license_success: {
    id: 'cw-home.license_success',
    defaultMessage: 'Add Licenses Successful'
  },
  license_error: {
    id: 'cw-home.license_error',
    defaultMessage: 'Add Licenses Failure'
  },
  control_plan: {
    id: 'cw-home.control_plan',
    defaultMessage: 'ScreenConnect Plan'
  },
  manage_benefit: {
    id: 'cw-home.manage_benefit',
    defaultMessage: 'Manage Benefit'
  },
  no_result: {
    id: 'cw-home.no_result',
    defaultMessage: 'No Result'
  },
  success_manager_photo: {
    id: 'cw-home.success_manager_photo',
    defaultMessage: 'Success Manager Photo'
  },
  account_manager_photo: {
    id: 'cw-home.account_manager_photo',
    defaultMessage: 'Account Manager Photo'
  },
  news_item_image: {
    id: 'cw-home.news_item_image',
    defaultMessage: 'News Item Image'
  },
  product_hub_empty_state_image: {
    id: 'cw-home.product_hub_empty_state_image',
    defaultMessage: 'Product Hub Empty State'
  },
  login_logo: {
    id: 'cw-home.login_logo',
    defaultMessage: 'Login Logo'
  },
  feature_display: {
    id: 'cw-home.feature_display',
    defaultMessage: 'Feature Display'
  },
  connectwise_logo: {
    id: 'cw-home.connectwise_logo',
    defaultMessage: 'ConnectWise Logo'
  },
  page_not_found: {
    id: 'cw-home.page_not_found',
    defaultMessage: 'Page Not Found'
  },
  issue_id: {
    id: 'cw-home.issue_id',
    defaultMessage: 'Issue #{issueId}'
  },
  last_updated: {
    id: 'cw-home.last_updated',
    defaultMessage: 'Last Updated'
  },
  components: {
    id: 'cw-home.components',
    defaultMessage: 'Components'
  },
  version_number: {
    id: 'cw-home.version_number',
    defaultMessage: 'Fix Version'
  },
  patch_number: {
    id: 'cw-home.patch_number',
    defaultMessage: 'Patch Version'
  },
  resolution: {
    id: 'cw-home.resolution',
    defaultMessage: 'Resolution'
  },
  known_issue_access_error: {
    id: 'cw-home.known_issue_access_error',
    defaultMessage:
      'Sorry, you do not have access to view this issue or the issue does not exist.'
  },
  search_known_issues_placeholder: {
    id: 'cw-home.search_known_issues_placeholder',
    defaultMessage: 'Search issue # or summary'
  },
  no_issues: {
    id: 'cw-home.no_issues',
    defaultMessage:
      'There are no issues to display. Resolved issues older than 365 days will not display.'
  },
  known_issues_product_selected: {
    id: 'cw-home.known_issues_product_selectes',
    defaultMessage: '{noOfItems} Products selected'
  },
  known_issues_component_selected: {
    id: 'cw-home.known_issues_component_selected',
    defaultMessage: '{noOfItems} Components selected'
  },
  known_issues_statuses_selected: {
    id: 'cw-home.known_issues_statuses_selected',
    defaultMessage: '{noOfItems} Statuses selected'
  },
  known_issues_fixversion_selected: {
    id: 'cw-home.known_issues_fixversion_selected',
    defaultMessage: '{noOfItems} Fix Versions selected'
  },
  known_issues_helptext: {
    id: 'cw-home.knownissues_help_icon_help_text',
    defaultMessage:
      'Use this list to see if an issue affecting you is already known and the latest status. This list is updated regularly and may not list all issues we are aware of or working to fix.  For detailed information on how to use this page, please see the Issues documentation'
  },
  known_issues_sub_helptext: {
    id: 'cw-home.knownissues_sub_helptext',
    defaultMessage: 'Things you may need to know:'
  },
  known_issues_helpLink: {
    id: 'cw-home.known_issues_helpLink',
    defaultMessage: 'here'
  },
  drawer_automate_description: {
    id: 'cw-home.drawer_automate_description',
    defaultMessage:
      'ConnectWise Automate will enable you to find, repair and maintain your clients IT assets remotely through a single interface, as well as automate redundant tasks and proactively resolve issues before they become full-blown problems. See for yourself why Automate was named the 2019 Best Remote Monitoring and Management tool by ChannelPro Magazine.'
  },
  drawer_automate_banner_header: {
    id: 'cw-home.drawer_automate_banner_header',
    defaultMessage:
      'See What ConnectWise Automate Can Do to Boost Your Service Delivery.'
  },
  drawer_automate_banner_center_description: {
    id: 'cw-home.drawer_automate_banner_center_description',
    defaultMessage:
      'Your business needs to move fast with IT services that are easy to deliver and continually monitored to keep your end users productive. ConnectWise Automate® makes that simple, allowing you to work on multiple machines at the same time, manage everything through a single console, manage policies across multiple endpoints, and much more.'
  },
  drawer_automate_banner_footer_description: {
    id: 'cw-home.drawer_automate_banner_footer_description',
    defaultMessage:
      'Give your team the software to efficiently manage the day-to-day operations of your business and provide the proactive support your clients rely on. Remotely monitor and  manage systems, expertly handle patching, and keep user’s productivity high without adding headcount.'
  },
  drawer_automate_product_owned: {
    id: 'cw-home.drawer_automate_product_owned',
    defaultMessage: 'Already own ConnectWise Automate?'
  },
  drawer_automate_benefit_header: {
    id: 'cw-home.drawer_automate_benefit_header',
    defaultMessage:
      'See ConnectWise Automate in action and discover features including:'
  },
  discovery: {
    id: 'cw-home.discovery',
    defaultMessage: 'Discovery'
  },
  drawer_automate_benefit_discovery_text: {
    id: 'cw-home.drawer_automate_benefit_discovery_text',
    defaultMessage:
      'Learn to confidently delegate non urgent tasks like asset discovery and inventory'
  },
  patching: {
    id: 'cw-home.patching',
    defaultMessage: 'Patching'
  },
  drawer_automate_benefit_patch_text: {
    id: 'cw-home.drawer_automate_benefit_patch_text',
    defaultMessage:
      'Schedule and monitor patching automatically and manage multiple machines at once'
  },
  management: {
    id: 'cw-home.management',
    defaultMessage: 'Management'
  },
  drawer_automate_benefit_management_text: {
    id: 'cw-home.drawer_automate_benefit_management_text',
    defaultMessage:
      'See how you can manage your entire environment from a single control center'
  },
  monitoring: {
    id: 'cw-home.monitoring',
    defaultMessage: 'Monitoring'
  },
  drawer_automate_benefit_monitor_text: {
    id: 'cw-home.drawer_automate_benefit_monitor_text',
    defaultMessage:
      'Monitor your environment proactively and deliver more value than ever before'
  },
  automation: {
    id: 'cw-home.automation',
    defaultMessage: 'Automation'
  },
  drawer_automate_benefit_automation_text: {
    id: 'cw-home.drawer_automate_benefit_automation_text',
    defaultMessage:
      'Automate any process or task through scripting, so your team can focus on value'
  },
  drawer_product_connect_text: {
    id: 'cw-home.drawer_product_connect_text',
    defaultMessage: 'Connect your account now!'
  },
  automate_benefit: {
    id: 'cw-home.automate_benefit',
    defaultMessage: 'Automate Benefit'
  },
  known_issues_my_watched_issues: {
    id: 'cw-home.known_issues_my_watched_issues',
    defaultMessage: '{noOfItems} My Watched Issues'
  },
  known_issues_my_watched_issues_placeholder: {
    id: 'cw-home.known_issues_my_watched_issues_placeholder',
    defaultMessage: 'Select My Watched Issues'
  },
  known_issues_no_partner_products: {
    id: 'cw-home.known_issues_no_partner_products',
    defaultMessage:
      'There are no issues for the products you own. You can view issues for other products by selecting the product(s) in the filters. '
  },
  known_issues_fix_version_placeholder: {
    id: 'cw-home.known_issues_fix_version_placeholder',
    defaultMessage: 'Select Fix Versions'
  },
  known_issues_fix_version_select_all_text: {
    id: 'cw-home.known_issues_fix_version_select_all_text',
    defaultMessage: 'All Fix Versions'
  },
  known_issues_products_placeholder: {
    id: 'cw-home.known_issues_products_placeholder',
    defaultMessage: 'Select Products'
  },
  known_issues_products_select_all_text: {
    id: 'cw-home.known_issues_products_select_all_text',
    defaultMessage: 'All Products'
  },
  known_issues_components_placeholder: {
    id: 'cw-home.known_issues_components_placeholder',
    defaultMessage: 'Select Components'
  },
  known_issues_components_select_all_text: {
    id: 'cw-home.known_issues_components_select_all_text',
    defaultMessage: 'All Components'
  },
  known_issues_statuses_placeholder: {
    id: 'cw-home.known_issues_statuses_placeholder',
    defaultMessage: 'Select Statuses'
  },
  known_issues_statuses_select_all_text: {
    id: 'cw-home.known_issues_statuses_select_all_text',
    defaultMessage: 'All Statuses'
  },
  known_issues_all: {
    id: 'cw-home.known_issues_all',
    defaultMessage: 'All'
  },
  backlog: {
    id: 'cw-home.backlog',
    defaultMessage: 'Backlog'
  },
  backlog_status_info: {
    id: 'cw-home.backlog_status_info',
    defaultMessage:
      ': The defect has been prioritized and is pending Engineering assignment.'
  },
  development: {
    id: 'cw-home.development',
    defaultMessage: 'Development'
  },
  development_status_info: {
    id: 'cw-home.development_status_info',
    defaultMessage: ': The defect is being worked on by the Engineering team.'
  },
  archived: {
    id: 'cw-home.archived',
    defaultMessage: 'Archived'
  },
  archived_status_info: {
    id: 'cw-home.archived_status_info',
    defaultMessage:
      ': Defects over two years of age have been moved to archived state as teams review and consolidate go forward in conjunction with other in-flight projects.'
  },
  testing: {
    id: 'cw-home.testing',
    defaultMessage: 'Testing'
  },
  testing_status_info: {
    id: 'cw-home.testing_status_info',
    defaultMessage:
      ': The defect has been handed off to Quality Engineers to validate that the issue has been resolved. If issues are found during testing, the status may change back to Development.'
  },
  pending_release: {
    id: 'cw-home.pending_release',
    defaultMessage: 'Pending Release'
  },
  pending_release_status_info: {
    id: 'cw-home.pending_release_status_info',
    defaultMessage:
      ': The defect has been resolved by Engineering and passed by the Quality Engineers and waiting to be released.'
  },
  closed: {
    id: 'cw-home.closed',
    defaultMessage: 'Closed'
  },
  closed_status_info: {
    id: 'cw-home.closed_status_info',
    defaultMessage: ': The defect that has been closed in the past.'
  },
  closed_enhancement_status_info: {
    id: 'cw-home.closed_enhancement_status_info',
    defaultMessage:
      ': Our Product Management and Engineering teams have reviewed this issue and determined that this issue is an enhancement to our current functionality and is outside of our normal maintenance process. Please raise an enhancement ticket so it will be taken up per priority.'
  },
  closed_enhancement: {
    id: 'cw-home.closed_enhancement',
    defaultMessage: 'Closed> Enhancement'
  },
  released_status_info: {
    id: 'cw-home.released_status_info',
    defaultMessage:
      ': The defect has been resolved and released in the product.'
  },
  released: {
    id: 'cw-home.released',
    defaultMessage: 'Released'
  },
  closed_wontdo_status_info: {
    id: 'cw-home.closed_wontdo_status_info',
    defaultMessage:
      ': The related product feature is either in extended support or in a retiring state. At this time there is no intent to resolve issues or enhance this area of the product.'
  },
  closed_wontdo: {
    id: 'cw-home.closed_wontdo',
    defaultMessage: "Closed> Won't Do"
  },
  not_reproducible_status_info: {
    id: 'cw-home.not_reproducible_status_info',
    defaultMessage:
      ': The defect is unable to be reproduced within our internal system.'
  },
  not_reproducible: {
    id: 'cw-home.not_reproducible',
    defaultMessage: 'Not Reproducible'
  },
  closed_intended_functionality_status_info: {
    id: 'cw-home.closed_intended_functionality_status_info',
    defaultMessage: ': Functionality is working as intended within the system.'
  },
  closed_intended_functionality: {
    id: 'cw-home.closed_intended_functionality',
    defaultMessage: 'Closed> Intended Functionality'
  },
  closed_known_issue: {
    id: 'cw-home.closed_known_issue',
    defaultMessage: 'Closed> Known Issue'
  },
  closed_known_issue_status_info: {
    id: 'cw-home.closed_known_issue_status_info',
    defaultMessage:
      ': Duplicate ticket that is known as an unexpected behaviour in system, but not yet fixed.'
  },
  known_issues_defect_priority: {
    id: 'cw-home.known_issues_defect_priority',
    defaultMessage: 'Defect Priority'
  },
  known_issues_follow_dialog_message: {
    id: 'cw-home.known_issues_follow_dialog_message',
    defaultMessage:
      'Would you like to be notified when this issue has been resolved?'
  },
  yes: {
    id: 'cw-home.yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'cw-home.no',
    defaultMessage: 'No'
  },
  yes_no: {
    id: 'cw-home.yes_no',
    defaultMessage: 'YES/NO'
  },
  known_issues_send_email_link: {
    id: 'cw-home.known_issues_send_email_link',
    defaultMessage: 'Send Link'
  },
  known_issues_send_email_text: {
    id: 'cw-home.known_issues_send_email_text',
    defaultMessage:
      "Enter a valid email address to send partner a link to the issue.  Once the partner validates the link, the issue will be automatically added to their 'Watched Issues' and will be enrolled in the email notification system to notify them when the issue is resolved or closed.  Partner must be signed in to Home to validate the link."
  },
  known_issues_email_placeholder: {
    id: 'cw-home.known_issues_email_placeholder',
    defaultMessage: 'Enter Email'
  },
  contact_my_connectwise_team: {
    id: 'cw-home.contact_my_connectwise_team',
    defaultMessage: 'Contact My ConnectWise Team'
  },
  account_manager_name: {
    id: 'cw-home.account_manager_name',
    defaultMessage: '{fullName} | Account Manager'
  },
  success_manager_name: {
    id: 'cw-home.success_manager_name',
    defaultMessage: '{fullName} | Success Manager'
  },
  partner_success_team: {
    id: 'cw-home.partner_success_team',
    defaultMessage: 'Partner Success Team'
  },
  known_issues_email_validate_label: {
    id: 'cw-home.known_issues_email_validate_label',
    defaultMessage: 'Validate Email'
  },
  wallet_add_card_info: {
    id: 'cw-home.wallet_add_card_info',
    defaultMessage: 'Please add a credit card'
  },
  account_manager_description: {
    id: 'cw-home.account_manager_description',
    defaultMessage:
      'I can help you with contract renewals, pricing and packaging options, adding software licenses, expanding products and services, and connecting you with resources within ConnectWise or other account related concerns.'
  },
  my_connectwise_team: {
    id: 'cw-home.my_connectwise_team',
    defaultMessage: 'My ConnectWise Team'
  },
  need_help_deciding: {
    id: 'cw-home.need_help_deciding',
    defaultMessage: 'Need Help Deciding?'
  },
  fun_facts_about_me: {
    id: 'cw-home.fun_facts_about_me',
    defaultMessage: 'Fun facts about me...'
  },
  account_manager: {
    id: 'cw-home.account_manager',
    defaultMessage: 'Account Manager'
  },
  success_manager: {
    id: 'cw-home.success_manager',
    defaultMessage: 'Partner Success Manager'
  },
  partner_sales_executive: {
    id: 'cw-home.partner_sales_executive',
    defaultMessage: 'Partner Sales Executive'
  },
  partner_sales_executive_description: {
    id: 'cw-home.partner_sales_executive_description',
    defaultMessage:
      'I can help you with the addition of new products and services offered by ConnectWise.'
  },
  partner_development_manager: {
    id: 'cw-home.partner_development_manager',
    defaultMessage: 'Partner Development Manager'
  },
  partner_development_manager_description: {
    id: 'cw-home.partner_development_manager_description',
    defaultMessage:
      'I can help accelerate your cybersecurity growth and guide your team through cybersecurity practice-building journeys.'
  },
  success_manager_description: {
    id: 'cw-home.success_manager_description',
    defaultMessage:
      'I can help you with Product utilization and health checks, product mentoring and best practices, and aligning your business strategy to our platform.'
  },
  psm_breadcrumb_topic: {
    id: 'cw-home.psm_breadcrumb_topic',
    defaultMessage: 'Topic'
  },
  my_account_manager: {
    id: 'cw-home.my_account_manager',
    defaultMessage: 'My ConnectWise Account Manager'
  },
  photo: {
    id: 'cw-home.photo',
    defaultMessage: 'Photo'
  },
  category: {
    id: 'cw-home.category',
    defaultMessage: 'Category'
  },
  about_me: {
    id: 'cw-home.about_me',
    defaultMessage: 'About Me'
  },
  show_less: {
    id: 'cw-home.show_less',
    defaultMessage: 'Show Less'
  },
  default_expired_card_failed_message: {
    id: 'cw-home.default_expired_card_failed_message',
    defaultMessage:
      'Payment Failed: There have been payment failures against your invoices since your card ending with {cardLastFourDigit} have expired. Please add a valid card and retry payment.'
  },
  payment_failed_default_message: {
    id: 'cw-home.payment_failed_default_message',
    defaultMessage:
      'Payment Failed: There have been payment failures against your invoices. Please retry payment.'
  },
  user_profile_success_message: {
    id: 'cw-home.user_profile_success_message',
    defaultMessage: 'User Profile has been updated.'
  },
  user_profile_error_message: {
    id: 'cw-home.user_profile_error_message',
    defaultMessage: 'There was a problem updating the user profile.'
  },
  billing_success_message: {
    id: 'cw-home.billing_success_message',
    defaultMessage: 'Billing notifications updated.'
  },
  billing_error_message: {
    id: 'cw-home.billing_error_message',
    defaultMessage: 'Unable to update billing notifications.'
  },
  password_success_message: {
    id: 'cw-home.password_success_message',
    defaultMessage: 'Password was successfully updated'
  },
  password_error_in_processing_message: {
    id: 'cw-home.password_error_in_processing_message',
    defaultMessage: 'Unable to process the request.'
  },
  profile_first_name_blank_error_message: {
    id: 'cw-home.profile_first_name_blank_error_message',
    defaultMessage: 'First name cannot be left blank.'
  },
  password_not_matched_message: {
    id: 'cw-home.password_not_matched_message',
    defaultMessage: 'Passwords do not match, Please try again.'
  },
  mfa_email_success_message: {
    id: 'cw-home.mfa_email_success_message',
    defaultMessage: 'Email MFA status Updated.'
  },
  mfa_email_error_message: {
    id: 'cw-home.mfa_email_error_message',
    defaultMessage: 'Unable to update the email MFA status.'
  },
  mfa_app_success_message: {
    id: 'cw-home.mfa_app_success_message',
    defaultMessage: 'MFA App status Updated.'
  },
  mfa_app_error_message: {
    id: 'cw-home.mfa_app_error_message',
    defaultMessage: 'Unable to update the MFA App status.'
  },
  mfa_app_setup_error_message: {
    id: 'cw-home.mfa_app_setup_error_message',
    defaultMessage: 'Unable to setup the MFA App.'
  },
  password_incorrect_message: {
    id: 'cw-home.password_incorrect_message',
    defaultMessage: 'Current Password is Incorrect'
  },
  billing_drawer_flyout_title: {
    id: 'cw-home.billing_drawer_flyout_title',
    defaultMessage: 'How can we help you...'
  },
  billing_drawer_flyout_body: {
    id: 'cw-home.billing_drawer_flyout_body',
    defaultMessage:
      'I can help you with questions about your invoices, credit memos and contracts as well as make changes to your address or contact information.'
  },
  user_not_found_in_one_trust: {
    id: 'cw-home.user_not_found_in_one_trust',
    defaultMessage:
      'Could not find account for email {email}. Please reach out to our <a href="mailto:help@connectwise.com">ConnectWise Support</a> team.'
  },
  redirect_dialog_header_title: {
    id: 'cwhome.redirect_dialog_header_title',
    defaultMessage: 'Leave ConnectWise'
  },
  redirect_dialog_header: {
    id: 'cwhome.redirect_dialog_header',
    defaultMessage: 'Attention'
  },
  arrow_company_registered: {
    id: 'cw-home.arrow_company_registered',
    defaultMessage: 'Company Registered'
  },
  arrow_create_user_account: {
    id: 'cw-home.arrow_create_user_account',
    defaultMessage: 'Create user account'
  },
  arrow_create_user_request_description: {
    id: 'cw-home.arrow_create_user_request_description',
    defaultMessage:
      "You are leaving the ConnectWise Home website and being redirected to Arrow's website. Arrow is a third party over which we have no control. Neither ConnectWise, nor its subsidiaries or affiliates, is responsible for the content of third-party sites hyper-linked from this page, nor do they guarantee or endorse the information, recommendations, products or services offered on third-party sites.  Third-party sites may have different Privacy and Security policies than ConnectWise. You should review the Privacy and Security policies of any third-party website before you provide personal or confidential information.  If you wish to provide Arrow your First Name, Last Name,  Email, Phone and Country you may click 'OK' below.  Are you sure you want to continue?"
  },
  arrow_register_redirect_message: {
    id: 'cwhome.arrow_register_redirect_message',
    defaultMessage:
      "You are leaving the ConnectWise Home website and being redirected to Arrow's website. Arrow is a third party over which we have no control. Neither ConnectWise, nor its subsidiaries or affiliates, is responsible for the content of third-party sites hyper-linked from this page, nor do they guarantee or endorse the information, recommendations, products or services offered on third-party sites.  Third-party sites may have different Privacy and Security policies than ConnectWise. You should review the Privacy and Security policies of any third-party website before you provide personal or confidential information.  If you wish to provide Arrow your First Name, Last Name, Company Name, Email, Phone and Country you may click 'OK' below.  Are you sure you want to continue?"
  },
  arrow_register_with_arrow: {
    id: 'cw-home.arrow_register_with_arrow',
    defaultMessage: 'Register with Arrow'
  },
  arrow_company_registration_failed: {
    id: 'cw-home.arrow_registration_failed',
    defaultMessage: 'Company Registration Failed'
  },
  arrow_company_registration_in_progress: {
    id: 'cw-home.arrow_company_registration_in_progress',
    defaultMessage: 'Company Registration in Progress'
  },
  arrow_company_not_registered: {
    id: 'cw-home.arrow_company_not_registered',
    defaultMessage: 'Company Not Registered'
  },
  arrow_user_registered: {
    id: 'cw-home.arrow_user_registered',
    defaultMessage: 'Company Registered'
  },
  arrow_try_again: {
    id: 'cw-home.arrow_try_again',
    defaultMessage: 'Please try again'
  },
  arrow_support_text: {
    id: 'cw-home.arrow_support',
    defaultMessage:
      'If you have questions for Arrow, please reach out to connectwisesupport@arrow.com'
  },
  arrow_cw_support_email: {
    id: 'cw-home.arrow_cw_support_email',
    defaultMessage: 'mailto:connectwisesupport@arrow.com'
  },
  partner_program_login_information: {
    id: 'cw-home.partner_program_login_information',
    defaultMessage:
      'For additional user request, please email: <a href="mailto:CPP@connectwise.com">CPP@connectwise.com</a>'
  },
  partner_program_registration_information: {
    id: 'cw-home.partner_program_registration_information',
    defaultMessage: 'Maximize your MRR, efficiency and effectiveness'
  },
  partner_program_registration: {
    id: 'cw-home.partner_program_registration',
    defaultMessage: 'Register'
  },
  branding_trademark: {
    id: 'cw-home.branding_trademark',
    defaultMessage: '™'
  },
  investigating: {
    id: 'cw-home.investigating',
    defaultMessage: 'Investigating'
  },
  investigating_status_info: {
    id: 'cw-home.investigating_status_info',
    defaultMessage:
      ': The defect is under review by Product and Engineering for acceptance and priority.'
  },
  banner: {
    id: 'cw-home.banner',
    defaultMessage: 'Banner'
  },
  upload_banner: {
    id: 'cw-home.upload_banner',
    defaultMessage: 'Upload Banner'
  },
  banner_name: {
    id: 'cw-home.banner_name',
    defaultMessage: 'Banner Name'
  },
  banner_status: {
    id: 'cw-home.banner_status',
    defaultMessage: 'Status'
  },
  banner_region: {
    id: 'cw-home.banner_region',
    defaultMessage: 'Region'
  },
  banner_submitted: {
    id: 'cw-home.banner_submitted',
    defaultMessage: 'Submitted'
  },
  publish_banner: {
    id: 'cw-home.publish_banner',
    defaultMessage: 'Publish'
  },
  edit_banner: {
    id: 'cw-home.edit_banner',
    defaultMessage: 'Edit'
  },
  delete_banner: {
    id: 'cw-home.delete_banner',
    defaultMessage: 'Delete'
  },
  create_new_banner: {
    id: 'cw-home.create_new_banner',
    defaultMessage: 'Create a new banner for Home'
  },
  banner_name_info: {
    id: 'cw-home.banner_name_info',
    defaultMessage: 'Banner name will not be published.'
  },
  banner_upload_label: {
    id: 'cw-home.banner_upload_label',
    defaultMessage: 'Please upload images in .SVG file type.'
  },
  banner_upload_label_one: {
    id: 'cw-home.banner_upload_label_one',
    defaultMessage: 'Image for resolution 320*371'
  },
  banner_upload_label_two: {
    id: 'cw-home.banner_upload_label_two',
    defaultMessage: 'Image for resolution 768*371'
  },
  banner_upload_label_three: {
    id: 'cw-home.banner_upload_label_three',
    defaultMessage: 'Image for resolution 1000*320'
  },
  banner_upload_label_four: {
    id: 'cw-home.banner_upload_label_four',
    defaultMessage: 'Image for resolution 1384*320'
  },
  automate_banner_headline: {
    id: 'cw-home.automate_banner_headline',
    defaultMessage: 'Copy'
  },
  automate_banner_subheadline: {
    id: 'cw-home.automate_banner_subheadline',
    defaultMessage: 'Sub Headline'
  },
  invalid_banner_image_format: {
    id: 'cw-home.invalid_banner_image_format',
    defaultMessage: 'Please upload image in .SVG format only.'
  },
  banner_successfully_created: {
    id: 'cw-home.banner_successfully_created',
    defaultMessage: 'Your banner has been successfully created!'
  },
  banner_successfully_edited: {
    id: 'cw-home.banner_successfully_edited',
    defaultMessage: 'Your banner has been successfully edited!'
  },
  attach: {
    id: 'cw-home.attach',
    defaultMessage: 'Attach'
  },
  banner_sub_header: {
    id: 'cw-home.banner_sub_header',
    defaultMessage: 'Sub-Header (To add the sub-header in two lines use <br>)'
  },
  banner_CTA_button_enable: {
    id: 'cw-home.banner_CTA_button_enable',
    defaultMessage: 'Enable CTA Button'
  },
  banner_CTA_button_text: {
    id: 'cw-home.banner_CTA_button_text',
    defaultMessage: 'CTA Button Text'
  },
  banner_CTA_button_url: {
    id: 'cw-home.banner_CTA_button_url',
    defaultMessage: 'CTA Button URL'
  },
  banner_demo_url: {
    id: 'cw-home.banner_demo_url',
    defaultMessage: 'https://www.abc.com/'
  },
  banner_CTA_button_primary_color: {
    id: 'cw-home.banner_CTA_button_primary_color',
    defaultMessage: 'CTA Button Primary Color'
  },
  banner_CTA_button_text_primary_color: {
    id: 'cw-home.banner_CTA_button_text_primary_color',
    defaultMessage: 'CTA Button Text Primary Color'
  },
  preview_banner: {
    id: 'cw-home.preview_banner',
    defaultMessage: 'Preview Banner'
  },
  pick_color: {
    id: 'cw-home.pick_color',
    defaultMessage: 'Select a color'
  },
  brand_color: {
    id: 'cw-home.brand_color',
    defaultMessage: 'Brand Color'
  },
  brand_color_description: {
    id: 'cw-home.brand_color_description',
    defaultMessage:
      'Add your own unique brand color. Make sure it’s dark enough to be seen with white text. You can preview changes before you publish.'
  },
  select_default_color: {
    id: 'cw-home.select_default_color',
    defaultMessage: 'Select a default color'
  },
  add_hex_code: {
    id: 'cw-home.add_hex_code',
    defaultMessage: 'Or Add a Hex Code'
  },
  invalid_hex_code: {
    id: 'cw-home.invalid_hex_code',
    defaultMessage: 'Invalid hex code'
  },
  required: {
    id: 'cw-home.required',
    defaultMessage: 'Required'
  },
  invalid_image_file: {
    id: 'cw-home.invalid_image_file',
    defaultMessage: 'Invalid image file'
  },
  banner_publish_error: {
    id: 'cw-home.banner_publish_error',
    defaultMessage: 'Please make sure Banner is published for every region.'
  },
  publish_default_banner_confirmation: {
    id: 'cw-home.publish_default_banner_confirmation',
    defaultMessage:
      'Publishing the default banner will remove the current banner of Home in the selected region. Are you sure you want to continue?'
  },
  publish_banner_confirmation: {
    id: 'cw-home.publish_banner_confirmation',
    defaultMessage:
      'Publishing the banner will remove the current banner of Home in the selected region. Are you sure you want to continue?'
  },
  publish: {
    id: 'cw-home.publish',
    defaultMessage: 'Publish'
  },
  select_banner_region: {
    id: 'cw-home.select_banner_region',
    defaultMessage: 'Select a region'
  },
  na_region: {
    id: 'cw-home.na_region',
    defaultMessage: 'NA'
  },
  emea_region: {
    id: 'cw-home.emea_region',
    defaultMessage: 'EMEA'
  },
  apac_region: {
    id: 'cw-home.apac_region',
    defaultMessage: 'APAC'
  },
  delete_banner_message: {
    id: 'cw-home.delete_banner_message',
    defaultMessage: 'Are you sure you want to delete this banner?'
  },
  delete_banner_message_warning: {
    id: 'cw-home.delete_banner_message_warning',
    defaultMessage:
      'This banner is currently published, please publish another banner before deleting.'
  },
  image_info_message: {
    id: 'cw-home.image_info_message',
    defaultMessage: 'Please upload images in .SVG file type'
  },
  number_of_regions_selected: {
    id: 'cw-home.number_of_regions_selected',
    defaultMessage: '{noOfItems} regions selected.'
  },
  banner_successfully_deleted: {
    id: 'cw-home.banner_successfully_deleted',
    defaultMessage: 'Banner has been successfully deleted'
  },
  invalid_image_resolution: {
    id: 'cw-home.invalid_image_resolution',
    defaultMessage: 'Image resolution exceeds the maximum allowed size.'
  },
  release_notes:{
    id: 'cw-home.release_notes',
    defaultMessage: 'Release Notes'
  },
  switch_account: {
    id: 'cw-home.switch_account',
    defaultMessage: 'Switch Account'
  },
  return_to_primary_account: {
    id: 'cw-home.return_to_primary_account',
    defaultMessage: 'Return to Primary Account'
  },
  sub_account_banner_description: {
    id: 'cw-home.sub_account_banner_description',
    defaultMessage: '<b>You are currently logged in as {guestUser} - {subAccount}\'s ({subAccountId}) Home</b>.<br/> Looking to switch back to your Primary Account? Simply go to your user profile and select the option to \'Return to Primary Account\'.'
  },
  banner_successfully_published: {
    id: 'cw-home.banner_successfully_published',
    defaultMessage: 'Banner is published successfully for {region} region.'
  },
  releaseETA: {
    id: 'cw-home.releaseETA',
    defaultMessage: 'Release ETA'
  },
  resolved_date: {
    id: 'cw-home.resolved_date',
    defaultMessage: 'Resolved Date'
  },
  inactivity_dialog: {
    id: 'cw-home.inactivity_dialog',
    defaultMessage: 'Attention: Are you still there?' 
  },
  sub_inactivity_dialog:{
    id: 'cw-home.sub_inactivity_dialog',
    defaultMessage: 'It seems that your session has been inactive for a while, and you are about to be logged out If you wish to continue this session, please click "Stay Logged In Home" Otherwise, you will be automatically logged out.'
  }
});
export default Locale;
