import * as core from 'cw-ui-core';
//import { call, put } from 'redux-saga/effects';
import { put } from 'redux-saga/effects';
//import { GET } from 'src/services/ApiService';
import { getCustomThemeTemplate } from 'src/services/CustomThemeService';
//import { getPortalUrl } from 'src/services/Utils';

let initialized = false;

function* loadConfiguration() {
  // do not attempt to load configuration multiple times
  // landing page and app containers attempt to load twice when user is logged in and mounts both components quickly
  if (initialized) {
    return;
  }

  initialized = true;

  const config = {
    featureImageUrl: '/images/homelanding.jpg',
    logoUrl: '/images/favicon.svg',
    titleText: 'Welcome Home!',
    titleSubtext: 'Please sign in...',
    primaryColor: '#267E9C'
  };

  // set theme from portal configuration
  core.setTheme(config.primaryColor, null, getCustomThemeTemplate());

  // update favicon
  var oldLink = document.getElementById('favicon');
  var link = document.createElement('link');
  link.rel = 'shortcut icon';
  link.href = config.logoUrl;
  document.head.removeChild(oldLink);
  document.head.appendChild(link);

  // dispatch portal theme to store
  yield put(core.setModuleCache(['home_configuration'], config));
}

export default loadConfiguration;
