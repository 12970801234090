import React from 'react';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import css from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawer.scss';

const BannerDrawerFooter = props => {
  if (!props.isBannerEdit) {
    return (
      <>
      <core.ButtonLight
        automationName="bannerCancelBtn"
        value={core.formatMessage(Locale.cancel)}
        onClick={props.onCancelClick}
        className={css.cancelButton}
        isSmall
      />
      <core.ButtonLight
        tabIndex={0}
        automationName="bannerPreviewBtn_OpenPreview"
        value={core.formatMessage(Locale.preview_banner)}
        onClick={props.onPreviewClick}
        isSmall
        className={css.btnPreview}
      />
      <core.ButtonStandard
        cwId="createBannerButton"
        automationName="createBannerButton"
        value={core.formatMessage(Locale.save)}
        onClick={props.onCreateBannerClick}
      ></core.ButtonStandard>
      </>
    );
  }
};

export default BannerDrawerFooter;