import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import Card from './Card';
import {
  setEditedCard,
  setSelectedCardAfterCancelEdit,
  addUpdateWalletCard,
  validateCVV,
  removeCardFromWallet
} from 'src/components/Wallet/CustomerWalletService';
import Routes from 'src/services/Routes';

const mapStateToProps = (state, ownProps) => {
  const editedCardId = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'editedCard',
    'ID'
  ]);
  const cvv = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'selectedCard',
    'CVV'
  ]);

  let cvvIsInvalid = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'selectedCard',
    'isValidCVV'
  ]);

  cvvIsInvalid = cvvIsInvalid === undefined ? false : !cvvIsInvalid;

  return {
    payFabricUrlEdit: editedCardId
      ? core.getScreenData(state.screen, [
          Routes.SHOPPING_CART.id,
          'card',
          editedCardId,
          'payFabricUrl'
        ])
      : undefined,
    isEditing: editedCardId === ownProps.card.ID,
    cvvIsInvalid,
    cvvIsEntered: cvv ? true : false,
    isSaving:
      ownProps.selected &&
      core.getScreenData(state.screen, [
        Routes.SHOPPING_CART.id,
        'selectedCard',
        'isSaving'
      ])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCardEdit: (card, isEditing) => {
      setEditedCard(card, isEditing);
    },
    onAddUpdateCard: async (cardId, isNew) => {
      dispatch(
        core.setScreenData(
          [Routes.SHOPPING_CART.id, 'selectedCard', 'isSaving'],
          true
        )
      );
      await addUpdateWalletCard(cardId, isNew);
      dispatch(
        core.setScreenData(
          [Routes.SHOPPING_CART.id, 'selectedCard', 'isSaving'],
          false
        )
      );
    },
    onCancelEditCard: cardId => setSelectedCardAfterCancelEdit(cardId),
    onAfterCVVChange: (value, isBlur) => validateCVV(value, isBlur),
    onCardRemove: async cardId => {
      dispatch(
        core.setScreenData(
          [Routes.SHOPPING_CART.id, 'selectedCard', 'isSaving'],
          true
        )
      );
      await removeCardFromWallet(cardId);
      dispatch(
        core.setScreenData(
          [Routes.SHOPPING_CART.id, 'selectedCard', 'isSaving'],
          false
        )
      );
    }
  };
};

const CardContainer = connect(mapStateToProps, mapDispatchToProps)(Card);

export default core.MobileLayoutHOC(CardContainer);
