import React, { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import css from 'src/components/HomePage/NewsPod/NewsFeed/NewsFeedItem.scss';
import cx from 'classnames';
import MobileLayoutHOC from 'cw-ui-core/lib/components/ui/MobileLayoutHOC';
import * as core from 'cw-ui-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/fontawesome-free-regular';
import Locale from 'src/Locale';
import AutoLinkMessage from 'src/components/AutoLinkMessage';
import Avatar from 'src/components/Avatar/Avatar';

const NewsFeedItem = props => {
  const { newsItem, onNewsItemChange, authorImageUrl, onThumbsUpClick } = props;
  const { formatDate } = props.intl;

  const thumbsUpContent = useRef(null);
  useEffect(() => {
    onNewsItemChange(newsItem);
  }, [newsItem, onNewsItemChange]);

  let authorName;
  if (newsItem.author === 'ConnectWise Automate') {
    authorName = 'Automate';
  }
  else if (newsItem.author === 'ConnectWise PSA™' || newsItem.author === 'ConnectWise Manage') {
    authorName = 'PSA';
  }
  else if (newsItem.author === 'ConnectWise CPQ™' || newsItem.author === 'ConnectWise Sell') {
    authorName = 'CPQ';
  }
  else if (newsItem.author === 'ConnectWise ScreenConnect™' || newsItem.author === 'ConnectWise Control™') {
    authorName = 'ScreenConnect';
  }

  return (
    <core.Flexbox className={props.className} column>
      <core.Flexbox className={css.titleWrapper}>
        <core.Label className={css.title} value={newsItem.title} />
        {newsItem.postId && (
          <core.Image
            className={
              newsItem.isBookmarked
                ? cx(css.actionBookmarkIcon, css.bookmark)
                : cx(css.actionBookmarkIcon, css.unbookmark)
            }
            src={core.IconPath.get('Action_Bookmark')}
            automationName="bookmarkIcon"
            onClick={props.onPostBookmarkClick}
          />
        )}
      </core.Flexbox>

      <div className={css.postInfoContainer}>
        {authorName && (
          <core.Label className={css.authorName} value={authorName}>
            <sup className={css.tradeMarkStyle}>
              {(newsItem.author === 'ConnectWise PSA™' ||
                newsItem.author === 'ConnectWise Manage' ||
                newsItem.author === 'ConnectWise CPQ™' ||
                newsItem.author === 'ConnectWise Sell'||
                newsItem.author === 'ConnectWise ScreenConnect™' ||
                newsItem.author === 'ConnectWise Control™') &&
                core.formatMessage(Locale.branding_trademark)}
            </sup>
          </core.Label>
        )}
        {newsItem.authorImageUrl && !authorName && (
          <core.Image
            className={cx(css.productImage, {
              [css.productImageBackground]: !authorImageUrl
            })}
            src={newsItem.authorImageUrl}
            alt={core.formatMessage(Locale.author)}
            initials={
              !authorImageUrl ? core.getInitials(newsItem.author) : undefined
            }
          />
        )}
        {!newsItem.authorImageUrl && !authorName && (
          <Avatar
            className={css.authorImage}
            src={authorImageUrl}
            isInlineSVG={false}
            alt={core.formatMessage(Locale.author)}
            initials={
              !authorImageUrl ? core.getInitials(newsItem.author) : undefined
            }
            style={
              authorImageUrl
                ? undefined
                : { backgroundColor: '#e6e6e6', color: '#7d7d7d' }
            }
          />
        )}
        <core.Flexbox
          column={props.isMobileLayout}
          className={css.authorContainer}
        >
          {!newsItem.authorImageUrl && !authorName && (
            <>
              {!!newsItem.author && (
                <core.Label
                  className={cx(css.author, {
                    CwThemeColor: newsItem.src !== null
                  })}
                  title={newsItem.author}
                  value={`${newsItem.author}${
                    newsItem.authorTitle ? ' | ' : ''
                  }`}
                />
              )}
              {!!newsItem.authorTitle && (
                <core.Label
                  className={cx(css.author, 'CwThemeColor')}
                  title={newsItem.authorTitle}
                  value={newsItem.authorTitle}
                  grey2
                />
              )}
            </>
          )}
          <core.Label
            className={css.newsItemDate}
            grey3
            value={
              newsItem.scheduledDate
                ? formatDate(newsItem.scheduledDate, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  })
                : formatDate(newsItem.createdDate, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  })
            }
          />
        </core.Flexbox>
      </div>
      <AutoLinkMessage
        automationName="newsDescLinkMessage"
        content={newsItem.content}
        className={css.postDescription}
      />
      {newsItem.imageUrl1 && (
        <core.Image
          className={css.image}
          src={newsItem.imageUrl1}
          isInlineSVG={false}
          alt={core.formatMessage(Locale.news_item_image)}
        />
      )}
      {newsItem.postimageFileObject &&
        newsItem.postimageFileObject.map(p => (
          <core.Image
            key={p.name}
            className={css.image}
            src={URL.createObjectURL(p)}
            isInlineSVG={false}
            alt={core.formatMessage(Locale.news_item_image)}
          />
        ))}
      {newsItem.videoUrl && props.isShowVideoPreview && (
        <div className={css.videoContainer}>
          <iframe
            className={css.video}
            width="820"
            height="491"
            title={newsItem.title}
            src={newsItem.videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
      <core.Flexbox
        className={css.actionsContainer}
        column={props.isMobileLayout}
      >
        {newsItem.callToActionText && (
          <core.ButtonStandard
            isSmall
            className={css.callToActionButton}
            automationName="call_to_action"
            value={newsItem.callToActionText}
            onClick={props.onCallToActionButtonClick}
          />
        )}
      </core.Flexbox>
      <core.Flexbox className={css.actionIconsContainer}>
        {newsItem.postId && (
          <>
            <core.Label
              value={
                newsItem.isLiked
                  ? core.formatMessage(Locale.helpfulText)
                  : core.formatMessage(Locale.wasHelpfulText)
              }
              className={
                newsItem.isLiked
                  ? cx(css.actionIcon, css.like)
                  : cx(css.actionIcon, css.unlike)
              }
            />
            <button
              className={
                !newsItem.isLiked
                  ? cx(css.icobutton, css.unlike)
                  : cx(css.icobutton, css.like)
              }
              ref={thumbsUpContent}
              aria-label={core.formatMessage(Locale.helpful)}
              onClick={() => {
                !newsItem.isLiked && onThumbsUpClick({ thumbsUpContent });
                props.onPostLikeClick();
              }}
            >
              <FontAwesomeIcon icon={faThumbsUp} />
            </button>
          </>
        )}
      </core.Flexbox>
    </core.Flexbox>
  );
};

export default injectIntl(MobileLayoutHOC(NewsFeedItem));
