import React from 'react';
import cx from 'classnames';
import animation from 'src/styles/animation.scss';
import css from './WithButtonLoadingIndicatorHOC.scss';

export default Button => {
  return ({ isBusy, className, wrapperStyle, loaderStyle, ...props }) => {
    if (!isBusy) {
      return <Button {...props} />;
    }

    return (
      <div className={cx(css.loadButton, className)} style={wrapperStyle}>
        <span className={cx(css.load, animation.open)} style={loaderStyle} />
      </div>
    );
  };
};
