import { IconPath, NotificationStatus, formatMessage } from 'cw-ui-core';
import Locale from 'src/Locale';

export const PROFILE_SUCCESS = () => {
  return {
    cwId: 'successNotification-userProfile',
    automationName: 'successNotification-userProfile',
    status: NotificationStatus.SUCCESSFUL,
    statusIcon: IconPath.get('Status_Success'),
    message: formatMessage(Locale.user_profile_success_message)
  };
};

export const PROFILE_ERROR = () => {
  return {
    cwId: 'errorNotification-userProfile',
    automationName: 'errorNotification-userProfile',
    status: NotificationStatus.ERROR,
    statusIcon: IconPath.get('Action_Deny'),
    message: formatMessage(Locale.user_profile_error_message)
  };
};

export const BILLING_SUCCESS = () => {
  return {
    cwId: 'successNotification-billingNotificationSuccess',
    automationName: 'successNotification-billingNotificationSuccess',
    status: NotificationStatus.SUCCESSFUL,
    statusIcon: IconPath.get('Status_Success'),
    message: formatMessage(Locale.billing_success_message)
  };
};

export const BILLING_ERROR = () => {
  return {
    cwId: 'errorNotification-billingNotificationError',
    automationName: 'errorNotification-billingNotificationError',
    status: NotificationStatus.ERROR,
    statusIcon: IconPath.get('Status_Unacknowledged'),
    message: formatMessage(Locale.billing_error_message)
  };
};

export const SECURITY_SUCCESS = () => {
  return {
    cwId: 'successNotification-securityNotificationSuccess',
    automationName: 'successNotification-securityNotificationSuccess',
    status: NotificationStatus.SUCCESSFUL,
    statusIcon: IconPath.get('Status_Success'),
    message: 'Security notifications updated.'
  };
};

export const SECURITY_ERROR = () => {
  return {
    cwId: 'errorNotification-securityNotificationError',
    automationName: 'errorNotification-securityNotificationError',
    status: NotificationStatus.ERROR,
    statusIcon: IconPath.get('Status_Unacknowledged'),
    message: 'Unable to update security notifications.'
  };
};

export const PASSWORD_SUCCESS = () => {
  return {
    cwId: 'successNotification-passwordNotificationSuccess',
    automationName: 'successNotification-passwordNotificationSuccess',
    status: NotificationStatus.SUCCESSFUL,
    statusIcon: IconPath.get('Status_Success'),
    message: formatMessage(Locale.password_success_message)
  };
};

export const PASSWORD_ERROR_IN_PROCESSING = () => {
  return {
    cwId: 'errorNotification-passwordNotificationsErrorProcessing',
    automationName: 'errorNotification-passwordNotificationsErrorProcessing',
    status: NotificationStatus.ERROR,
    statusIcon: IconPath.get('Tile_Alerts'),
    message: formatMessage(Locale.password_error_in_processing_message)
  };
};

export const PROFILE_FIRST_NAME_BLANK_ERROR = () => {
  return {
    cwId: 'errorNotification-userProfileBlankFirstName',
    automationName: 'errorNotification-userProfileBlankFirstName',
    status: NotificationStatus.ERROR,
    statusIcon: IconPath.get('Tile_Alerts'),
    message: formatMessage(Locale.profile_first_name_blank_error_message)
  };
};

export const PASSWORD_NOT_MATCHED = () => {
  return {
    cwId: 'errorNotification-userProfilePasswordNotMatched',
    automationName: 'errorNotification-userProfilePasswordNotMatched',
    status: NotificationStatus.ERROR,
    statusIcon: IconPath.get('Tile_Alerts'),
    message: formatMessage(Locale.password_not_matched_message)
  };
};

export const MFA_EMAIL_SUCCESS = () => {
  return {
    cwId: 'successNotification-mfaEmailSuccess',
    automationName: 'successNotification-mfaEmailSuccess',
    status: NotificationStatus.SUCCESSFUL,
    statusIcon: IconPath.get('Status_Success'),
    message: formatMessage(Locale.mfa_email_success_message)
  };
};

export const MFA_EMAIL_ERROR = () => {
  return {
    cwId: 'errorNotification-mfaEmailError',
    automationName: 'errorNotification-mfaEmailError',
    status: NotificationStatus.ERROR,
    statusIcon: IconPath.get('Tile_Alerts'),
    message: formatMessage(Locale.mfa_email_error_message)
  };
};

export const MFA_APP_SUCCESS = () => {
  return {
    cwId: 'successNotification-mfaAppSuccess',
    automationName: 'successNotification-mfaAppSuccess',
    status: NotificationStatus.SUCCESSFUL,
    statusIcon: IconPath.get('Status_Success'),
    message: formatMessage(Locale.mfa_app_success_message)
  };
};

export const MFA_APP_ERROR = () => {
  return {
    cwId: 'errorNotification-mfaAppError',
    automationName: 'errorNotification-mfaAppError',
    status: NotificationStatus.ERROR,
    statusIcon: IconPath.get('Tile_Alerts'),
    message: formatMessage(Locale.mfa_app_error_message)
  };
};

export const MFA_APP_SETUP_ERROR = () => {
  return {
    cwId: 'errorNotification-mfaAppSetupError',
    automationName: 'errorNotification-mfaAppSetupError',
    status: NotificationStatus.ERROR,
    statusIcon: IconPath.get('Tile_Alerts'),
    message: formatMessage(Locale.mfa_app_setup_error_message)
  };
};

export const PASSWORD_INCORRECT = () => {
  return {
    cwId: 'errorNotification-userProfilePasswordIncorrect',
    automationName: 'errorNotification-userProfilePasswordIncorrect',
    status: NotificationStatus.ERROR,
    statusIcon: IconPath.get('Tile_Alerts'),
    message: formatMessage(Locale.password_incorrect_message)
  };
};
