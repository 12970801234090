import React from 'react';

class PayFabricFrame extends React.Component {

  handleFrameTasks = e => {
    if (e.data.type && e.data.type === 'payfabric') {
      if (e.data.isCancel) {
        this.props.onCancel();
        return;
      }

      if (e.data.cardId) {
        this.props.onAddUpdateCard(e.data.cardId, e.data.isNew);
      }
    }
  }
  
  componentDidMount() {
    window.addEventListener('message', this.handleFrameTasks,false);
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.handleFrameTasks);
  }

  render() {
    return <iframe src={this.props.payFabricUrl} title="PayFabricPortal"/>;
  }
}

export default PayFabricFrame;