import React from 'react';
import * as PropTypes from 'prop-types';
import CustomLoadingComponent from './CustomLoadingComponent';
import DefaultLoadingComponent from './DefaultLoadingComponent';
import css from './GenericLoadingComponent.scss';

const LoadingComponent = ({columns, parentClass, ...props}) => {
  let loader;
  if (columns && columns.length > 0) {
    loader = <CustomLoadingComponent columns={columns} parentClass={parentClass} {...props} />;
  } else {
    loader = <DefaultLoadingComponent columnCount={4} {...props} />;
  }

  return (
    <div key={props.id} className={css.wrap} >
      <div key={props.id} className={css.row}>
        {loader}
      </div>
    </div>
  );
};

LoadingComponent.propTypes = {
  columns: PropTypes.array
};

export default LoadingComponent;