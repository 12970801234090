import React, { useEffect, useRef } from 'react';
import css from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsPostDrawer.scss';
import * as core from 'cw-ui-core';
import {
  Steps,
  mediaTypes,
  buttonTypes,
  postPriority,
  postTypes,
  postScheduleTypes
} from 'src/services/NewsAdmin/NewsAdminService';
import MultiCheckBoxContainer from 'src/components/MultiCheckBox/MultiCheckBoxContainer';
import Breadcrumbs from 'src/components/Breadcrumbs/Breadcrumbs';
import Locale from 'src/Locale';
import NewsFeedItemContainer from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsFeedItemContainer';
import cx from 'classnames';

const DrawerContent = props => {
  const {
    onImageUrlChange,
    onVideoUrlChange,
    onCallToActionUrlChange,
    onPostScheduledDateChange,
    onPostScheduledTimeChange
  } = props;
  const showFullBreadcrumb = props.currentStep === Steps.Step_5 ? false : true;

  const drawerContent = useRef(null);
  useEffect(() => {
    const heightToApply = window.innerHeight - 250;
    drawerContent.current &&
      (drawerContent.current.style.maxHeight = `${heightToApply}px`);
  });

  return (
    <>
      {props.showBreadCrumbs && (
        <Breadcrumbs
          breadcrumbs={props.breadcrumbList}
          lastBreadcrumbName={props.lastBreadcrumbName}
          showFullBreadcrumb={showFullBreadcrumb}
          onBreadcrumbClick={props.onBreadcrumbClick}
        />
      )}
      {props.currentStep === Steps.Step_1 && (
        <core.Flexbox column className={css.details}>
          {props.mappingsReady &&
            props.newsPostCriteria.types.map(
              c =>
                !c.isFeatured && (
                  <core.Flexbox
                    key={`postType_${c.name}`}
                    column
                    className={css.typeContainer}
                    cwId={`postType_${c.name}`}
                    onClick={() => {
                      props.onTypeClick(c.name, props.currentStep);
                    }}
                  >
                    <core.Label className={css.mediumLabel} value={c.name} />
                    <core.Label
                      className={css.typeDetails}
                      value={c.description}
                      grey7
                    />
                  </core.Flexbox>
                )
            )}
        </core.Flexbox>
      )}

      {props.currentStep === Steps.Step_2 && props.mappingsReady && (
        <core.Flexbox column className={css.details}>
          <core.Label
            className={css.labels}
            value={core.formatMessage(Locale.select_product_label)}
          />
          <core.FormFieldContainer
            path={['checkboxes', 'selectedProducts']}
            screenKey="NEWS_POST_DRAWER"
          >
            <MultiCheckBoxContainer
              pathKey={['checkboxes', 'selectedProducts']}
              screenKey="NEWS_POST_DRAWER"
              items={props.productsList}
              style={{ height: '25vh' }}
            />
          </core.FormFieldContainer>
          <core.Label
            className={css.labels}
            value={core.formatMessage(Locale.select_personas_label)}
          />
          <core.FormFieldContainer
            path={['checkboxes', 'selectedPersonas']}
            screenKey="NEWS_POST_DRAWER"
          >
            <MultiCheckBoxContainer
              pathKey={['checkboxes', 'selectedPersonas']}
              screenKey="NEWS_POST_DRAWER"
              items={props.personasList}
              style={{ height: '25vh' }}
            />
          </core.FormFieldContainer>
          <core.Label
            className={css.labels}
            value={core.formatMessage(Locale.select_markets_label)}
          />
          <core.FormFieldContainer
            path={['checkboxes', 'selectedMarkets']}
            screenKey="NEWS_POST_DRAWER"
          >
            <MultiCheckBoxContainer
              pathKey={['checkboxes', 'selectedMarkets']}
              screenKey="NEWS_POST_DRAWER"
              items={props.marketsList}
              style={{ height: '25vh' }}
            />
          </core.FormFieldContainer>
        </core.Flexbox>
      )}
      {props.currentStep === Steps.Step_3 && (
        <core.Flexbox className={css.detailsAndPreviewcontainer}>
          <core.Flexbox column className={cx(css.details, css.postDetails)}>
            <core.Label
              className={css.labels}
              value={core.formatMessage(Locale.select_post_author)}
            />
            <core.FormFieldContainer
              path={['author']}
              screenKey="NEWS_POST_DRAWER"
              onAfterChange={props.onAuthorChange}
            >
              <core.ComboBox
                cwId="postAuthor"
                automationName="postAuthor"
                className={css.dropDown}
                items={props.newsPostCriteria && props.authorsList}
                allowCustomEntry={false}
                expandOnClick
                readOnly
                placeholder={core.formatMessage(
                  Locale.select_author_placeHolder
                )}
              />
            </core.FormFieldContainer>
            <core.Label
              automationName="postTitle"
              className={css.labels}
              value={core.formatMessage(Locale.enter_post_title)}
            />
            <core.FormFieldContainer
              path={['title']}
              screenKey="NEWS_POST_DRAWER"
            >
              <core.TextField
                className={css.textfield}
                placeholder={core.formatMessage(Locale.enter_title_placeholder)}
                maxLength={100}
              />
            </core.FormFieldContainer>
            <core.Label
              className={css.labels}
              value={core.formatMessage(Locale.enter_post_content)}
            />
            <core.FormFieldContainer
              path={['content']}
              screenKey="NEWS_POST_DRAWER"
            >
              <core.TextArea
                className={css.textArea}
                placeholder={core.formatMessage(
                  Locale.enter_content_placeholder
                )}
              />
            </core.FormFieldContainer>
            {!(props.postType === postTypes.PINNED_ANNOUNCEMENT) && (
              <>
                <core.Label
                  className={css.labels}
                  value={core.formatMessage(Locale.select_media)}
                />
                <core.FormFieldContainer
                  path={['mediaType']}
                  screenKey="NEWS_POST_DRAWER"
                  onAfterChange={props.onMediaTypeChange}
                >
                  <core.ComboBox
                    cwId="mediaType"
                    automationName="mediaType"
                    className={css.dropDown}
                    items={mediaTypes}
                    allowCustomEntry={false}
                    expandOnClick
                    readOnly
                  />
                </core.FormFieldContainer>
              </>
            )}
            {props.mediaType === mediaTypes[1].name &&
              !(
                props.postDetails.newsPost &&
                props.postDetails.newsPost.featureImage
              ) && (
                <core.FormFieldContainer
                  path={['postimageFileObject']}
                  screenKey="NEWS_POST_DRAWER"
                  onAfterChange={onImageUrlChange}
                >
                  <core.AttachmentUpload
                    uploadText={core.formatMessage(Locale.addAttachment)}
                  />
                </core.FormFieldContainer>
              )}
            {props.mediaType === mediaTypes[2].name && (
              <core.FormFieldContainer
                path={['videoUrl']}
                screenKey="NEWS_POST_DRAWER"
                onAfterChange={onVideoUrlChange}
              >
                <core.TextField
                  className={css.textfield}
                  placeholder={core.formatMessage(Locale.enter_video_url)}
                />
              </core.FormFieldContainer>
            )}
            <core.Label
              className={css.labels}
              value={core.formatMessage(Locale.post_button)}
            />
            <core.FormFieldContainer
              path={['buttonType']}
              screenKey="NEWS_POST_DRAWER"
              onAfterChange={props.onButtonTypeChange}
            >
              <core.ComboBox
                cwId="buttonType"
                automationName="buttonType"
                className={css.dropDown}
                items={buttonTypes}
                allowCustomEntry={false}
                expandOnClick
                readOnly
              />
            </core.FormFieldContainer>
            {props.buttonType === buttonTypes[1].name && (
              <>
                <core.FormFieldContainer
                  path={['callToActionText']}
                  screenKey="NEWS_POST_DRAWER"
                >
                  <core.TextField
                    className={css.textfield}
                    placeholder={core.formatMessage(Locale.call_to_action_text)}
                    maxLength={100}
                  />
                </core.FormFieldContainer>
                <core.FormFieldContainer
                  path={['ctaButtonUrl']}
                  screenKey="NEWS_POST_DRAWER"
                  onAfterChange={onCallToActionUrlChange}
                >
                  <core.TextField
                    className={css.textfield}
                    placeholder={core.formatMessage(Locale.call_to_action_url)}
                    maxLength={200}
                  />
                </core.FormFieldContainer>
              </>
            )}
            <core.Label
              className={css.labels}
              value={core.formatMessage(Locale.post_priority)}
            />
            <core.FormFieldContainer
              path={['postPriority']}
              screenKey="NEWS_POST_DRAWER"
              onAfterChange={props.onPriorityChange}
            >
              <core.ComboBox
                cwId="postPriority"
                automationName="postPriority"
                className={css.dropDown}
                items={postPriority}
                allowCustomEntry={false}
                expandOnClick
                readOnly
              />
            </core.FormFieldContainer>

            <core.Flexbox
              className={
                props.allowPostScheduleTypeChange ? css.scheduledDateContainer : ''
              }
              column
            >
              <core.Label
                className={css.labels}
                value={core.formatMessage(Locale.select_date_time)}
              />
              <core.FormFieldContainer
                path={['postScheduleType']}
                screenKey="NEWS_POST_DRAWER"
                onAfterChange={props.onPostScheduledChange}
              >
                <core.ComboBox
                  cwId="postScheduleType"
                  automationName="postScheduleType"
                  className={css.dropDown}
                  items={postScheduleTypes}
                  allowCustomEntry={false}
                  expandOnClick
                  readOnly
                />
              </core.FormFieldContainer>

              {props.postSchedule === postScheduleTypes[1].name && (
                <core.FormFieldContainer
                  path={['scheduledDate']}
                  screenKey="NEWS_POST_DRAWER"
                  onAfterChange={onPostScheduledDateChange}
                >
                  <core.DatePicker
                    isDateTime={false}
                    placeholder={core.formatMessage(Locale.select_date)}
                    className={css.dropDown}
                  />
                </core.FormFieldContainer>
              )}

              {props.scheduledDate && (
                <core.FormFieldContainer
                  path={['scheduledTime']}
                  screenKey="NEWS_POST_DRAWER"
                  onAfterChange={onPostScheduledTimeChange}
                >
                  <core.TimeField
                    placeholder={core.formatMessage(Locale.select_time)}
                    className={css.timeField}
                  />
                </core.FormFieldContainer>
              )}
            </core.Flexbox>
          </core.Flexbox>

          {props.postAuthor || props.postDetails.author ? (
            <div className={css.previewContainer} ref={drawerContent}>
              <NewsFeedItemContainer newsItem={props.postDetails} />
            </div>
          ) : (
            <core.Label
              className={css.labels}
              value={core.formatMessage(Locale.start_previewing)}
            />
          )}
        </core.Flexbox>
      )}
    </>
  );
};

export default DrawerContent;
