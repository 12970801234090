import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import NewCard from './NewCard';
import {
  setSelectedCardAfterCancelEdit,
  addUpdateWalletCard,
  ADDNEWCARD_ID,
  setEditedCard
} from 'src/components/Wallet/CustomerWalletService';
import Routes from 'src/services/Routes';

const mapStateToProps = (state, ownProps) => {
  return {
    payFabricUrlCreate: core.getScreenData(state.screen, [
      Routes.SHOPPING_CART.id,
      'card',
      ADDNEWCARD_ID,
      'payFabricUrl'
    ]),
    isSaving:
      ownProps.selected &&
      core.getScreenData(state.screen, [
        Routes.SHOPPING_CART.id,
        'selectedCard',
        'isSaving'
      ])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setEditedCard: async card => {
      await setEditedCard(card, true);
    },
    onAddCard: async cardId => {
      core
        .Store()
        .dispatch(
          core.removeScreenData([Routes.SHOPPING_CART.id, 'editedCard'])
        );
      dispatch(
        core.setScreenData(
          [Routes.SHOPPING_CART.id, 'selectedCard', 'isSaving'],
          true
        )
      );
      await addUpdateWalletCard(cardId, true);
      dispatch(
        core.setScreenData(
          [Routes.SHOPPING_CART.id, 'selectedCard', 'isSaving'],
          false
        )
      );
    },
    onCancelEditCard: cardId => setSelectedCardAfterCancelEdit(cardId)
  };
};

const NewCardContainer = connect(mapStateToProps, mapDispatchToProps)(NewCard);

export default NewCardContainer;
