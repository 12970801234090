import { connect } from 'react-redux';
import SecurityBulletinDrawerHeader from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawerHeader';
import { Steps } from 'src/services/SecurityAdmin/SecurityBulletinAdminService';
import Locale from 'src/Locale';
import * as core from 'cw-ui-core';
import { SECURITY_BULLETIN_DRAWER } from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawerContainer';

const mapStateToProps = state => {
  const step = core.getScreenData(
    state.screen,
    'step',
    SECURITY_BULLETIN_DRAWER
  );
  let criteriaName;
  let criteriaDescription;
  if (step === Steps.Step_1) {
    criteriaName = core.formatMessage(Locale.product);
    criteriaDescription = core.formatMessage(Locale.select_product);
  }

  if (step === Steps.Step_2) {
    criteriaName = core.formatMessage(Locale.security_bulletin_details);
    criteriaDescription = core.formatMessage(
      Locale.bulletin_details_description
    );
  }

  return { criteriaName, criteriaDescription };
};

const SecurityBulletinDrawerHeaderContainer = connect(mapStateToProps, null)(SecurityBulletinDrawerHeader);

export default SecurityBulletinDrawerHeaderContainer;
