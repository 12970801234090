import * as core from 'cw-ui-core';
import { ADD_LICENSE_DRAWER } from 'src/components/Drawer/AddLicenseDrawer/LicenseDrawerContainer';
import InstanceLicensesService from 'src/services/Api/HomeApi/InstanceLicensesService';
import { format } from 'date-fns';
import Locale from 'src/Locale';
import { POLICIES } from '../Authorization';

export const Steps = {
  Step_1: 'step1',
  Step_2: 'step2',
  Step_3: 'step3'
};

export const TimePeriod = {
  Manage: '1 hour',
  Sell: '1 hour',
  Automate: '12 hours'
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const getLicenseType = product => {
  const stateModules = core.Store().getState().module;
  const securityPolicies = core.getModuleCache(stateModules, [
    'userSecurity',
    'securityPolicies'
  ]);
  const securityPoliciesJs = securityPolicies ? securityPolicies.toJS() : null;
  const canAddLicenses =
    (product.productIdentifier === 'manage' ||
      product.productIdentifier === 'sell') &&
    product.instanceId &&
    !product.provisioning &&
    !product.failed &&
    securityPoliciesJs &&
    (securityPoliciesJs.includes(POLICIES.ADMIN) ||
      securityPoliciesJs.includes(POLICIES.BILLING_ADMIN));
  const canAddAgent =
    product.productIdentifier === 'automate' &&
    product.instanceId &&
    !product.provisioning &&
    !product.failed &&
    securityPoliciesJs &&
    (securityPoliciesJs.includes(POLICIES.ADMIN) ||
      securityPoliciesJs.includes(POLICIES.BILLING_ADMIN));
  return [canAddLicenses, canAddAgent];
};
export const toFixedTrunc = (value, precision = 2) => {
  const valueParts = value.toString().split('.');
  if (precision <= 0) {
    return Number(valueParts[0]);
  }

  let decimals = valueParts[1] || '';
  if (decimals.length > precision) {
    return Number(`${valueParts[0]}.${decimals.substr(0, precision)}`);
  }

  while (decimals.length < precision) {
    decimals += '0';
  }

  return Number(`${valueParts[0]}.${decimals}`);
};

export const calculateLicensePrice = () => {
  const stateScreen = core.Store().getState().screen;
  let quantityValue = core.getScreenData(
    stateScreen,
    'totalQuantity',
    ADD_LICENSE_DRAWER
  );
  const noOfItemsToAdd = quantityValue ? quantityValue : 1;

  let productLicenseInfo = core.getScreenData(
    stateScreen,
    'provisionLicensingInfo',
    ADD_LICENSE_DRAWER
  );
  let costPerItem = 0;
  productLicenseInfo = productLicenseInfo ? productLicenseInfo.toJS() : null;
  if (productLicenseInfo !== null) {
    const unitPrice = productLicenseInfo.unitPrice;
    const futurePrice = productLicenseInfo.futurePrice;
    const futurePriceEndDate = productLicenseInfo.futurePriceEndDate;
    const futurePricingBlocks = productLicenseInfo.futurePricingBlocks;
    const today = format(new Date(), 'yyyy-MM-dd');
    if (noOfItemsToAdd >= futurePricingBlocks) {
      if (futurePrice <= 0) {
        costPerItem = unitPrice;
      } else {
        if (
          !futurePriceEndDate ||
          (futurePriceEndDate && futurePriceEndDate >= today)
        ) {
          costPerItem = futurePrice;
        } else if (futurePriceEndDate && futurePriceEndDate < today) {
          costPerItem = unitPrice;
        }
      }
    } else if (unitPrice > 0) {
      costPerItem = unitPrice;
    }

    if (costPerItem > 0) {
      productLicenseInfo.priceChargedPerLicense = toFixedTrunc(costPerItem);
    } else {
      const errorCode = 'LEC603';
      const errorDescription =
        'LEC603 - Either regular price or future contracted price is 0.';
      productLicenseInfo.isError = true;
      productLicenseInfo.errorCode = errorCode;
      productLicenseInfo.errorDescription = errorDescription;
    }

    productLicenseInfo.additionalMonthlyCharge = (
      productLicenseInfo.priceChargedPerLicense * noOfItemsToAdd
    ).toFixed(2);
  }

  return productLicenseInfo;
};

let isApiError = false;
export const onAddLicensesCall = async () => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  let productInstance = core.getScreenData(
    state.screen,
    ['licenseInfo', 'productInstance'],
    ADD_LICENSE_DRAWER
  );
  const result = await InstanceLicensesService.getProductInfoByIntanceId(
    productInstance
  );
  let isUnAuthorized = false;
  isApiError = result.isSuccess;
  if (result && !result.isSuccess) {
    if (result.status === 401) {
      isUnAuthorized = true;
      const licenseInfo = {
        isUnAuthorized,
        step: Steps.Step_1
      };

      dispatch(
        core.setScreenData(['licenseInfo'], licenseInfo, ADD_LICENSE_DRAWER)
      );

      return;
    }

    core.CwLog.error(
      'Cannot get licensing information for instance ID=' + productInstance
    );

    dispatch(core.hideDrawer(ADD_LICENSE_DRAWER));
    dispatch(
      core.setErrorScreenMessage(
        core.formatMessage(Locale.add_license_request_error),
        false,
        null
      )
    );

    return;
  }

  const apiResponse = result.response;
  let licenseType;
  if (
    apiResponse.productIdentifier === 'manage' ||
    apiResponse.productIdentifier === 'sell'
  ) {
    licenseType = core.formatMessage(Locale.add_licenses);
  } else if (apiResponse.productIdentifier === 'automate') {
    licenseType = core.formatMessage(Locale.add_agents);
  }

  const licenseError = apiResponse ? apiResponse.isError : true;
  const errorCode = apiResponse ? apiResponse.errorCode : '';
  const productIdentifier = apiResponse ? apiResponse.productIdentifier : '';
  if (!licenseError) {
    const prorateFactor = toFixedTrunc(apiResponse.dailyProrationRate, 4);
    dispatch(
      core.setScreenData(
        'provisionLicensingInfo',
        apiResponse,
        ADD_LICENSE_DRAWER
      )
    );
    const provisionLicensingInfoResponse = calculateLicensePrice();
    const provisionLicenseInfoResponseIsError = provisionLicensingInfoResponse
      ? provisionLicensingInfoResponse.isError
      : '';
    const provisionLicenseInfoResponseErrorCode = provisionLicensingInfoResponse
      ? provisionLicensingInfoResponse.errorCode
      : '';
    if (!provisionLicenseInfoResponseIsError) {
      const additionalMonthlyCharge =
        provisionLicensingInfoResponse.additionalMonthlyCharge;
      const prorateAmount = (prorateFactor * additionalMonthlyCharge).toFixed(
        2
      );
      let prorateAmountToProps = toFixedTrunc(prorateAmount, 0);
      const prorateAmountToShow = prorateAmountToProps
        ? prorateAmountToProps
        : null;
      const prorateFactorToShow = prorateFactor ? prorateFactor : null;
      const licenseInfo = {
        step: Steps.Step_1,
        licenseType,
        productName: productIdentifier,
        licenseError,
        errorCode,
        prorateFactor: prorateFactorToShow,
        proratePrice: prorateAmountToShow
      };
      dispatch(
        core.setScreenData(['licenseInfo'], licenseInfo, ADD_LICENSE_DRAWER)
      );
    } else {
      dispatch(
        core.setScreenData(
          'provisionLicensingInfo',
          provisionLicensingInfoResponse,
          ADD_LICENSE_DRAWER
        )
      );
      const licenseInfo = {
        step: Steps.Step_1,
        licenseType,
        productName: productIdentifier,
        licenseError: provisionLicenseInfoResponseIsError,
        errorCode: provisionLicenseInfoResponseErrorCode,
        isUnAuthorized
      };
      dispatch(
        core.setScreenData(['licenseInfo'], licenseInfo, ADD_LICENSE_DRAWER)
      );
    }
  } else {
    dispatch(
      core.setScreenData(
        'provisionLicensingInfo',
        apiResponse,
        ADD_LICENSE_DRAWER
      )
    );
    const licenseInfo = {
      step: Steps.Step_1,
      licenseType,
      productName: productIdentifier,
      licenseError,
      errorCode,
      isUnAuthorized
    };
    dispatch(
      core.setScreenData(['licenseInfo'], licenseInfo, ADD_LICENSE_DRAWER)
    );
  }
};

export const getProratePriceDisplay = () => {
  const stateScreen = core.Store().getState().screen;
  let quantityValue = core.getScreenData(
    stateScreen,
    'totalQuantity',
    ADD_LICENSE_DRAWER
  );
  quantityValue = quantityValue ? quantityValue : 1;
  const prorateFactor = core.getScreenData(
    stateScreen,
    ['licenseInfo', 'prorateFactor'],
    ADD_LICENSE_DRAWER
  );
  const provisionLicensingResponse = calculateLicensePrice();
  if (provisionLicensingResponse && !provisionLicensingResponse.isError) {
    const priceChargedPerLicense =
      provisionLicensingResponse.priceChargedPerLicense;
    const prorateTotalPrice = (
      quantityValue *
      priceChargedPerLicense *
      prorateFactor
    ).toFixed(2);
    return prorateTotalPrice;
  }

  return provisionLicensingResponse;
};

export const jumpOnToStep = (
  step,
  currentTitle,
  jumpToStep,
  licenseProcessingError,
  ticketProcessingError,
  quoteRequested
) => {
  const dispatch = core.Store().dispatch;
  let keys = Object.keys(Steps);
  let index = keys.indexOf(getKeyByValue(Steps, step));
  let nextStep =
    jumpToStep && jumpToStep === 'next'
      ? Steps[keys[index + 1]]
      : Steps[keys[index - 1]];
  dispatch(
    core.setScreenData(['licenseInfo', 'step'], nextStep, ADD_LICENSE_DRAWER)
  );
  dispatch(
    core.setScreenData(
      ['licenseInfo', 'licenseType'],
      currentTitle,
      ADD_LICENSE_DRAWER
    )
  );
  licenseProcessingError &&
    nextStep === 'step3' &&
    dispatch(
      core.setScreenData(
        'processingError',
        licenseProcessingError,
        ADD_LICENSE_DRAWER
      )
    );
  quoteRequested &&
    nextStep === 'step3' &&
    dispatch(
      core.setScreenData(
        'ticketProcessingError',
        ticketProcessingError,
        ADD_LICENSE_DRAWER
      )
    );
  dispatch(
    core.setScreenData('quoteRequested', quoteRequested, ADD_LICENSE_DRAWER)
  );
};

export const createQuoteTicket = async () => {
  if (isApiError) {
    const state = core.Store().getState();

    const baseUrl = core.getModuleConfig(state.module, [
      'SalesForceServiceUrlBase'
    ]);
    const email = core.getUserEmail(state.oidc);
    const redirectAmUrl =
      baseUrl +
      '?startURL=' +
      encodeURIComponent('/s/#step2?supportArea=AM&owner=' + email);
    console.log('AM URL: ' + redirectAmUrl);
    window.location.href = redirectAmUrl;
    return;
  }

  const stateScreen = core.Store().getState().screen;
  const dispatch = core.Store().dispatch;
  let licenseProvisionRequestErrorTicket = core.getScreenData(
    stateScreen,
    ['provisionLicensingInfo'],
    ADD_LICENSE_DRAWER
  );
  let totalQuantityToShow = core.getScreenData(
    stateScreen,
    ['totalQuantity'],
    ADD_LICENSE_DRAWER
  );
  totalQuantityToShow =
    totalQuantityToShow === undefined ? 1 : totalQuantityToShow;
  const licenseQuoteComment = core.getScreenData(
    stateScreen,
    'licenseQuoteComment',
    ADD_LICENSE_DRAWER
  );
  licenseProvisionRequestErrorTicket = licenseProvisionRequestErrorTicket
    ? licenseProvisionRequestErrorTicket.toJS()
    : null;
  let quoteRequested = true;
  licenseProvisionRequestErrorTicket.AdditionalQuantity = totalQuantityToShow;
  licenseProvisionRequestErrorTicket.partnerErrorNote = licenseQuoteComment;
  const result = await InstanceLicensesService.createQuoteTicket(
    licenseProvisionRequestErrorTicket
  );
  const ticketProcessingError =
    result &&
    result.response &&
    (result.response.isError || !result.response.ticketId);
  ticketProcessingError
    ? dispatch(
        core.setScreenData(
          'ticketProcessingError',
          ticketProcessingError,
          ADD_LICENSE_DRAWER
        )
      )
    : dispatch(
        core.setScreenData(
          'ticketId',
          result.response.ticketId,
          ADD_LICENSE_DRAWER
        )
      );
  const jumpToStep = 'next';
  const currentStep = Steps.Step_2;
  jumpOnToStep(
    currentStep,
    null,
    jumpToStep,
    false,
    ticketProcessingError,
    quoteRequested
  );
  core
    .Store()
    .dispatch(core.setScreenData('isDrawerUpdated', false, ADD_LICENSE_DRAWER));
};

export const validateQuantity = value => {
  let updatedValue = value.replace('.', '');
  let updatedQuantity;
  if (updatedValue > -1 && updatedValue.length > 4) {
    updatedQuantity = updatedValue.slice(0, 4);
  } else if (updatedValue < 1) {
    updatedQuantity = 1;
  } else {
    updatedQuantity = updatedValue;
  }

  return updatedQuantity;
};

export const createLicenseProvision = async () => {
  const stateScreen = core.Store().getState().screen;
  const currentStep = core.getScreenData(
    stateScreen,
    ['licenseInfo', 'step'],
    ADD_LICENSE_DRAWER
  );
  const currentTitle = core.getScreenData(
    stateScreen,
    ['licenseInfo', 'licenseType'],
    ADD_LICENSE_DRAWER
  );
  const totalQuantityToShow = core.getScreenData(
    stateScreen,
    ['totalQuantity'],
    ADD_LICENSE_DRAWER
  );
  const urlParams = core.getScreenData(
    stateScreen,
    ['RequestParams'],
    ADD_LICENSE_DRAWER
  );
  const totalQuantityToShowCal =
    totalQuantityToShow === undefined ? 1 : totalQuantityToShow;
  const provisionLicensingInfoResponse = calculateLicensePrice();
  if (currentStep === Steps.Step_2) {
    let prorateAmount = (
      provisionLicensingInfoResponse.priceChargedPerLicense *
      totalQuantityToShowCal *
      provisionLicensingInfoResponse.dailyProrationRate
    ).toFixed(2);
    let prorateAmountTotal = toFixedTrunc(prorateAmount, 0);

    provisionLicensingInfoResponse.requestParams = urlParams ? urlParams : {};
    provisionLicensingInfoResponse.requestParams.instance =
      provisionLicensingInfoResponse.instanceId;
    provisionLicensingInfoResponse.additionalMonthlyCharge = (
      totalQuantityToShowCal *
      provisionLicensingInfoResponse.priceChargedPerLicense
    ).toFixed(2);
    provisionLicensingInfoResponse.proratedAmount = prorateAmountTotal;
    provisionLicensingInfoResponse.additionalQuantity = totalQuantityToShowCal;
    const result = await InstanceLicensesService.provisionLicenseInfo(
      provisionLicensingInfoResponse
    );

    const licenseProcessingError = result.isSuccess
      ? result && result.response && result.response.isError
      : true;
    jumpOnToStep(currentStep, currentTitle, 'next', licenseProcessingError);
    core
      .Store()
      .dispatch(
        core.setScreenData('isDrawerUpdated', false, ADD_LICENSE_DRAWER)
      );
  }

  jumpOnToStep(currentStep, currentTitle, 'next');
};

export const getNextMonth = () => {
  const monthNames = [
    'January',
    'Febrauary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = monthNames[currentMonthIndex];
  const nextMonth = monthNames[currentMonthIndex + 1];
  return [currentMonth, nextMonth];
};
