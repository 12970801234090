import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/ProductDrawer/AutomateProductDrawer.scss';
import cssCommon from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon.scss';
import cx from 'classnames';
import { ProductHeader } from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import Locale from 'src/Locale';

const AutomateProductDrawer = props => {
  const PlanCardButton = ({
    toolTip,
    disabled,
    buttonText,
    onCardButtonClick,
    automationName
  }) => {
    return (
      <core.ButtonLight
        toolTip={toolTip}
        disabled={disabled}
        automationName={automationName}
        className={css.planCardButton}
        value={buttonText}
        onClick={onCardButtonClick}
      />
    );
  };

  const PlanCardButtonWithLoadingIndicator = WithButtonLoadingIndicatorHOC(
    PlanCardButton
  );

  return (
    <core.Drawer
      automationName="automateDrawer"
      onCloseIconClicked={props.onClose}
    >
      <core.Flexbox
        className={cx(css.container, css.scrollable, cssCommon.scrollable)}
        column
      >
        <ProductHeader
          {...props}
          productIconClassName={css.productIconHeader}
          descriptionClassName={css.productDescription}
          dividerLineClassName={css.dividerLineHeader}
          learnMoreClassName={css.learnMoreAutomate}
        />
        <core.Flexbox className={css.bannerHeader} column>
          <core.Flexbox column className={css.banner}>
            <core.Label
              value={core.formatMessage(Locale.drawer_automate_banner_header)}
              className={css.bannerHeaderText}
            />
            <core.Label
              value={core.formatMessage(
                Locale.drawer_automate_banner_center_description
              )}
              className={css.bannerDescription}
            />

            <core.Label
              value={core.formatMessage(
                Locale.drawer_automate_banner_footer_description
              )}
              className={css.bannerDescription}
            />
          </core.Flexbox>
          <core.Flexbox
            column={props.isMobileLayout}
            className={css.planContainer}
          >
            {props.plans.map(plan => (
              <div title={props.buttonTitle} key={plan.name}>
                <PlanCardButtonWithLoadingIndicator
                  automationName={`planCardLoaderButton_${plan.automationName}`}
                  buttonText={plan.buttonText}
                  onCardButtonClick={() => props.onCardButtonClick(plan)}
                  isBusy={plan.isBusy}
                  wrapperStyle={{
                    bottom: '0',
                    width: '188px',
                    alignSelf: 'center',
                    fontSize: '14px'
                  }}
                  disabled={!props.isDemoOrQuoteEnable}
                />
              </div>
            ))}
          </core.Flexbox>
        </core.Flexbox>
        <core.Flexbox column>
          <core.Label
            value={props.benefitHeader}
            className={css.benefitHeader}
          ></core.Label>
          <core.Flexbox column className={css.benefits}>
            {props.benefits.map(benefit => (
              <core.Flexbox
                key={benefit.name}
                className={css.benefit}
                column={props.isMobileLayout}
              >
                <core.Flexbox>
                  <core.Image
                    cwId="benefitImage"
                    isInlineSVG={false}
                    alt={core.formatMessage(Locale.automate_benefit)}
                    src={benefit.imageSrc}
                    className={css.benefitImage}
                  ></core.Image>
                  <core.Flexbox column className={css.benefitMessage}>
                    <core.Label
                      value={benefit.title}
                      className={css.textBold}
                    ></core.Label>
                    <core.Label value={benefit.description}></core.Label>
                  </core.Flexbox>
                </core.Flexbox>
              </core.Flexbox>
            ))}
          </core.Flexbox>
        </core.Flexbox>

        <core.Label
          value={props.productOwnedDescription}
          className={css.textBold}
        />
        <core.Label
          automationName="automateConnectAccount"
          isLink
          className={css.connectPlan}
          value={props.productConnectLinkText}
          onClick={props.onConnectLinkClick}
        />
      </core.Flexbox>
    </core.Drawer>
  );
};

export default core.MobileLayoutHOC(AutomateProductDrawer);
