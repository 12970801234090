import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import css from './Avatar.scss';
import { Image, Flexbox, Label } from 'cw-ui-core';

function Avatar(props) {
  let content;
  const dynStyle = { CwThemeBackgroundColor: props.initials !== undefined };
  if (props.src) {
    content = <Image src={props.src} isInlineSVG={props.isInlineSVG} />;
  } else if (props.initials) {
    content = props.initials;
  }

  return (
    <Flexbox
      automationName="avatarContainer"
      onClick={props.onClick}
      className={cx(css.avatarContainer, props.className)}
    >
      <div
        className={cx(dynStyle, props.popover ? props.popover : css.container)}
        style={props.style}
      >
        {content}
      </div>
      <Label value={props.label} />
    </Flexbox>
  );
}

Avatar.defaultProps = {
  isInlineSVG: true
};

Avatar.propTypes = {
  style: PropTypes.object,
  initials: PropTypes.string,
  src: PropTypes.string,
  popover: PropTypes.string,
  isInlineisInlineSVGSvg: PropTypes.bool
};

export default Avatar;
