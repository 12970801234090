import React from 'react';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import cx from 'classnames';
import flexCss from 'src/styles/flex.scss';
import css from 'src/components/PartnerSupportPage/KnownIssues/KnownIssues.scss';
import {
  getKnownIssueById,
  onFollowKnownIssueInDrawerClicked,
  followKnownIssue
} from 'src/services/Tickets/KnownIssuesService';
import { VIEW_KNOWN_ISSUE_DRAWER } from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesDrawer';
import { FormattedDate } from 'react-intl';
import KnownIssuesListHeaderWithSortingContainer from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesListHeaderWithSortingContainer';
import { POLICIES } from 'src/services/Authorization';
import HasAccess from 'src/services/RoleAccess';
import FollowKnownIssuesButtonContainer from 'src/components/PartnerSupportPage/KnownIssues/FollowKnownIssuesButtonContainer';
import KnownIssuesOptionColumnContainer from './KnownIssuesOptionColumnContainer';

export const getStatusClass = status => {
  switch (status) {
    case 'Backlog':
      return css.backlog;
    case 'Development':
      return css.development;
    case 'Testing':
      return css.testing;
    case 'Needs Info':
      return css.needsInfo;
    case 'Pending Release':
      return css.pendingRelease;
    case 'Unknown':
      return css.unknown;
    case 'Closed':
      return css.closed;
    case 'Released':
      return css.closed;
    case 'Not Reproducible':
      return css.unknown;
    case 'Closed > Won\'t Do':
      return css.closed;
    case 'Closed > Intended Functionality':
      return css.closed;
    case 'Closed > Enhancement':
      return css.closed;
    case 'Closed > Known Issue':
      return css.closed;
    case 'Archived':
      return css.closed;
    case 'Investigating':
      return css.closed;
    default:
      return '';
  }
};

const flyoutContent = (
  <core.Flexbox className={css.statusScrollableArea}>
    <ul>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.backlog)} grey7 bold />
      {core.formatMessage(Locale.backlog_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.development)} grey7 bold />
      {core.formatMessage(Locale.development_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.testing)} grey7 bold />
      {core.formatMessage(Locale.testing_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label
        value={core.formatMessage(Locale.pending_release)}
        grey7
        bold
      />
      {core.formatMessage(Locale.pending_release_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.closed)} grey7 bold />
      {core.formatMessage(Locale.closed_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.closed_enhancement)} grey7 bold />
      {core.formatMessage(Locale.closed_enhancement_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.released)} grey7 bold />
      {core.formatMessage(Locale.released_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.closed_wontdo)} grey7 bold />
      {core.formatMessage(Locale.closed_wontdo_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.not_reproducible)} grey7 bold />
      {core.formatMessage(Locale.not_reproducible_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.closed_intended_functionality)} grey7 bold />
      {core.formatMessage(Locale.closed_intended_functionality_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.closed_known_issue)} grey7 bold />
      {core.formatMessage(Locale.closed_known_issue_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.archived)} grey7 bold />
      {core.formatMessage(Locale.archived_status_info)}
    </li>
    <li className={css.listItemBulletPoint}>
      <core.Label value={core.formatMessage(Locale.investigating)} grey7 bold />
      {core.formatMessage(Locale.investigating_status_info)}
    </li>
    </ul>
  </core.Flexbox>
);

export const openKnownIssueDrawer = async (jiraId, followIssue = false) => {
  core.Store().dispatch(core.showDrawer(VIEW_KNOWN_ISSUE_DRAWER));
  const knownIssueDetails = await getKnownIssueById(jiraId);
  if (knownIssueDetails) {
    core
      .Store()
      .dispatch(
        core.setScreenData(
          ['knownIssueDetails'],
          knownIssueDetails,
          VIEW_KNOWN_ISSUE_DRAWER
        )
      );
    if (followIssue && !knownIssueDetails.result.isFollowedByUser) {
      const isConnectwiseColleague = HasAccess(
        [POLICIES.CONNECTWISE_COLLEAGUE],
        null
      );
      if (!isConnectwiseColleague) {
        followKnownIssue(knownIssueDetails.result);
        core
          .Store()
          .dispatch(
            core.setScreenData(
              ['knownIssueDetails'],
              knownIssueDetails,
              VIEW_KNOWN_ISSUE_DRAWER
            )
          );
        await onFollowKnownIssueInDrawerClicked(true);
      }
    }
  } else {
    core
      .Store()
      .dispatch(
        core.setErrorScreenMessage(
          core.formatMessage(Locale.security_bulletin_access_error),
          false,
          null,
          VIEW_KNOWN_ISSUE_DRAWER
        )
      );
  }
};

export const desktop = [
  {
    id: 'issueId',
    accessor: 'issueId',
    Header: (
      <KnownIssuesListHeaderWithSortingContainer
        automationName="issueHeader"
        name={core.formatMessage(Locale.issue)}
        columnOrder={0}
        sortColumn="Key"
      />
    ),
    Cell: ({ row: { original: knownIssue } }) => {
      return (
        <core.Label
          className={css.cursorPointer}
          automationName={`issueid_cell_${knownIssue.jiraId}`}
          value={knownIssue.key}
          onClick={() => {
            openKnownIssueDrawer(knownIssue.jiraId);
          }}
        />
      );
    },
    headerClassName: cx(flexCss.flex, css.knownIssuesColumnSmallWidth),
    className: cx(flexCss.flex, css.knownIssuesColumnSmallWidth)
  },
  {
    id: 'product',
    accessor: 'product',
    Header: (
      <core.Flexbox className={cx(css.fullWidth, flexCss.alignCenter)}>
        <div className={css.knownIssuesProductColumnHeader}>
          <KnownIssuesListHeaderWithSortingContainer
            automationName="productHeader"
            name={core.formatMessage(Locale.product)}
            columnOrder={1}
            sortColumn="Product"
          />
        </div>

        <core.Label
          automationName="summaryHeader"
          className={css.summaryColumnHeader}
          value={core.formatMessage(Locale.summary)}
        />
      </core.Flexbox>
    ),
    Cell: ({ row: { original: knownIssue } }) => {
      return (
        <core.Flexbox
          className={cx(css.fullWidth, css.itemsDirection)}
          onClick={() => {
            core.addClickTrack(`product_cell_${knownIssue.jiraId}`);
            openKnownIssueDrawer(knownIssue.jiraId);
          }}
        >
          <core.Label
            className={cx(css.knownIssuesProductColumnData, css.cursorPointer)}
            value={knownIssue.product}
            grey7
          />
          <core.Label
            className={css.summaryColumn}
            value={knownIssue.summary}
          />
        </core.Flexbox>
      );
    },
    headerClassName: cx(flexCss.flex, css.knownIssuesProductColumn),
    className: cx(flexCss.flex, css.knownIssuesProductColumn)
  },
  {
    id: 'status',
    accessor: 'status',
    Header: (
      <>
        <KnownIssuesListHeaderWithSortingContainer
          automationName="statusHeader"
          name={core.formatMessage(Locale.status)}
          columnOrder={2}
          sortColumn="Status"
        />
        <core.FlyoutComposer
          automationName="statusInfo"
          className={css.flyoutContainer}
          flyoutClassName={css.knownIssueStatusInfoFlyout}
          widget={
            <core.Image
              automationName="infoImage"
              className={cx(css.informationIcon)}
              isInlineSvg
              src={core.IconPath.get('Application_Information_New')}
            />
          }
          flyout={flyoutContent}
        />
      </>
    ),
    Cell: ({ row: { original: knownIssue } }) => {
      const statusClass = getStatusClass(knownIssue.status);
      return (
        <core.Label
          automationName={`status_cell_${knownIssue.jiraId}`}
          className={cx(css.knownIssueStatus, statusClass)}
          value={knownIssue.status}
          grey7
          onClick={() => {
            openKnownIssueDrawer(knownIssue.jiraId);
          }}
        />
      );
    },
    headerClassName: cx(flexCss.flex, css.knownIssuesStatusColumnMediumWidth),
    className: cx(flexCss.flex, css.knownIssuesStatusColumnMediumWidth)
  },
  {
    id: 'workAround',
    accessor: 'workAround',
    Header: (
      <core.Label
        automationName="workAroundHeader"
        value={core.formatMessage(Locale.work_around)}
      />
    ),
    Cell: ({ row: { original: knownIssue } }) => {
      return (
        <>
          <core.Label
            automationName={`workaround_cell_${knownIssue.jiraId}`}
            onClick={() => {
              if (knownIssue.kcsArticleLink) {
                window.open(knownIssue.kcsArticleLink);
              } else {
                openKnownIssueDrawer(knownIssue.jiraId);
              }
            }}
            className={
              knownIssue.kcsArticleLink
                ? cx(css.cursorPointer, css.titleColumnData)
                : css.cursorPointer
            }
            value={
              knownIssue.kcsArticleLink
                ? core.formatMessage(Locale.learn_more)
                : '-'
            }
          />
        </>
      );
    },
    headerClassName: cx(flexCss.flex, css.knownIssuesStatusColumnMediumWidth),
    className: cx(flexCss.flex, css.knownIssuesStatusColumnMediumWidth)
  },
  {
    id: 'follows',
    accessor: 'follows',
    Header: (
      <KnownIssuesListHeaderWithSortingContainer
        automationName="followsHeader"
        name={core.formatMessage(Locale.reported)}
        columnOrder={3}
        sortColumn="Follows"
      />
    ),
    Cell: ({ row: { original: knownIssue } }) => {
      const isConnectwiseColleague = HasAccess(
        [POLICIES.CONNECTWISE_COLLEAGUE],
        null
      );
      return !isConnectwiseColleague ? (
        <FollowKnownIssuesButtonContainer knownIssue={knownIssue} />
      ) : (
        <core.Label
          className={css.cursorPointer}
          automationName={`follows_cell_${knownIssue.jiraId}`}
          value={knownIssue.follows}
          grey7
          onClick={() => {
            openKnownIssueDrawer(knownIssue.jiraId);
          }}
        />
      );
    },
    headerClassName: cx(flexCss.flex, css.knownIssuesColumnSmallWidth),
    className: cx(flexCss.flex, css.knownIssuesColumnSmallWidth)
  },
  {
    id: 'version',
    accessor: 'version',
    Header: (
      <KnownIssuesListHeaderWithSortingContainer
        automationName="versionHeader"
        name={core.formatMessage(Locale.version)}
        columnOrder={4}
        sortColumn="FixVersion"
      />
    ),
    Cell: ({ row: { original: knownIssue } }) => {
      return (
        <core.Label
          automationName={`version_cell_${knownIssue.jiraId}`}
          className={css.cursorPointer}
          value={knownIssue.fixVersion || '-'}
          grey7
          onClick={() => {
            openKnownIssueDrawer(knownIssue.jiraId);
          }}
        />
      );
    },
    headerClassName: cx(flexCss.flex, css.knownIssuesStatusColumnMediumWidth),
    className: cx(flexCss.flex, css.knownIssuesStatusColumnMediumWidth)
  },
  {
    id: 'date',
    accessor: 'date',
    Header: (
      <KnownIssuesListHeaderWithSortingContainer
        automationName="dateHeader"
        name={core.formatMessage(Locale.updated)}
        columnOrder={5}
        sortColumn="Updated"
      />
    ),
    Cell: ({ row: { original: knownIssue } }) => {
      return (
        <core.Label
          className={css.cursorPointer}
          automationName={`date_cell_${knownIssue.jiraId}`}
          value={core.formatDate(new Date(knownIssue.updated))}
          grey7
          onClick={() => {
            openKnownIssueDrawer(knownIssue.jiraId);
          }}
        />
      );
    },
    headerClassName: cx(flexCss.flex, css.knownIssuesColumnSmallWidth),
    className: cx(flexCss.flex, css.knownIssuesColumnSmallWidth)
  }
];

export const mobile = [
  {
    id: 'ticketSingleColumn',
    Header: () => {
      return (
        <core.Flexbox className={css.mobileHeader}>
          <KnownIssuesListHeaderWithSortingContainer
            automationName="issueHeader"
            name={core.formatMessage(Locale.issue)}
            columnOrder={0}
            sortColumn="Key"
          />

          <KnownIssuesListHeaderWithSortingContainer
            automationName="dateHeader"
            name={core.formatMessage(Locale.updated)}
            columnOrder={5}
            sortColumn="Updated"
          />
        </core.Flexbox>
      );
    },
    Cell: ({ row: { original: knownIssue } }) => {
      const isConnectwiseColleague = HasAccess(
        [POLICIES.CONNECTWISE_COLLEAGUE],
        null
      );
      const statusClass = getStatusClass(knownIssue.status);
      return (
        <core.Flexbox className={css.issueHighlights}>
          <core.Flexbox
            column
            className={css.leftSideDetailsContainer}
            onClick={() => {
              core.addClickTrack(`key_cell_${knownIssue.jiraId}`);
              openKnownIssueDrawer(knownIssue.jiraId);
            }}
          >
            <core.Label
              grey7
              value={knownIssue.key}
              className={css.mobileLabel}
            />

            <div>
              <core.Label
                grey7
                value={knownIssue.product}
                className={css.mobileLabel}
              />
              {knownIssue.fixVersion && (
                <core.Label
                  grey7
                  value={` - ${knownIssue.fixVersion}`}
                  className={css.mobileLabel}
                />
              )}
            </div>

            <core.Flexbox className={css.fullWidth}>
              <div className={css.summary}>{knownIssue.summary}</div>
              {knownIssue.kcsArticleLink && (
                <span
                  onClick={e => {
                    knownIssue.kcsArticleLink &&
                      window.open(knownIssue.kcsArticleLink);
                    e.stopPropagation();
                  }}
                  className={cx(css.learnMoreLink, css.titleColumnData)}
                  data-cwid="learn_more_link"
                >
                  {core.formatMessage(Locale.learn_more)}
                </span>
              )}
            </core.Flexbox>
          </core.Flexbox>
          <core.Flexbox column className={css.rightSideDetailsContainer}>
            <core.Label
              automationName={`date_cell_${knownIssue.jiraId}`}
              grey7
              value={<FormattedDate value={new Date(knownIssue.updated)} />}
              className={css.mobileLabel}
              onClick={() => {
                openKnownIssueDrawer(knownIssue.jiraId);
              }}
            />
            <core.Label
              automationName={`status_cell${knownIssue.jiraId}`}
              className={cx(
                css.knownIssueStatusMobile,
                statusClass,
                css.mobileLabel
              )}
              value={knownIssue.status}
              grey7
              onClick={() => {
                openKnownIssueDrawer(knownIssue.jiraId);
              }}
            />

            {!isConnectwiseColleague ? (
              <FollowKnownIssuesButtonContainer knownIssue={knownIssue} />
            ) : (
              <>
                <core.Flexbox>
                  <core.Label
                    automationName={`reported_cell_${knownIssue.jiraId}`}
                    value={`${core.formatMessage(Locale.reported)}: `}
                    onClick={() => {
                      openKnownIssueDrawer(knownIssue.jiraId);
                    }}
                  />
                  <core.Label
                    automationName="reported"
                    grey7
                    value={knownIssue.follows}
                    className={css.label}
                  />
                </core.Flexbox>
              </>
            )}
            {isConnectwiseColleague && (
              <KnownIssuesOptionColumnContainer
                jiraId={knownIssue.jiraId}
                jiraKey={knownIssue.key}
                jiraSummary={knownIssue.summary}
              />
            )}
          </core.Flexbox>
        </core.Flexbox>
      );
    },
    headerClassName: cx(
      flexCss.flex,
      flexCss.one,
      flexCss.spaceBetween,
      css.mobileColumn
    ),
    className: cx(
      flexCss.flex,
      flexCss.one,
      flexCss.spaceBetween,
      css.mobileColumn
    ),
    stackCount: 5
  }
];

const optionColumn = {
  id: 'option',
  accessor: 'option',
  Header: <core.Label automationName="optionHeader" value="" />,
  Cell: ({ row: { original: knownIssue } }) => {
    const isConnectwiseColleague = HasAccess(
      [POLICIES.CONNECTWISE_COLLEAGUE],
      null
    );
    return (
      isConnectwiseColleague && (
        <KnownIssuesOptionColumnContainer
          jiraId={knownIssue.jiraId}
          jiraKey={knownIssue.key}
          jiraSummary={knownIssue.summary}
        />
      )
    );
  },
  headerClassName: cx(flexCss.flex, css.knownIssuesOptionColumn),
  className: cx(flexCss.flex, css.knownIssuesOptionColumn)
};

export const desktopForColleague = [...desktop, optionColumn];
