import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawer.scss';
import svgCss from 'src/styles/svg.scss';
import cx from 'classnames';
import { copyToClipboard } from 'src/services/Utils';
import Locale from 'src/Locale';

const CreateBulletinSuccess = props => {
  const {
    bulletinId,
    isBulletinEdit,
    onViewBulletinClick,
    securityBulletinUrl
  } = props;

  return (
    <core.Flexbox column className={css.successContainer}>
      <core.Label
        className={css.title}
        value={core.formatMessage(
          isBulletinEdit
            ? Locale.bulletin_successfully_edited
            : Locale.bulletin_successfully_created
        )}
        grey7
      />
      <core.Image
        src="/images/SupportTicket/ticket_created_message.svg"
        className={css.successImage}
      />
      <core.Flexbox className={css.linkBulletin}>
        <core.Label value={securityBulletinUrl} />
        <core.Image
          title={core.formatMessage(Locale.copy_to_clipboard)}
          automationName="copyToClipboard"
          className={cx(css.copyLink, svgCss.actionState)}
          onClick={() => copyToClipboard(securityBulletinUrl)}
          src={core.IconPath.get('Action_Copy')}
        />
      </core.Flexbox>
      <core.ButtonStandard
        cwId="createBulletinSuccess"
        automationName="createBulletinSuccess"
        className={css.viewButton}
        value={core.formatMessage(Locale.view_bulletin_button)}
        onClick={() => onViewBulletinClick(bulletinId)}
      ></core.ButtonStandard>
    </core.Flexbox>
  );
};

export default CreateBulletinSuccess;
