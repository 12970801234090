import { GET, getUrl, POST } from 'src/services/ApiService';

const ToolsService = () => {
  const apiUrl = () => getUrl('home', '/tools');

  const getProducts = async () => {
    return await GET(`${apiUrl()}/products`, true, '1.0');
  };

  const getProductPricingPlans = async productId => {
    return await GET(
      `${apiUrl()}/products/${productId}/pricingplans`,
      true,
      '1.0'
    );
  };

  const getCart = async () => {
    return await GET(`${apiUrl()}/cart`, true, '1.0');
  };

  const addToCart = async productId => {
    return await POST(`${apiUrl()}/cart/${productId}`);
  };

  const submitSignupData = async (productId, signupData) => {
    return await POST(`${apiUrl()}/forms/${productId}`, signupData);
  };

  const getFormSignupFields = async (productId, formType) => {
    return await GET(
      `${apiUrl()}/forms/${productId}/signupFields?formType=${formType}`
    );
  };

  const validateCustomUrl = async customUrl => {
    return await GET(`${apiUrl()}/validateCustomUrl/${customUrl}`);
  };

  return {
    getProducts,
    getProductPricingPlans,
    getCart,
    addToCart,
    submitSignupData,
    getFormSignupFields,
    validateCustomUrl
  };
};

export default ToolsService();
