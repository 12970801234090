import { GET, getUrl, POST, PUT } from 'src/services/ApiService';

const SecurityBulletinAdminService = () => {
  const apiUrl = () => getUrl('home', '/admin');
  return {
    getSecurityBulletinsOverview: (allBulletins, product, page, pageSize) => {
      return GET(
        `${apiUrl()}/bulletins/overview?allBulletins=${allBulletins}&product=${product}&page=${page}&pageSize=${pageSize}`
      );
    },
    addSecurityBulletin: securityBulletinDetails => {
      return POST(`${apiUrl()}/bulletins`, securityBulletinDetails);
    },
    editSecurityBulletin: (editBulletinId, securityBulletinDetails) => {
      return PUT(
        `${apiUrl()}/bulletins/${editBulletinId}`,
        securityBulletinDetails
      );
    }
  };
};

export default SecurityBulletinAdminService();
