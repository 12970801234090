import React from 'react';
import * as core from 'cw-ui-core';
import css from './LandingPage.scss';
import Background from 'src/components/Templates/Background';
import LoadConfigurationHOC from 'src/components/App/LoadConfigurationHOC';
import Locale from 'src/Locale';

const LoginProvider = props => {
  const automationName = `signin_${props.idp}`;

  const onClick = () => {
    core.addClickTrack(automationName);
    props.onClick(props.idp);
  };

  return (
    <div data-cwid={automationName} className={css.login} onClick={onClick}>
      <core.Image
        src={props.logo}
        isInlineSVG={false}
        className={css.loginLogo}
        alt={core.formatMessage(Locale.login_logo)}
      />
      <div className={css.loginTitle}>{props.name}</div>
    </div>
  );
};

const LandingPage = props => {
  return (
    <Background>
      <core.Flexbox className={css.loginSection} column>
        <div className={css.imageHolder}>
          <core.Image
            className={css.featureImage}
            src={props.featureImageUrl || '/images/homelanding.jpg'}
            isInlineSVG={false}
            alt={core.formatMessage(Locale.feature_display)}
          />
          {props.isMobileLayout ? (
            <core.Image
              className={css.connectwiseIconWhite}
              src="/images/connectwise-icon-white.svg"
            />
          ) : null}
        </div>

        <core.Flexbox className={css.rightPanel} column>
          <div className={css.panel}>
            {!props.isMobileLayout ? (
              <div className={css.cwIconHolder}>
                <core.Image
                  className={css.cwIcon}
                  src={core.IconPath.get("CW_Logo")}
                  isInlineSVG={false}
                  alt={core.formatMessage(Locale.connectwise_logo)}
                />
              </div>
            ) : null}
            <div className={css.panelBody}>
              <div>
                <h2 className={css.titleText}>{props.titleText}</h2>
              </div>
              <core.Label
                className={css.subtitle}
                grey2
                value={props.titleSubtext}
              />
              <core.Flexbox className={css.loginWrap}>
                <LoginProvider
                  automationName="login"
                  idp="ConnectWise"
                  logo={core.IconPath.get("CW_Logo")}
                  name="Sign in with ConnectWise"
                  onClick={props.onSignInClicked}
                />
              </core.Flexbox>
            </div>
          </div>
          <div className={css.panelFooter}>
            <core.Flexbox className={css.loginFooter}>
              <span className={css.copyrightMessage}>
                {core.formatMessage(Locale.landing_page_copyright_message, {
                  currentYear: new Date().getFullYear()
                })}
              </span>
              <span>
                <core.Label
                  className={css.footerHelpLinks}
                  value={core.formatMessage(Locale.landing_page_about)}
                  isLink
                  onClick={props.onAboutClick}
                />
                <core.Label
                  className={css.footerHelpLinks}
                  value={core.formatMessage(Locale.landing_page_privacy)}
                  isLink
                  onClick={props.onPrivacyClick}
                />
                <core.Label
                  className={css.footerHelpLinks}
                  value={core.formatMessage(Locale.contact_us)}
                  isLink
                  onClick={props.onContactUsClick}
                />
              </span>
            </core.Flexbox>
          </div>
        </core.Flexbox>
      </core.Flexbox>
    </Background>
  );
};

export default LoadConfigurationHOC(core.MobileLayoutHOC(LandingPage)); // cannot use localization for landing page
