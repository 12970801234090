import React, { useState, useEffect } from 'react';
import css from './FailedPaymentComponent.scss';
import BillingEventService from 'src/services/Api/BillingApi/BillingEventService';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import {
  manageInvoiceStatuses,
  downloadInvoicePdf
} from 'src/services/Invoices/InvoiceService';
import { ADDNEWCARD_ID } from 'src/components/Wallet/CustomerWalletService';
import { isCreditCardExpired } from '../../../services/Wallet/WalletService';

const FailedPaymentComponent = props => {
  const [userMessages, setUserMessages] = useState([]);
  const [processingPayment, setProcessingPayment] = useState(false);
  let failedInvoices = props.failedInvoices ? props.failedInvoices.toJS() : [];
  let isLoading = props.isLoading;
  const loadFailedInvoices = props.loadFailedInvoices;
  const setBillingErrorEvents = props.setBillingErrorEvents;
  const billingErrorEvents = props.billingErrorEvents
    ? props.billingErrorEvents.toJS()
    : [];
  const errorInvoiceFlag =
    failedInvoices.length === 0 && billingErrorEvents.length > 0;
  const notificationMessages = {
    'wallet-drawer-notification-info-retry-payment': {
      type: core.NotificationStatus.INFORMATIONAL,
      message: core.formatMessage(
        Locale.wallet_drawer_info_notification_retry_payment
      ),
      iconName: 'Application_Information'
    },
    'wallet-drawer-notification-error-retry-payment': {
      type: core.NotificationStatus.ERROR,
      message: core.formatMessage(
        Locale.wallet_drawer_error_notification_retry_payment
      ),
      iconName: 'Tile_Alerts'
    }
  };
  const isNewCard =
    props.selectedCard &&
    (ADDNEWCARD_ID === props.selectedCard.ID ||
      ADDNEWCARD_ID === props.selectedCard.id);

  useEffect(() => {
    if (errorInvoiceFlag) {
      let userMessagesCopy = userMessages ? [...userMessages] : [];
      userMessagesCopy.push('wallet-drawer-notification-error-retry-payment');
      setUserMessages(userMessagesCopy);
    }
    // eslint-disable-next-line
  }, [props.billingErrorEvents, props.failedInvoices]);

  useEffect(() => {
    loadFailedInvoices();
    setBillingErrorEvents();
  }, [setBillingErrorEvents, loadFailedInvoices, processingPayment]);

  const retryPayment = () => {
    setProcessingPayment(true);
    failedInvoices.map(invoice => {
      const lastTaskToken =
        invoice.billingEventResponse.taskTokens[
          invoice.billingEventResponse.taskTokens.length - 1
        ];
      BillingEventService.sendSuccessTaskToken(
        // eslint-disable-next-line no-underscore-dangle
        invoice.billingEventResponse._id,
        lastTaskToken,
        props.selectedCard
      );
      return true;
    });
    let userMessagesCopy = userMessages ? [...userMessages] : [];
    userMessagesCopy.push('wallet-drawer-notification-info-retry-payment');
    setUserMessages(userMessagesCopy);
    setTimeout(() => {
      setProcessingPayment(false);
    }, 3000);
    props.setPaymentProcessing(true);
    return;
  };

  const renderNotfications = () => {
    const notifications = [];
    for (let i = 0; i < userMessages.length; i++) {
      const messageSetting = notificationMessages[userMessages[i]];
      if (messageSetting) {
        notifications.push(
          <core.Notification
            cwId={userMessages[i]}
            automationName={userMessages[i]}
            message={messageSetting.message}
            statusIcon={core.IconPath.get(messageSetting.iconName)}
            status={messageSetting.type}
            onClose={() => {
              let userMessagesCopy = [...userMessages];
              userMessagesCopy.pop(i);
              setUserMessages(userMessagesCopy);
            }}
          />
        );
      }
    }

    return notifications;
  };

  const onInvoiceNumberClick = async (invoiceId, downloadFileName) => {
    await downloadInvoicePdf(invoiceId, downloadFileName);
  };

  const getInvoiceStatus = invoice => {
    if (
      invoice.status === manageInvoiceStatuses.PaymentPending ||
      invoice.status === manageInvoiceStatuses.ReadyForGp ||
      invoice.status === manageInvoiceStatuses.SentToGp ||
      invoice.status === manageInvoiceStatuses.Closed
    ) {
      return (
        <core.Flexbox className={[css.columnData, css.paymentFailedStatus]}>
          {core.formatMessage(
            Locale.wallet_drawer_invoice_status_payment_failed
          )}
        </core.Flexbox>
      );
    } else if (errorInvoiceFlag) {
      return (
        <core.Flexbox className={[css.columnData, css.paymentFailedStatus]}>
          {core.formatMessage(
            Locale.wallet_drawer_invoice_status_invoice_error
          )}
        </core.Flexbox>
      );
    }
  };

  const isCCExpired =
    props.selectedCard &&
    props.selectedCard.ExpDate &&
    props.selectedCard.ExpDate.length === 4
      ? isCreditCardExpired(
          props.selectedCard.ExpDate.slice(0, 2),
          props.selectedCard.ExpDate.slice(2, 4),
          new Date()
        )
      : true;

  const getCurrencyNumber = (currencySymbol, amount) => {
    let parsedAmt = amount === 0 ? 0 : Number.parseFloat(amount).toFixed(2);
    return `${currencySymbol}${parsedAmt}`;
  };

  const totalOverdueAmount = failedInvoices.reduce((total, current) => {
    return total + current.balance;
  }, 0);

  if (isLoading || failedInvoices.length === 0) {
    return null;
  } else {
    return (
      <div className={css.container}>
        <h2 className={css.subtitle}>
          {core.formatMessage(Locale.wallet_drawer_retry_payment_title)}
        </h2>
        <p className={css.failedPaymentMessage}>
          {core.formatMessage(Locale.wallet_drawer_retry_payment_message)}
        </p>
        {renderNotfications()}
        <core.Flexbox className={css.table}>
          <div className={css.header}>
            <core.Flexbox className={css.columnHeader}>
              INVOICE NUMBER
            </core.Flexbox>
            <core.Flexbox className={css.columnHeader}>STATUS</core.Flexbox>
            <core.Flexbox className={[css.columnHeader, css.amount]}>
              AMOUNT DUE
            </core.Flexbox>
          </div>
          <div className={css.tableBody}>
            {failedInvoices.map(inv => (
              <div className={css.row}>
                <core.Flexbox className={css.columnData}>
                  <core.Label
                    automationName="invoiceNumber"
                    onClick={() =>
                      onInvoiceNumberClick(inv.id, inv.invoiceNumber)
                    }
                    isLink
                    value={'#' + inv.invoiceNumber}
                    className={css.failedPaymentListLabel}
                  />
                </core.Flexbox>
                {getInvoiceStatus(inv)}
                <core.Flexbox className={[css.columnData, css.amount]}>
                  {getCurrencyNumber(inv.currencySymbol, inv.balance)}
                </core.Flexbox>
              </div>
            ))}
          </div>
          <div className={css.footer}>
            <div className={css.columnFooter}>
              {'Total: '}
              {getCurrencyNumber(
                failedInvoices[0].currencySymbol,
                totalOverdueAmount
              )}
            </div>
          </div>
        </core.Flexbox>
        <core.Flexbox className={css.retryPaymentContainer}>
          <div
            title={
              isNewCard ? core.formatMessage(Locale.wallet_add_card_info) : ''
            }
          >
            <core.ButtonStandard
              className={css.retryPaymentButton}
              onClick={retryPayment}
              value={core.formatMessage(Locale.retry_payment)}
              disabled={isNewCard || isCCExpired}
            />
          </div>
        </core.Flexbox>
      </div>
    );
  }
};

export default core.MobileLayoutHOC(FailedPaymentComponent);
