import * as core from 'cw-ui-core';
import { connect } from 'react-redux';
import Routes from 'src/services/Routes';
import FailedPaymentComponent from 'src/components/Drawer/PartnerWalletDrawer/FailedPaymentComponent';
import BillingEventService from 'src/services/Api/BillingApi/BillingEventService';
import { loadFailedInvoices } from 'src/services/Invoices/InvoiceService';

const mapStateToProps = state => {
  return {
    isLoading: core.getScreenData(state.screen, [
      Routes.BILLING_PAGE.id,
      'showLoadingComponent'
    ]),
    failedInvoices: core.getScreenData(state.screen, ['failedInvoices']),
    billingErrorEvents: core.getScreenData(state.screen, [
      Routes.BILLING_PAGE.id,
      'billingErrorEvents'
    ])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadFailedInvoices: async () => {
      await loadFailedInvoices();
      return;
    },
    setBillingErrorEvents: async () => {
      const events =
        BillingEventService.getBillingEventsByStatus('error_review');
      events.then(data => {
        if (data.isSuccess) {
          const billingErrorEvents = data.response.msg;
          dispatch(
            core.setScreenData(
              [Routes.BILLING_PAGE.id, 'billingErrorEvents'],
              billingErrorEvents
            )
          );
        } else {
          dispatch(
            core.setScreenData(
              [Routes.BILLING_PAGE.id, 'billingErrorEvents'],
              []
            )
          );
        }
      });
    }
  };
};

const FailedPaymentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FailedPaymentComponent);

export default FailedPaymentContainer;
