import { connect } from 'react-redux';
import BannerDrawerHeader from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawerHeader';
import Locale from 'src/Locale';
import * as core from 'cw-ui-core';

const mapStateToProps = () => {
  let headerName = core.formatMessage(Locale.upload_banner);
  let subHeaderName = core.formatMessage(Locale.create_new_banner);

  return { headerName, subHeaderName };
};

const BannerDrawerHeaderContainer = connect(mapStateToProps, null)(BannerDrawerHeader);

export default BannerDrawerHeaderContainer;