import * as core from 'cw-ui-core';
import CompanyService from 'src/services/Api/HomeApi/CompanyService';

export const fetchAccountManager = async () => {
  const result = await CompanyService.getAccountManager();

  if (!result.isSuccess) {
    const error = 'No Account Manager assigned to this company.';
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(core.setModuleCache(['accountManager'], result.isSuccess));
    return result.isSuccess;
  }

  core
    .Store()
    .dispatch(core.setModuleCache(['accountManager'], result.response));
  return result.response;
};

export const fetchAccountManagerPhoto = async () => {
  var imageUrl = await CompanyService.getAccountManagerPhoto();
  core
    .Store()
    .dispatch(core.setModuleCache(['accountManagerPhoto'], imageUrl.response));
  return imageUrl.response;
};

export const AMType = {
  RecordType: 'ServiceTicket',
  Status: {
    New: 'New'
  },
  TicketType: 'AM'
};
