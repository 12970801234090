import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import CompanyAccountSetting from 'src/components/Drawer/UserProfileDrawer/CompanyAccountSetting/CompanyAccountSetting';
import {
  isSupportEnabled,
  enableSupport,
  disableSupport
} from 'src/services/User/UserService';
import { SUPPORT_ENABLED_DIALOG } from 'src/components/Drawer/UserProfileDrawer/CompanyAccountSetting/SupportEnabledDialog/SupportEnabledDialog';

export const COMPANY_ACCOUNT_SETTING_DRAWER = 'COMPANY_ACCOUNT_SETTING_DRAWER';

const loadSupport = async () => {
  const dispatch = core.Store().dispatch;
  const reponse = await isSupportEnabled();
  if (reponse) {
    dispatch(
      core.setScreenData(
        'isSupportEnabled',
        reponse.enabled,
        COMPANY_ACCOUNT_SETTING_DRAWER
      )
    );
  }
};

const mapStateToProps = state => {
  const partnerId = core.getPartnerId(state.oidc);
  const isSupportEnabled = core.getScreenData(
    state.screen,
    'isSupportEnabled',
    COMPANY_ACCOUNT_SETTING_DRAWER
  );
  return {
    partnerId,
    isSupportEnabled
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDidMount: () => {
      loadSupport();
    },
    onCloseIconClicked: () => {
      dispatch(core.hideDrawer(COMPANY_ACCOUNT_SETTING_DRAWER));
    },
    onChange: async () => {
      const state = core.Store().getState();
      const supportEnabled = core.getScreenData(
        state.screen,
        'isSupportEnabled',
        COMPANY_ACCOUNT_SETTING_DRAWER
      );

      if (supportEnabled) {
        await disableSupport();
        await loadSupport();
      } else {
        const result = await enableSupport();
        const password = result.password;
        await loadSupport();
        dispatch(
          core.showDialog(SUPPORT_ENABLED_DIALOG, { password })
        );
      }
    }
  };
};

const CompanyAccountSettingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyAccountSetting);

export default CompanyAccountSettingContainer;
