import NewsApi from 'src/services/Api/HomeApi/NewsService';
import * as core from 'cw-ui-core';
import Routes from 'src/services/Routes';

export const getNewsPostCriteria = async () => {
  const dispatch = core.Store().dispatch;
  const result = await NewsApi.getNewsPostCriteria();
  if (result.isSuccess) {
    dispatch(core.setModuleCache(['newsPostCriteria'], result.response));
  } else {
    dispatch(
      core.setErrorScreenMessage(
        result.error,
        false,
        result.errorMessages,
        core.ACTIVE_SCREEN_KEY
      )
    );
  }
};

export const likePost = async postId => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const newsItemsChk = core.getScreenData(state.screen, [
    Routes.HOME_PAGE.id,
    'news',
    'feed',
    'data'
  ]);
  let newsItems = newsItemsChk ? newsItemsChk.toJS() : [];
  const postIndex = newsItems.findIndex(x => x.postId === postId);
  newsItems[postIndex].isLiked = true;
  dispatch(
    core.setScreenData([Routes.HOME_PAGE.id, 'news', 'feed', 'data'], newsItems)
  );
  const result = await NewsApi.likePost(postId);

  if (result.isSuccess) {
    return;
  } else {
    newsItems[postIndex].isLiked = false;
    dispatch(
      core.setScreenData(
        [Routes.HOME_PAGE.id, 'news', 'feed', 'data'],
        newsItems
      )
    );
    const error = result.error;
    core.CwLog.error(error);
    return;
  }
};

export const unlikePost = async postId => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const newsItemsChk = core.getScreenData(state.screen, [
    Routes.HOME_PAGE.id,
    'news',
    'feed',
    'data'
  ]);
  let newsItems = newsItemsChk ? newsItemsChk.toJS() : [];
  const postIndex = newsItems.findIndex(x => x.postId === postId);
  newsItems[postIndex].isLiked = false;
  dispatch(
    core.setScreenData([Routes.HOME_PAGE.id, 'news', 'feed', 'data'], newsItems)
  );
  const result = await NewsApi.unlikePost(postId);

  if (result.isSuccess) {
    return;
  } else {
    newsItems[postIndex].isLiked = true;
    dispatch(
      core.setScreenData(
        [Routes.HOME_PAGE.id, 'news', 'feed', 'data'],
        newsItems
      )
    );
    const error = result.error;
    core.CwLog.error(error);
    return;
  }
};

export const bookmarkPost = async postId => {
  updateBookmarkStore(true, postId);
  const result = await NewsApi.bookmarkPost(postId);
  if (result.isSuccess) {
    return;
  } else {
    updateBookmarkStore(false, postId);
    const error = result.error;
    core.CwLog.error(error);
  }
};

export const unBookmarkPost = async postId => {
  updateBookmarkStore(false, postId);
  const result = await NewsApi.unBookmarkPost(postId);
  if (result.isSuccess) {
    return;
  } else {
    updateBookmarkStore(true, postId);
    const error = result.error;
    core.CwLog.error(error);
  }
};

function updateBookmarkStore(isBookmark, postId) {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const newsItemsChk = core.getScreenData(state.screen, [
    Routes.HOME_PAGE.id,
    'news',
    'feed',
    'data'
  ]);
  let newsItems = newsItemsChk ? newsItemsChk.toJS() : [];
  const postIndex = newsItems.findIndex(x => x.postId === postId);
  if (postIndex > -1) {
    newsItems[postIndex].isBookmarked = isBookmark;
    dispatch(
      core.setScreenData(
        [Routes.HOME_PAGE.id, 'news', 'feed', 'data'],
        newsItems
      )
    );
  }
}
