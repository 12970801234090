import { connect } from 'react-redux';
import NewsFeedItem from 'src/components/HomePage/NewsPod/NewsFeed/NewsFeedItem';
import * as core from 'cw-ui-core';
import { NEWS_POST_DRAWER } from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsPostDrawerContainer';
import { buttonTypes } from 'src/services/NewsAdmin/NewsAdminService';
import { getAvatarPhoto } from 'src/services/Avatar/AvatarService';

const mapStateToProps = state => {
  const newsItemCheck = core.getScreenData(state.screen, [], NEWS_POST_DRAWER);
  const newsItem = newsItemCheck ? newsItemCheck.toJS() : null;
  const isShowVideoPreview = core.getScreenData(
    state.screen,
    ['isValidYoutubeLink'],
    NEWS_POST_DRAWER
  );
  let previewNewsItem = newsItem;
  if (newsItem && newsItem.content) {
    previewNewsItem.content = previewNewsItem.content.replace(/\n/g, '<br />');
  }

  const authorImageUrl =
    newsItem && newsItem.authorImageUrl
      ? core.getModuleCache(state.module, [
          'avatar',
          'photos',
          newsItem.authorImageUrl
        ])
      : undefined;
  return {
    newsItem: previewNewsItem,
    authorImageUrl:
      authorImageUrl === 'useInitials' ? undefined : authorImageUrl,
    isShowVideoPreview
  };
};

const mapDispatchToProps = () => {
  const state = core.Store().getState();
  const newsItemCheck = core.getScreenData(state.screen, [], NEWS_POST_DRAWER);
  const newsItem = newsItemCheck ? newsItemCheck.toJS() : null;
  return {
    onNewsItemChange: async newsItem => {
      if (newsItem && newsItem.authorImageUrl) {
        return await getAvatarPhoto(newsItem.authorImageUrl);
      }
    },
    onCallToActionButtonClick: () => {
      if (newsItem.buttonType === buttonTypes[1].name) {
        window.open(newsItem.ctaButtonUrl);
      }
    }
  };
};

const NewsFeedItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsFeedItem);

export default NewsFeedItemContainer;
