import { getUrl, GETFILEDOWNLOAD } from 'src/services/ApiService';

const MemberService = () => {

  return {
    getMemberImage: async memberId => {
      const getBaseUrl = () => getUrl('home', '/system');
      return GETFILEDOWNLOAD(`${getBaseUrl()}/members/${memberId}/image`); 
    }
  };
};

export default MemberService();