import BaseService from '../BaseApiService';

class TransactionService extends BaseService {
  getBaseUrl() {
    return `${this.getBaseUrlFromConfig('billing')}/api/transaction`;
  }

  getPrice(email, buyerCart, cartReferenceKey) {
    const body = {
      reqData: {
        email,
        productsCart: buyerCart,
        cartReferenceKey
      }
    };
    return this.post(`${this.apiUrl()}/dummy/getPrice`, body);
  }

  getTax(params) {
    const body = {
      reqData: params
    };
    return this.post(`${this.apiUrl()}/dummy/getTax`, body);
  }
}

export default new TransactionService();
