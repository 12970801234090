import React from 'react';
import { Label, TextArea, TextField } from 'cw-ui-core';
import css from 'src/components/FieldWithLabel/FieldWithLabel.scss';
import cx from 'classnames';

const addFormLabel = Component => {
  return props => {
    const {
      labelProps: { isCount, value, onClick, automationName },
    } = props;
    return (
      <>
        <Component {...props} />
        <Label
          automationName={
            automationName ? automationName : props.automationName
          }
          className={cx(css.label, { [css.textAreaLabel]: props.isTextArea })}
          value={
            isCount
              ? `${props.value ? props.value.length : 0} / ${props.maxLength}`
              : value
          }
          grey3={!!onClick ? false : true}
          isLink={!!onClick ? true : false}
          onClick={onClick}
        />
      </>
    );
  };
};

export const FieldWithLabel = addFormLabel(TextField);
export const TextAreaWithLabel = addFormLabel(TextArea);
