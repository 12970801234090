import {useEffect} from 'react';

const escKeycode = 27;

export default cb => {
  useEffect(() => {
    const onClick = e => {
      cb(e);
    };

    const keyDown = e => {
      if (e.which === escKeycode) {
        cb(e);
      }
    };

    document.addEventListener('keydown', keyDown);
    document.addEventListener('click', onClick);
    document.addEventListener('touchend', onClick);
    return () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener("click", onClick);
      document.removeEventListener('touchend', onClick);
    };
  }, [cb]);
};