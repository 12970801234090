import React, { useState } from 'react';
import {
  Dialog,
  Flexbox,
  ButtonStandard,
  ButtonLight,
  FormFieldContainer,
  ComboBoxTypeAheadContainer,
  ScreenMessagePanelContainer,
  MobileLayoutHOC
} from 'cw-ui-core';

import css from './TicketUpdateStatusDialog.scss';
import { GET, getUrl } from 'src/services/ApiService';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import Locale from 'src/Locale';

export const UPDATE_TICKET_STATUS_DIALOG = 'UPDATE_TICKET_STATUS_DIALOG';

const TicketUpdateStatusDialog = props => {
  const [isBusy, setIsBusy] = useState(false);
  const getEndpoint = () => {
    const endpoint = `/boards/${props.boardId}/statuses`;
    return getUrl('home', '/service') + endpoint;
  };

  const onSave = async () => {
    setIsBusy(true);
    await props.onSave();
    setIsBusy(false);
  };

  const { formatMessage } = props.intl;

  const getSaveButton = () => {
    const button = () => (
      <ButtonStandard
        cwId="changeStatus"
        automationName="changeStatus"
        title="Change Status"
        value={formatMessage(Locale.change_status)}
        onClick={onSave}
        className={css.submitButton}
        disabled={!props.isStatusSet}
      />
    );
    const LoadingButton = WithButtonLoadingIndicatorHOC(button);
    return (
      <LoadingButton
        isBusy={isBusy}
        wrapperStyle={{ height: '44px', width: '146px' }}
      />
    );
  };

  return (
    <Dialog
      automationName="changeStatusDialog"
      className={
        props.isMobileLayout ? css.containerMobile : css.containerNormal
      }
      includeCloseIcon={!props.isMobileLayout}
      includeMobileCloseIcon={props.isMobileLayout}
      onCloseIconClicked={props.onCancel}
    >
      <Flexbox className={css.topContainer}>
        <ScreenMessagePanelContainer
          screenKey={UPDATE_TICKET_STATUS_DIALOG}
          className={
            props.isMobileLayout ? css.screenMessageMobile : css.screenMessage
          }
        />
        <div className={css.closeMessage}>
          {formatMessage(Locale.change_status)}
        </div>
        <div className={css.ticketNumberText}>
          {formatMessage(Locale.update_ticket_status, {
            ticketId: props.ticketId
          })}
        </div>
        <FormFieldContainer
          path={['status']}
          screenKey={UPDATE_TICKET_STATUS_DIALOG}
          className={props.isMobileLayout ? css.comboBoxMobile : css.comboBox}
        >
          <ComboBoxTypeAheadContainer getEndpoint={getEndpoint} apiGet={GET} />
        </FormFieldContainer>
      </Flexbox>
      <Flexbox className={css.buttonContainer}>
        <ButtonLight
          cwId="cancelDialog"
          automationName="cancelDialog"
          title="Cancel"
          value="Cancel"
          onClick={props.onCancel}
          className={css.cancelButton}
        />
        {getSaveButton()}
      </Flexbox>
    </Dialog>
  );
};

export default MobileLayoutHOC(TicketUpdateStatusDialog);
