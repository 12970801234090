import * as core from 'cw-ui-core';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import NotificationBeforeClose from 'src/components/Drawer/NotificationBeforeClose';
import { hideNotificationBeforeClose } from 'src/services/DrawerNotification/DrawerNotification';

const mapStateToProps = (state, ownProps) => {
  return {
    showNotificationBeforeClose: core.getScreenData(
      state.screen,
      'showNotificationBeforeClose',
      ownProps.drawerScreenKey
    )
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onNotificationStayClick: drawerScreenKey => {
      hideNotificationBeforeClose(drawerScreenKey);
    },
    onNotificationCloseClick: drawerScreenKey => {
      hideNotificationBeforeClose(drawerScreenKey);
      dispatch(core.hideDrawer(drawerScreenKey));
    }
  };
};

const NotificationBeforeCloseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationBeforeClose);

export default injectIntl(NotificationBeforeCloseContainer);
