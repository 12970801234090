import { getUrl, GET, GETFILE } from 'src/services/ApiService';

const InvoiceService = () => {
  const apiUrl = () => getUrl('home', '/finance');

  const getInvoiceList = (
    conditions = '',
    orderBy = '',
    page = 1,
    pageSize = 10
  ) => {
    var url = `${apiUrl()}/invoices?page=${page}&orderBy=${orderBy}&pageSize=${pageSize}`;
    if (conditions !== '') {
      url = url + `&conditions=${conditions}`;
    }

    return GET(url);
  };

  const getInvoicePdf = manageInvoiceId => {
    return GETFILE(`${apiUrl()}/invoices/${manageInvoiceId}/pdf`);
  };

  const getInvoiceLink = manageInvoiceId => {
    return GETFILE(`${apiUrl()}/invoices/${manageInvoiceId}/ePay`);
  };

  const getEPaySsoLink = locationId => {
    return GET(`${apiUrl()}/invoices/ePay/${locationId}`);
  };

  const getEPayInvoiceList = () => {
    return GET(`${apiUrl()}/invoices/ePay`);
  };

  const getFailedInvoiceList = (page = 1, pageSize = 10) => {
    var url = `${apiUrl()}/failedInvoices?page=${page}&pageSize=${pageSize}`;
    return GET(url);
  };

  return {
    getInvoiceList,
    getInvoicePdf,
    getInvoiceLink,
    getEPaySsoLink,
    getEPayInvoiceList,
    getFailedInvoiceList
  };
};

export default InvoiceService();
