import {
  Store,
  setErrorScreenMessage,
  getScreenData,
  toggleMask,
  removeScreenUpdated,
  setScreenData,
  downloadFile,
  formatMessage
} from 'cw-ui-core';
import { fromJS } from 'immutable';

import Locale from 'src/Locale';
import TicketService from 'src/services/Api/HomeApi/TicketService';
import { EDIT_VIEW_TICKET_DRAWER_ID } from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawer';

export const PartnerSupportPageTab = {
  SUPPORT_TICKETS: 'supportTickets',
  ARCHIVED_TICKETS: 'archivedTickets'
};

export const IssuePageTab = {
  ISSUES: 'issues'
};

export const updateTicket = async (
  ticketId,
  updates,
  screenKey,
  field = 'Field'
) => {
  const dispatch = Store().dispatch;
  if (updates.size <= 0) {
    const error = formatMessage(Locale.field_is_required, { field });
    dispatch(setErrorScreenMessage(error, false, null, screenKey));
    return {
      error,
      isSuccess: false
    };
  }

  const result = await TicketService.updateTicket(ticketId, updates);
  if (!result.isSuccess) {
    dispatch(
      setErrorScreenMessage(
        result.error,
        false,
        result.errorMessages,
        screenKey
      )
    );
  }

  return result;
};

export const addTicketNotes = async (ticketId, updates, screenKey) => {
  const dispatch = Store().dispatch;
  dispatch(toggleMask(true));

  if (updates && updates.text) {
    let notes = {
      text: updates.text,
      detailDescriptionFlag: true,
      customerUpdatedFlag: true,
      processNotifications: true
    };

    const result = await TicketService.addTicketNotes(ticketId, notes);

    if (!result.isSuccess) {
      dispatch(
        setErrorScreenMessage(
          result.error,
          false,
          result.errorMessages,
          screenKey
        )
      );
      dispatch(toggleMask(false));
      return;
    }
  }

  if (updates && updates.attachments && updates.attachments.length > 0) {
    await addAttachment(updates.attachments, dispatch, ticketId, screenKey);
  }

  dispatch(removeScreenUpdated([], screenKey));
  dispatch(setScreenData('attachments', fromJS([]), screenKey));
  dispatch(setScreenData('text', fromJS(''), screenKey));

  let notes = await TicketService.getNotesAndAttachments(ticketId);

  if (!notes.isSuccess) {
    dispatch(
      setErrorScreenMessage(notes.error, false, notes.errorMessages, screenKey)
    );
  } else {
    dispatch(setScreenData('notes', notes.response, screenKey));
  }

  dispatch(toggleMask(false));
};

export const downloadDocument = async (
  ticketId,
  documentId,
  fileName,
  screenKey
) => {
  const dispatch = Store().dispatch;
  dispatch(toggleMask(true));

  const result = await TicketService.downloadAttachment(ticketId, documentId);

  if (!result.isSuccess) {
    const error = formatMessage(Locale.failed_download_attachment, {
      resultError: result.error
    });
    dispatch(
      setErrorScreenMessage(error, false, result.errorMessages, screenKey)
    );
    dispatch(toggleMask(false));
    return;
  }

  downloadFile(result.response, fileName);
  dispatch(toggleMask(false));
};

export const getTicketBoardStatus = async (boardId, screenKey) => {
  const dispatch = Store().dispatch;
  const state = Store().getState();
  const boardStatus = getScreenData(
    state.screen,
    ['boardStatus', boardId],
    screenKey
  );
  if (!boardStatus) {
    const result = await TicketService.getTicketBoardStatus(boardId);
    if (!result.isSuccess) {
      const error = formatMessage(Locale.failed_get_board_status, {
        resultError: result.error
      });
      dispatch(
        setErrorScreenMessage(error, false, result.errorMessages, screenKey)
      );
      return;
    }

    return result;
  }
};

export const getBoardStatusCount = async (boardId, screenKey) => {
  const result = await TicketService.getBoardStatusCount(boardId);
  const dispatch = Store().dispatch;
  if (!result.isSuccess) {
    const error = formatMessage(Locale.failed_status_count, {
      resultError: result.error
    });
    dispatch(
      setErrorScreenMessage(error, false, result.errorMessages, screenKey)
    );
    return;
  }

  return result;
};

export const reopenClosedTicket = async () => {
  const state = Store().getState();
  const status = getScreenData(
    state.screen,
    'status',
    EDIT_VIEW_TICKET_DRAWER_ID
  );
  const boardId = getScreenData(
    state.screen,
    ['ticket', 'board', 'id'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );
  const boardStatus = getScreenData(
    state.screen,
    ['boardStatus', boardId],
    EDIT_VIEW_TICKET_DRAWER_ID
  );
  const ticket = getScreenData(
    state.screen,
    'ticket',
    EDIT_VIEW_TICKET_DRAWER_ID
  );
  const ticketJs = ticket ? ticket.toJS() : {};

  const boardStatusJS = boardStatus ? boardStatus.toJS() : {};
  if (boardStatusJS.emailConnectorAllowReopenClosedFlag) {
    const result = await TicketService.updateTicket(ticketJs.id, status);
    if (boardStatusJS.emailConnectorReopenResourcesFlag) {
      const resultStatus = await TicketService.getScheduleEntries(ticketJs.id);
      if (resultStatus.isSuccess && resultStatus.response.length > 0) {
        const updateMap = new Map();
        updateMap.set('doneFlag', false);
        resultStatus.response.forEach(async function (r) {
          await TicketService.updateScheduleEntry(r.id, updateMap);
        });
      }
    }

    return result;
  }
};

const attachmentSizeValidation = (attachments, drawer) => {
  const dispatch = Store().dispatch;
  let totalFileSize = 0.0;
  const allowedTotalFileSizeInMB = 50.0;
  const allowedFileSizeInMB = 10.0;
  const fileSizeFromKBToMB = 1048576;
  let isAttachmnentSizeValidationPassed = true;
  attachments.map(item => {
    const fileSize = item.size;
    const sizeInMB = parseFloat((fileSize / fileSizeFromKBToMB).toFixed(2));
    if (sizeInMB > allowedFileSizeInMB) {
      isAttachmnentSizeValidationPassed = false;
      const error = `${item.name} ${formatMessage(
        Locale.attachment_file_size_limit
      )} `;
      dispatch(setErrorScreenMessage(error, false, null, drawer));
      return isAttachmnentSizeValidationPassed;
    }

    totalFileSize += sizeInMB;
    return isAttachmnentSizeValidationPassed;
  });

  if (totalFileSize > allowedTotalFileSizeInMB) {
    isAttachmnentSizeValidationPassed = false;
    dispatch(
      setErrorScreenMessage(
        formatMessage(Locale.attachment_total_file_size_limit),
        false,
        null,
        drawer
      )
    );
    return isAttachmnentSizeValidationPassed;
  }

  return isAttachmnentSizeValidationPassed;
};

const duplicateAttachmentValidation = (attachments, drawer) => {
  const dispatch = Store().dispatch;
  let isDuplicate = false;
  if (attachments.length > 1) {
    const attachmentNameArr = attachments.map(item => {
      return item.name;
    });
    const attachmentNameSet = [...new Set(attachmentNameArr)];
    isDuplicate = attachmentNameSet.length !== attachmentNameArr.length;
    if (isDuplicate) {
      dispatch(
        setErrorScreenMessage(
          formatMessage(Locale.duplicate_attachments_error_msg),
          false,
          null,
          drawer
        )
      );
    }
  }

  return !isDuplicate;
};

export const validateAttachment = (value, drawer) => {
  return (
    duplicateAttachmentValidation(value, drawer) &&
    attachmentSizeValidation(value, drawer)
  );
};

export const addAttachment = async (
  attachments,
  dispatch,
  ticketId,
  screenKey
) => {
  const uploadAttachments = attachments.map(attachment => {
    const formData = new FormData();
    formData.append('files', attachment, attachment.name);
    return new Promise((resolve, reject) => {
      let ticketResult = TicketService.addAttachmentToTicket(
        ticketId,
        formData
      );
      if (ticketResult) {
        resolve(ticketResult);
      } else reject(new Error('error'));
    });
  });

  await Promise.all(uploadAttachments).then(function (results) {
    let errorMessage = '';
    results.map(result => {
      if (!result.isSuccess) {
        errorMessage = `${errorMessage}${result.error} <br/>`;
      }

      return errorMessage;
    });

    if (errorMessage) {
      dispatch(setErrorScreenMessage(errorMessage, true, null, screenKey));
      dispatch(toggleMask(false));
    }
  });
  return;
};

export const calculateDaysDiffBetweenDates = (date1, date2) => {
  const oneDayMillisec = 86400000;
  const dateDiffMillisec = Math.abs(date1 - date2);
  return Math.floor(dateDiffMillisec / oneDayMillisec);
};
