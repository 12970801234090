import React from 'react';
import * as core from 'cw-ui-core';
import cx from 'classnames';
import css from 'src/components/Drawer/AddLicenseDrawer/LicenseDrawerLoader/LicenseDrawerLoading.scss';

const LicenseDrawerLoading = () => {
  return (
    <core.Flexbox className={css.loadingComponent} column>
      <core.Flexbox className={css.titlePlaceholder} column>
        <core.Placeholder className={cx(css.titleLoader, css.loader)} />
        <core.Placeholder className={css.loader} />
      </core.Flexbox>

      <core.Flexbox className={css.contentPlaceholder} column>
        <core.Placeholder className={css.loader} />
        <core.Placeholder className={cx(css.halfWidthLoader, css.loader)} />

        <core.Placeholder className={cx(css.quantityLoader, css.loader)} />
        <input
          automationName="licenseQuantity"
          className={css.licenseQuantityDropDown}
          type="number"
          name="numberQuantity"
          disabled
        />

        <core.Placeholder className={cx(css.smallWidthLoader, css.loader)} />
        <core.Placeholder className={css.loader} />
        <core.Placeholder className={cx(css.smallWidthLoader, css.loader)} />
        <core.Placeholder className={css.loader} />
        <core.Placeholder className={cx(css.lastLoader, css.loader)} />
      </core.Flexbox>
    </core.Flexbox>
  );
};

export default LicenseDrawerLoading;
