import React from 'react';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import css from 'src/components/Admin/NewsPage/Banner/PublishBannerDialog.scss';
import Locale from 'src/Locale';
import { useEffect } from 'react';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import LoadingComponent from 'src/components/Loading/GenericLoadingComponent';
import cx from 'classnames';

export const PUBLISH_BANNER_DIALOG = 'PUBLISH_BANNER_DIALOG';

const PublishBannerLoadingComponent = () => {
  const columns = [
    {
      className: cx(css.flex, css.square),
      stackCount: 1
    }
  ];

  return (
    <LoadingComponent
      id="loading-component-1"
      columns={columns}
      parentClass={css.parentClass}
    />
  );
};

const PublishBannerDialog = props => {
  const {
    onCancel,
    onPublish,
    onMount,
    isSaving,
    selectedRegions,
    onRegionSelectionChanged,
    noOfRegionsSelected,
    isDefaultBanner
  } = props;
  let isLoading = props.isLoading;

  useEffect(() => {
    onMount();
  }, [onMount]);

  const PublishButton = () => {
    const button = () => (
      <core.ButtonStandard
        key="publish"
        automationName="publishBannerButton"
        value={formatMessage(Locale.publish_banner)}
        isSmall
        onClick={onPublish}
        className={css.button}
        disabled={props.disabledPublishButton}
      />
    );
    const LoadingButton = WithButtonLoadingIndicatorHOC(button);
    return (
      <LoadingButton
        isBusy={isSaving}
        wrapperStyle={{ height: '36px', width: '89px', marginLeft: '10px' }}
      />
    );
  };

  const regions = [
    { id: 'APAC', name: 'APAC' },
    { id: 'EMEA', name: 'EMEA' },
    { id: 'NA', name: 'NA' }
  ];

  const { formatMessage } = props.intl;
  const dialogButtons = () => {
    return [
      <core.ButtonLight
        key="cancel"
        automationName="banner-cancel"
        value={formatMessage(Locale.cancel)}
        onClick={onCancel}
        isSmall
      />,
      PublishButton()
    ];
  };

  const handleSelection = (selectedItems, allItems, changeEvent, filter) => {
    if (
      selectedItems[0] === -1 &&
      selectedItems.length !== 1 &&
      selectedItems.length !== allItems.length + 1
    ) {
      let localselectedItems = selectedItems;
      localselectedItems.splice(0, 1);
      changeEvent(localselectedItems);
    } else if (
      (selectedItems[0] === -1 && selectedItems.length === 1) ||
      selectedItems.length === allItems.length
    ) {
      if (
        selectedItems[0] !== allItems[0] &&
        selectedItems[0] === -1 &&
        filter.length !== 0 &&
        changeEvent.name === 'onMyWatchedIssuesSelectionChanged'
      ) {
        selectedItems.length = 0;
        changeEvent(selectedItems);
      } else {
        let localselectedItems = [-1];
        allItems.forEach(element => {
          localselectedItems.push(element.id);
        });
        changeEvent(localselectedItems);
      }
    } else if (
      selectedItems[0] === -1 &&
      selectedItems.length === 1 &&
      selectedItems[0] === allItems[0]
    ) {
      let localselectedItems = selectedItems.concat();
      localselectedItems.splice(0, 1);
      changeEvent(localselectedItems);
    } else {
      changeEvent(selectedItems);
    }
  };

  const handleRegionSelectionChange = selectedItems => {
    handleSelection(
      selectedItems,
      regions,
      onRegionSelectionChanged,
      selectedRegions
    );
  };

  return (
    <core.Dialog
      dialogId={PUBLISH_BANNER_DIALOG}
      className={css.dialog}
      includeCloseIcon
      header={formatMessage(Locale.publish_banner)}
      automationName="publish-banner-dialog"
      buttons={dialogButtons()}
      onCloseIconClicked={onCancel}
    >
      <p>
        {isDefaultBanner
          ? formatMessage(Locale.publish_default_banner_confirmation)
          : formatMessage(Locale.publish_banner_confirmation)}
      </p>

      <core.Label
        className={css.labels}
        value={core.formatMessage(Locale.select_banner_region)}
      />
      {isLoading ? (
        <PublishBannerLoadingComponent />
      ) : (
        <>
          <core.Label
            automationName="selectRegionLabel"
            value={formatMessage(Locale.number_of_regions_selected, {
              noOfItems: noOfRegionsSelected
            })}
            className={css.regionLabel}
            isLink
          />
          <core.DynamicMultiSelect
            automationName="regionFilter"
            items={regions}
            onChange={handleRegionSelectionChange}
            value={selectedRegions}
            placeholder={formatMessage(Locale.select_banner_region)}
            selectAllText={formatMessage(Locale.select_all)}
            allPillText="All"
          />
        </>
      )}
    </core.Dialog>
  );
};

export default injectIntl(PublishBannerDialog);
