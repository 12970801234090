import React from 'react';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import css from './RetryPayment.scss';

const RetryPayment = props => {
  return (
    <core.Flexbox className={css.container}>
      <h2 className={css.retryPaymentTitle}>
        {core.formatMessage(Locale.retry_payment_title)}
      </h2>
      <core.Image className={css.image} src="/images/Billing/credit-card.svg" />
      <p className={css.messageContent}>
        {core.formatMessage(Locale.retry_payment_message)}
      </p>
      <core.ButtonStandard
        className={css.btn}
        value={core.formatMessage(Locale.ok)}
        onClick={props.onCloseIconClicked}
      />
    </core.Flexbox>
  );
};

export default RetryPayment;
