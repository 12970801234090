import React, { useState, useEffect } from 'react';
import { Image, IconPath, getInitials, Placeholder, formatMessage } from 'cw-ui-core';
import cx from 'classnames';
import Locale from 'src/Locale';
import css from './SupportTicketNotesSection.scss';
import loadingCss from './SupportTicketNotesLoading.scss';

export const FetchImageState = {
  RUNNING: 'running',
  FAILED: 'failed',
  COMPLETED: 'completed'
};

const SupportTicketNotesMemberImage = props => {
  const { note, getMemberImage, memberImage, memberImageState } = props;

  useEffect(() => {
    const doWork = async () => {
      if (note.memberId > 0) {
        await getMemberImage(note.memberId);
      }
    };

    return () => {
      doWork().then();
    };
    // eslint-disable-next-line
  }, []);

  const [isUrlValid, setIsUrlValid] = useState(true);
  const getDisplayIcon = () => {
    const memberImageUrl = memberImageState && memberImageState === FetchImageState.COMPLETED ? URL.createObjectURL(memberImage.image) : "";
    const initialsDiv = (
      <div className={cx(css.initials, css.externalName)}>
        {getInitials(note.name)}
      </div>
    );
    if (note.isAttachment) {
      return (
        <Image
          className={css.attachmentIcon}
          src={IconPath.get('Action_Attach')}
        />
      );
    } else if (note.memberId > 0 && (!note.contactId || note.contactId < 0)) {
      if (memberImageUrl && isUrlValid) {
        return (
          <img
            alt={formatMessage(Locale.member_image)}
            className={css.memberImage}
            src={memberImageUrl}
            onerror={setIsUrlValid(false)}
          />
        );
      } else if (
        (memberImageState === FetchImageState.FAILED) || !memberImageUrl
      ) {
        return initialsDiv;
      } else {
        return <Placeholder className={loadingCss.initials} />;
      }
    } else {
      return initialsDiv;
    }
  };

  return getDisplayIcon();
};

export default SupportTicketNotesMemberImage;
