import React from 'react';
import * as core from 'cw-ui-core';
import css from './NotFoundPage.scss';
import { connect } from 'react-redux';
import Locale from 'src/Locale';

class NotFoundPage extends React.Component {
  componentDidMount() {
    this.props.onDidMount();
  }

  render() {
    return (
      <div className={css.content}>
        <core.Image
          className={css.image}
          src="/images/pagenotfound.png"
          isInlineSVG={false}
          alt={core.formatMessage(Locale.page_not_found)}
        />
        <core.Label
          className={css.title}
          value={this.props.title || "Hmm... We can't seem to locate this page"}
        />
        <core.Label
          className={css.subtitle}
          grey2
          value={this.props.subtitle || 'Please check the URL'}
        />
        {this.props.includeHome ? (
          <core.ButtonStandard
            automationName="take_me_home"
            className={css.btn}
            value={this.props.btnText}
            onClick={this.props.onClick}
          />
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = () => {
  return {
    onDidMount: () => {
      core.runSaga(
        core.addNavigation,
        'not_found_page',
        undefined,
        window.location.href
      ); // log error page navigation
    }
  };
};

export default connect(undefined, mapDispatchToProps)(NotFoundPage);
