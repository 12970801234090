import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import Wallet from './Wallet';
import { setSelectedCard } from 'src/components/Wallet/CustomerWalletService';
import Routes from 'src/services/Routes';
import { isCompanyNotVerified } from 'src/services/Partners/PartnersService';

const mapStateToProps = state => {
  const customerWallet = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'customerWallet'
  ]);
  return {
    customerWallet: customerWallet ? customerWallet.toJS() : [],
    selectedCardId: core.getScreenData(state.screen, [
      Routes.SHOPPING_CART.id,
      'selectedCard',
      'ID'
    ]),
    allowAddNewCard: !isCompanyNotVerified()
  };
};

const mapDispatchToProps = () => {
  return {
    onCardSelected: async card => {
      await setSelectedCard(card);
    }
  };
};

const WalletContainer = connect(mapStateToProps, mapDispatchToProps)(Wallet);

export default WalletContainer;
