import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import {
  addBanner,
  loadBanners,
  removeScreenMessages,
  toObject
} from 'src/services/Banner/BannerService';
import BannerDrawer from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawer';
import Routes from 'src/services/Routes';
import { put } from 'redux-saga/effects';
import { showNotificationBeforeClose } from 'src/services/DrawerNotification/DrawerNotification';
import { validateBannerDrawer } from 'src/services/Banner/BannerService';
import getPreviewArgs from 'src/services/Banner/BannerPreviewService';
import { BANNER_PREVIEW_DIALOG_ID } from 'src/components/Admin/NewsPage/Banner/BannerPreviewDialog/BannerPreviewDialogContainer';
import Locale from 'src/Locale';

export const BANNER_DRAWER = 'BANNER_DRAWER';

const mapStateToProps = () => {
  const state = core.Store().getState();
  const createdBannerId = core.getScreenData(
    state.screen,
    ['bannerId'],
    BANNER_DRAWER
  );

  const isBannerEdit = core.getScreenData(
    state.screen,
    ['bannerEdit'],
    BANNER_DRAWER
  );

  const firstBanner = core.getScreenCustom(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'firstBanner'
  ]);

  const secondBanner = core.getScreenCustom(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'secondBanner'
  ]);

  const thirdBanner = core.getScreenCustom(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'thirdBanner'
  ]);

  const fourthBanner = core.getScreenCustom(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'fourthBanner'
  ]);

  const primaryColor = core.getScreenData(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'primaryColor'
  ]);

  const primaryButtonTextColor = core.getScreenData(state.screen, [
    Routes.BANNER_CONFIGURATION.id,
    'primaryButtonTextColor'
  ]);

  const toggle = core.getScreenData(
    state.screen,
    ['isToggleEnabled'],
    BANNER_DRAWER
  );

  return {
    toggle,
    createdBannerId,
    isBannerEdit,
    primaryColor: primaryColor ? [{ name: primaryColor }] : undefined,
    primaryButtonTextColor: primaryButtonTextColor
      ? [{ name: primaryButtonTextColor }]
      : undefined,
    firstBanner: firstBanner ? firstBanner.toJS() : undefined,
    firstBannerFileName: core.getScreenData(state.screen, [
      Routes.BANNER_CONFIGURATION.id,
      'firstBannerFileName'
    ]),
    firstBannerUrl: core.getScreenData(state.screen, [
      Routes.BANNER_CONFIGURATION.id,
      'firstBannerUrl'
    ]),
    secondBanner: secondBanner ? secondBanner.toJS() : undefined,
    secondBannerFileName: core.getScreenData(state.screen, [
      Routes.BANNER_CONFIGURATION.id,
      'secondBannerFileName'
    ]),
    secondBannerUrl: core.getScreenData(state.screen, [
      Routes.BANNER_CONFIGURATION.id,
      'secondBannerUrl'
    ]),
    thirdBanner: thirdBanner ? thirdBanner.toJS() : undefined,
    thirdBannerFileName: core.getScreenData(state.screen, [
      Routes.BANNER_CONFIGURATION.id,
      'thirdBannerFileName'
    ]),
    thirdBannerUrl: core.getScreenData(state.screen, [
      Routes.BANNER_CONFIGURATION.id,
      'thirdBannerUrl'
    ]),
    fourthBanner: fourthBanner ? fourthBanner.toJS() : undefined,
    fourthBannerFileName: core.getScreenData(state.screen, [
      Routes.BANNER_CONFIGURATION.id,
      'fourthBannerFileName'
    ]),
    fourthBannerUrl: core.getScreenData(state.screen, [
      Routes.BANNER_CONFIGURATION.id,
      'fourthBannerUrl'
    ])
  };
};

function* onWillMount() {
  // have isReady on store instead of state bc needs latest dispatched state
  yield put(
    core.setScreenCustom([Routes.BANNER_CONFIGURATION.id, 'isReady'], true)
  );
}

const mapDispatchToProps = dispatch => {
  const isUrlInvalid = () => {
    const state = core.Store().getState();
    const toggle = core.getScreenData(
      state.screen,
      ['isToggleEnabled'],
      BANNER_DRAWER
    );
    if (toggle) {
      const isValid = core.getScreenInvalid(state.screen, [
        Routes.BANNER_CONFIGURATION.id,
        'bannerCTAButtonUrl'
      ]);
      if (isValid !== undefined) {
        return true;
      }
    }

    return false;
  };

  const validateImageResolutions = (value, name, maxWidth, maxHeight) => {
    dispatch(core.removeScreenInvalid([Routes.BANNER_CONFIGURATION.id, name]));
    const file = value[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = e => {
        const text = e.target.result;
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(text, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');
        if (svgElement) {
          let width = svgElement.getAttribute('width');
          let height = svgElement.getAttribute('height');
          if (!width || !height) {
            const viewBox = svgElement.getAttribute('viewBox');
            if (viewBox) {
              const [viewBoxWidth, viewBoxHeight] = viewBox
                .split(' ')
                .splice(2, 2)
                .map(Number);
              width = viewBoxWidth.toString();
              height = viewBoxHeight.toString();
            }
          }

          if (width !== maxWidth || height !== maxHeight) {
            dispatch(
              core.setScreenInvalid(
                [Routes.BANNER_CONFIGURATION.id, name],
                core.formatMessage(Locale.invalid_image_resolution)
              )
            );
          }
        }
      };

      reader.readAsText(file);
    }
  };

  return {
    onWillMount: () => {
      core.runSaga(onWillMount);
    },
    onCloseIconClicked: () => {
      const state = core.Store().getState();
      const pageUpdates = toObject(
        core.getScreenUpdated(state.screen, [Routes.BANNER_CONFIGURATION.id])
      );
      //Excluding banner preview from is-dirty check. Because we are not displaying the unsaved data warning on banner preview.
      const isFormDirty = Object.keys(pageUpdates).length > 0;

      if (isFormDirty) {
        showNotificationBeforeClose(BANNER_DRAWER);
      } else {
        dispatch(core.hideDrawer(BANNER_DRAWER));
      }
    },
    onCancelClick: () => {
      const state = core.Store().getState();
      const pageUpdates = toObject(
        core.getScreenUpdated(state.screen, [Routes.BANNER_CONFIGURATION.id])
      );
      //Excluding banner preview from is-dirty check. Because we are not displaying the unsaved data warning on banner preview.
      const isFormDirty = Object.keys(pageUpdates).length > 0;

      if (isFormDirty) {
        showNotificationBeforeClose(BANNER_DRAWER);
      } else {
        dispatch(core.hideDrawer(BANNER_DRAWER));
      }
    },
    onBannerChange: isChanged => {
      dispatch(core.setScreenData('isDrawerUpdated', isChanged, BANNER_DRAWER));
    },
    onCreateBannerClick: async () => {
      let violations = validateBannerDrawer();
      let isUrlValid = isUrlInvalid();
      if (violations.size || isUrlValid) {
        dispatch(
          core.setScreenInvalid(Routes.BANNER_CONFIGURATION.id, violations)
        );
        isUrlValid &&
          dispatch(
            core.setScreenInvalid(
              [Routes.BANNER_CONFIGURATION.id, 'bannerCTAButtonUrl'],
              core.formatMessage(Locale.wrong_call_to_action_url)
            )
          );
      } else {
        const state = core.Store().getState();
        let bannerDetails = core.getScreenData(state.screen, [
          Routes.BANNER_CONFIGURATION.id
        ]);
        bannerDetails = bannerDetails ? bannerDetails.toJS() : null;

        const result = await addBanner(bannerDetails);
        if (result) {
          dispatch(core.removeScreenCustom([Routes.BANNER_CONFIGURATION.id]));
          dispatch(core.removeScreenData([Routes.BANNER_CONFIGURATION.id]));
          dispatch(core.removeScreenInvalid([Routes.BANNER_CONFIGURATION.id]));
          dispatch(core.removeScreenUpdated([Routes.BANNER_CONFIGURATION.id]));
          dispatch(core.hideDrawer(BANNER_DRAWER));
          removeScreenMessages(Routes.BANNER_CONFIGURATION.id);
          await loadBanners();
        }
      }
    },
    onFirstBannerChanged: value => {
      const name = "firstBannerFileName";
      let maxWidth = "320";
      let maxHeight = "371";
      validateImageResolutions(value, name, maxWidth, maxHeight);
      dispatch(
        core.setScreenCustom(
          [Routes.BANNER_CONFIGURATION.id, 'firstBanner'],
          value
        )
      );
      dispatch(
        core.fieldEditUpdate(
          [Routes.BANNER_CONFIGURATION.id, 'firstBannerFileName'],
          value && value.length > 0 ? value[0].name : undefined
        )
      ); // update file name
      dispatch(
        core.fieldEditUpdate(
          [Routes.BANNER_CONFIGURATION.id, 'firstBannerUrl'],
          undefined
        )
      ); // remove firstBannerUrl anytime banner image is uploaded/removed on UI
    },
    onSecondBannerChanged: value => {
      const name = "secondBannerFileName";
      let maxWidth = "768";
      let maxHeight = "371";
      validateImageResolutions(value, name, maxWidth, maxHeight);
      dispatch(
        core.setScreenCustom(
          [Routes.BANNER_CONFIGURATION.id, 'secondBanner'],
          value
        )
      );
      dispatch(
        core.fieldEditUpdate(
          [Routes.BANNER_CONFIGURATION.id, 'secondBannerFileName'],
          value && value.length > 0 ? value[0].name : undefined
        )
      ); // update file name
      dispatch(
        core.fieldEditUpdate(
          [Routes.BANNER_CONFIGURATION.id, 'secondBannerUrl'],
          undefined
        )
      ); // remove secondBannerUrl anytime banner image is uploaded/removed on UI
    },
    onThirdBannerChanged: value => {
      const name = "thirdBannerFileName";
      let maxWidth = "1000";
      let maxHeight = "320";
      validateImageResolutions(value, name, maxWidth, maxHeight);
      dispatch(
        core.setScreenCustom(
          [Routes.BANNER_CONFIGURATION.id, 'thirdBanner'],
          value
        )
      );
      dispatch(
        core.fieldEditUpdate(
          [Routes.BANNER_CONFIGURATION.id, 'thirdBannerFileName'],
          value && value.length > 0 ? value[0].name : undefined
        )
      ); // update file name
      dispatch(
        core.fieldEditUpdate(
          [Routes.BANNER_CONFIGURATION.id, 'thirdBannerUrl'],
          undefined
        )
      ); // remove thirdBannerUrl anytime banner image is uploaded/removed on UI
    },
    onForthBannerChanged: value => {
      const name = "fourthBannerFileName";
      let maxWidth = "1384";
      let maxHeight = "320";
      validateImageResolutions(value, name, maxWidth, maxHeight);
      dispatch(
        core.setScreenCustom(
          [Routes.BANNER_CONFIGURATION.id, 'fourthBanner'],
          value
        )
      );
      dispatch(
        core.fieldEditUpdate(
          [Routes.BANNER_CONFIGURATION.id, 'fourthBannerFileName'],
          value && value.length > 0 ? value[0].name : undefined
        )
      ); // update file name
      dispatch(
        core.fieldEditUpdate(
          [Routes.BANNER_CONFIGURATION.id, 'fourthBannerUrl'],
          undefined
        )
      ); // remove fourthBannerUrl anytime banner image is uploaded/removed on UI
    },
    onPrimaryColorUploadClicked: () => {
      dispatch(core.setScreenData('isColorUploadClicked', true, BANNER_DRAWER));
      dispatch(core.showDialog('BRAND_COLOR_DIALOG'));
    },
    onPrimaryButtonTextColorUploadClicked: () => {
      dispatch(
        core.setScreenData('isColorUploadClicked', false, BANNER_DRAWER)
      );
      dispatch(core.showDialog('BRAND_COLOR_DIALOG'));
    },
    onPrimaryColorChanged: value => {
      dispatch(
        core.fieldEditUpdate(
          [Routes.BANNER_CONFIGURATION.id, 'primaryColor'],
          value && value.length > 0 ? value[0].name : undefined
        )
      );
    },
    onPrimaryButtonTextColorChanged: value => {
      dispatch(
        core.fieldEditUpdate(
          [Routes.BANNER_CONFIGURATION.id, 'primaryButtonTextColor'],
          value && value.length > 0 ? value[0].name : undefined
        )
      );
    },
    onPreviewClick: () => {
      let violations = validateBannerDrawer();
      let isUrlValid = isUrlInvalid();
      if (violations.size || isUrlValid) {
        dispatch(
          core.setScreenInvalid(Routes.BANNER_CONFIGURATION.id, violations)
        );
        isUrlValid &&
          dispatch(
            core.setScreenInvalid(
              [Routes.BANNER_CONFIGURATION.id, 'bannerCTAButtonUrl'],
              core.formatMessage(Locale.wrong_call_to_action_url)
            )
          );
      } else {
        const args = getPreviewArgs();
        dispatch(core.showDialog(BANNER_PREVIEW_DIALOG_ID, args));
      }
    },
    onCTAUrlChange: url => {
      dispatch(
        core.removeScreenInvalid([
          Routes.BANNER_CONFIGURATION.id,
          'bannerCTAButtonUrl'
        ])
      );
      const pattern =
        /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
      url &&
        !pattern.test(url) &&
        dispatch(
          core.setScreenInvalid(
            [Routes.BANNER_CONFIGURATION.id, 'bannerCTAButtonUrl'],
            core.formatMessage(Locale.wrong_call_to_action_url)
          )
        );
    }
  };
};

const BannerDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BannerDrawer);

export default BannerDrawerContainer;