import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerSuccessDrawer/PartnerSuccessManagerDrawer.scss';
import Locale from 'src/Locale';
import { Steps } from 'src/services/SuccessManagerService';

const DrawerFooter = props => {
  return (
    <core.Flexbox>
      {props.currentStep === Steps.Step_1 && (
        <div className={css.buttonContainer}>
          <core.Label
            className={css.createTicketLink}
            automationName="openSupportTicketDrawer"
            value={core.formatMessage(Locale.product_support)}
            isLink
            onClick={() => {
              props.onCreateTicketClick();
            }}
          />
        </div>
      )}
    </core.Flexbox>
  );
};

export default DrawerFooter;
