import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/NotificationBeforeClose.scss';
import flexCss from 'src/styles/flex.scss';
import Locale from 'src/Locale';
import cx from 'classnames';

const NotificationBeforeClose = props => {
  return props.showNotificationBeforeClose ? (
    <core.Notification
      message={
        <core.Flexbox className={cx(flexCss.spaceBetween, css.container)}>
          <core.Label
            className={css.closingText}
            value={core.formatMessage(Locale.on_before_close_notification)}
          />
          <core.Flexbox className={css.closingButtonContainer}>
            <core.Label
              className={css.closingButton}
              onClick={() => {
                props.onNotificationStayClick(props.drawerScreenKey);
              }}
              value={core.formatMessage(Locale.stay)}
              isLink
            />
            <core.Label
              className={css.closingButton}
              onClick={() => {
                props.onNotificationCloseClick(props.drawerScreenKey);
              }}
              value={core.formatMessage(Locale.close)}
              isLink
            />
          </core.Flexbox>
        </core.Flexbox>
      }
      statusIcon={core.IconPath.get('Tile_Alerts')}
      status={core.NotificationStatus.ERROR}
      closeIcon=""
    />
  ) : null;
};

export default core.MobileLayoutHOC(NotificationBeforeClose);
