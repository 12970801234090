import React, { useState } from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Breadcrumbs/Breadcrumbs.scss';
import cx from 'classnames';

const Breadcrumbs = ({
  breadcrumbs,
  separator,
  showFullBreadcrumb,
  lastBreadcrumbName,
  onBreadcrumbClick,
  breadcrumbCustomClasses
}) => {
  const [breadCrumbExpanded, setBreadCrumbExpanded] = useState(false);

  const breadCrumbseparator = separator ? separator : '/';

  return (
    <core.Flexbox className={css.breadcrumbs}>
      {showFullBreadcrumb || breadCrumbExpanded ? (
        breadcrumbs.map((breadcrumbItem, index) => (
          <span key={breadcrumbItem.name}>
            <label
              className={cx(
                css.breadcrumblist,
                breadcrumbCustomClasses && breadcrumbCustomClasses[index]
              )}
              onClick={() => onBreadcrumbClick(index)}
            >
              {breadcrumbItem.name}
            </label>
            {(index < breadcrumbs.length - 1 ||
              (index === breadcrumbs.length - 1 && lastBreadcrumbName)) && (
              <span className={css.separator}>{breadCrumbseparator}</span>
            )}
            {index === breadcrumbs.length - 1 && lastBreadcrumbName && (
              <span className={css.colorTextPrimary}>{lastBreadcrumbName}</span>
            )}
          </span>
        ))
      ) : (
        <>
          <label
            className={cx(
              css.breadcrumblist,
              breadcrumbCustomClasses && breadcrumbCustomClasses[0]
            )}
            onClick={() => onBreadcrumbClick(0)}
          >
            {breadcrumbs[0] && breadcrumbs[0].name}
          </label>
          <span className={css.ellipsisSeparator}>{breadCrumbseparator}</span>
          <core.Image
            src="/images/more.svg"
            onClick={() => setBreadCrumbExpanded(true)}
          />

          {lastBreadcrumbName && (
            <>
              <span className={css.ellipsisSeparator}>
                {breadCrumbseparator}
              </span>
              <p className={css.colorTextPrimary}>{lastBreadcrumbName}</p>
            </>
          )}
        </>
      )}
    </core.Flexbox>
  );
};

export default core.MobileLayoutHOC(Breadcrumbs);
