import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import UserAccountPopover from 'src/components/UserAccount/UserAccountPopover';
import { UPDATE_PROFILE_DRAWER } from 'src/components/Drawer/UserProfileDrawer/UserProfileDrawer';
import { MOBILE_NAVIGATION_MENU_DRAWER } from 'src/components/Navigation/TopNavigationContentContainer';
import history from 'src/services/History';
import Routes from 'src/services/Routes';
import {
  POLICIES,
  getUserSecurityPoliciesDisplayText
} from 'src/services/Authorization';
import HasAccess from 'src/services/RoleAccess';
import { COMPANY_ACCOUNT_SETTING_DRAWER } from 'src/components/Drawer/UserProfileDrawer/CompanyAccountSetting/CompanyAccountSettingContainer';
import { openGlobalPreferenceInNewTab } from 'src/components/UserAccount/GlobalPreference';
import {
  switchAccount,
  returnToPrimaryAccount
} from 'src/components/UserAccount/SwitchAccount';

const UserAccountComponent = props => {
  return (
    <UserAccountPopover
      avatarStyle={props.avatarStyle}
      initials={props.initials}
      name={props.name}
      email={props.email}
      hasPopup={props.hasPopup}
      items={props.items}
      onMenuItemClick={props.onMenuItemClick}
      label={props.label}
      showProfileDrawer={props.showProfileDrawer}
      companyName={props.companyName}
      role={props.role}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const firstName = core.getUserFirstName(state.oidc);
  const lastName = core.getUserLastName(state.oidc);
  const name = core.getUserName(state.oidc);
  const email = core.getUserEmail(state.oidc);
  const path = window.location.pathname;
  const showProfileDrawer = path && path === Routes.PROFILE.path ? true : false;
  let companyName = core.getModuleCache(state.module, [
    'company',
    'companyName'
  ]);

  const partnerCompanyName = core.getModuleCache(state.module, [
    'partner',
    'companyName'
  ]);

  const childAccountCompanyName = core.getModuleCache(state.module, [
    'childAccountCompanyName'
  ]);

  if (companyName === undefined || companyName.length === 0) {
    companyName = partnerCompanyName;
  }

  const user = core.getUser(state.oidc);
  const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
  if (isGuestUserLoggedIn) {
    companyName = childAccountCompanyName;
  }

  const companyNameToProps = HasAccess(null, [
    POLICIES.NO_COMPANY_CONTACT,
    POLICIES.NO_COMPANY
  ])
    ? companyName
    : '';

  const validCompanyRoles = HasAccess(
    [POLICIES.ADMIN, POLICIES.USER_MANAGEMENT],
    null
  );
  let profileItems = ownProps.items;
  if (!validCompanyRoles) {
    profileItems = profileItems.filter(
      profile => profile.id !== 'companyAccountSetting'
    );
  }

  return {
    hasPopup: ownProps.hasPopup,
    initials:
      !firstName && !lastName
        ? core.getInitials(name)
        : core.getInitialsFromFirstLastName(firstName, lastName),
    name,
    email,
    items: profileItems,
    avatarStyle: ownProps.avatarStyle,
    showProfileDrawer,
    companyName: companyNameToProps,
    role: getUserSecurityPoliciesDisplayText()
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onMenuItemClick: menuId => {
      switch (menuId) {
        case 'logout':
          history().push('/logout');
          break;
        case 'updateProfile':
          !ownProps.hasPopup &&
            dispatch(core.hideDrawer(MOBILE_NAVIGATION_MENU_DRAWER));
          dispatch(core.showDrawer(UPDATE_PROFILE_DRAWER, {}));
          break;
        case 'updateEmailPreferences':
          openGlobalPreferenceInNewTab();
          break;
        case 'companyAccountSetting':
          dispatch(core.showDrawer(COMPANY_ACCOUNT_SETTING_DRAWER));
          break;
        case 'switchAccount':
          switchAccount();
          break;
        case 'returnToPrimaryAccount':
          returnToPrimaryAccount();
          break;
        default:
          break;
      }
    }
  };
};

UserAccountComponent.propTypes = {
  hasPopup: PropTypes.bool.isRequired,
  items: PropTypes.array,
  avatarStyle: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UserAccountComponent));
