import BaseService from '../BaseApiService';

class PartnerBillingService extends BaseService {
    _partnerBillingApiUrl;
    _userBillingApiUrl;

    apiUrl() {
      if (!this._partnerBillingApiUrl) {
        this._partnerBillingApiUrl = `${this.getBaseUrlFromConfig("billing")}/api/partner_billing`;
      }

      return this._partnerBillingApiUrl;
    }

    userBillingApiUrl() {
      if (!this._userBillingApiUrl) {
        this._userBillingApiUrl = `${this.getBaseUrlFromConfig("billing")}/api/user_billing_info`;
      }

      return this._userBillingApiUrl;
    }

    getUserBillingAccess() {
        return this.post(`${this.apiUrl()}/dummy/getUserBillingAccess`);
    }

    getPartnerBillingInfo() {
        return this.post(`${this.apiUrl()}/dummy/getPartnerBillingInfo`);
    }

    getBillingSettings = () => this.post(`${this.userBillingApiUrl()}/dummy/getBillingSettings`)

    setBillingNotifications = isEnabled => this.post(`${this.userBillingApiUrl()}/dummy/enableBillingNotifications`, {'enable': isEnabled})
}

export default new PartnerBillingService();
