import * as core from 'cw-ui-core';
import { CwLog, setScreenData, getScreenData, Store } from 'cw-ui-core';
import linkHeaderParser from 'parse-link-header';
import Locale from 'src/Locale';
import InvoiceService from 'src/services/Api/HomeApi/InvoiceService';
import WalletService from 'src/services/Api/BillingApi/WalletService';
import { isCreditCardExpired } from 'src/services/Wallet/WalletService';
import Routes from 'src/services/Routes';

export const defaultPageSize = 10;
export const manageInvoiceStatuses = {
  PaymentPending: 'Payment Pending',
  Processing: 'Processing',
  ReadyForGp: 'Ready for GP',
  SentToGp: 'Sent to GP',
  Closed: 'Closed'
};
export const defaultParams = {
  pageIndex: 0,
  conditions: `invoiceNumber like "IN%"`,
  orderBy: 'date DESC',
  pageSize: defaultPageSize
};

export const INVOICE_FILTERS = [
  {
    id: 'allInvoices',
    name: 'All Invoices',
    condition: defaultParams.conditions
  },
  {
    id: 'paid',
    name: 'Paid',
    condition: `invoiceNumber like "IN%" and Balance=0`
  },
  {
    id: 'paymentFailed',
    name: 'Payment Failed',
    condition: `Status/Name in ("${manageInvoiceStatuses.PaymentPending}" ,"${manageInvoiceStatuses.ReadyForGp}","${manageInvoiceStatuses.SentToGp}", "${manageInvoiceStatuses.Closed}") and balance > 0 and invoiceNumber like "IN%"`
  }
];

export const ePayInvoiceFilters = [
  {
    id: 'allInvoices',
    name: 'All Invoices'
  },
  {
    id: 'outstanding',
    name: 'Outstanding'
  },
  {
    id: 'paid',
    name: 'Paid'
  },
  {
    id: 'partialPayment',
    name: 'Partial Payment'
  },
  {
    id: 'pastDue',
    name: 'Past Due'
  }
];

export const InvoiceTab = {
  EPAY: 'ePayInvoices',
  CONNECTWISE: 'connectWiseInvoices'
};

export const loadInvoiceList = async (params = {}) => {
  const dispatch = Store().dispatch;
  const state = Store().getState();
  let existingParams =
    getScreenData(state.screen, ['params']) || Map(defaultParams);
  existingParams = existingParams.toJS();
  const { conditions: oldConditions } = existingParams;
  const mergedParams = { ...existingParams, ...params };
  var { pageIndex, pageSize, conditions, orderBy, search } = mergedParams;
  dispatch(setScreenData(['isManageInvoicesReady'], false));
  dispatch(core.removeAllScreenMessages(Routes.BILLING_PAGE.id));
  let isCleanData = false;
  if (params.search) {
    conditions =
      conditions +
      (params.search ? ` and InvoiceNumber like "*${params.search}*"` : '');
  } else {
    conditions =
      conditions + (search ? ` and InvoiceNumber like "*${search}*"` : '');
  }

  if (oldConditions !== conditions) {
    isCleanData = true;
  }

  let invoices = getScreenData(state.screen, ['invoices']);
  invoices = isCleanData ? invoices.clear() : invoices;
  try {
    const result = await InvoiceService.getInvoiceList(
      conditions,
      orderBy,
      pageIndex + 1,
      pageSize
    );
    const link = result.headers.get('Link');
    const count = result.headers.get('X-Total-Count');
    const linkParsed = linkHeaderParser(link);
    const sortedInvoices = result.response.sort((x, y) => {
      if (
        x.status === manageInvoiceStatuses.PaymentPending &&
        y.status !== manageInvoiceStatuses.PaymentPending
      ) {
        return -1;
      }

      if (
        x.status !== manageInvoiceStatuses.PaymentPending &&
        y.status === manageInvoiceStatuses.PaymentPending
      ) {
        return 1;
      }

      if (x.dueDate && y.dueDate) {
        const xDueDate = Date.parse(x.dueDate);
        const yDueDate = Date.parse(y.dueDate);

        if (xDueDate > yDueDate) {
          return -1;
        }

        if (xDueDate < yDueDate) {
          return 1;
        }
      }

      return 0;
    });
    let updatedInvoices = invoices.set(pageIndex, sortedInvoices);
    let pageCount = 1;
    if (linkParsed) {
      if (linkParsed.last) {
        pageCount = parseInt(linkParsed.last.page);
      } else if (linkParsed.prev) {
        pageCount = pageIndex + 1;
      }
    }

    dispatch(setScreenData(['invoices'], updatedInvoices));
    var manageInvoicePaginationData = {
      manageTotalCount: parseInt(count),
      managePageCount: pageCount
    };
    dispatch(
      setScreenData(
        ['manageInvoicePaginationData'],
        manageInvoicePaginationData
      )
    );
    dispatch(setScreenData(['params'], mergedParams));
    const invoiceObj = sortedInvoices.find(x => x.status === manageInvoiceStatuses.PaymentPending);
    if (invoiceObj) {
      const results = await WalletService.retrieve();
      let customerWallet = results.response.msg;
      let isExpiredCard = false;
      const defaultCard = customerWallet.find(x=> x.IsDefaultCard === true);
      if (defaultCard) {
        isExpiredCard = isCreditCardExpired(defaultCard.ExpDate.substr(0,2), defaultCard.ExpDate.substr(-2), new Date());
      }

      let message;
      if (isExpiredCard) {
        message = core.formatMessage(Locale.default_expired_card_failed_message, {
          cardLastFourDigit: defaultCard.Account.substr(-4)});
      }
      else {
        message = core.formatMessage(Locale.payment_failed_default_message);
      }

      dispatch(
        core.setErrorScreenMessage(
          message,
          false,
          null,
          Routes.BILLING_PAGE.id
        )
      );
    }
  } catch (e) {
    CwLog.error('Unable to get invoices for pageIndex ' + pageIndex);
  }

  dispatch(setScreenData(['isManageInvoicesReady'], true));
};

const searchInvoicesList = (list, value) => {
  return list.filter(u => u.invoiceNumber.indexOf(value) > -1);
};

const filterInvoicesList = (list, value) => {
  const ePayInvoices = core.getScreenData(core.Store().getState().screen, [
    'epayInvoicesLoaded'
  ]);
  const totalEPayInvoices = ePayInvoices ? ePayInvoices.toJS() : [];
  var todayDateTime = new Date();
  const today =
    todayDateTime.getFullYear() +
    '-' +
    (todayDateTime.getMonth() + 1) +
    '-' +
    todayDateTime.getDate();
  let filteredInvoicesList = [];
  if (value === 'paid') {
    filteredInvoicesList = list.filter(u => u.balanceDue === '0.00');
  } else if (value === 'pastDue') {
    filteredInvoicesList = list.filter(
      u => u.balanceDue > 0 && u.dueOn < today
    );
  } else if (value === 'allInvoices') {
    filteredInvoicesList = totalEPayInvoices;
  } else if (value === 'outstanding') {
    filteredInvoicesList = list.filter(
      u => u.balanceDue === u.amount && u.dueOn >= today
    );
  } else if (value === 'partialPayment') {
    filteredInvoicesList = list.filter(
      u =>
        u.balanceDue !== '0.00' && u.amount !== u.balanceDue && u.dueOn >= today
    );
  } else {
    filteredInvoicesList = totalEPayInvoices;
  }

  return filteredInvoicesList;
};

export const getPaginationData = (ePayInvoices, pageindex = 0, pageSize) => {
  Store().dispatch(setScreenData(['ePayInvoices'], ePayInvoices));
  const ePayPageSize = pageSize ? pageSize : 10;
  const totalCount = ePayInvoices.length;
  const currentPageIndex = pageindex ? pageindex : 0;
  const pageCount = Math.ceil(totalCount / ePayPageSize);
  const arraySpliceIndex =
    currentPageIndex === 0 ? 0 : currentPageIndex * 10 + 1;
  const filteredInvoicesToShow = ePayInvoices.splice(arraySpliceIndex, 10);
  var paginationData = {
    ePayTotalCount: totalCount,
    ePayCurrentPageIndex: currentPageIndex,
    ePayPageCount: pageCount
  };
  Store().dispatch(setScreenData(['ePayPaginationData'], paginationData));
  Store().dispatch(
    setScreenData(['ePayInvoicesFiltered'], filteredInvoicesToShow)
  );
};

export const loadEPayInvoiceList = async () => {
  const dispatch = Store().dispatch;
  const state = Store().getState();
  const ePayInvoicesLoaded = getScreenData(state.screen, [
    'epayInvoicesLoaded'
  ]);
  try {
    if (!ePayInvoicesLoaded) {
      dispatch(setScreenData(['isEPayInvoicesReady'], false));
      const epayInvoiceList = await InvoiceService.getEPayInvoiceList();
      if (epayInvoiceList.isSuccess && epayInvoiceList.response) {
        const sortDateList = epayInvoiceList.response.sort((x, y) => {
          if (x.dueOn && y.dueOn) {
            const xDueDate = Date.parse(x.dueOn);
            const yDueDate = Date.parse(y.dueOn);

            if (xDueDate > yDueDate) {
              return -1;
            }

            if (xDueDate < yDueDate) {
              return 1;
            }
          }

          return 0;
        });
        dispatch(setScreenData(['epayInvoicesLoaded'], sortDateList));
        getPaginationData(sortDateList);
      }

      dispatch(setScreenData(['isEPayInvoicesReady'], true));
    }
  } catch (e) {
    CwLog.error('Unable to get invoices for EPay');
  }
};

export const getEPayInvoicesList = () => {
  const state = Store().getState();
  const searchText = getScreenData(state.screen, ['ePaySearchText']);
  const filterText = getScreenData(state.screen, ['ePayFilterText']);
  const ePayInvoices = core.getScreenData(core.Store().getState().screen, [
    'epayInvoicesLoaded'
  ]);

  const totalEPayInvoices = ePayInvoices ? ePayInvoices.toJS() : [];
  if (totalEPayInvoices.length > 0) {
    const searchedInvoicesList = searchText
      ? searchInvoicesList(totalEPayInvoices, searchText)
      : totalEPayInvoices;
    const refinedInvoicesList = filterText
      ? filterInvoicesList(searchedInvoicesList, filterText)
      : searchedInvoicesList;
    getPaginationData(refinedInvoicesList);
  }
};

export const loadFailedInvoices = async () => {
  const dispatch = Store().dispatch;
  const result = await InvoiceService.getFailedInvoiceList();
  dispatch(setScreenData(['failedInvoices'], result.response));
};

export const downloadInvoicePdf = async (manageInvoiceId, downloadFileName) => {
  core.Store().dispatch(core.toggleMask(true));

  const result = await InvoiceService.getInvoicePdf(manageInvoiceId);
  if (!result.isSuccess) {
    const error = 'Failed open invoice pdf. ' + result.error;
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
    core.Store().dispatch(core.toggleMask(false));
    return;
  }

  const fileBlob = new Blob([result.response], { type: 'application/pdf' });
  core.downloadFile(fileBlob, downloadFileName + '.pdf');

  core.Store().dispatch(core.toggleMask(false));
};

export const getEPayLocationIds = async () => {
  const state = Store().getState();
  core.Store().dispatch(core.removeScreenData('ePayLocationIds'));
  const ePayInvoices = core.getScreenData(state.screen, ['epayInvoicesLoaded']);
  if (!ePayInvoices) {
    return;
  }

  const locationIds = ePayInvoices
    .toJS()
    .map(i => i.locationId)
    .filter((value, index, self) => self.indexOf(value) === index);
  if (!locationIds) {
    return;
  }

  core.Store().dispatch(core.setScreenData('ePayLocationIds', locationIds));
};

export const getEPaySsoLink = async locationId => {
  const result = await InvoiceService.getEPaySsoLink(locationId);
  return result.response;
};

export const getInvoiceDueDate = invoice => {
  // invoice due dates are just dates (no time) so we want to just get
  // the date out and not treat as a UTC datetime.
  var invoiceUtcDate = new Date(
    invoice.dueDate ? invoice.dueDate : invoice.dueOn
  );
  return new Date(
    invoiceUtcDate.getUTCFullYear(),
    invoiceUtcDate.getUTCMonth(),
    invoiceUtcDate.getUTCDate()
  );
};

export const getFormattedPriceWithCurrency = (currency, price) => {
  const currencyFormat = {
    style: 'currency',
    currency,
    minimumFractionDigits: 0
  };
  return core.formatNumber(price, currencyFormat);
};