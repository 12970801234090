import * as core from 'cw-ui-core';
import { connect } from 'react-redux';
import RetryPayment from 'src/components/Drawer/PartnerWalletDrawer/RetryPayment';
import { getCustomerWallet } from 'src/components/Wallet/CustomerWalletService';
import Routes from 'src/services/Routes';
import { getPartnerBillingInfo } from 'src/services/ShoppingCart/ShoppingCartService';
import { loadInvoiceList } from 'src/services/Invoices/InvoiceService';
import { WALLET_DRAWER } from 'src/components/Drawer/PartnerWalletDrawer/WalletDrawerContainer';

const mapDispatchToProps = dispatch => {
  return {
    onCloseIconClicked: () => {
      loadInvoiceList();
      dispatch(core.hideDrawer(WALLET_DRAWER));
    },
    onDidMount: async () => {
      dispatch(
        core.setScreenData(
          [Routes.BILLING_PAGE.id, 'showLoadingComponent'],
          true
        )
      );
      await getPartnerBillingInfo();
      await getCustomerWallet();
      dispatch(
        core.setScreenData(
          [Routes.BILLING_PAGE.id, 'showLoadingComponent'],
          false
        )
      );
    }
  };
};

const RetryPaymentContainer = connect(null, mapDispatchToProps)(RetryPayment);

export default RetryPaymentContainer;
