import React, { useState, useEffect } from 'react';
import { formatMessage } from 'cw-ui-core';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import css from './InActivity.scss';
import history from 'src/services/History';

const InactivityDialog = () => {
  const state = core.Store().getState();
  const inactivity = core.getModuleCache(state.module, ['inactivityDuration']);
  const timer = core.getModuleCache(state.module, ['timeDuration']);

  const [timeRemaining, setTimeRemaining] = useState(timer);
  const [showDialog, setShowDialog] = useState(false);
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    let inactivityTimer;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        setInactive(true);
        setShowDialog(true);
      }, inactivity);
    };

    const handleActivity = () => {
      if (!showDialog) {
        setInactive(false);
        setShowDialog(false);
        setTimeRemaining(timer);
        resetInactivityTimer();
      }
    };

    resetInactivityTimer();
    const activityEvents = [
      'mousemove',
      'keydown',
      'keypress',
      'mousedown',
      'mouseup',
      'touchstart',
      'scroll',
      'click'
    ];
    activityEvents.forEach(event =>
      document.addEventListener(event, handleActivity)
    );

    return () => {
      clearTimeout(inactivityTimer);
      activityEvents.forEach(event =>
        document.removeEventListener(event, handleActivity)
      );
    };
  }, [showDialog, inactivity, timer]);

  useEffect(() => {
    if (inactive) {
      const countdownTimer = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      }, 1000);

      if (timeRemaining <= 0) {
        clearInterval(countdownTimer);
        handleLogout();
      }

      return () => clearInterval(countdownTimer);
    }
  }, [inactive, timeRemaining]);

  const handleStayLoggedIn = () => {
    setInactive(false);
    setShowDialog(false);
    setTimeRemaining(timer);
  };

  const handleLogout = () => {
    history().push('/logout');
  };

  return (
    showDialog && (
      <div className={css.container}>
        <div className={css.inactivity}>
          <core.Label
            className={`${css.label} ${css.boldLabel}`}
            value={formatMessage(Locale.inactivity_dialog)}
          />
          <div>
            <br />
            <core.Label
              className={css.label}
              value={formatMessage(Locale.sub_inactivity_dialog)}
            />
          </div>

          <div className={`${css.timer} ${css.stopwatch}`}>
            Time remaining:
            <span className={timeRemaining > 30 ? '' : css.time}>
              {` ${'0' + Math.floor(timeRemaining / 60)}:${
                timeRemaining % 60 > 9
                  ? timeRemaining % 60
                  : '0' + (timeRemaining % 60)
              }`}
            </span>
          </div>
          <div className={css.buttonContainer}>
            <button
              className={`${css.button} ${css.logoutButton}`}
              onClick={handleLogout}
            >
              Log out Home Now
            </button>
            <button
              className={`${css.button} ${css.stayLoggedInButton}`}
              onClick={handleStayLoggedIn}
            >
              Stay Logged In Home
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default InactivityDialog;
