import React from 'react';
import * as core from 'cw-ui-core';
import css from './CardDropdownLabel.scss';
import cx from 'classnames';

/**
 * Component displays data relevant to the selected credit card in the user wallet.
 * @param {function} actionHandler Primary event handler
 * @param {string} actionLabel Label for primary button. I.E Remove, Update
 * @param {object} automationName Object consisting of automationName values
 * @param {string} cancelLabel Label for cancel button
 * @param {function} closeHandler Event handler for closing the label
 * @param {string} message Primary label content
 * @param {string} messageTitle Title of message
 * @param {boolean} warningDisplay Boolean to set label style. If true a red theme is used or else a default blue theme will be set.
 * @returns JSX.Element
 */

const CardDropdownLabel = props => {
  const {
    actionHandler,
    actionLabel,
    automationName,
    warningDisplay,
    cancelLabel,
    closeHandler,
    message,
    messageTitle
  } = props;

  const style = warningDisplay
    ? {
        cardContainer: css.updateCardContainer,
        icon: css.updateCardIcon
      }
    : {
        cardContainer: css.removeCardContainer,
        icon: css.removeCardIcon
      };

  return (
    <core.Flexbox className={style.cardContainer} column>
      <core.Flexbox>
        <core.Image
          className={style.icon}
          automationName={'card-' + automationName + '-unacknowledged'}
          src={core.IconPath.get('Status_Unacknowledged')}
        />
        <core.Flexbox column className={css.cardMessage}>
          <core.Label value={messageTitle} />
          <core.Label
            automationName={'card-' + automationName + '-message'}
            value={message}
          />
        </core.Flexbox>
        <core.Image
          className={css.updateCardClose}
          automationName={'card-' + automationName + '-close'}
          src={core.IconPath.get('Action_Close')}
          onClick={closeHandler}
        />
      </core.Flexbox>
      <core.Flexbox className={css.cardUpdateButtons}>
        <core.Label
          className={cx(
            css.cancelButton,
            warningDisplay ? css.warningText : ''
          )}
          automationName={'cancel-' + automationName + '-card'}
          value={cancelLabel}
          isStandard={false}
          onClick={closeHandler}
          isLink
        />

        <core.Label
          className={cx(
            css.actionButton,
            warningDisplay ? css.warningText : ''
          )}
          automationName={automationName + `Card`}
          value={actionLabel}
          isStandard={false}
          onClick={actionHandler}
          isLink
        />
      </core.Flexbox>
    </core.Flexbox>
  );
};

export default CardDropdownLabel;
