import React from 'react';
import {Placeholder, MobileLayoutHOC} from 'cw-ui-core';

import css from './SupportTicketNotesLoading.scss';

const LoadingComponent = () => {
  return (
    <div className={css.wrap}>
      <div className={css.details}>
        <Placeholder className={css.initials} />
        <Placeholder className={css.placeholder1} />
      </div>
      <Placeholder className={css.placeholder3} />
      <Placeholder className={css.placeholder3} />
    </div>
  );
};

const LoadingComponentMobile = () => {
  return (
    <div className={css.wrap}>
      <div className={css.details}>
        <Placeholder className={css.initials} />
        <div className={css.placeholderContainer}>
          <Placeholder className={css.placeholder1} />
          <Placeholder className={css.placeholder2} />
        </div>
      </div>
      <Placeholder className={css.placeholder3} />
      <Placeholder className={css.placeholder3} />
    </div>
  );
};

const SupportTicketNotesLoading = props => {
  return props.isMobileLayout ? <LoadingComponentMobile/> : <LoadingComponent/>;
};

export default MobileLayoutHOC(SupportTicketNotesLoading);
