import * as core from 'cw-ui-core';
import { connect } from 'react-redux';
import TopNavigationMenuContent from 'src/components/Navigation/TopNavigationMenuContent';

export const MOBILE_NAVIGATION_MENU_DRAWER = 'MOBILE_NAVIGATION_MENU_DRAWER';

const mapDispatchToProps = dispatch => {
  return {
    onCloseIconClicked: () => {
      dispatch(core.hideDrawer(MOBILE_NAVIGATION_MENU_DRAWER));
    }
  };
};

const TopNavigationContentConatiner = connect(
  null,
  mapDispatchToProps
)(TopNavigationMenuContent);
export default TopNavigationContentConatiner;
