import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/PartnerSuccessManagerDrawer/ResourceCard.scss';
import Locale from 'src/Locale';
import cx from 'classnames';
import AutoLinkMessage from 'src/components/AutoLinkMessage';

const onEmailClick = e => {
  const automationName = e.currentTarget.getAttribute('data-cwid');
  automationName && core.addClickTrack(automationName);
  e.stopPropagation();
};

const nameAndBio = props => {
  return (
    <React.Fragment>
      <core.Flexbox row className={css.nameRow}>
        <core.Label
          className={css.resourceCardLabel}
          value={props.name ? core.formatMessage(Locale.contact_manager, {
            managerName: props.name
          }) : core.formatMessage(Locale.contact_partner_success_team)}
          automationName={`${props.title}_Card_Label`}
        />
        {props.aboutMe && (
          <core.FlyoutComposer
            automationName={`AboutMe-${props.name}`}
            widget={
              <core.Image
                automationName="aboutMeIcon"
                title={core.formatMessage(Locale.about_me)}
                src={core.IconPath.get('Application_Information')}
                className={css.toolTipIcon}
              />
            }
            fixedPointerPosition={
              props.title === core.formatMessage(Locale.account_manager) &&
              !props.isMobileLayout
                ? 'TOP_RIGHT'
                : undefined
            }
            onCloseFlyout={() => {
              props.onShowLessClick();
            }}
            flyout={
              <core.Flexbox className={css.aboutMeContent} column>
                <core.Label
                  automationName="aboutMeTitleText"
                  value={core.formatMessage(Locale.fun_facts_about_me)}
                  className={css.aboutMeTitle}
                />
                <AutoLinkMessage
                  automationName="aboutMeLinkMessage"
                  content={
                    props.showFullBio
                      ? props.aboutMe
                      : props.aboutMe.slice(0, 214)
                  }
                  className={css.aboutMeDescription}
                  useCustomMatcher={false}
                />
                {props.aboutMe.length > 215 && !props.showFullBio && (
                  <core.Label
                    automationName="aboutMeShowMore"
                    value={core.formatMessage(Locale.show_more)}
                    onClick={props.onShowMoreClick}
                    className={css.showMoreShowLess}
                  />
                )}
                {props.aboutMe.length && props.showFullBio && (
                  <core.Label
                    automationName="aboutMeShowLess"
                    value={core.formatMessage(Locale.show_less)}
                    onClick={props.onShowLessClick}
                    className={css.showMoreShowLess}
                  />
                )}
              </core.Flexbox>
            }
          />
        )}
      </core.Flexbox>
    </React.Fragment>
  );
};

const contactMethods = props => {
  let content = (
    <>
        <core.Flexbox column>
          <core.Flexbox>
            {props.phone && (
              <core.Flexbox className={css.contactMethod}>
                <core.Image
                  automationName="phoneIcon"
                  title={core.formatMessage(Locale.phone)}
                  src={core.IconPath.get('Action_Phone')}
                  className={css.contactMethodIcon}
                />
                <core.Label
                  className={cx(css.contactMethodLabel, css.withPointer)}
                  value={props.phone}
                />
              </core.Flexbox>
            )}
            {props.phone && props.email && <div className={css.rectangle} />}
            {props.email && (
              <core.Flexbox class={css.contactMethod}>
                <a
                  data-cwid="emailLink"
                  className={cx(css.emailWrap, css.contactMethod)}
                  href={`mailto:${props.email}`}
                  onClick={onEmailClick}
                >
                  <core.Image
                    automationName="emailIcon"
                    title={core.formatMessage(Locale.profile_email)}
                    src={core.IconPath.get('Action_email')}
                    className={cx(css.contactMethodIcon, css.withPointer)}
                  />
                  <core.Label
                    className={cx(css.contactMethodLabel, css.withPointer)}
                    value={props.email}
                  />
                </a>
              </core.Flexbox>
            )}
          </core.Flexbox>
          <core.Flexbox className={cx(css.contactMethod, css.contactMethodMargin)}>
            <a
              data-cwid="supportLink"
              className={css.contactLinkWrap}
              href={props.supportLink}
            >
              <core.Label
                className={cx(css.contactMethodLabel, css.withPointer)}
                value={props.supportLinkText}
              />
            </a>
          </core.Flexbox>
        </core.Flexbox>
      </>
  );

  return (
    <React.Fragment>
      <core.Flexbox className={css.contactMethods}>{content}</core.Flexbox>
    </React.Fragment>
  );
};

const ResourceCard = props => {
  let managerContent;
  let dynStyle = { CwThemeBackgroundColor: !props.photoUrl };
  if (props.initials && !props.photoUrl) {
    managerContent = (
      <core.Label className={css.initials} value={props.initials} />
    );
  } else if (props.photoUrl) {
    managerContent = (
      <core.Image
        src={props.photoUrl}
        isInlineSVG={false}
        alt={props.title + ' ' + core.formatMessage(Locale.photo)}
      />
    );
  }

  if (props.isMobileLayout) {
    return (
      <core.Flexbox
        key={props.name}
        className={cx(css.managerContainer)}
        automationName={`${props.title}_Box_${props.name}`}
      >
        <core.Flexbox className={css.resourceHeader}>
          <core.Flexbox
            className={cx(dynStyle, css.profileImage)}
            automationName={`${props.title}_Card_Image`}
          >
            {managerContent}
          </core.Flexbox>

          <core.Flexbox column className={css.resourceInfo}>
            {nameAndBio(props)}
            <core.Label
              className={cx(css.resourceCardTitleLabel)}
              value={props.title}
            />
          </core.Flexbox>
        </core.Flexbox>
        <core.Flexbox column automationName={`${props.title}_Card_Info`}>
          <core.Label
            className={cx(css.description)}
            value={props.description}
          />
          {contactMethods(props)}
        </core.Flexbox>
      </core.Flexbox>
    );
  }

  return (
    <core.Flexbox
      key={props.name}
      row
      className={cx(css.managerContainer)}
      automationName={`${props.title}_Box_${props.name}`}
    >
      <core.Flexbox
        className={cx(dynStyle, css.profileImage)}
        automationName={`${props.title}_Card_Image`}
      >
        {managerContent}
      </core.Flexbox>

      <core.Flexbox column className={css.resourceInfo}>
        {nameAndBio(props)}
        <core.Flexbox column automationName={`${props.title}_Card_Info`}>
          <core.Label
            className={cx(css.resourceCardTitleLabel)}
            value={props.title}
          />
          <core.Label
            className={cx(css.description)}
            value={props.description}
          />
          {contactMethods(props)}
        </core.Flexbox>
      </core.Flexbox>
    </core.Flexbox>
  );
};

export default core.MobileLayoutHOC(ResourceCard);
