import BaseService from '../BaseApiService';

class WalletService extends BaseService {
  getBaseUrl() {
    return `${this.getBaseUrlFromConfig('billing')}/api/wallet`;
  }

  retrieve() {
    return this.post(`${this.apiUrl()}/dummy/Retrieve`);
  }

  removeCard(cardId) {
    return this.post(`${this.apiUrl()}/${cardId}/Remove`);
  }

  updateCardPartnerData(cardId, isNewCard) {
    const body = {
      companyAddressId: null,
      makeDefaultCard: null,
      isNewCard,
    };
    return this.post(`${this.apiUrl()}/${cardId}/updateCardPartnerData`, body);
  }

  sendFailedCreditCardAttemptsEmail(cardId, attemptCount) {
    const body = {
      attemptCount,
    };

    return this.post(
      `${this.apiUrl()}/${cardId}/sendFailedCreditCardAttemptsEmail`,
      body
    );
  }
}

export default new WalletService();
