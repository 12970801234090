import React, {useState} from 'react';
import {Dialog, ButtonStandard} from 'cw-ui-core';
import {injectIntl} from 'react-intl';

import Locale from 'src/Locale';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';

export const CLOSE_ACCOUNT_CONFIRMATION_DIALOG_ID = 'CLOSE_ACCOUNT_CONFIRMATION_DIALOG';

const UserProfileCloseAccountConfirmationDialog = ({ intl, onCloseDialog, onCloseAccount }) => {
  const {formatMessage} = intl;
  const [isBusy, setIsBusy] = useState(false);

  const onButtonClick = async () => {
    setIsBusy(true);
    await onCloseAccount();
    setIsBusy(false);
  };

  const dialogButton = () => {
    const button = () => <ButtonStandard
                            automationName="close-account-button"
                            value={formatMessage(Locale.confirm)}
                            isSmall
                            onClick={async () => await onButtonClick()}
                          />;
    const LoadingButton = WithButtonLoadingIndicatorHOC(button);
    return [<LoadingButton key='ok' isBusy={isBusy} wrapperStyle={{'height': '40px', 'width': '94px'}}/>];
  };

  return (
    <Dialog
      includeCloseIcon
      automationName="confirm-close-account"
      buttons={dialogButton()}
      onCloseIconClicked={onCloseDialog}
      header={formatMessage(Locale.close_account_header_text)}
    >
      {formatMessage(Locale.confirm_close_account)}
    </Dialog>
  );
};

export default injectIntl(UserProfileCloseAccountConfirmationDialog);