import React from 'react';
import {Initialization} from 'coveo-search-ui';
import getDialog from './DialogFactory';
import getDrawer from 'src/DrawerFactory';
import * as core from 'cw-ui-core';
import AppContainer from 'src/components/App/AppContainer';
import LandingPageContainer from 'src/components/LandingPage/LandingPageContainer';
import Deflection from 'src/coveo/Deflection';
import ResultIcon from 'src/coveo/ResultIcon';

export const MODULE_ID = 'cw-home';

const addPrefetchLink = href => {
    let link = document.createElement('link');
    link.setAttribute('rel', 'prefetch');
    link.setAttribute('href', href);
    document.head.appendChild(link);
};

(function initMarketoTracking() {
    var didInit = false;

    function initMunchkin() {
        if (didInit === false) {
            didInit = true;
            window.Munchkin.init('417-HWY-826');
        }
    }

    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://lp.connectwise.com/js/munchkin.js';
    s.onreadystatechange = function () {
        if (this.readyState === 'complete' || this.readyState === 'loaded') {
            initMunchkin();
        }
    };

    s.onload = initMunchkin;
    document.getElementsByTagName('head')[0].appendChild(s);
})();

const onBeforeAppLoad = configJson => {
    // disable oidc auto signin but not signout
    core.Store().dispatch(core.oidcDisableAutoSigninSignoutRedirect(true, false));

    const oidcUserManagerConfig = {
        client_id: 'cwhome_code',
        redirect_uri: `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? `:${window.location.port}` : ''
        }/signin-oidc`,
        response_type: 'code',
        scope: 'openid profile email api.home api.users approles roles',
        authority: configJson.authority,
        silent_redirect_uri: `${window.location.protocol}//${
            window.location.hostname
        }${
            window.location.port ? `:${window.location.port}` : ''
        }/silent_refresh.html`,
        automaticSilentRenew: true,
        silentRequestTimeout: 1000 * 20,
        post_logout_redirect_uri: `${window.location.protocol}//${
            window.location.hostname
        }${window.location.port ? `:${window.location.port}` : ''}`
    };
    core.initializeOidc(oidcUserManagerConfig);
    addPrefetchLink(configJson.stsUserAdminUrl);
    Initialization.registerAutoCreateComponent(ResultIcon);
    Initialization.registerAutoCreateComponent(Deflection);
};

const appConfig = {
    includeOidc: true,
    oidcLoginComponent: <LandingPageContainer/>,
    onBeforeAppLoad,
    includeMobileLayout: true,
    loadingComponent: <core.Loader/>,
    screenComponent: <AppContainer/>,
    getDialog,
    getDrawer
};

// set default theme
core.setTheme('#0067B1');

core.appEntryPoint(MODULE_ID, appConfig);
