import * as core from 'cw-ui-core';
import CompanyService from 'src/services/Api/HomeApi/CompanyService';

export const fetchPartnerSalesExecutive = async () => {
    const result = await CompanyService.getPartnerSalesExecutive();
  
    if (!result.isSuccess) {
      const error = 'No Partner Sales Executive assigned to this company.';
      core.CwLog.error(error);
      core
        .Store()
        .dispatch(core.setModuleCache(['partnerSalesExecutive'], result.isSuccess));
      return result.isSuccess;
    }
  
    core
      .Store()
      .dispatch(core.setModuleCache(['partnerSalesExecutive'], result.response));
    return result.response;
  };

  export const fetchPartnerSalesExecutivePhoto = async () => {
    var imageUrl = await CompanyService.getPartnerSalesExecutivePhoto();
    core
      .Store()
      .dispatch(core.setModuleCache(['partnerSalesExecutivePhoto'], imageUrl.response));
    return imageUrl.response;
  };