import { GET, getUrl, PUT } from 'src/services/ApiService';

const PartnersService = () => {
  const apiUrl = () => getUrl('home', '/partner');

  const getPartnerProfile = async () => {
    return await GET(`${apiUrl()}`, true, '1.0');
  };

  const updatePartnerPhoneNumber = async phoneNumber => {
    return await PUT(`${apiUrl()}/PhoneNumber`, phoneNumber);
  };

  const setCurrencyFromBilling = async (
    countryCode,
    stateCode,
    zipCode,
    currency
  ) => {
    const body = {
      country: countryCode,
      state: stateCode,
      zip: zipCode,
      currency
    };
    return PUT(`${apiUrl()}/currency`, body);
  };

  const updatePartnerCountryStateZip = async (
    country,
    state,
    zip,
    currency
  ) => {
    const body = {
      country,
      state,
      zip,
      currency
    };
    return PUT(`${apiUrl()}/mainaddress`, body);
  };

  return {
    getPartnerProfile,
    updatePartnerPhoneNumber,
    setCurrencyFromBilling,
    updatePartnerCountryStateZip
  };
};

export default PartnersService();
