import {
  GET,
  PATCH,
  POST,
  POSTFORMDATA,
  GETFILEDOWNLOAD,
  getUrl
} from 'src/services/ApiService';

const TicketService = () => {
  return {
    get: (ticketRecId = 0, isArchivedList = false) => {
      const apiUrl = getUrl('home', '/service');
      return GET(`${apiUrl}/tickets/${ticketRecId}?isArchivedList=${isArchivedList}`);
    },
    list: (
      conditions = '',
      orderBy = '',
      page = 1,
      pageSize = 10,
      ticketType
    ) => {
      const apiUrl = getUrl('home', '/service');
      return GET(
        `${apiUrl}/tickets?conditions=${conditions}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}&ticketType=${ticketType}`
      );
    },
    archivedList: (
      conditions = '',
      orderBy = '',
      page = 1,
      pageSize = 10,
      ticketType,
      isArchivedList = true
    ) => {
      const apiUrl = getUrl('home', '/service');
      return GET(
        `${apiUrl}/tickets?conditions=${conditions}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}&ticketType=${ticketType}&isArchivedList=${isArchivedList}`
      );
    },
    getNotes: (
      ticketId,
      conditions = '',
      orderBy = '',
      page = 1,
      pageSize = 10
    ) => {
      const apiUrl = getUrl('home', '/service');
      return GET(
        `${apiUrl}/tickets/${ticketId}/notes?conditions=${conditions}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}`
      );
    },
    getNotesAndAttachments: ticketId => {
      const apiUrl = getUrl('home', '/service');
      return GET(`${apiUrl}/tickets/${ticketId}/allNotes`);
    },
    getArchivedTicketsNotesAndAttachments: (ticketId, isArchivedList = true) => {
      const apiUrl = getUrl('home', '/service');
      return GET(`${apiUrl}/tickets/${ticketId}/allArchivedTicketNotes?isArchivedList=${isArchivedList}`);
    },
    updateTicket: (ticketId, updates) => {
      const apiUrl = getUrl('home', '/service');
      return PATCH(`${apiUrl}/tickets/${ticketId}`, updates);
    },
    updateScheduleEntry: (entryId, updates) => {
      const apiUrl = getUrl('home', '/service');
      return PATCH(`${apiUrl}/tickets/${entryId}/scheduleEntries`, updates);
    },
    addTicketNotes: (ticketId, notes) => {
      const apiUrl = getUrl('home', '/service');
      return POST(`${apiUrl}/tickets/${ticketId}/notes`, notes);
    },
    addAttachmentToTicket: (ticketId, formData) => {
      const apiUrl = getUrl('home', '/service');
      return POSTFORMDATA(
        `${apiUrl}/tickets/${ticketId}/attachments`,
        formData
      );
    },
    downloadAttachment: (ticketId, documentId) => {
      const apiUrl = getUrl('home', '/service');
      return GETFILEDOWNLOAD(
        `${apiUrl}/tickets/${ticketId}/attachments/${documentId}/download`
      );
    },
    getBoardStatusCount: (boardId = 0) => {
      const apiUrl = getUrl('home', '/service');
      return GET(`${apiUrl}/boards/${boardId}/statuses/count`);
    },
    getTicketBoardStatus: (boardId = 0) => {
      const apiUrl = getUrl('home', '/service');
      return GET(`${apiUrl}/boards/${boardId}/boardStatus`);
    },
    getScheduleEntries: (ticketId = 0) => {
      const apiUrl = getUrl('home', '/service');
      return GET(`${apiUrl}/tickets/${ticketId}/scheduleEntries`);
    }
  };
};

export default TicketService();
