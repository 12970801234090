import { connect } from 'react-redux';
import { KNOWN_ISSUES_SEND_EMAIL_DIALOG } from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesSendEmailLinkDialog';
import * as core from 'cw-ui-core';
import KnownIssuesOptionColumn from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesOptionColumn';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSendEmailLinkClick: () => {
      var jiraIssue = {
        key: ownProps.jiraKey,
        id: ownProps.jiraId,
        summary: ownProps.jiraSummary
      };
      dispatch(
        core.showDialog(KNOWN_ISSUES_SEND_EMAIL_DIALOG, {
          jiraIssue
        })
      );     
    }
  };
};

const KnownIssuesOptionColumnContainer = connect(
  undefined,
  mapDispatchToProps
)(KnownIssuesOptionColumn);

export default KnownIssuesOptionColumnContainer;
