import React from 'react';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import PayFabricFrame from './PayFabricFrame';
import cx from 'classnames';
import Locale from 'src/Locale';
import Routes from 'src/services/Routes';
import css from 'src/components/Wallet/Wallet.scss';
import CardDropdownLabel from './CardDropdownLabel';

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      isShowEditOptions: false,
      isShowRemoveOption: false,
      isShowEdit: false,
      isShowUpdateExpiredCard: false,
      isMobileHover: true
    };
  }

  componentDidMount() {
    if (this.props.card.isExpired && this.props.selected) {
      this.setState({
        isShowUpdateExpiredCard: true
      });
    }
  }

  onMouseHover = () => {
    this.setState({ isHovering: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  onEditClick = () => {
    this.setState({
      isShowEdit: !this.state.isShowEdit,
      isShowRemoveOption: false,
      isShowUpdateExpiredCard: false,
      isMobileHover: this.props.isMobileLayout && !this.state.isMobileHover
    });
    this.props.onCardEdit(this.props.card, !this.props.isEditing);
    this.onMouseLeave();
  };

  onCardRemoveClick = () => {
    if (this.props.disableRemove) return;
    this.props.onCardEdit(this.props.card, !this.props.isEditing);
    this.setState({
      isShowRemoveOption: !this.state.isShowRemoveOption,
      isShowEdit: false,
      isShowUpdateExpiredCard: false,
      isMobileHover: this.props.isMobileLayout && !this.state.isMobileHover
    });
    this.onMouseLeave();
  };

  onCardRemoveCancelClick = () => {
    this.setState({
      isShowRemoveOption: !this.state.isShowRemoveOption,
      isShowEdit: false,
      isMobileHover: this.props.isMobileLayout && !this.state.isMobileHover
    });
    this.props.onCardEdit(this.props.card, false);
    this.onMouseLeave();
  };

  onCardEditCancel = () => {
    this.onEditClick();
    this.props.onCancelEditCard(this.props.card.ID);
  };

  onCardUpdate = cardId => {
    this.onEditClick();
    this.props.onAddUpdateCard(cardId, false);
  };

  onCardRemove = cardId => {
    this.props.onCardRemove(cardId);
    this.onMouseLeave();
    core
      .Store()
      .dispatch(core.removeScreenData([Routes.SHOPPING_CART.id, 'editedCard']));
    return;
  };

  onCardExpiredCancelClick = () => {
    this.setState({
      isShowUpdateExpiredCard: false,
      isMobileHover: this.props.isMobileLayout && !this.state.isMobileHover
    });
    this.onMouseLeave();
  };

  render() {
    const {
      card,
      isEditing,
      payFabricUrlEdit,
      selected,
      standalone,
      isSaving,
      isShowCVV,
      isMobileLayout
    } = this.props;
    const value = { id: card.ID, checked: selected, cardInWallet: card };
    const cardTurncatedValue = card.Account.substr(card.Account.length - 4);
    const { formatMessage } = this.props.intl;
    const FlyoutContent = () => {
      return (
        <>
          <core.Label
            value={formatMessage(Locale.wallet_card_edit)}
            className={css.editCardOptions}
            onClick={() => this.onEditClick()}
          />
          <core.Label
            value={formatMessage(Locale.wallet_card_remove)}
            disabled={this.props.disableRemove}
            className={css.editCardOptions}
            onClick={() => this.onCardRemoveClick()}
          />
        </>
      );
    };

    const formatExpirationDate = function (expDate) {
      if (expDate && expDate.length === 4) {
        return core.formatMessage(Locale.wallet_card_expiration, {
          expDate: `${expDate.slice(0, 2)}/${expDate.slice(2, 4)}`
        });
      }
    };

    const handleCardSelect = value => {
      this.props.onSelectChange(value);
      if (card.isExpired) {
        this.setState({
          isShowUpdateExpiredCard: true,
          isShowRemoveOption: false
        });
      }
    };

    return (
      <div
        className={cx(
          css.creditCard,
          { [css.selected]: selected && !card.isExpired },
          { [css.expired]: card.isExpired },
          { [css.standalone]: standalone }
        )}
        key={card.ID}
        onMouseEnter={this.onMouseHover}
        onMouseLeave={this.onMouseLeave}
      >
        {isSaving ? (
          <core.Placeholder className={css.cardLoading} />
        ) : (
          <React.Fragment>
            <core.Flexbox className={css.cardName}>
              {!this.props.standalone && (
                <core.RadioButton
                  className={css.card}
                  value={value}
                  automationName={card.ID}
                  onChange={handleCardSelect}
                  disabled={card.disabled}
                />
              )}
              <core.Image
                className={cx(css.cardImage, { [css.standalone]: standalone })}
                src={`/images/ShoppingCart/${card.CardName}.svg`}
              />
              <core.Flexbox className={css.cardDetail} column>
                <core.Label
                  value={formatMessage(Locale.wallet_credit_card_info, {
                    cardNumber: cardTurncatedValue
                  })}
                  disabled={card.disabled}
                />
                <core.Label
                  value={formatExpirationDate(card.ExpDate)}
                  className={css.defaultCard}
                />
                {card.IsDefaultCard && (
                  <core.Label
                    value={formatMessage(Locale.wallet_card_default)}
                    className={css.defaultCard}
                  />
                )}
              </core.Flexbox>
              {card.disabled && (
                <core.Label
                  value={formatMessage(Locale[card.disabledReason])}
                  disabled={card.disabled}
                />
              )}
            </core.Flexbox>
            <core.Flexbox className={css.cardActionsRight}>
              {!isEditing && selected && isShowCVV && (
                <core.FormFieldContainer
                  className={cx(css.cvv, {
                    [css.cvvRequired]: !this.props.cvvIsEntered
                  })}
                  path={[Routes.SHOPPING_CART.id, 'selectedCard', 'CVV']}
                  onAfterChange={this.props.onAfterCVVChange}
                >
                  <core.TextField
                    autoFocus
                    placeholder="CVV"
                    maxLength={4}
                    regex="^([\b0-9])*$"
                    invalid={this.props.cvvIsInvalid}
                    automationName="cvv"
                    onBlur={e =>
                      this.props.onAfterCVVChange(e.target.value, true)
                    }
                  />
                </core.FormFieldContainer>
              )}

              {((this.state.isHovering &&
                !this.props.readOnly &&
                !isMobileLayout) ||
                (!this.props.readOnly &&
                  isMobileLayout &&
                  this.state.isMobileHover)) && (
                <core.FlyoutComposer
                  automationName="info"
                  className={css.editCardFlyoutContainer}
                  widget={
                    <core.Image
                      className={css.editCard}
                      automationName="edit-card"
                      src={core.IconPath.get('Application_More')}
                    />
                  }
                  flyout={<FlyoutContent />}
                />
              )}
            </core.Flexbox>
            {isEditing && this.state.isShowEdit && payFabricUrlEdit && (
              <PayFabricFrame
                payFabricUrl={payFabricUrlEdit}
                onAddUpdateCard={this.onCardUpdate}
                onCancel={this.onCardEditCancel}
              />
            )}

            {this.state.isShowRemoveOption &&
              CardDropdownLabel({
                actionHandler: () => this.onCardRemove(card.ID),
                actionLabel: formatMessage(Locale.remove),
                automationName: 'remove',
                cancelLabel: formatMessage(Locale.cancel),
                closeHandler: this.onCardRemoveCancelClick,
                message: formatMessage(
                  this.props.card.isExpired
                    ? Locale.wallet_remove_expired_card_message
                    : Locale.wallet_remove_card_message,
                  {
                    cardNumber: cardTurncatedValue
                  }
                ),
                messageTitle: formatMessage(Locale.wallet_remove_card_info, {
                  cardNumber: cardTurncatedValue
                }),
                warningDisplay: this.props.card.isExpired
              })}

            {this.state.isShowUpdateExpiredCard &&
              this.props.selected &&
              CardDropdownLabel({
                actionHandler: () => this.onEditClick(card.ID),
                actionLabel: formatMessage(Locale.update),
                automationName: 'expired',
                cancelLabel: formatMessage(Locale.cancel),
                closeHandler: this.onCardExpiredCancelClick,
                message: formatMessage(Locale.wallet_card_is_expired_message),
                messageTitle: formatMessage(Locale.wallet_card_is_expired, {
                  cardInfo: cardTurncatedValue
                }),
                warningDisplay: true
              })}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default injectIntl(Card);
