import NewsAdminApi from 'src/services/Api/HomeApi/NewsAdminService';
import * as core from 'cw-ui-core';
import { NEWS_POST_DRAWER } from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsPostDrawerContainer';
import Locale from 'src/Locale';

export const NewsAdminTab = {
  NEWS_FEED: 'newsFeed',
  SECURITY_BULLETIN: 'securityBulletin',
  BANNER: 'banner'
};

export const Steps = {
  Step_1: 'step1',
  Step_2: 'step2',
  Step_3: 'step3',
  Step_4: 'step4',
  Step_5: 'step5',
  Step_6: 'step6'
};

export const postTypes = {
  STANDARD_POST: 'standardPost',
  PINNED_ANNOUNCEMENT: 'pinnedAnnouncement'
};

export const postTypeFilters = [
  {
    id: 'allTypes',
    name: 'All types'
  },
  {
    id: 'product',
    name: 'Product'
  },
  {
    id: 'sales',
    name: 'Sales'
  },
  {
    id: 'education',
    name: 'Education'
  },
  {
    id: 'marketing',
    name: 'Marketing'
  },
  {
    id: 'it_nation',
    name: 'IT Nation'
  },
  {
    id: 'announcement',
    name: 'Announcement'
  }
];

export const postCreatorFilters = [
  {
    id: 'allPosts',
    name: 'All posts'
  },
  {
    id: 'myPosts',
    name: 'My posts'
  }
];

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};
export const mediaTypes = [
  {
    id: 'noMedia',
    name: core.formatMessage(Locale.no_media)
  },
  {
    id: 'image',
    name: core.formatMessage(Locale.image)
  },
  {
    id: 'video',
    name: core.formatMessage(Locale.video)
  }
];

export const buttonTypes = [
  {
    id: 'noButton',
    name: core.formatMessage(Locale.no_button)
  },
  {
    id: 'ctaButton',
    name: core.formatMessage(Locale.cta_button)
  }
];

export const postPriority = [
  {
    id: 'low',
    name: core.formatMessage(Locale.low)
  },
  {
    id: 'medium',
    name: core.formatMessage(Locale.medium)
  },
  {
    id: 'high',
    name: core.formatMessage(Locale.high)
  }
];

export const postScheduleTypes = [
  {
    id: 'postNow',
    name: core.formatMessage(Locale.post_now)
  },
  {
    id: 'postSchedule',
    name: core.formatMessage(Locale.post_sehedule)
  }
];

export const getScheduledDateTime = (dateSelected, timeSelected) => {
  return (
    dateSelected &&
    timeSelected &&
    new Date(
      dateSelected.getFullYear(),
      dateSelected.getMonth(),
      dateSelected.getDate(),
      timeSelected.getHours(),
      timeSelected.getMinutes(),
      timeSelected.getSeconds()
    )
  );
};

const defaultPage = 1;

export const getNewsPosts = async (allposts, type, page) => {
  const pageSize = 50;
  const pageName = page ? page : defaultPage;
  const result = await NewsAdminApi.getNewsPostsOverview(
    allposts,
    type,
    pageName,
    pageSize
  );
  if (!result.isSuccess) {
    const error = result.error;
    core.CwLog.error(error);
    return;
  }

  return result.response;
};

export const getNewsPostById = async id => {
  const result = await NewsAdminApi.getNewsPostById(id);
  if (!result.isSuccess) {
    const error = result.error;
    core.CwLog.error(error);
    return;
  }

  return result.response;
};

export const loadPosts = async pageIndex => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  dispatch(core.setScreenData(['isReady'], false));
  const postTypeFilter =
    core.getScreenData(state.screen, ['postTypeFilter']) || '';
  const creatorFilter = core.getScreenData(state.screen, ['postCreatorFilter']);
  const postCreatorFilter = creatorFilter === undefined ? true : creatorFilter;
  const response = await getNewsPosts(
    postCreatorFilter,
    postTypeFilter,
    pageIndex
  );
  dispatch(core.setScreenData(['newsPosts'], response.posts));
  dispatch(core.setScreenData('pagination', response.metadata.pagination));
  dispatch(core.setScreenData(['isReady'], true));
};

export const addNewsPost = async postDetails => {
  const dispatch = core.Store().dispatch;
  dispatch(core.toggleMask(true));
  let imageUrl;
  if (
    postDetails.postimageFileObject &&
    postDetails.postimageFileObject.length > 0
  ) {
    const formData = new FormData();
    formData.append(
      'imageFile',
      postDetails.postimageFileObject[0],
      postDetails.postimageFileObject[0].name
    );
    const getImageUrl = await NewsAdminApi.postImage(formData);
    if (getImageUrl.isSuccess) {
      imageUrl = getImageUrl.response.url;
    }
  }

  let actions;
  if (postDetails.buttonType === buttonTypes[1].name) {
    actions = [
      {
        Name: postDetails.callToActionText,
        Uri: postDetails.ctaButtonUrl
      }
    ];
  }

  let content = postDetails.content;
  if (content) {
    content = content.replace(/\n/g, '<br />');
  }

  let scheduledDateTime;
  if (postDetails.scheduledDate) {
    const timeSelected = new Date(postDetails.scheduledTime);
    const date = postDetails.scheduledDate.split('T')[0].split('-');
    const dateSelected = new Date(date[0], date[1] - 1, date[2]);
    scheduledDateTime = getScheduledDateTime(dateSelected, timeSelected);
  }

  let postInfo = {
    type: postDetails.type ? postDetails.type : 'Announcement',
    originator: postDetails.originator,
    status: postDetails.scheduledDate ? 'scheduled' : 'published',
    title: postDetails.title,
    description: content,
    priority: postDetails.postPriority,
    personas: postDetails.checkboxes.selectedPersonas,
    markets: postDetails.checkboxes.selectedMarkets,
    Products: postDetails.checkboxes.selectedProducts,
    videoUri: postDetails.videoUrl,
    featureImage: imageUrl,
    actions,
    featured: postDetails.type ? false : true,
    scheduled: scheduledDateTime
  };

  const result = await NewsAdminApi.addPost(postInfo);
  if (!result.isSuccess) {
    dispatch(
      core.setErrorScreenMessage(
        result.error,
        false,
        result.errorMessages,
        NEWS_POST_DRAWER
      )
    );
    dispatch(core.toggleMask(false));
    return;
  } else {
    dispatch(core.toggleMask(false));
    return result.response;
  }
};

export const editNewsPost = async (postId, postDetails) => {
  const dispatch = core.Store().dispatch;
  dispatch(core.toggleMask(true));
  let imageUrl = postDetails.imageUrl1;
  if (
    postDetails.postimageFileObject &&
    postDetails.postimageFileObject.length > 0
  ) {
    const formData = new FormData();
    formData.append(
      'imageFile',
      postDetails.postimageFileObject[0],
      postDetails.postimageFileObject[0].name
    );
    const getImageUrl = await NewsAdminApi.postImage(formData);
    if (getImageUrl.isSuccess) {
      imageUrl = getImageUrl.response.url;
    }
  }

  const actions =
    postDetails.buttonType === buttonTypes[1].name
      ? [
          {
            Name: postDetails.callToActionText,
            Uri: postDetails.ctaButtonUrl
          }
        ]
      : null;

  const content =
    postDetails.content && postDetails.content.replace(/\n/g, '<br />');

  let scheduledDateTime;
  if (postDetails.scheduledDate) {
    const timeSelected = new Date(postDetails.scheduledTime);
    const date = postDetails.scheduledDate.split('T')[0].split('-');
    const dateSelected = new Date(date[0], date[1] - 1, date[2]);
    scheduledDateTime = getScheduledDateTime(dateSelected, timeSelected);
  }

  const postInfo = {
    type: postDetails.type ? postDetails.type : 'Announcement',
    originator: postDetails.originator,
    status: postDetails.scheduledDate ? 'scheduled' : 'published',
    title: postDetails.title,
    description: content,
    priority: postDetails.postPriority,
    personas: postDetails.checkboxes.selectedPersonas,
    markets: postDetails.checkboxes.selectedMarkets,
    Products: postDetails.checkboxes.selectedProducts,
    videoUri: postDetails.videoUrl,
    featureImage: imageUrl,
    actions,
    featured: postDetails.type === 'Announcement' ? true : false,
    scheduled: scheduledDateTime
  };

  const result = await NewsAdminApi.editPost(postId, postInfo);
  if (!result.isSuccess) {
    dispatch(
      core.setErrorScreenMessage(
        result.error,
        false,
        result.errorMessages,
        NEWS_POST_DRAWER
      )
    );
    dispatch(core.toggleMask(false));
    return;
  } else {
    dispatch(core.toggleMask(false));
    return result.response;
  }
};

export const deletePost = async postId => {
  const result = await NewsAdminApi.deletePost(postId);
  if (!result.isSuccess) {
    const error = result.error;
    core.CwLog.error(error);
    return;
  }

  return result.response;
};

export const updatePost = async (postId, feedData, screenKey) => {
  const result = await NewsAdminApi.updatePost(postId, feedData);
  if (!result.isSuccess) {
    const error = result.error;
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(
        core.setErrorScreenMessage(
          error,
          false,
          result.errorMessages,
          screenKey
        )
      );
    return;
  }

  return result.response;
};

export const validatePostScheduledDate = (date, time) => {
  const dispatch = core.Store().dispatch;
  dispatch(core.removeScreenInvalid(['scheduledDate'], NEWS_POST_DRAWER));
  const dateDetails = date.split('T')[0].split('-');
  const dateSelected = new Date(
    dateDetails[0],
    dateDetails[1] - 1,
    dateDetails[2]
  );

  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  let dateLimit = new Date();
  dateLimit.setHours(0, 0, 0, 0);
  dateLimit.setDate(dateLimit.getDate() + 60);
  if (
    dateSelected.getTime() > dateLimit.getTime() ||
    dateSelected.getTime() < currentDate.getTime()
  ) {
    dispatch(
      core.setScreenInvalid(
        ['scheduledDate'],
        core.formatMessage(Locale.wrong_scheduled_date),
        NEWS_POST_DRAWER
      )
    );
  }

  const timeSelected = new Date(time);
  const scheduledDateTime = getScheduledDateTime(dateSelected, timeSelected);
  if (scheduledDateTime && scheduledDateTime.getTime() < new Date().getTime()) {
    dispatch(
      core.setScreenInvalid(
        ['scheduledDate'],
        core.formatMessage(Locale.wrong_scheduled_date),
        NEWS_POST_DRAWER
      )
    );
  }
};
