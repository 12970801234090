import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import {
  Dialog,
  ButtonLight,
  ButtonStandard,
  Flexbox,
  FormFieldContainer,
  ComboBoxTypeAheadContainer,
  ScreenMessagePanelContainer,
  MobileLayoutHOC,
  isEmpty
} from 'cw-ui-core';

import { GET, getUrl } from 'src/services/ApiService';
import css from './UpdateContactDialog.scss';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import Locale from 'src/Locale';

export const UPDATE_CONTACT_DIALOG_ID = 'UPDATE_CONTACT_DIALOG';

const UpdateContactDialog = ({ copyContactOnLoad, ...props }) => {
  const { formatMessage } = props.intl;
  const [isBusy, setIsBusy] = useState(false);

  const getEndpoint = text => {
    let endpoint = `/contacts?orderBy=lastName&Conditions=inactiveFlag=false`;
    if (!isEmpty(text)) {
      endpoint += ` and (firstName like "*${text}*" or lastName like "*${text}*")`;
    }

    return getUrl('home', '/company') + endpoint;
  };

  const onSave = async () => {
    setIsBusy(true);
    await props.onSave();
    setIsBusy(false);
  };

  const getSaveButton = () => {
    const title = formatMessage(Locale.change_contact);
    const button = () => (
      <ButtonStandard
        cwId="updateContact"
        automationName="updateContact"
        title={title}
        value={title}
        className={css.submitButton}
        onClick={onSave}
        disabled={!props.contactIsSet}
      />
    );
    const LoadingButton = WithButtonLoadingIndicatorHOC(button);
    return (
      <LoadingButton
        isBusy={isBusy}
        wrapperStyle={{ height: '44px', width: '176px' }}
      />
    );
  };

  useEffect(() => copyContactOnLoad(), [copyContactOnLoad]);

  const dialogStyle = cx({
    [css.containerMobile]: props.isMobileLayout,
    [css.containerNormal]: !props.isMobileLayout
  });

  return (
    <Dialog
      automationName="updateContactDialog"
      className={dialogStyle}
      includeCloseIcon={!props.isMobileLayout}
      includeMobileCloseIcon={props.isMobileLayout}
      onCloseIconClicked={props.onCancel}
    >
      <Flexbox className={css.topContainer}>
        <Flexbox className={css.topTextContainer}>
          <ScreenMessagePanelContainer
            screenKey={UPDATE_CONTACT_DIALOG_ID}
            className={
              props.isMobileLayout ? css.screenMessageMobile : css.screenMessage
            }
          />
          <div className={css.title}>
            {formatMessage(Locale.change_contact)}
          </div>
          <div className={css.subTitle}>
            {formatMessage(Locale.change_contact_on_ticket, {
              ticketId: props.ticketId
            })}
          </div>
        </Flexbox>
        <FormFieldContainer
          path={['contact']}
          screenKey={UPDATE_CONTACT_DIALOG_ID}
          className={props.isMobileLayout ? css.comboBoxMobile : css.comboBox}
        >
          <ComboBoxTypeAheadContainer getEndpoint={getEndpoint} apiGet={GET} />
        </FormFieldContainer>
      </Flexbox>
      <Flexbox className={css.buttonContainer}>
        <ButtonLight
          cwId="cancelDialog"
          automationName="cancelDialog"
          title={formatMessage(Locale.cancel)}
          value={formatMessage(Locale.cancel)}
          className={css.cancelButton}
          onClick={props.onCancel}
        />
        {getSaveButton()}
      </Flexbox>
    </Dialog>
  );
};

export default MobileLayoutHOC(UpdateContactDialog);
