import * as core from 'cw-ui-core';

/*
apiFetch returns {
    isSuccess: !error,
    status,
    response,
    error,
    errorMessages
  };

*/

const getHeaders = (acceptJson = true, jsonType = true) => {
  const headers = new Headers();

  headers.append(
    'Authorization',
    `Bearer ${core.getAccessToken(core.Store().getState().oidc)}`
  );

  if (acceptJson) {
    headers.append(
      'Accept',
      'application/vnd.connectwise.com+json;version=1.0'
    );
  }

  if (jsonType) {
    headers.append(
      'Content-Type',
      'application/vnd.connectwise.com+json;version=1.0'
    );
  }

  return headers;
};

export default class BaseService {
  _baseUrl;

  getBaseUrl() {
    return undefined;
  }

  apiUrl() {
    if (!this._baseUrl) {
      this._baseUrl = this.getBaseUrl();
    }

    return this._baseUrl;
  }

  getBaseUrlFromConfig(configKey) {
    return core.getModuleConfig(core.Store().getState().module, [
      'apiUrls',
      configKey
    ]);
  }

  appendParamsToUrl(url, params) {
    let apiUrl;
    if (url.startsWith('/')) {
      apiUrl = new URL(
        url,
        `${window.location.protocol}//${window.location.hostname}`
      );
    } else {
      apiUrl = new URL(url);
    }

    Object.keys(params).forEach(key =>
      apiUrl.searchParams.append(key, params[key])
    );
    return apiUrl.toString();
  }

  async post(url, body) {
    const options = {
      method: 'POST',
      credentials: 'include',
      headers: getHeaders(),
      body: JSON.stringify(body)
    };

    return await core.apiFetch(url, options);
  }

  async put(url, body) {
    const options = {
      method: 'PUT',
      credentials: 'include',
      headers: getHeaders(),
      body: JSON.stringify(body)
    };

    return await core.apiFetch(url, options);
  }

  async patch(url, data, op = 'replace') {
    const patches = [];
    // convert map of data to array of patch entries
    data.forEach((value, key) => {
      patches.push({
        op,
        path: '/' + key,
        value
      });
    });

    const options = {
      method: 'PATCH',
      credentials: 'include',
      headers: getHeaders(),
      body: JSON.stringify(patches)
    };

    return await core.apiFetch(url, options);
  }

  async get(url) {
    const options = {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders(),
      body: null
    };

    return await core.apiFetch(url, options);
  }

  async getFile(url) {
    const options = {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders(),
      body: null
    };

    return await core.apiFetch(url, options, false);
  }

  async getFileDownload(url) {
    const options = {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders(false, false),
      body: null
    };

    return await core.apiFetch(url, options, false);
  }

  async delete(url) {
    const options = {
      method: 'DELETE',
      credentials: 'include',
      headers: getHeaders(),
      body: null
    };

    return await core.apiFetch(url, options);
  }
}
