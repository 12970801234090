import React, { useEffect, useRef } from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawer.scss';
import { SECURITY_BULLETIN_DRAWER } from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawerContainer';
import SecurityBulletinDrawerContent from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawerContent';
import SecurityBulletinDrawerHeaderContainer from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawerHeaderContainer';
import SecurityBulletinDrawerFooter from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawerFooter';
import { Steps } from 'src/services/SecurityAdmin/SecurityBulletinAdminService';
import CreateBulletinSuccess from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/CreateBulletinSuccess';

const SecurityBulletinDrawer = props => {
  const drawerTitle = useRef(null);
  const drawerContent = useRef(null);
  const drawerBtns = useRef(null);
  useEffect(() => {
    let titleHeight;
    let buttonsHeight;
    titleHeight = drawerTitle.current
      ? drawerTitle.current.getBoundingClientRect().height
      : 0;
    buttonsHeight = drawerBtns.current
      ? drawerBtns.current.getBoundingClientRect().height
      : 0;
    let heightToApply = window.innerHeight - (buttonsHeight + titleHeight + 48);
    drawerContent.current &&
      (drawerContent.current.style.height = `${heightToApply}px`);
  });

  const {
    getSecurityBulletinCriteria,
    securityBulletinCriteria,
    onCloseIconClicked,
    mappingsReady,
    step,
    onProductClick,
    showBreadCrumbs,
    setBreadcrumb,
    onBreadcrumbClick,
    breadcrumbList,
    authorsList,
    securityBulletinDetails,
    onAuthorChange,
    onCreateSecurityBulletinClick,
    createdBulletinId,
    copyToClipboard,
    onViewBulletinClick,
    disabledCreateBulletinButton,
    securityBulletinUrl,
    isBulletinEdit,
    onEditSecurityBulletinClick
  } = props;

  const isBulletinCreated = createdBulletinId !== undefined;

  useEffect(() => {
    drawerContent.current && (drawerContent.current.scrollTop = 0);
  }, []);

  useEffect(() => {
    getSecurityBulletinCriteria();
  }, [getSecurityBulletinCriteria]);

  return (
    <core.Drawer
      automationName="securityBulletinDrawer"
      onCloseIconClicked={onCloseIconClicked}
      customClass={{ [css.securityBulletinDrawer]: !isBulletinCreated }}
    >
      {!isBulletinCreated ? (
        <>
          <div ref={drawerTitle}>
            <SecurityBulletinDrawerHeaderContainer />
          </div>
          <core.ScreenMessagePanelContainer
            screenKey={SECURITY_BULLETIN_DRAWER}
          />
          <div className={css.scrollableArea} ref={drawerContent}>
            <SecurityBulletinDrawerContent
              securityBulletinCriteria={securityBulletinCriteria}
              mappingsReady={mappingsReady}
              currentStep={step}
              onProductClick={onProductClick}
              showBreadCrumbs={showBreadCrumbs}
              setBreadcrumb={setBreadcrumb}
              onBreadcrumbClick={onBreadcrumbClick}
              breadcrumbList={breadcrumbList}
              authorsList={authorsList}
              securityBulletinDetails={securityBulletinDetails}
              onAuthorChange={onAuthorChange}
            />
          </div>

          {step !== Steps.Step_1 && (
            <div ref={drawerBtns} className={css.footerContainer}>
              <SecurityBulletinDrawerFooter
                currentStep={step}
                onCreateSecurityBulletinClick={onCreateSecurityBulletinClick}
                disabledCreateBulletinButton={disabledCreateBulletinButton}
                isBulletinEdit={isBulletinEdit}
                onEditSecurityBulletinClick={onEditSecurityBulletinClick}
              />
            </div>
          )}
        </>
      ) : (
        <CreateBulletinSuccess
          bulletinId={createdBulletinId}
          isBulletinEdit={isBulletinEdit}
          copyToClipboard={copyToClipboard}
          onViewBulletinClick={onViewBulletinClick}
          securityBulletinUrl={securityBulletinUrl}
        ></CreateBulletinSuccess>
      )}
    </core.Drawer>
  );
};

export default SecurityBulletinDrawer;
