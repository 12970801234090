import React from 'react';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';

const SecurityBulletinDrawerFooter = props => {
  if (!props.isBulletinEdit) {
    return (
      <core.ButtonStandard
        cwId="createSecurityBulletinButton"
        automationName="createSecurityBulletinButton"
        value={core.formatMessage(Locale.create_bulletin)}
        disabled={props.disabledCreateBulletinButton}
        onClick={props.onCreateSecurityBulletinClick}
      ></core.ButtonStandard>
    );
  }

  return (
    <core.ButtonStandard
      cwId="editSecurityBulletinButton"
      automationName="editSecurityBulletinButton"
      value={core.formatMessage(Locale.save)}
      disabled={props.disabledCreateBulletinButton}
      onClick={props.onEditSecurityBulletinClick}
    ></core.ButtonStandard>
  );
};

export default SecurityBulletinDrawerFooter;
