import { connect } from 'react-redux';
import {
  showDialog,
  getScreenData,
  getScreenUpdated,
  Store,
  fieldEditUpdate,
  setScreenData,
  getScreenCustom
} from 'cw-ui-core';
import { UPDATE_TICKET_STATUS_DIALOG } from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/TicketUpdateStatusDialog/TicketUpdateStatusDialog';
import { UPDATE_CONTACT_DIALOG_ID } from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/UpdateContactDialog/UpdateContactDialog';
import EditSupportTicketDrawerSummarySection from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawerSummarySection/EditSupportTicketDrawerSummarySection';
import {
  addTicketNotes,
  validateAttachment,
  getTicketBoardStatus,
  reopenClosedTicket,
  getBoardStatusCount,
  calculateDaysDiffBetweenDates
} from 'src/services/Tickets/TicketsService';
import { EDIT_VIEW_TICKET_DRAWER_ID } from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawer';
import { POLICIES } from 'src/services/Authorization';
import HasAccess from 'src/services/RoleAccess';

const loadStatusCount = async boardId => {
  const result = await getBoardStatusCount(boardId, EDIT_VIEW_TICKET_DRAWER_ID);
  if (result && result.isSuccess) {
    const count = result.response ? result.response.count : 0;
    const dispatch = Store().dispatch;
    dispatch(setScreenData(['statusCount'], count, EDIT_VIEW_TICKET_DRAWER_ID));
  }
};

const isClosedTicketReopenAllowed = async (boardStatusJS, ticketJs) => {
  const dispatch = Store().dispatch;
  const today = new Date();
  const ticketOpenDate = new Date(ticketJs.dateEntered);
  const ticketCloseDate = new Date(ticketJs.closedDate);
  if (boardStatusJS.emailConnectorAllowReopenClosedFlag) {
    if (
      !boardStatusJS.emailConnectorNeverReopenByDaysClosedFlag &&
      !boardStatusJS.emailConnectorNeverReopenByDaysFlag
    ) {
      dispatch(
        setScreenData(
          ['allowCloseTicketToOpen'],
          true,
          EDIT_VIEW_TICKET_DRAWER_ID
        )
      );
    } else if (
      boardStatusJS.emailConnectorNeverReopenByDaysClosedFlag ||
      boardStatusJS.emailConnectorNeverReopenByDaysFlag
    ) {
      const dateToCompare = boardStatusJS.emailConnectorNeverReopenByDaysClosedFlag
        ? ticketCloseDate
        : ticketOpenDate;
      const dateDiff = calculateDaysDiffBetweenDates(today, dateToCompare);
      const daysLimitFromApi = boardStatusJS.emailConnectorNeverReopenByDaysClosedFlag
        ? boardStatusJS.emailConnectorReopenDaysClosedLimit
        : boardStatusJS.emailConnectorReopenDaysLimit;
      if (!(dateDiff >= daysLimitFromApi)) {
        dispatch(
          setScreenData(
            ['allowCloseTicketToOpen'],
            true,
            EDIT_VIEW_TICKET_DRAWER_ID
          )
        );
      }
    }
  }
};

const mapStateToProps = state => {
  const ticket = getScreenData(
    state.screen,
    'ticket',
    EDIT_VIEW_TICKET_DRAWER_ID
  );
  let ticketJs = {};
  let isReady = false;
  if (ticket) {
    ticketJs = ticket.toJS();
    isReady = true;
  }

  const boardId = getScreenData(
    state.screen,
    ['ticket', 'board', 'id'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );

  const statusCount = getScreenData(
    state.screen,
    'statusCount',
    EDIT_VIEW_TICKET_DRAWER_ID
  );

  const allowCloseTicketToOpen = getScreenData(
    state.screen,
    'allowCloseTicketToOpen',
    EDIT_VIEW_TICKET_DRAWER_ID
  );

  const contactId = getScreenData(
    state.screen,
    ['ticket', 'contact', 'id'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );
  const attachments = getScreenData(
    state.screen,
    ['attachments'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );
  const isBusy = getScreenData(
    state.screen,
    ['isBusy'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );

  let canUpdateTicket = false;
  const loggedInUserContactId = getScreenData(state.screen, [
    'usersContact',
    'contact',
    'id'
  ]);

  const isArchivedTicket = getScreenCustom(
    state.screen, ['showArchivedTicketDrawer']
  );
  if (
    loggedInUserContactId === contactId &&
    HasAccess(
      [
        POLICIES.HOME_ADMIN,
        POLICIES.HOME_ADVANCED_USER,
        POLICIES.HOME_STANDARD_USER,
        POLICIES.HOME_RESTRICTED_USER,
        POLICIES.HOME_BASIC_USER
      ],
      [POLICIES.UNDEFINED]
    )
  ) {
    canUpdateTicket = true;
  }

  let canUpdateContact = false;
  let canUpdateMessage = false;
  if (
    HasAccess(
      [POLICIES.HOME_ADMIN, POLICIES.HOME_STANDARD_USER],
      [POLICIES.UNDEFINED]
    )
  ) {
    canUpdateTicket = true;
  }

  if (
    HasAccess(
      [POLICIES.HOME_ADMIN, POLICIES.HOME_STANDARD_USER],
      [POLICIES.UNDEFINED]
    ) &&
    isReady &&
    ticketJs.contact &&
    canUpdateTicket &&
    (ticketJs.isClosed ? allowCloseTicketToOpen : true)
  ) {
    canUpdateContact = true;
  }

  let editStatusReady = false;
  if (
    statusCount > 0 &&
    isReady &&
    ticketJs.status &&
    canUpdateTicket &&
    (ticketJs.isClosed ? allowCloseTicketToOpen : true)
  ) {
    editStatusReady = true;
  }

  let note = getScreenUpdated(state.screen, [], EDIT_VIEW_TICKET_DRAWER_ID);
  note && (note = note.toJS());
  if (
    canUpdateTicket &&
    (note.text || (note.attachments && note.attachments.length > 0))
  ) {
    canUpdateMessage = true;
  }

  return {
    ticket: ticketJs,
    boardId,
    isReady,
    contactId,
    attachments: attachments ? attachments.toJS() : undefined,
    disableSendTextBox: ticketJs.isClosed ? !allowCloseTicketToOpen : false,
    isBusy,
    editStatusReady,
    canUpdateTicket,
    canUpdateContact,
    canUpdateMessage,
    isArchivedTicket
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openUpdateStatusDialog: () => {
      dispatch(showDialog(UPDATE_TICKET_STATUS_DIALOG));
    },
    openUpdateContactDialog: () => {
      dispatch(showDialog(UPDATE_CONTACT_DIALOG_ID));
    },
    onSendNote: async () => {
      dispatch(setScreenData('isBusy', true, EDIT_VIEW_TICKET_DRAWER_ID));
      const state = Store().getState();
      const ticket = getScreenData(
        state.screen,
        'ticket',
        EDIT_VIEW_TICKET_DRAWER_ID
      );
      const ticketJs = ticket ? ticket.toJS() : {};

      const ticketId = getScreenData(
        state.screen,
        ['ticket', 'id'],
        EDIT_VIEW_TICKET_DRAWER_ID
      );

      let note = getScreenUpdated(state.screen, [], EDIT_VIEW_TICKET_DRAWER_ID);
      note = note && note.toJS();
      if (ticketJs.isClosed) {
        const result = await reopenClosedTicket();
        if (result && result.response) {
          dispatch(
            setScreenData('ticket', result.response, EDIT_VIEW_TICKET_DRAWER_ID)
          );
        }
      }

      await addTicketNotes(ticketId, note, EDIT_VIEW_TICKET_DRAWER_ID);
      dispatch(setScreenData('isBusy', false, EDIT_VIEW_TICKET_DRAWER_ID));
    },
    onAttachmentChanged: value => {
      const isValidAttachment = validateAttachment(
        value,
        EDIT_VIEW_TICKET_DRAWER_ID
      );
      if (isValidAttachment) {
        dispatch(
          fieldEditUpdate('attachments', value, EDIT_VIEW_TICKET_DRAWER_ID)
        );
      }
    },
    onTicketBoardChange: async boardId => {
      await loadStatusCount(boardId);
      const dispatch = Store().dispatch;
      const state = Store().getState();
      const ticket = getScreenData(
        state.screen,
        'ticket',
        EDIT_VIEW_TICKET_DRAWER_ID
      );
      const ticketJs = ticket ? ticket.toJS() : {};
      if (ticketJs.isClosed) {
        const result = await getTicketBoardStatus(
          boardId,
          EDIT_VIEW_TICKET_DRAWER_ID
        );
        if (result && result.isSuccess) {
          if (result.response) {
            dispatch(
              setScreenData(
                ['boardStatus', boardId],
                result.response,
                EDIT_VIEW_TICKET_DRAWER_ID
              )
            );
            dispatch(
              setScreenData(
                ['status'],
                {
                  status: {
                    id: result.response.emailConnectorReopenStatusViewModel
                      ? result.response.emailConnectorReopenStatusViewModel.id
                      : null,
                    value: result.response.emailConnectorReopenStatusViewModel
                      ? result.response.emailConnectorReopenStatusViewModel.name
                      : null
                  }
                },
                EDIT_VIEW_TICKET_DRAWER_ID
              )
            );
          }

          isClosedTicketReopenAllowed(result.response, ticketJs);
        }
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSupportTicketDrawerSummarySection);
