import { connect } from 'react-redux';
import NewsFeedItem from 'src/components/HomePage/NewsPod/NewsFeed/NewsFeedItem';
import * as core from 'cw-ui-core';
import { VIEW_ADMIN_NEWS_DRAWER } from 'src/components/Admin/NewsPage/NewsFeed/NewsPageDrawer/NewsPageDrawer';
import { getAvatarPhoto } from 'src/services/Avatar/AvatarService';

const mapStateToProps = state => {
  const newsItemCheck = core.getScreenData(
    state.screen,
    ['newsPost'],
    VIEW_ADMIN_NEWS_DRAWER
  );
  const newsItem = newsItemCheck ? newsItemCheck.toJS() : null;
  let authorImageUrl = undefined;
  const isShowVideoPreview = true;
  let previewNewsItem;
  if (newsItem) {
    previewNewsItem = {
      title: newsItem.title,
      author: newsItem.originator,
      authorImageUrl: newsItem.originatorImageUrl,
      authorTitle: newsItem.originatorTitle,
      createdDate: newsItem.scheduled || newsItem.published,
      content: newsItem.description,
      imageUrl1: newsItem.featureImage,
      videoUrl: newsItem.videoUri,
      callToActionText: newsItem.actions && newsItem.actions[0].name
    };
    authorImageUrl =
      previewNewsItem.authorImageUrl &&
      core.getModuleCache(state.module, [
        'avatar',
        'photos',
        previewNewsItem.authorImageUrl
      ]);
  }

  return {
    newsItem: previewNewsItem,
    authorImageUrl:
      authorImageUrl === 'useInitials' ? undefined : authorImageUrl,
    isShowVideoPreview
  };
};

const mapDispatchToProps = () => {
  const state = core.Store().getState();
  const newsItemCheck = core.getScreenData(
    state.screen,
    ['newsPost'],
    VIEW_ADMIN_NEWS_DRAWER
  );
  const newsItem = newsItemCheck ? newsItemCheck.toJS() : null;
  return {
    onNewsItemChange: async newsItem => {
      if (newsItem && newsItem.authorImageUrl) {
        return await getAvatarPhoto(newsItem.authorImageUrl);
      }
    },
    onCallToActionButtonClick: () => {
      if (newsItem.actions && newsItem.actions[0].uri) {
        window.open(newsItem.actions[0].uri);
      }
    }
  };
};

const NewsFeedItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsFeedItem);

export default NewsFeedItemContainer;
