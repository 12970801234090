import { connect } from 'react-redux';
import * as core from 'cw-ui-core';

import PartnerSuccessManagerDrawerHeader from './PartnerSuccessManagerDrawerHeader';
import { PARTNER_SUCCESS_MANAGER_DRAWER } from './PartnerSuccessManagerDrawerContainer';

const mapStateToProps = state => {
  const fullName = core.getScreenData(
    state.screen,
    ['resourceSelected', 'member', 'fullName'],
    PARTNER_SUCCESS_MANAGER_DRAWER
  );

  const photoUrl = core.getScreenData(
    state.screen,
    ['resourceSelected', 'photoUrl'],
    PARTNER_SUCCESS_MANAGER_DRAWER
  );

  const photoAltText = core.getScreenData(
    state.screen,
    ['resourceSelected', 'photoAltText'],
    PARTNER_SUCCESS_MANAGER_DRAWER
  );

  const title = core.getScreenData(
    state.screen,
    ['resourceSelected', 'tooltip'],
    PARTNER_SUCCESS_MANAGER_DRAWER
  );

  return {
    initials: fullName && core.getInitials(fullName),
    photoUrl,
    photoAltText,
    fullName,
    title
  };
};

const mapDispatchToProps = () => {
  return {};
};

const PartnerSuccessManagerDrawerHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerSuccessManagerDrawerHeader);

export default PartnerSuccessManagerDrawerHeaderContainer;
