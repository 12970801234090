import { connect } from 'react-redux';
import {
  MobileLayoutHOC,
  getScreenData,
  getUserEmail,
  setScreenData,
  Store
} from 'cw-ui-core';
import {
  turnOnEmailMfa,
  getValidMfaProviders,
  turnOffEmailMfa,
  disableEmailMfa
} from 'src/services/User/UserService';
import { UPDATE_PROFILE_DRAWER } from 'src/components/Drawer/UserProfileDrawer/UserProfileDrawer';
import MfaEmail from 'src/components/Drawer/UserProfileDrawer/MultiFactor/MfaEmail';
import {
  MFA_EMAIL_ERROR,
  MFA_EMAIL_SUCCESS
} from 'src/components/Drawer/UserProfileDrawer/UserProfileNotifications';

const toArray = data => (data ? data.toJS() : []);
const mapStateToProps = state => {
  const email = getUserEmail(state.oidc);
  const activeMfaProviders = toArray(
    getScreenData(state.screen, ['validMfaProviders'], UPDATE_PROFILE_DRAWER)
  );
  const isDisableEmailMfaVerificationFailed = getScreenData(
    state.screen,
    ['isDisableEmailMfaVerificationFailed'],
    UPDATE_PROFILE_DRAWER
  );
  const isEmailMfaDisabled = getScreenData(
    state.screen,
    ['isEmailMfaDisabled'],
    UPDATE_PROFILE_DRAWER
  );
  const isShowTurnOffVerification = getScreenData(
    state.screen,
    ['isTurnOffCodeSent'],
    UPDATE_PROFILE_DRAWER
  );
  const emailMfaTurnOffCode = getScreenData(state.screen, [
    'emailMfaTurnOffCode'
  ]);

  return {
    email,
    isEmailMfaEnabled:
      isEmailMfaDisabled !== undefined
        ? !isEmailMfaDisabled
        : activeMfaProviders.includes('Email'),
    isShowTurnOffVerification,
    emailMfaTurnOffCode,
    isDisableEmailMfaVerificationFailed,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onDidMount: async () => {
      const result = await getValidMfaProviders(UPDATE_PROFILE_DRAWER);
      dispatch(
        setScreenData(['validMfaProviders'], result, UPDATE_PROFILE_DRAWER)
      );
    },
    onTurnOnEmailMfaClick: async () => {
      const result = await turnOnEmailMfa();
      dispatch(
        setScreenData(['isEmailMfaDisabled'], !result, UPDATE_PROFILE_DRAWER)
      );
      dispatch(
        setScreenData(['isTurnOffCodeSent'], false, UPDATE_PROFILE_DRAWER)
      );
      ownProps.mfaNotificationCallback(MFA_EMAIL_SUCCESS);
    },
    onTurnOffEmailMfaClick: async () => {
      const result = await turnOffEmailMfa();
      dispatch(
        setScreenData(['isTurnOffCodeSent'], result, UPDATE_PROFILE_DRAWER)
      );
    },
    onDisableEmailMfaClick: async () => {
      const verificationCode = getScreenData(Store().getState().screen, [
        'emailMfaTurnOffCode'
      ]);
      const result = await disableEmailMfa(verificationCode);
      if (result) {
        dispatch(
          setScreenData(
            ['isDisableEmailMfaVerificationFailed'],
            !result.isSuccess,
            UPDATE_PROFILE_DRAWER
          )
        );
        ownProps.mfaNotificationCallback(MFA_EMAIL_SUCCESS);
      } else {
        ownProps.mfaNotificationCallback(MFA_EMAIL_ERROR);
      }

      dispatch(setScreenData(['emailMfaTurnOffCode'], ''));
      dispatch(
        setScreenData(
          ['isEmailMfaDisabled'],
          result.response,
          UPDATE_PROFILE_DRAWER
        )
      );
    },
    onEmailMfaDisableCancelClick: () => {
      dispatch(
        setScreenData(['isTurnOffCodeSent'], false, UPDATE_PROFILE_DRAWER)
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileLayoutHOC(MfaEmail));
