import { GET, getUrl } from 'src/services/ApiService';

const SecurityBulletinService = () => {
  const apiUrl = () => getUrl('home', '/bulletins');
  return {
    getSecurityBulletinCriteria: () => {
      return GET(`${apiUrl()}/criteria`);
    },
    getSecurityBulletinById: id => {
      return GET(`${apiUrl()}/${id}`);
    }
  };
};

export default SecurityBulletinService();
