import { GET, getUrl } from 'src/services/ApiService';

const SwitchAccountService = () => {
  const apiUrl = () => getUrl('home', '/partners');

  const getChildren = async partnerId => {
    return await GET(`${apiUrl()}/${partnerId}/children`);
  };

  const getParent = async partnerId => {
    return await GET(`${apiUrl()}/${partnerId}/parent`);
  };

  return {
    getChildren,
    getParent
  };
};

export default SwitchAccountService();
