import React, { useEffect } from 'react';
import * as core from 'cw-ui-core';
import LicenseContainer from 'src/components/Drawer/AddLicenseDrawer/LicenseContainer';
import LicenseDrawerLoading from 'src/components/Drawer/AddLicenseDrawer/LicenseDrawerLoader/LicenseDrawerLoading';

const LicenseDrawer = props => {
  const { onDidMount } = props;
  useEffect(() => {
    onDidMount();
  }, [onDidMount]);

  return (
    <core.Drawer
      automationName="listItemClose"
      fadeDirection="left"
      onCloseIconClicked={props.onCloseIconClicked}
      onBeforeClose={props.onBeforeClose}
      closeOnBlur
    >
      {props.isLoading ? <LicenseDrawerLoading /> : <LicenseContainer />}
    </core.Drawer>
  );
};

export default core.MobileLayoutHOC(LicenseDrawer);
