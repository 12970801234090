import { useState } from 'react';

const initState = {
  defaultName: {
    value: undefined,
    regex: undefined
  }
};

export default (initial = initState) => {
  const [form, setForm] = useState(initial);
  const [originalForm] = useState(initial);
  const [isDirty, setIsDirty] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isCloseAccountVaild, setIsCloseAccountValid] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);

  const passwordValidityChecks = [
    {
      isInvalid: value => {
        return value.length < 10;
      }
    },
    {
      isInvalid: value => {
        return !value.match(/[!@#$%^&*]/);
      }
    },
    {
      isInvalid: value => {
        return !value.match(/[A-Z]/);
      }
    },
    {
      isInvalid: value => {
        return !value.match(/[a-z]/);
      }
    },
    {
      isInvalid: value => {
        return !value.match(/[0-9]/);
      }
    }
  ];

  const isCustomUrlValid = value => {
    return value.match(/^[a-zA-Z0-9]{2,30}$/i);
  };

  const onFormChange = (name, value) => {
    setIsDirty(true);
    const newForm = { ...form };
    newForm[name] = { ...newForm[name], value };
    setForm(newForm);
  };

  const isValidPassword = () => {
    if (
      form.newPassword.validationmsg.filter(item => item.colorCode === 'red')
        .length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setDirtyButton = name => {
    if (name === 'closeAccountPassword') {
      setIsCloseAccountValid(true);
    } else if (name !== 'currentPassword') {
      if (isValidPassword()) {
        setIsPasswordValid(true);
      } else {
        setIsPasswordValid(false);
      }
    }
  };

  const onFormChangePassword = (name, value, validationMessage) => {
    if (value !== null) {
      if (name !== 'currentPassword') {
        passwordValidityChecks.forEach((item, index) => {
          let isInvalid = item.isInvalid(value);
          if (isInvalid) {
            validationMessage[index].imgSrc = 'Tile_Alerts';
            validationMessage[index].cssClass =
              'UserProfileDrawer-messageInvalid';
            validationMessage[index].colorCode = 'red';
          } else {
            validationMessage[index].imgSrc = 'Flag_Enabled';
            validationMessage[index].cssClass =
              'UserProfileDrawer-messageValid';
            validationMessage[index].colorCode = 'green';
          }
        });
      }

      setIsFormUpdated(true);
      setDirtyButton(name);
      const newForm = { ...form };
      newForm[name] = { ...newForm[name], value, validationMessage };
      setForm(newForm);
    }
  };

  const onFormChangeCustomUrl = (name, value) => {
    let error = '';
    if (!value) {
      setIsValidUrl(false);
      error = 'Url required';
    } else if (!isCustomUrlValid(value)) {
      setIsValidUrl(false);
      error = 'Url is invalid';
    } else {
      error = '';
      setIsValidUrl(true);
    }

    const newForm = { ...form };
    newForm[name] = { ...newForm[name], value, error };
    setForm(newForm);
  };

  const resetForm = () => {
    setForm(originalForm);
    setIsPasswordValid(false);
    setIsDirty(false);
    setIsFormUpdated(false);
  };

  const newForm = () => {
    setIsDirty(false);
  };

  const normalizeData = () => {
    const data = Object.entries(form).reduce((normal, [key, valueMap]) => {
      normal[key] = valueMap.value;
      return normal;
    }, {});

    return data;
  };

  return {
    form,
    onFormChange,
    isDirty,
    normalizeData,
    resetForm,
    newForm,
    onFormChangePassword,
    isPasswordValid,
    isCloseAccountVaild,
    onFormChangeCustomUrl,
    isValidUrl,
    isFormUpdated
  };
};
