import React from 'react';
import { withRouter } from 'react-router-dom';
import Routes from 'src/services/Routes';
import NotFoundPage from './NotFoundPage';
import { injectIntl } from 'react-intl';
import Locale from 'src/Locale';

const NotFound404Page = props => {
  const onClick = () => {
    props.history.push(Routes.HOME_PAGE.path);
  };

  const { formatMessage } = props.intl;

  return (
    <NotFoundPage
      automationName="notFound"
      includeHome
      title={formatMessage(Locale.not_found_title)}
      subtitle={formatMessage(Locale.not_found_subtitle)}
      btnText={formatMessage(Locale.take_me_home)}
      onClick={onClick}
    />
  );
};

export default withRouter(injectIntl(NotFound404Page));
