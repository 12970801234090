import { connect } from 'react-redux';
import Routes from 'src/services/Routes';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import AutomateProductDrawer from 'src/components/Drawer/ProductDrawer/AutomateProductDrawer';
import {
  startTrial,
  getTileButtonTextLocaleKey,
  formTypes
} from 'src/services/Products/ProductsService';
import Locale from 'src/Locale';
import { POLICIES } from 'src/services/Authorization';
import HasAccess from 'src/services/RoleAccess';

export const AUTOMATE_PRODUCT_DRAWER = 'AUTOMATE_PRODUCT_DRAWER';

const mapStateToProps = state => {
  const productChk = core.getScreenData(
    state.screen,
    ['product'],
    AUTOMATE_PRODUCT_DRAWER
  );
  const product = productChk ? productChk.toJS() : undefined;
  const hasAccessToDemoOrQuote = HasAccess(
    [POLICIES.ADMIN, POLICIES.BILLING_ADMIN],
    null
  );
  const automatePlans = [
    {
      name: 'quote',
      buttonText: product.allowRequestForQuoteOrDemo
        ? core.formatMessage(Locale.request_quote_button)
        : core.formatMessage(Locale[getTileButtonTextLocaleKey(product)]),
      automationName: 'automate_quote',
      isBusy: core.getScreenData(
        state.screen,
        ['isProcessingQuoteButtonClick'],
        AUTOMATE_PRODUCT_DRAWER
      )
    }
  ];

  const automateLaunch = [
    {
      name: 'launch',
      buttonText:
        !product.allowRequestForQuoteOrDemo &&
        core.formatMessage(Locale[getTileButtonTextLocaleKey(product)]),
      automationName: 'automate_launch',
      isBusy: false
    }
  ];

  const automateBenefits = [
    {
      name: 'discovery',
      imageSrc: 'images/drawer/discovery.png',
      title: core.formatMessage(Locale.discovery),
      description: core.formatMessage(
        Locale.drawer_automate_benefit_discovery_text
      )
    },
    {
      name: 'patching',
      imageSrc: 'images/drawer/patching.png',
      title: core.formatMessage(Locale.patching),
      description: core.formatMessage(Locale.drawer_automate_benefit_patch_text)
    },
    {
      name: 'management',
      imageSrc: 'images/drawer/management.png',
      title: core.formatMessage(Locale.management),
      description: core.formatMessage(
        Locale.drawer_automate_benefit_management_text
      )
    },
    {
      name: 'monitoring',
      imageSrc: 'images/drawer/monitor.png',
      title: core.formatMessage(Locale.monitoring),
      description: core.formatMessage(
        Locale.drawer_automate_benefit_monitor_text
      )
    },
    {
      name: 'automation',
      imageSrc: 'images/drawer/automate.png',
      title: core.formatMessage(Locale.automation),
      description: core.formatMessage(
        Locale.drawer_automate_benefit_automation_text
      )
    }
  ];

  return {
    isDemoOrQuoteEnable: hasAccessToDemoOrQuote,
    buttonTitle: hasAccessToDemoOrQuote
      ? ''
      : core.formatMessage(Locale.unauthorize_disable_button_title_text),
    productName: core.formatMessage(Locale.drawer_automate_title),
    productDescription: core.formatMessage(Locale.drawer_automate_description),
    productLearnMoreText: core.formatMessage(Locale.learn_more),
    plans:
      product && product.allowRequestForQuoteOrDemo
        ? automatePlans
        : automateLaunch,
    benefitHeader: core.formatMessage(Locale.drawer_automate_benefit_header),
    benefits: automateBenefits,
    productOwnedDescription: core.formatMessage(
      Locale.drawer_automate_product_owned
    ),
    productConnectLinkText: core.formatMessage(
      Locale.drawer_product_connect_text
    )
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(core.hideDrawer(AUTOMATE_PRODUCT_DRAWER));
    },
    onUniversityLinkClick: () => {
      window.open(
        'https://www.connectwise.com/globalassets/media/assets/feature-sheet/automatefeaturesheet.docx'
      );
    },
    onConnectLinkClick: () => {
      window.open(
        'https://docs.connectwise.com/ConnectWise_Automate/ConnectWise_Automate_Documentation/060/400 '
      );
    },
    onCardButtonClick: async plan => {
      const state = core.Store().getState();
      const productChk = core.getScreenData(
        state.screen,
        ['product'],
        AUTOMATE_PRODUCT_DRAWER
      );
      const product = productChk ? productChk.toJS() : undefined;
      if (product.allowRequestForQuoteOrDemo) {
        if (plan.name === 'demo') {
          dispatch(
            core.setScreenData(
              ['isProcessingDemoButtonClick'],
              true,
              AUTOMATE_PRODUCT_DRAWER
            )
          );
          dispatch(
            core.setScreenData(
              [
                Routes.HOME_PAGE.id,
                'trialSignup',
                product.productId,
                'formType'
              ],
              formTypes.Demo
            )
          );
        } else if (plan.name === 'quote') {
          dispatch(
            core.setScreenData(
              ['isProcessingQuoteButtonClick'],
              true,
              AUTOMATE_PRODUCT_DRAWER
            )
          );
          dispatch(
            core.setScreenData(
              [
                Routes.HOME_PAGE.id,
                'trialSignup',
                product.productId,
                'formType'
              ],
              formTypes.Quote
            )
          );
        }

        const result = await startTrial(product);
        if (plan.name === 'demo') {
          dispatch(
            core.setScreenData(
              ['isProcessingDemoButtonClick'],
              false,
              AUTOMATE_PRODUCT_DRAWER
            )
          );
        } else if (plan.name === 'quote')
          dispatch(
            core.setScreenData(
              ['isProcessingQuoteButtonClick'],
              false,
              AUTOMATE_PRODUCT_DRAWER
            )
          );
        return result;
      }

      if (product.purchased) {
        const user = core.getUser(state.oidc);
        const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
        if (isGuestUserLoggedIn) {
          const partnerId = core.getPartnerId(state.oidc);
          const stsBaseUrl = core.getModuleConfig(state.module, ['authority']);
          return window.open(
            stsBaseUrl +
              '/account/switch?clientId=cwhome-backend&accountId=' +
              partnerId +
              '&redirectUri=' +
              product.launchUrl
          );
        } else {
          return window.open(product.launchUrl);
        }
      }
    }
  };
};

const AutomateProductDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomateProductDrawer);

export default injectIntl(AutomateProductDrawerContainer);
