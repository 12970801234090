import { createBrowserHistory } from 'history';

let history;

/**
 * Initialize once invoked for first time in App.jsx to avoid breaking
 * oidc redirect uri token_id in url hash during login redirect
 * 
 * https://github.com/ory/hydra/issues/819
 */
export const getHistory = () => {
  if (!history) {
    /**
     * https://stackoverflow.com/questions/42672842/how-to-get-history-on-react-router-v4
     * https://stackoverflow.com/questions/44153517/how-to-access-history-object-outside-of-a-react-component
     * https://stackoverflow.com/questions/42701129/how-to-push-to-history-in-react-router-v4/42716055
     */
    history = createBrowserHistory();
  }

  return history;
};

export default getHistory;