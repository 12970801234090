import React from 'react';
import cx from 'classnames';
import {
  Dialog,
  Label,
  formatMessage,
  MobileLayoutHOC,
  ButtonStandard
} from 'cw-ui-core';
import css from 'src/components/Wallet/WalletNotFound.scss';
import Locale from 'src/Locale';

export const CUSTOMER_WALLET_NOT_FOUND = 'CUSTOMER_WALLET_NOT_FOUND';

const NoWalletFound = props => {
  const dialogButtons = (
    <ButtonStandard
      key="contactUs"
      automationName="contactUs"
      className={css.contactUsButton}
      value={formatMessage(Locale.contact_us)}
      onClick={props.onContactUsClicked}
    />
  );

  const dialogStyle = cx({
    [css.containerMobile]: props.isMobileLayout,
    [css.containerNormal]: !props.isMobileLayout
  });
  return (
    <Dialog
      dialogId={CUSTOMER_WALLET_NOT_FOUND}
      className={dialogStyle}
      buttons={dialogButtons}
      includeCloseIcon={!props.isMobileLayout}
      includeMobileCloseIcon={props.isMobileLayout}
      onCloseIconClicked={props.onCancel}
    >
      <Label
        className={css.headertext}
        value={formatMessage(Locale.wallet_not_found_message)}
      />
    </Dialog>
  );
};

export default MobileLayoutHOC(NoWalletFound);
