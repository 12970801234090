import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import IdentifyProductDrawer from './IdentifyProductDrawer';
import { addToCart } from 'src/services/ShoppingCart/CartService';
import Locale from 'src/Locale';
import {
  startTrial,
  getProductStatusLocaleKey,
  getTileButtonTextLocaleKey,
  contactSupport
} from 'src/services/Products/ProductsService';
import { WALLET_DRAWER } from 'src/components/Drawer/PartnerWalletDrawer/WalletDrawerContainer';

export const IDENTIFY_PRODUCT_DRAWER = 'IDENTIFY_PRODUCT_DRAWER';

const mapStateToProps = state => {
  const productChk = core.getScreenData(
    state.screen,
    ['product'],
    IDENTIFY_PRODUCT_DRAWER
  );
  const product = productChk ? productChk.toJS() : undefined;

  const pricingPlansChk = core.getScreenData(
    state.screen,
    ['pricingPlans'],
    IDENTIFY_PRODUCT_DRAWER
  );
  const pricingPlans = pricingPlansChk ? pricingPlansChk.toJS() : [];

  const currencyFormat = {
    style: 'currency',
    currency: pricingPlans[0].currency,
    minimumFractionDigits: 0
  };

  const isBusy =
    core.getScreenData(state.screen, ['isProcessingCardButtonClick']) === true;

  const statusLocaleKey = product && getProductStatusLocaleKey(product);
  const tileButtonTextLocaleKey = getTileButtonTextLocaleKey(product);

  return {
    productName: core.formatMessage(Locale.drawer_identify_title),
    productDescription: core.formatMessage(Locale.drawer_identify_description),
    productLearnMoreText: core.formatMessage(Locale.learn_more),
    planDescription: core.formatMessage(
      Locale.drawer_identify_plan_description
    ),
    productLabel: core.formatMessage(Locale.identify_label),
    isBusy,
    plans: product && [
      {
        name: 'Basic',
        description: null,
        showButton: tileButtonTextLocaleKey ? true : false,
        buttonText:
          tileButtonTextLocaleKey &&
          core.formatMessage(Locale[tileButtonTextLocaleKey]),
        automationName: `Identify_${getTileButtonTextLocaleKey(product)}`,
        price: core.formatNumber(pricingPlans[0].price, currencyFormat),
        priceInterval: `/${core.formatMessage(Locale.license_per_month)}`,
        billingType: core.formatMessage(Locale.identify_billing_type),
        status: statusLocaleKey
          ? core.formatMessage(Locale[statusLocaleKey], {
              days: product.daysLeftInTrial
            })
          : '',
        features: [
          core.formatMessage(Locale.assessments_level),
          core.formatMessage(Locale.unlimited_assessments),
          core.formatMessage(Locale.brandable_assessment_report),
          core.formatMessage(Locale.remediation_steps),
          core.formatMessage(Locale.single_portal_access),
          core.formatMessage(Locale.manage_intergration),
          core.formatMessage(Locale.reporting),
          core.formatMessage(Locale.industry_comparisons)
        ],
        statusKey: getProductStatusLocaleKey(product)
      }
    ]
  };
};

const showWalletDrawer = () => {
  const dispatch = core.Store().dispatch;
  dispatch(core.hideDrawer(IDENTIFY_PRODUCT_DRAWER));
  dispatch(core.showDrawer(WALLET_DRAWER));
};

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(core.hideDrawer(IDENTIFY_PRODUCT_DRAWER));
      dispatch(core.setScreenData(['isProcessingCardButtonClick'], false));
    },
    onUniversityLinkClick: () => {
      window.open('https://docs.connectwise.com/ConnectWise_Identify');
    },
    onCardButtonClick: async plan => {
      const state = core.Store().getState();
      dispatch(core.setScreenData(['isProcessingCardButtonClick'], true));
      const productChk = core.getScreenData(
        state.screen,
        ['product'],
        IDENTIFY_PRODUCT_DRAWER
      );
      const product = productChk ? productChk.toJS() : undefined;
      if (plan.name === 'Basic') {
        let result;
        if (product.allowFreeTrial) {
          result = await startTrial(product);
        }

        if (product.failed) {
          contactSupport();
          return;
        }

        if (product.purchased && product.paymentPastDue) {
          result = await showWalletDrawer();
        }

        if (product.purchased) {
          const partnerId = core.getPartnerId(state.oidc);
          const user = core.getUser(state.oidc);
          const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
          const stsBaseUrl = core.getModuleConfig(state.module, ['authority']);
          if (isGuestUserLoggedIn) {
            result = window.open(
              stsBaseUrl +
                '/account/switch?clientId=cwhome-backend&accountId=' +
                partnerId +
                '&redirectUri=' +
                product.launchUrl
            );
          } else {
            result = window.open(product.launchUrl);
          }
        }

        if (product.trialExpired || product.inTrial || product.cancelled) {
          result = await addToCart(product);
        }

        dispatch(core.setScreenData(['isProcessingCardButtonClick'], false));
        return result;
      }
    }
  };
};

const IdentifyProductDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentifyProductDrawer);

export default injectIntl(IdentifyProductDrawerContainer);
