import { connect } from 'react-redux';
import { getModuleCache, setModuleCache, Store } from 'cw-ui-core';
import { fromJS } from 'immutable';

import MemberService from 'src/services/Api/HomeApi/MemberService';
import SupportTicketNotesMemberImage, { FetchImageState } from './SupportTicketNotesMemberImage';

const getMemberImage = async memberId => {
  const state = Store().getState();
  let memberImageList = getModuleCache(state.module, ['memberImages']);
  memberImageList = memberImageList ? memberImageList.toJS() : null;

  if ((memberImageList && !memberImageList[memberId]) || !memberImageList) {
    const dispatch = Store().dispatch;

    dispatch(setModuleCache(['memberImages', memberId, 'state'], FetchImageState.RUNNING));
    const result = await MemberService.getMemberImage(memberId);
    if (result && result.isSuccess) {
      const memberImage = {
        image: result.response,
        state: FetchImageState.COMPLETED
      };
      dispatch(setModuleCache(['memberImages', memberId], fromJS(memberImage)));
    } else {
      dispatch(setModuleCache(['memberImages', memberId, 'state'], FetchImageState.FAILED));
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  const note = ownProps.note;
  let memberImageList = getModuleCache(state.module, ['memberImages']);
  memberImageList = memberImageList ? memberImageList.toJS() : null;
  const memberImage = memberImageList ? memberImageList[note.memberId] : '';
  const memberImageState = memberImage ? memberImage.state : '';
  return {
    memberImage,
    memberImageState
  };
};

const mapDispatchToProps = () => {
  return {
    getMemberImage
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportTicketNotesMemberImage);