import CompanyApi from 'src/services/Api/HomeApi/CompanyService';
import { Store, setModuleCache, getModuleCache, getUserId } from 'cw-ui-core';
import { getPartner } from 'src/services/Partners/PartnersService';

export const isOldPortalUrl = () => {
  if (window.location.host.includes('localhost:3000')) {
    return false;
  }

  return !window.location.host.includes('myportallogin');
};
export const getBasePath = () => {
  const pathArray = window.location.pathname.split('/');
  return `/${pathArray[1]}`;
};

export const getPortalUrl = () => {
  if (isOldPortalUrl()) {
    return `${window.location.protocol}//${
      window.location.host
    }${getBasePath()}`;
  }

  return `${window.location.protocol}//${window.location.host}`;
};

export const setCookie = (name, value, days) => {
  var expires=null;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toGMTString();
  }
  else { 
   expires = "";
  }

  document.cookie = name + "=" + value + expires + "; path=/";
};

export const  getCookie = name=> {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
};

export const getManageContactId = stateOidcData => {
  if (stateOidcData) {
    const manageContactId = stateOidcData.getIn([
      'user',
      'profile',
      'manage_contact_id'
    ]);
    if (manageContactId) {
      return manageContactId ? parseInt(manageContactId, 10) : undefined;
    }
  }

  const state = Store().getState();
  const contactModule = getModuleCache(state.module, ['contact']);
  let contact = contactModule ? contactModule.toJS() : undefined;
  if (contact) {
    return contact.id ? contact.id : undefined;
  }
};
export const getManageCompanyId = stateOidcData => {
  if (stateOidcData) {
    const manageCompanyId = stateOidcData.getIn([
      'user',
      'profile',
      'manage_company_id'
    ]);
    if (manageCompanyId) {
      return manageCompanyId ? parseInt(manageCompanyId, 10) : undefined;
    }
  }

  const state = Store().getState();
  const partnerModule = getModuleCache(state.module, ['partner']);
  let partner = partnerModule ? partnerModule.toJS() : undefined;
  if (partner) {
    return partner.companyId ? partner.companyId : undefined;
  }
};

export const getManageCompanyIdAsync = async () => {
  const state = Store().getState();
  const partnerModule = getModuleCache(state.module, ['partners']);
  let partner = partnerModule ? partnerModule.toJS() : undefined;
  if (partner) {
    return partner.companyId ? partner.companyId : undefined;
  }

  partner = await getPartner();
  return partner.companyId ? partner.companyId : undefined;
};

export const getManageCompanyName = stateOidcData => {
  if (stateOidcData) {
    const manageCompanyName = stateOidcData.getIn([
      'user',
      'profile',
      'manage_company_name'
    ]);
    if (manageCompanyName) {
      return manageCompanyName;
    }
  }

  const state = Store().getState();
  const partnerModule = getModuleCache(state.module, ['partner']);
  let partner = partnerModule ? partnerModule.toJS() : undefined;
  if (partner) {
    return partner.companyName ? partner.companyName : undefined;
  }
};

export const getManageCompanyNameAsync = async () => {
  const state = Store().getState();
  const partnerModule = getModuleCache(state.module, ['partners']);
  let partner = partnerModule ? partnerModule.toJS() : undefined;
  if (partner) {
    return partner.companyName ? partner.companyName : undefined;
  }

  partner = await getPartner();
  return partner.companyName ? partner.companyName : undefined;
};

export const getManageContactIdAsync = async () => {
  const state = Store().getState();
  const dispatch = Store().dispatch;
  const userId = getUserId(state.oidc);

  const contactModule = getModuleCache(state.module, ['contact']);
  let contact = contactModule ? contactModule.toJS() : undefined;
  if (contact) {
    return contact.id ? contact.id : undefined;
  }

  const result = await CompanyApi.getContactId(userId);
  if (!result.isSuccess) {
    return undefined;
  }

  contact = result.response;
  dispatch(setModuleCache(['contact'], contact));
  return contact.id ? contact.id : undefined;
};

export const getArrayHelper = () => {
  const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return Array.from(map);
  };

  return {
    groupBy
  };
};

export const once = (fn, context) => {
  var result;
  /* eslint-disable no-param-reassign */
  return function () {
    if (fn) {
      result = fn.apply(context || this, arguments);
      fn = null;
    }

    return result;
  };
  /* eslint-enable no-param-reassign */
};

export const exponentialDelay = (
  interval,
  retryCount,
  startBackoffRetryCount = 0
) => {
  let modifier = retryCount - (startBackoffRetryCount - 1);
  if (modifier <= 0) modifier = 1;
  return new Promise(resolve => setTimeout(resolve, interval * 2 ** modifier));
};

export const copyToClipboard = item => {
  const textField = document.createElement('textarea');
  textField.innerText = item;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const isUrlContainsMaliciousCode = url => {
  /* eslint-disable max-len */
  /* eslint-disable-next-line */
  const isJavaScriptProtocol = /^[\u0000-\u001F ]*j[\r\n\t]*a[\r\n\t]*v[\r\n\t]*a[\r\n\t]*s[\r\n\t]*c[\r\n\t]*r[\r\n\t]*i[\r\n\t]*p[\r\n\t]*t[\r\n\t]*\:/i;
  if (isJavaScriptProtocol.test(url)) {
    return true;
  } else {
    return false;
  }
};

export const emptyFunc = () => {};