import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/AddLicenseDrawer/DrawerActionButtons/DrawerActionButtons.scss';
import { Steps } from 'src/services/Licenses/LicensesService';
import Locale from 'src/Locale';
import cx from 'classnames';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';

const DrawerActionButtons = props => {
  const {
    currentStep,
    enableConfirm,
    licenseError,
    processingError,
    ticketProcessingError,
    onClickBack,
    onClickContinue,
    onClickQuote,
    contactEmail,
    isBusy
  } = props;
  const continueBtnText =
    currentStep === Steps.Step_1
      ? core.formatMessage(Locale.continue)
      : core.formatMessage(Locale.confirm);
  const enableConfirmBtn = currentStep === Steps.Step_2 ? enableConfirm : true;

  const confirmButton = () => (
    <core.ButtonStandard
      className={css.btnConfirm}
      automationName="license-step1-continue"
      value={continueBtnText}
      onClick={() => onClickContinue()}
      disabled={!enableConfirmBtn}
    />
  );
  const ConfirmButtonWithLoading = WithButtonLoadingIndicatorHOC(confirmButton);

  const quoteButton = () => (
    <core.ButtonStandard
      automationName="license-request-quote"
      value={
        licenseError
          ? core.formatMessage(Locale.create_case)
          : core.formatMessage(Locale.license_request_quote)
      }
      className={css.licenseQuoteBtn}
      onClick={() => onClickQuote()}
    />
  );
  const QuoteButtonWithLoading = WithButtonLoadingIndicatorHOC(quoteButton);

  return (
    <core.Flexbox
      className={cx(
        css.licenseActions,
        {
          [css.licenseQuote]: licenseError
        },
        {
          [css.contactManagerBtn]: processingError || ticketProcessingError
        }
      )}
    >
      {!licenseError && !processingError && !ticketProcessingError ? (
        <core.Flexbox className={css.normalActions}>
          {currentStep === Steps.Step_2 && (
            <core.ButtonLight
              className={css.btnBack}
              automationName="license-step1-back"
              value={core.formatMessage(Locale.back)}
              onClick={() => onClickBack()}
            />
          )}
          {currentStep !== Steps.Step_3 && (
            <ConfirmButtonWithLoading
              isBusy={isBusy}
              className={css.loadingBtnContinue}
              loaderStyle={{ width: '10px', height: '10px' }}
            />
          )}
        </core.Flexbox>
      ) : (
        <core.Flexbox className={css.errorActions}>
          {licenseError && (
            <QuoteButtonWithLoading
              isBusy={isBusy}
              className={css.loadingBtnQuote}
              loaderStyle={{ width: '10px', height: '10px' }}
            />
          )}
          {(processingError || ticketProcessingError) && (
            <a
              className={css.contactManagerAction}
              href={`mailto:${contactEmail}`}
            >
              {core.formatMessage(Locale.license_contact_manager)}
            </a>
          )}
        </core.Flexbox>
      )}
    </core.Flexbox>
  );
};

export default DrawerActionButtons;
