import * as core from 'cw-ui-core';
import { GETFILEDOWNLOAD } from 'src/services/ApiService';

export const getAvatarPhoto = async url => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();

  const extension = url.slice(((url.lastIndexOf('.') - 1) >>> 0) + 2);
  const avatarImageUrl = core.getModuleCache(state.module, [
    'avatar',
    'photos',
    url
  ]);
  if (avatarImageUrl) {
    return avatarImageUrl;
  }

  if (!['svg', 'png', 'jpg', 'jpeg'].includes(extension)) {
    const result = await GETFILEDOWNLOAD(url);
    if (result.isSuccess && result.response) {
      const avatarImageUrl = URL.createObjectURL(result.response);
      dispatch(core.setModuleCache(['avatar', 'photos', url], avatarImageUrl));
      return avatarImageUrl;
    } else {
      dispatch(core.setModuleCache(['avatar', 'photos', url], 'useInitials'));
      return;
    }
  }

  dispatch(core.setModuleCache(['avatar', 'photos', url], url));
  return url;
};
