import React from 'react';
import { Flexbox, Placeholder } from 'cw-ui-core';
import cx from 'classnames';
import css from './GenericLoadingComponent.scss';

const CustomLoadingComponent = ({ columns, parentClass }) => {
  return columns.map((column, colIndex) => {
    if (column.stackCount && column.stackCount > 0) {
      const nested = [...Array(column.stackCount)].map((disregard, index) => {
        return (
          <Flexbox className={parentClass} key={`${colIndex}_${index}`}>
            <Placeholder
              key={`Placeholder_${colIndex}_${index}`}
              className={css.placeholder}
            />
          </Flexbox>
        );
      });

      return (
        <Flexbox
          key={`stackedColumn_${column.stackCount}+${colIndex}`}
          className={cx(column.className, css.stackedColumn)}
        >
          {nested}
        </Flexbox>
      );
    }

    return (
      <Flexbox
        className={parentClass}
        key={`parentClass_${column.stackCount}+${colIndex}`}
      >
        <Placeholder className={cx(column.className, css.placeholder)} />
      </Flexbox>
    );
  });
};

export default CustomLoadingComponent;
