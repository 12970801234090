import React from 'react';
import * as core from 'cw-ui-core';
import UserAccountContainer from 'src/components/UserAccount/UserAccountContainer';
import NotFound404Page from 'src/components/NotFoundPage/NotFound404Page';
import LoadConfigurationHOC from 'src/components/App/LoadConfigurationHOC';
import { Router } from 'react-router-dom';
import history from 'src/services/History';
import Locale from 'src/Locale';
import { injectIntl } from 'react-intl';
import InactivityDialog from '../HomePage/InActivity';

class App extends React.Component {
  componentDidMount() {
    this.props.onDidMount();
  }

  getMenuRoutes = () => {
    return this.props.routeList.filter(r => r.showInMenu);
  };

  getMobileMenuRoutes = () => {
    const routes = this.props.routeList;

    let mobileMenuRoutes = routes.filter(c => c.showInMenu);
    routes
      .filter(r => r.showInMobileMenu)
      .forEach(r => {
        let menuCopy = Object.assign({}, r);
        menuCopy.showInMenu = true;
        mobileMenuRoutes.push(menuCopy);
        return mobileMenuRoutes;
      });

    return mobileMenuRoutes;
  };

  render() {
    if (!this.props.isAppReady) {
      return <core.Loader />;
    }

    const { formatMessage } = this.props.intl;

    let menuItems = [
      {
        id: 'updateProfile',
        name: formatMessage(Locale.updateHomeProfile)
      },
      {
        id: 'updateEmailPreferences',
        name: formatMessage(Locale.updateProfileAndPreferenceCenter)
      },
      {
        id: 'companyAccountSetting',
        name: formatMessage(Locale.company_account_setting)
      },
      {
        id: 'logout',
        name: formatMessage(Locale.logout)
      }
    ];

    const state = core.Store().getState();
    const partnerModule = core.getModuleCache(state.module, ['partner']).toJS();

    if (partnerModule.crmAccountId === null) {
      menuItems.splice(1, 1);
    }

    if (this.props.isChild) {
      menuItems.splice(menuItems.length - 1, 0, {
        id: 'switchAccount',
        name: formatMessage(Locale.switch_account)
      });
    }

    if (this.props.isParent && this.props.isGuestUserLoggedIn) {
      menuItems.splice(menuItems.length - 1, 0, {
        id: 'returnToPrimaryAccount',
        name: formatMessage(Locale.return_to_primary_account)
      });

      menuItems.forEach((item, index) => {
        if (item.id === 'updateProfile') {
          menuItems.splice(index, 1);
        }
      });
    }

    return (
      <Router history={history()}>
        <core.NavigationAppContainer
          routes={this.props.routeList}
          noMatch={NotFound404Page}
          menu={
            !this.props.isMobileLayout && this.props.showMenu ? (
              <core.NavigationMenu
                routes={this.getMenuRoutes()}
                logo={core.IconPath.get('CW_Owl')}
                logoIsInlineSVG
                isSkinnyNav
                logoStyle={{ fill: '#22326E', stroke: '#22326E' }}
                footer={
                  <span title={formatMessage(Locale.user_profile)}>
                    <UserAccountContainer
                      avatarStyle={{
                        width: '32px',
                        height: '32px',
                        cursor: 'pointer'
                      }}
                      hasPopup
                      items={menuItems}
                    />
                  </span>
                }
              />
            ) : (
              <span />
            )
          }
        />
        <div>
          <InactivityDialog />
        </div>
      </Router>
    );
  }
}

export default LoadConfigurationHOC(core.MobileLayoutHOC(injectIntl(App)));
