import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  Flexbox,
  ButtonStandard,
  ButtonLight,
  Label,
  FormFieldContainer,
  TextField,
  TextArea,
  Image,
  IconPath,
  ScreenMessagePanelContainer,
  MobileLayoutHOC,
  Placeholder
} from 'cw-ui-core';

import css from 'src/components/HomePage/ToolsPod/Resources/ResourcesDialogs/AddEditResourceDialog.scss';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import Locale from 'src/Locale';

export const ADD_EDIT_RESOURCE_DIALOG = 'ADD_EDIT_RESOURCE_DIALOG';

const AddEditResourceDialog = props => {
  const {
    onSaveClick,
    onCancelClick,
    onTestUrlClick,
    isMobileLayout,
    isSaveDisabled,
    isSaving,
    isEdit,
    isDeleteEnabled,
    onDeleteClick,
    onUrlChange,
    onDidMount,
    isEditLoading,
    intl: { formatMessage }
  } = props;

  const descriptionRef = useRef(null);
  const [remainingDescChar, setRemainingDescChar] = useState(150);

  useEffect(() => {
    onDidMount();
  }, [onDidMount]);

  useEffect(() => {
    const descriptionText =
      descriptionRef.current && descriptionRef.current.props.value;
    const remainingChar = descriptionText ? 150 - descriptionText.length : 150;
    setRemainingDescChar(remainingChar);
  }, [isEditLoading]);

  const onDescriptionChange = (value = '') => {
    setRemainingDescChar(150 - value.length);
  };

  const SaveButton = () => {
    const button = () => (
      <ButtonStandard
        automationName="saveResourceButton"
        value={formatMessage(Locale.save)}
        isSmall
        onClick={onSaveClick}
        className={css.button}
        disabled={isSaveDisabled}
      />
    );
    const LoadingButton = WithButtonLoadingIndicatorHOC(button);
    return (
      <LoadingButton
        isBusy={isSaving}
        wrapperStyle={{ height: '36px', width: '68px', marginLeft: '10px' }}
      />
    );
  };

  return (
    <Dialog
      automationName="resourceDialog"
      className={isMobileLayout ? css.containerMobile : css.containerNormal}
      includeCloseIcon={!isMobileLayout}
      includeMobileCloseIcon={isMobileLayout}
      onCloseIconClicked={onCancelClick}
    >
      <Flexbox
        className={isMobileLayout ? css.topContainerMobile : css.topContainer}
      >
        <ScreenMessagePanelContainer
          screenKey={ADD_EDIT_RESOURCE_DIALOG}
          className={
            isMobileLayout ? css.screenMessageMobile : css.screenMessage
          }
        />
        <Label
          className={css.headerTitle}
          value={
            isEdit
              ? formatMessage(Locale.edit_resource)
              : formatMessage(Locale.add_resource)
          }
        />
        {isEditLoading ? (
          <>
            <Flexbox column>
              <Placeholder className={css.loadingOne} />
              <Placeholder className={css.loadingTwo} />
              <Placeholder className={css.loadingOne} />
              <Placeholder className={css.loadingThree} />
              <Placeholder className={css.loadingOne} />
              <Placeholder className={css.loadingFour} />
            </Flexbox>
          </>
        ) : (
          <>
            <FormFieldContainer
              label={formatMessage(Locale.link_name)}
              className={css.formField}
              path={['linkName']}
              screenKey={ADD_EDIT_RESOURCE_DIALOG}
            >
              <TextField
                className={css.textField}
                placeholder={formatMessage(Locale.link_name_placeholder)}
                maxLength={40}
              />
            </FormFieldContainer>
            <FormFieldContainer
              label={formatMessage(Locale.url)}
              className={css.formField}
              path={['url']}
              screenKey={ADD_EDIT_RESOURCE_DIALOG}
              onAfterChange={onUrlChange}
            >
              <TextField
                className={css.textField}
                placeholder={formatMessage(Locale.url_placeholder)}
              />
            </FormFieldContainer>
            <Flexbox className={css.testLink}>
              <Image
                src="/images/HomePage/application-web-proxy.svg"
                className={css.webImage}
              />
              <Label
                automationName="resourceTestUrlAction"
                value={formatMessage(Locale.test_url)}
                onClick={onTestUrlClick}
              />
            </Flexbox>
            <FormFieldContainer
              label={formatMessage(Locale.description)}
              className={css.formField}
              path={['description']}
              screenKey={ADD_EDIT_RESOURCE_DIALOG}
              onAfterChange={onDescriptionChange}
            >
              <TextArea
                ref={descriptionRef}
                className={css.textAreaField}
                placeholder={formatMessage(Locale.description_placeholder)}
                maxLength={150}
              />
            </FormFieldContainer>
            <Label
              className={css.labelHint}
              value={`${remainingDescChar} / 150`}
            />
          </>
        )}
        <Flexbox className={css.buttonContainer}>
          {!isEditLoading && isDeleteEnabled && (
            <div className={css.leftWrapper}>
              <Flexbox
                automationName="resourceDeleteAction"
                className={css.buttonDelete}
                onClick={onDeleteClick}
              >
                <Image
                  src={IconPath.get('Action_Delete')}
                  className={css.deleteImage}
                />
                <Label value="Delete" className={css.deleteLabel} />
              </Flexbox>
            </div>
          )}
          <ButtonLight
            automationName="cancelDialogButton"
            value={formatMessage(Locale.cancel)}
            isSmall
            onClick={onCancelClick}
            className={css.button}
          />
          {SaveButton()}
        </Flexbox>
      </Flexbox>
    </Dialog>
  );
};

export default MobileLayoutHOC(AddEditResourceDialog);
