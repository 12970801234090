import React, { useState, useEffect } from 'react';
import {
  Flexbox,
  Label,
  formatMessage,
  ButtonStandard,
  ButtonLight,
  TextField,
  Image,
  IconPath,
  FormFieldContainer,
  FlyoutComposer
} from 'cw-ui-core';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import svgCss from 'src/styles/svg.scss';
import css from 'src/components/Drawer/UserProfileDrawer/MultiFactor/MfaApp.scss';
import Locale from 'src/Locale';
import { copyToClipboard } from 'src/services/Utils';
import { UPDATE_PROFILE_DRAWER } from 'src/components/Drawer/UserProfileDrawer/UserProfileDrawer';
import QRCode from 'qrcode.react';

const MfaApp = props => {
  const {
    onDidMount,
    isMobileLayout,
    isAuthMFAEnabled,
    mfaAuthUrl,
    disableMFAKey,
    onAuthMfaSubmitClick,
    mfaCode,
    isTurnOffTokenInvalid,
    onTurnOffAuthMfaClick,
    isTurnOffMfaClick,
    mfaSecretKey,
    onSetUpAuthMfaClick,
    onSetupAuthMfaCancelClick,
    onTurnOffMfaCloseClick
  } = props;

  useEffect(() => {
    onDidMount();
  }, [onDidMount]);

  const [isTurnOffMfaClicked, setIsTurnOffMfaClicked] = useState(false);

  return (
    <Flexbox column className={css.mfaContainer}>
      <Label
        className={css.mfaAuthAppHeader}
        value={
          isMobileLayout
            ? formatMessage(Locale.mfa_app_header_mobile)
            : !mfaAuthUrl
            ? formatMessage(Locale.mfa_app_header)
            : formatMessage(Locale.mfa_app_header_setup)
        }
      />
      <Label
        value={
          isAuthMFAEnabled
            ? formatMessage(Locale.mfa_app_header_detailtext)
            : !isTurnOffMfaClicked
            ? formatMessage(Locale.mfa_app_sub_header_text)
            : ''
        }
      />
      {isAuthMFAEnabled ? (
        <Flexbox className={css.mfaAppEnable} column={isMobileLayout}>
          <Flexbox
            verticalAlign
            className={
              !isTurnOffMfaClick
                ? css.authAppContainer
                : css.authAppContainerTurnOff
            }
          >
            <Image
              src={IconPath.get('Status_GoodProgram')}
              className={svgCss.green}
            />
            <Label value={formatMessage(Locale.mfa_app_active)}></Label>
          </Flexbox>

          {isTurnOffMfaClick && (
            <Flexbox className={css.mfaAppTurnOff} column={isMobileLayout}>
              <Flexbox column className={css.mfaWarning}>
                <Image
                  automationName="turnOffAppClose"
                  className={css.warningCancelIcon}
                  src={IconPath.get('Action_Close')}
                  onClick={onTurnOffMfaCloseClick}
                />
                <Image
                  className={cx(css.warningIcon, {
                    [css.warningIconRed]: isTurnOffTokenInvalid
                  })}
                  src={IconPath.get('Status_Unacknowledged')}
                />
                <Label
                  value={
                    isTurnOffTokenInvalid
                      ? formatMessage(Locale.mfa_app_token_invalid)
                      : formatMessage(Locale.mfa_app_turn_off_confirmation)
                  }
                  grey7
                  className={css.mfaWarningText}
                />
                <FormFieldContainer
                  path={['mfaVerifyCode']}
                  screenKey={UPDATE_PROFILE_DRAWER}
                >
                  <TextField
                    className={css.textfield}
                    automationName="mfaVerifyCode"
                    maxLength={6}
                    placeholder={formatMessage(
                      Locale.mfa_verify_token_placeholder
                    )}
                  />
                </FormFieldContainer>
                <Flexbox className={css.authMfaTurnOff}>
                  <Label
                    automationName="disableAuthMfa"
                    className={cx(css.mfaButton, {
                      [css.mfaButtonRed]: isTurnOffTokenInvalid
                    })}
                    value={formatMessage(Locale.mfa_turn_off)}
                    onClick={() => {
                      disableMFAKey();
                      setIsTurnOffMfaClicked(true);
                    }}
                    isLink
                  ></Label>
                </Flexbox>
              </Flexbox>
            </Flexbox>
          )}
        </Flexbox>
      ) : (
        <>
          {mfaAuthUrl ? (
            <>
              <Flexbox className={css.qrMfa} column={isMobileLayout}>
                <QRCode className={css.qrCode} value={mfaAuthUrl} />
                <Flexbox column>
                  <Label
                    className={css.mfaToken}
                    value={formatMessage(Locale.mfa_authsetup_header_token)}
                  />
                  <FormFieldContainer
                    path={['mfaCode']}
                    screenKey={UPDATE_PROFILE_DRAWER}
                  >
                    <TextField
                      className={css.textfieldMfaCode}
                      automationName="mfaCode"
                      maxLength={6}
                      placeholder={formatMessage(
                        Locale.mfa_app_token_input_placeholder
                      )}
                    />
                  </FormFieldContainer>
                  <Label
                    className={cx(css.mfaToken, css.keyOpacity)}
                    value={formatMessage(Locale.mfa_secret_key)}
                  ></Label>
                  <TextField
                    automationName="secretKey"
                    className={css.email}
                    readOnly
                    value={mfaSecretKey}
                  ></TextField>
                  <Image
                    automationName="copyClipboard"
                    className={cx(css.copyKey, svgCss.actionState)}
                    onClick={() => copyToClipboard(mfaSecretKey)}
                    src={IconPath.get('Action_Copy')}
                  />
                </Flexbox>
              </Flexbox>
              <Flexbox
                className={
                  isMobileLayout
                    ? css.setupButtonContainerMobile
                    : css.setupButtonContainer
                }
              >
                <ButtonLight
                  automationName="setupAuthAppCancel"
                  className={cx(css.mfaAppButton, css.cancelButton)}
                  isStandrd
                  value={formatMessage(Locale.back)}
                  onClick={onSetupAuthMfaCancelClick}
                />
                <ButtonStandard
                  automationName="mfaAppButtonSubmit"
                  className={css.mfaAppButton}
                  isSmall
                  disabled={!mfaCode}
                  value={formatMessage(Locale.submit)}
                  onClick={onAuthMfaSubmitClick}
                />
              </Flexbox>
            </>
          ) : (
            <Flexbox className={css.mfaAppEnable} column={isMobileLayout}>
              <Flexbox verticalAlign className={css.authAppContainer}>
                <Image
                  src={IconPath.get('Action_BlackList')}
                  className={css.mfaDisabled}
                />
                <Label value={formatMessage(Locale.mfa_app_turned_off)}></Label>
              </Flexbox>
              <FlyoutComposer
                className={css.flyoutContainer}
                widget={
                  <Image
                    automationName="turnOffImage"
                    src={IconPath.get('Application_More')}
                  />
                }
                flyout={
                  <>
                    <Label
                      automationName="authMfaSetUp"
                      className={css.menuItem}
                      value={formatMessage(Locale.mfa_app_setup)}
                      onClick={onSetUpAuthMfaClick}
                    />
                    {isAuthMFAEnabled && (
                      <Label
                        automationName="turnOffAuthMfa"
                        className={css.menuItem}
                        value={formatMessage(Locale.mfa_turn_off)}
                        onClick={onTurnOffAuthMfaClick}
                      />
                    )}
                  </>
                }
              />
            </Flexbox>
          )}
        </>
      )}
    </Flexbox>
  );
};

MfaApp.propTypes = {
  mfaAuthSetupButton: PropTypes.bool,
  isMobileLayout: PropTypes.bool,
  isAuthMFAEnabled: PropTypes.bool,
  isTokenValid: PropTypes.bool,
  mfaAuthUrl: PropTypes.string,
  MfaAppBtnWithLoading: PropTypes.func,
  email: PropTypes.string,
  disableMFAKey: PropTypes.func,
  copySharedKeyToClipboard: PropTypes.func,
  isTurnOffMfaClick: PropTypes.bool,
  onMfaAuthSetUpClick: PropTypes.func,
  onTurnOffAuthMfaClick: PropTypes.func,
  onMoreClick: PropTypes.func,
  onMfaAppSetupBackButtonClick: PropTypes.func,
  onSetUpAuthMfaClick: PropTypes.func,
  mfaAuthAppSetUpBackFromMenu: PropTypes.func,
  onSetupAuthMfaCancelClick: PropTypes.func,
  onTurnOffMfaCloseClick: PropTypes.func
};

export default MfaApp;
