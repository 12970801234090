import * as core from 'cw-ui-core';
import { navigateBack } from 'src/services/ShoppingCart/ShoppingCartService';
import { getUser, getPartner } from 'src/services/Partners/PartnersService';
import TransactionService from 'src/services/Api/BillingApi/TransactionService';
import CartItemsService from 'src/services/Api/BillingApi/CartItemsService';
import ScreenConnectService from 'src/services/Api/PartnersApi/ScreenConnectService';
import ToolsService from 'src/services/Api/HomeApi/ToolsService';
import ControlCartItem from './CartItem/ControlCartItem';
import MarketplaceCartItem from './CartItem/MarketplaceCartItem';
import UniteCartItem from './CartItem/UniteCartItem';
import IdentifyCartItem from './CartItem/IdentifyCartItem';
import { getArrayHelper } from 'src/services/Utils';
import Routes from 'src/services/Routes';
import { ADD_TO_CART_DIALOG } from 'src/DialogFactory';
import Locale from 'src/Locale';

const CONTROL_STANDARD_PRODUCT = 'STANDARD-1606';
const CONTROL_PREMIUM_PRODUCT = 'PREMIUM-1606';

export const getBuyerCart = () => {
  const state = core.Store().getState();
  const cartItemsCheck = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'cartItems'
  ]);
  const cartItems = cartItemsCheck ? cartItemsCheck.toJS() : [];
  return cartItems
    .map(c => c.buyerCart)
    .reduce((a, b) => {
      return a.concat(b);
    }, []);
};

const getControlAccountData = async () => {
  const dispatch = core.Store().dispatch;
  const result = await ScreenConnectService.getAccountInstanceInfo();
  if (!result.isSuccess || result.response.status === 'error') {
    const errorMessage = 'Failed to get control account data. ';
    const error =
      errorMessage +
      (result.error || result.response.msg);
    core.CwLog.error(error);
    dispatch(core.setErrorScreenMessage(errorMessage, false, result.errorMessages));
    return;
  }

  return result.response.msg;
};

const cartItemFactory = async cartData => {
  switch (cartData[0].product.toLowerCase()) {
    case 'control':
      const controlAccountData = await getControlAccountData();
      return new ControlCartItem(cartData, controlAccountData);
    case 'cw unite':
      return new UniteCartItem(cartData);
    case 'identify':
      return new IdentifyCartItem(cartData);
    default:
      return new MarketplaceCartItem(cartData);
  }
};

export const deleteCart = async () => {
  const state = core.Store().getState();
  const dispatch = core.Store().dispatch;
  const currentCartId = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'currentCart',
    '_id'
  ]);
  if (currentCartId) {
    const result = await CartItemsService.deleteCurrentCart(currentCartId);
    if (!result.isSuccess || result.response.status === 'error') {
      const errorMessage = 'Failed to delete cart. ';
      const error =
        errorMessage + (result.error || result.response.msg);
      core.CwLog.error(error);
      dispatch(core.setErrorScreenMessage(errorMessage, false, result.errorMessages));
      return;
    }
  }

  return currentCartId;
};

export const updateCart = async currentCart => {
  const dispatch = core.Store().dispatch;
  const result = await CartItemsService.updateCartItems(currentCart);
  if (!result.isSuccess || result.response.status === 'error') {
    const errorMessage = 'Failed to update cart. ';
    const error =
      errorMessage + (result.error || result.response.msg);
    core.CwLog.error(error);
    dispatch(core.setErrorScreenMessage(errorMessage, false, result.errorMessages));
    return;
  }

  return result.response.msg;
};

const getPrice = async (buyerCart, cartReferenceKey) => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const email = core.getUserEmail(state.oidc);
  const result = await TransactionService.getPrice(
    email,
    buyerCart,
    cartReferenceKey
  );
  if (!result.isSuccess || result.response.status === 'error') {
    const errorMessage = 'Failed to get price for cart items. ';
    const error =
      errorMessage +
      (result.error || result.response.msg);
    core.CwLog.error(error);
    dispatch(core.setErrorScreenMessage(errorMessage, false, result.errorMessages));
    return;
  }

  let subtotal = 0;
  let totalProrateAmount = 0;
  const cartPrices = result.response.msg.items;
  cartPrices.forEach(cartPrice => {
    cartPrice.isUsageBased = cartPrice.chargeType === 'usage';
    if (cartPrice.lastInvoiceDate === undefined) {
      buyerCart.forEach(cartItem => {
        const cartItemInstanceId = cartItem.instanceId || null;
        if (cartItemInstanceId === cartPrice.instanceId) {
          if (
            cartItem.quantity > cartItem.currentSeats &&
            (cartItem.licenseType === CONTROL_STANDARD_PRODUCT ||
              cartItem.licenseType === CONTROL_PREMIUM_PRODUCT)
          ) {
            cartPrice.quantity = cartItem.quantity - cartItem.currentSeats;
          }

          if (
            parseFloat(cartItem.price) > cartPrice.price &&
            cartPrice.price !== 0
          ) {
            let prorate =
              (cartPrice.price - cartItem.price) * cartItem.quantity;
            totalProrateAmount += prorate;
            cartPrice.prorate = prorate;
            cartPrice.originalPrice = cartItem.price;
          }
        }
      });
      subtotal += Number(cartPrice.price) * cartPrice.quantity;
      cartPrices.forEach(cartPrice => {
        if (cartPrice.lastInvoiceDate === undefined) {
          buyerCart.forEach(cartItem => {
            if (
              cartItem.instanceId === cartPrice.instanceId &&
              cartItem.quantity !== cartPrice.quantity
            ) {
              cartPrice.quantity = cartItem.quantity;
            }
          });
        }
      });
    }
  });

  core
    .Store()
    .dispatch(
      core.setScreenData([Routes.SHOPPING_CART.id, 'subtotal'], subtotal)
    );
  core
    .Store()
    .dispatch(
      core.setScreenData(
        [Routes.SHOPPING_CART.id, 'totalProrateAmount'],
        totalProrateAmount
      )
    );
  dispatch(
    core.setScreenData([Routes.SHOPPING_CART.id, 'cartPrices'], cartPrices)
  );
  return cartPrices;
};

export const removeCartItem = async () => {
  const cartReferenceKey = core.getQueryParameter('cartReferenceKey');
  if (cartReferenceKey) {
    navigateBack('canceled');
    return;
  }

  const state = core.Store().getState();
  const dispatch = core.Store().dispatch;

  const cartItem = core.getScreenData(
    state.screen,
    ['cartItem'],
    'REMOVE_CART_ITEM'
  );
  const cartItemsCheck = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'cartItems'
  ]);
  let cartItems = cartItemsCheck ? cartItemsCheck.toJS() : [];
  cartItems = cartItems.filter(
    c =>
      !(
        c.productName === cartItem.productName &&
        c.instanceId === cartItem.instanceId
      )
  );

  const currentCartCheck = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'currentCart'
  ]);
  if (!currentCartCheck) {
    const error = 'Failed to update cart. Current cart does not exist.';
    core.CwLog.error(error);
    dispatch(core.setErrorScreenMessage(error, false));
    return;
  }

  let currentCart = currentCartCheck.toJS();
  currentCart.buyercart = cartItems
    .map(c => c.buyerCart)
    .reduce((a, b) => {
      return a.concat(b);
    }, []);
  currentCart = await updateCart(currentCart);
  dispatch(
    core.setScreenData([Routes.SHOPPING_CART.id, 'currentCart'], currentCart)
  );
  dispatch(
    core.setScreenData([Routes.SHOPPING_CART.id, 'cartItems'], cartItems)
  );
  if (currentCart.buyercart.length > 0) {
    await getPrice(currentCart.buyercart);
  } else {
    dispatch(core.setScreenData([Routes.SHOPPING_CART.id, 'subtotal'], 0));
  }
};

const getCartItemsFromBuyerCart = async buyerCart => {
  let cartItems = [];
  const groupedBuyerCart = getArrayHelper().groupBy(
    buyerCart,
    item => item.productName || item.product
  );
  await Promise.all(
    groupedBuyerCart.map(async c => {
      cartItems.push(await cartItemFactory(c[1]));
    })
  );
  return cartItems;
};

const getCartItemsForMarketplace = async cartReferenceKey => {
  const dispatch = core.Store().dispatch;
  const cartPrices = await getPrice([], cartReferenceKey);
  if (!cartPrices) {
    return;
  }

  const cartItems = await getCartItemsFromBuyerCart(cartPrices);
  dispatch(
    core.setScreenData([Routes.SHOPPING_CART.id, 'cartItems'], cartItems)
  );
};

export const getCartItems = async () => {
  const state = core.Store().getState();
  const cartReferenceKey = core.getQueryParameter('cartReferenceKey');
  if (cartReferenceKey) {
    await getCartItemsForMarketplace(cartReferenceKey);
  } else {
    const user = await getUser();
    const dispatch = core.Store().dispatch;
    let result = await CartItemsService.getCartItems(user.id);
    if (!result.isSuccess || result.response.status === 'error') {
      const errorMessage = 'Failed to load cart. ';
      const error =
        errorMessage + (result.error || result.response.msg);
      core.CwLog.error(error);
      dispatch(core.setErrorScreenMessage(errorMessage, false, result.errorMessages));
      return;
    }

    let cartData = result.response.msg.data;
    const partner = await getPartner();
    let currentCart;
    let buyerCart;
    if (cartData.length === 0) {
      currentCart = await updateCart({
        buyerid: user.id,
        partnerId: partner.id,
        buyercart: []
      });
      if (!currentCart) {
        return;
      }

      buyerCart = currentCart.buyercart;
    } else {
      currentCart = cartData[0];
      buyerCart = currentCart.buyercart;
    }

    const cartItems = await getCartItemsFromBuyerCart(buyerCart);
    cartItems.forEach((c, index) => {
      if (c.allowQuantityChange) {
        const quantity = core.getScreenData(state.screen, [
          Routes.SHOPPING_CART.id,
          'cartItemQuantity',
          index.toString(),
          'value'
        ]);
        dispatch(
          core.setScreenData(
            [Routes.SHOPPING_CART.id, 'cartItemQuantity', index.toString()],
            {
              value: quantity || c.quantity,
              changed: quantity && quantity !== c.quantity
            }
          )
        );
      }
    });
    if (buyerCart.length > 0) {
      await getPrice(buyerCart);
    }

    dispatch(
      core.setScreenData([Routes.SHOPPING_CART.id, 'currentCart'], currentCart)
    );
    dispatch(
      core.setScreenData([Routes.SHOPPING_CART.id, 'cartItems'], cartItems)
    );
  }
};

export const updateCartItemQuantity = async (index, value) => {
  const state = core.Store().getState();
  const dispatch = core.Store().dispatch;
  const cartItemsCheck = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'cartItems'
  ]);
  if (!cartItemsCheck) {
    const error = 'Failed to update cart item. Cart items does not exist.';
    core.CwLog.error(error);
    dispatch(core.setErrorScreenMessage(error, false));
    return;
  }

  let cartItems = cartItemsCheck.toJS();
  // eslint-disable-next-line no-underscore-dangle
  let buyerCartItem = cartItems[index]._buyerCartItem;
  if (buyerCartItem.useSeatsAsQuantity) {
    buyerCartItem.seats = value;
  } else {
    buyerCartItem.quantity = value;
  }

  if (buyerCartItem.restrictQuantity && buyerCartItem.allowedQuantities) {
    const newPrice = buyerCartItem.allowedQuantities.find(q => q.id === value);
    if (newPrice) {
      if (newPrice.price) {
        buyerCartItem.price = newPrice.price;
      }

      if (newPrice.product) {
        buyerCartItem.productPlan = newPrice.product;
      }
    }
  }

  const currentCartCheck = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'currentCart'
  ]);
  if (!currentCartCheck) {
    const error = 'Failed to update cart. Current cart does not exist.';
    core.CwLog.error(error);
    dispatch(core.setErrorScreenMessage(error, false));
    return;
  }

  let currentCart = currentCartCheck.toJS();
  currentCart.buyercart = cartItems
    .map(c => c.buyerCart)
    .reduce((a, b) => {
      return a.concat(b);
    }, []);
  await updateCart(currentCart);
  dispatch(
    core.setScreenData([Routes.SHOPPING_CART.id, 'currentCart'], currentCart)
  );
  dispatch(
    core.setScreenData([Routes.SHOPPING_CART.id, 'cartItems'], cartItems)
  );
  if (currentCart.buyercart.length > 0) {
    await getPrice(currentCart.buyercart);
  }
};

export const addToCart = async product => {
  const dispatch = core.Store().dispatch;
  var result = await ToolsService.addToCart(product.productId);
  if (!result.isSuccess) {
    if (result.status === 400 && result.error) {
      dispatch(
        core.showDialog(ADD_TO_CART_DIALOG, {
          cartItems: [],
          productImageSrc: null,
          error: result.error
        })
      );
      return;
    }

    if (result.error || result.response.status === 'error') {
      const error = core.formatMessage(Locale.add_to_cart_unauthorized_error);
      core.CwLog.error(error);
      dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
    }

    return;
  }

  var cartItems = result.response;
  dispatch(
    core.showDialog(ADD_TO_CART_DIALOG, {
      cartItems,
      productImageSrc: `/images/products/${
        product.name === 'CW Unite' ? 'unite' : product.name.toLowerCase()
      }.svg`
    })
  );
};
