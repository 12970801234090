import * as core from 'cw-ui-core';
import React from 'react';
import CardContainer from './CardContainer';
import NewCardContainer from './NewCardContainer';
import css from './Wallet.scss';
import { isCreditCardExpired } from 'src/services/Wallet/WalletService';

class Wallet extends React.Component {
  onSelectedCardChange = value => {
    const selectedCard = this.props.customerWallet.find(w => w.ID === value.id);
    this.props.onCardSelected(selectedCard);
  };

  getCards = () => {
    let { customerWallet, selectedCardId, allowAddNewCard, isShowCVV } =
      this.props;
    return customerWallet.map(item => {
      const selected = selectedCardId && selectedCardId === item.ID;

      item.isExpired =
        item && item.ExpDate
          ? isCreditCardExpired(
              item.ExpDate.slice(0, 2),
              item.ExpDate.slice(2, 4),
              new Date()
            )
          : true;

      return item.IsAddNewCard ? (
        allowAddNewCard && (
          <>
            <NewCardContainer
              disableRemove={customerWallet.length <= 2 || item.IsDefaultCard}
              key={item.ID}
              card={item}
              selected={selected}
              onSelectChange={this.onSelectedCardChange}
              hasCards={customerWallet.length > 1}
            />
          </>
        )
      ) : (
        <CardContainer
          disableRemove={customerWallet.length <= 2 || item.IsDefaultCard}
          isShowCVV={isShowCVV}
          key={item.ID}
          card={item}
          readOnly={item.disabled}
          selected={selected}
          onSelectChange={this.onSelectedCardChange}
        />
      );
    });
  };

  render() {
    return (
      <core.Flexbox className={css.wallet} column leftAlign>
        {this.getCards()}
      </core.Flexbox>
    );
  }
}

export default Wallet;
