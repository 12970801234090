import React from 'react';
import {Placeholder} from 'cw-ui-core';
import cx from 'classnames';
import flexCss from 'src/styles/flex.scss';
import css from './GenericLoadingComponent.scss';

const DefaultLoadingComponent = ({columnCount}) => {
  return [...Array(columnCount)].map((disregard, index) => {
    const isStacked = index % 2 === 0;
    if (isStacked) {
      return <Placeholder key={index} className={cx(flexCss.one, css.placeholder)} />;
    }

    return (
      <div className={cx(css.stacked, flexCss.two)}>
        <Placeholder className={css.two} />
        <Placeholder className={css.three} />
      </div>
    );
  });
};

export default DefaultLoadingComponent;