import React from 'react';
import css from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawer.scss';
import * as core from 'cw-ui-core';

const BannerDrawerHeader = props => {
  return (
    <core.Flexbox column className={css.labelContainer}>
      <core.Label value={props.headerName} className={css.largeLabel} grey7 />
      <core.Label
        className={css.smallLabel}
        value={props.subHeaderName}
      />
    </core.Flexbox>
  );
};

export default BannerDrawerHeader;