import { connect } from 'react-redux';
import {
  MobileLayoutHOC,
  getScreenData,
  removeScreenData,
  setScreenData,
  Store
} from 'cw-ui-core';
import MfaApp from 'src/components/Drawer/UserProfileDrawer/MultiFactor/MfaApp';
import {
  getMfaAuthUrl,
  validateMFAKey,
  disableMFAKey,
  getValidMfaProviders
} from 'src/services/User/UserService';
import { UPDATE_PROFILE_DRAWER } from 'src/components/Drawer/UserProfileDrawer/UserProfileDrawer';
import {
  MFA_APP_SUCCESS,
  MFA_APP_ERROR,
  MFA_APP_SETUP_ERROR
} from 'src/components/Drawer/UserProfileDrawer/UserProfileNotifications';

const checkNumeric = data => {
  let regEx = new RegExp(/^[0-9]{6,6}$/);
  return regEx.test(data);
};

const toArray = data => (data ? data.toJS() : []);
const mapStateToProps = state => {
  const mfaCode = getScreenData(
    state.screen,
    ['mfaCode'],
    UPDATE_PROFILE_DRAWER
  );
  const mfaVerifyCode = getScreenData(
    state.screen,
    ['mfaVerifyCode'],
    UPDATE_PROFILE_DRAWER
  );
  const activeMfaProviders = toArray(
    getScreenData(state.screen, ['validMfaProviders'], UPDATE_PROFILE_DRAWER)
  );
  const isTurnOffTokenInvalid = getScreenData(
    state.screen,
    ['isTurnOffTokenInvalid'],
    UPDATE_PROFILE_DRAWER
  );
  const mfaAuthUrl = getScreenData(
    state.screen,
    ['mfaAuthUrl'],
    UPDATE_PROFILE_DRAWER
  );
  const mfaSecretKey = getScreenData(
    state.screen,
    ['mfaSecretKey'],
    UPDATE_PROFILE_DRAWER
  );
  const isTurnOffMfaClick = getScreenData(
    state.screen,
    ['isTurnOffMfaClick'],
    UPDATE_PROFILE_DRAWER
  );

  return {
    mfaCode,
    mfaVerifyCode,
    mfaAuthUrl,
    mfaSecretKey,
    isAuthMFAEnabled: activeMfaProviders
      ? activeMfaProviders.includes('Authenticator')
      : getScreenData(
          state.screen,
          ['isAuthMFAEnabled'],
          UPDATE_PROFILE_DRAWER
        ),
    isTurnOffMfaClick: isTurnOffMfaClick ? isTurnOffMfaClick : false,
    isTurnOffTokenInvalid: isTurnOffTokenInvalid ? isTurnOffTokenInvalid : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const resetMfaAppCode = () => {
    dispatch(removeScreenData(['mfaCode']), UPDATE_PROFILE_DRAWER);
    dispatch(removeScreenData(['mfaVerifyCode']), UPDATE_PROFILE_DRAWER);
  };

  const getMfaAuthAppUrl = async () => {
    var result = await getMfaAuthUrl();
    if (result) {
      var authenticateUrl = JSON.parse(result).authenticatorUri;
      var key = JSON.parse(result).sharedKey;
      dispatch(setScreenData(['mfaSecretKey'], key, UPDATE_PROFILE_DRAWER));
      dispatch(
        setScreenData(['mfaAuthUrl'], authenticateUrl, UPDATE_PROFILE_DRAWER)
      );
    } else {
      ownProps.mfaNotificationCallback(MFA_APP_SETUP_ERROR);
    }
  };

  const validateAuthMFAKey = async () => {
    const mfaCode = getScreenData(
      Store().getState().screen,
      ['mfaCode'],
      UPDATE_PROFILE_DRAWER
    );
    const result = await validateMFAKey(mfaCode);
    if (result) {
      dispatch(
        setScreenData(['isAuthMFAEnabled'], true, UPDATE_PROFILE_DRAWER)
      );
      ownProps.mfaNotificationCallback(MFA_APP_SUCCESS);
    } else {
      ownProps.mfaNotificationCallback(MFA_APP_ERROR);
    }
  };

  return {
    onDidMount: async () => {
      const result = await getValidMfaProviders(UPDATE_PROFILE_DRAWER);
      dispatch(
        setScreenData(['validMfaProviders'], result, UPDATE_PROFILE_DRAWER)
      );
    },
    disableMFAKey: async () => {
      const mfaVerifyCode = getScreenData(
        Store().getState().screen,
        ['mfaVerifyCode'],
        UPDATE_PROFILE_DRAWER
      );
      if (checkNumeric(mfaVerifyCode)) {
        const result = await disableMFAKey(mfaVerifyCode);
        if (result) {
          await resetMfaAppCode();
          dispatch(
            setScreenData(['isAuthMFAEnabled'], false, UPDATE_PROFILE_DRAWER)
          );
          dispatch(
            setScreenData(
              ['isTurnOffTokenInvalid'],
              false,
              UPDATE_PROFILE_DRAWER
            )
          );
          dispatch(setScreenData(['mfaAuthUrl'], false, UPDATE_PROFILE_DRAWER));
          dispatch(
            setScreenData(['isTurnOffMfaClick'], false, UPDATE_PROFILE_DRAWER)
          );
          ownProps.mfaNotificationCallback(MFA_APP_SUCCESS);
        } else {
          dispatch(
            setScreenData(
              ['isTurnOffTokenInvalid'],
              true,
              UPDATE_PROFILE_DRAWER
            )
          );
          dispatch(
            setScreenData(['isTurnOffMfaClick'], true, UPDATE_PROFILE_DRAWER)
          );
        }
      } else {
        dispatch(
          setScreenData(['isTurnOffTokenInvalid'], true, UPDATE_PROFILE_DRAWER)
        );
        dispatch(
          setScreenData(['isTurnOffMfaClick'], true, UPDATE_PROFILE_DRAWER)
        );
      }
    },
    onTurnOffAuthMfaClick: () => {
      dispatch(
        setScreenData(['isTurnOffMfaClick'], true, UPDATE_PROFILE_DRAWER)
      );
    },
    onSetUpAuthMfaClick: () => {
      getMfaAuthAppUrl();
    },
    onAuthMfaSubmitClick: () => {
      validateAuthMFAKey();
    },
    onTurnOffMfaCloseClick: async () => {
      await resetMfaAppCode();
      dispatch(
        setScreenData(['isAuthMFAEnabled'], true, UPDATE_PROFILE_DRAWER)
      );
      dispatch(
        setScreenData(['isTurnOffMfaClick'], false, UPDATE_PROFILE_DRAWER)
      );
    },
    onSetupAuthMfaCancelClick: async () => {
      await resetMfaAppCode();
      dispatch(setScreenData(['mfaAuthUrl'], false, UPDATE_PROFILE_DRAWER));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileLayoutHOC(MfaApp));
