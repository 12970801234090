import React, { useEffect } from 'react';
import {
  MobileLayoutHOC,
  ScreenMessagePanelContainer,
  Drawer
} from 'cw-ui-core';
import EditSupportTicketDrawerHeaderContainer from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawerHeader/EditSupportTicketDrawerHeaderContainer';
import EditSupportTicketDrawerSummarySectionContainer from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawerSummarySection/EditSupportTicketDrawerSummarySectionContainer';
import SupportTicketNotesSectionContainer from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/SupportTicketNotes/SupportTicketNotesSectionContainer';
import TicketNotAuthorized from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/TicketNotAuthorized';
import Routes from 'src/services/Routes';
import css from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawer.scss';
import NotificationBeforeCloseContainer from 'src/components/Drawer/NotificationBeforeCloseContainer';

export const EDIT_VIEW_TICKET_DRAWER_ID = 'EDIT_VIEW_TICKET_DRAWER';

const SupportTicketDrawer = ({ loadTicket, ...props }) => {
  useEffect(() => {
    const doWork = async () => {
      await loadTicket();
    };

    doWork().then();
  }, [loadTicket, props.ticketFromListView.id, props.ticketIdFromUrl]);

  useEffect(() => {
    if (props.ticketFromListView && props.ticketFromListView.id > 0) {
      const url = Routes.TICKET_DRAWER.path.replace(
        ':ticketId',
        props.ticketFromListView.id
      );
      window.history.pushState(null, 'View Ticket', url);
    }
  });

  const handleDrawerClose = () => {
    setTimeout(props.onClose, 500);
  };

  return (
    <Drawer
      fadeDirection="left"
      onCloseIconClicked={handleDrawerClose}
      closeOnBlur
      onBeforeClose={props.onBeforeClose}
      customClass={css.editSupportTicketDrawerContainer}
    >
      <ScreenMessagePanelContainer screenKey={EDIT_VIEW_TICKET_DRAWER_ID} />
      <NotificationBeforeCloseContainer
        drawerScreenKey={EDIT_VIEW_TICKET_DRAWER_ID}
      ></NotificationBeforeCloseContainer>
      <EditSupportTicketDrawerHeaderContainer
        ticketIdFromUrl={props.ticketIdFromUrl}
      />
      {props.displayNotAuthorizedScreen ? (
        <TicketNotAuthorized ticketId={props.ticketIdFromUrl} />
      ) : (
        <div className={css.summaryAndNotes}>
          <EditSupportTicketDrawerSummarySectionContainer />
          <SupportTicketNotesSectionContainer />
        </div>
      )}
    </Drawer>
  );
};

export default MobileLayoutHOC(SupportTicketDrawer);
