import React from 'react';
import { Redirect } from 'react-router-dom';
import * as core from 'cw-ui-core';
import getScreen from 'src/ScreenFactory';
import Locale from 'src/Locale';
import { POLICIES } from 'src/services/Authorization';
import { IssuePageTab, PartnerSupportPageTab } from 'src/services/Tickets/TicketsService';

/**
 * @typedef Route
 * @property {string} id // name used to identify route
 * @property {string} path // react router path
 * @property {string} [regex] // regex used to identify route when url is dynamic
 * @property {boolean} [exact] // true to tell react router to match path exactly
 * @property {function} component // returns component to have react router render for route
 * @property {boolean} [showInMenuFooter] // true to tell if it should come in bottomn list
 * @property {boolean} [showInMenu] // true to show in left menu
 * @property {string} [icon] // left menu icon
 * @property {function} [name] // returns localized name for left menu
 *
 * @property {string[]} roles // allowed roles that have access to route
 * @property {function} [configured] // returns if route feature is configured
 */

const Routes = {
  HOME_PAGE: {
    id: 'home_page',
    path: '/',
    exact: true,
    component: props => getScreen('HomePage', props),
    showInMenuFooter: false,
    showInMenu: true,
    icon: 'Object_HomeAutomation',
    name: () => core.formatMessage(Locale.home)
  },
  HOME_PAGE_NOTIFICATION: {
    id: 'home_page_notification',
    path: '/',
    exact: true,
    showInMenuFooter: false
  },
  ONE_TRUST_NOTIFICATION: {
    id: 'one_trust_notification',
    path: '/',
    exact: true,
    showInMenuFooter: false
  },
  HOME_PAGE_ALT: {
    id: 'home_page_alt',
    path: '/home',
    exact: true,
    component: props => getScreen('HomePage', props),
    showInMenuFooter: false,
    showInMenu: false,
    icon: 'Object_HomeAutomation',
    name: () => core.formatMessage(Locale.home)
  },
  UNIVERSITY_REDIRECT_FROM_PAGE: {
    id: 'university_redirect_from_page',
    path: '/university',
    exact: true,
    component: () => {
      return <Redirect to="/education/university" />;
    }
  },
  UNIVERSITY_PAGE: {
    id: 'university_page',
    path: '/education/university',
    exact: false,
    component: props =>
      getScreen('UniversityPage', { ...props, routePath: '/university' }),
    showInMenuFooter: false,
    showInMenu: true,
    icon: 'Menu_University',
    name: () => core.formatMessage(Locale.university),
    allowedRoles: () => [
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER,
      POLICIES.HOME_STANDARD_USER,
      POLICIES.HOME_BASIC_USER,
      POLICIES.HOME_RESTRICTED_USER
    ]
  },
  DOCUMENTATION_PAGE: {
    id: 'documentation_page',
    path: '/education/documentation',
    exact: false,
    component: props =>
      getScreen('DocumentationPage', { ...props, routePath: '/documentation' }),
    showInMenuFooter: false,
    showInMenu: false,
    icon: 'Menu_University',
    name: () => core.formatMessage(Locale.documentation),
    allowedRoles: () => [
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER,
      POLICIES.HOME_STANDARD_USER,
      POLICIES.HOME_BASIC_USER,
      POLICIES.HOME_RESTRICTED_USER
    ]
  },
  BILLING_PAGE: {
    id: 'billing_page',
    path: '/billing',
    exact: true,
    component: props => getScreen('BillingPage', props),
    showInMenuFooter: false,
    showInMenu: true,
    icon: 'Application_Expense_Report',
    name: () => core.formatMessage(Locale.billing),
    allowedRoles: () => [
      POLICIES.ADMIN,
      POLICIES.BILLING_ADMIN,
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER
    ],
    disallowedRoles: () => [
      POLICIES.NO_COMPANY_CONTACT,
      POLICIES.NO_COMPANY,
      POLICIES.UNDEFINED
    ]
  },
  SSO_USER_MGMT_PAGE: {
    id: 'sso_user_mgmt_page',
    path: '/sso/users',
    exact: true,
    component: props => getScreen('SsoUserManagementPage', props),
    showInMenuFooter: false,
    showInMenu: true,
    icon: 'Object_Group',
    name: isMobileLayout =>
      core.formatMessage(
        isMobileLayout ? Locale.sso_management : Locale.users_menu_hover_text
      ),
    allowedRoles: () => [POLICIES.ADMIN, POLICIES.USER_MANAGEMENT]
  },
  SSO_USER_MGMT_PAGE_SUPPORT: {
    id: 'sso_user_mgmt_page_support',
    path: '/sso/support',
    exact: true,
    component: props =>
      getScreen('SsoUserManagementPage', { ...props, ssoRoute: '/support' })
  },
  SSO_USER_MGMT_PAGE_ROLES: {
    id: 'sso_user_mgmt_page_support',
    path: '/sso/roles',
    exact: true,
    component: props =>
      getScreen('SsoUserManagementPage', { ...props, ssoRoute: '/roles' })
  },
  SSO_USER_MGMT_PAGE_AUTHENTICATION: {
    id: 'sso_user_mgmt_page_support',
    path: '/sso/authentication',
    exact: true,
    component: props =>
      getScreen('SsoUserManagementPage', {
        ...props,
        ssoRoute: '/authentication'
      })
  },
  SSO_USER_MGMT_PAGE_IMPORTED_USERS: {
    id: 'sso_user_mgmt_page_support',
    path: '/sso/importedusers',
    exact: true,
    component: props =>
      getScreen('SsoUserManagementPage', {
        ...props,
        ssoRoute: '/importedusers'
      })
  },
  SSO_USER_MGMT_PAGE_PRODUCTS: {
    id: 'sso_user_mgmt_page_support',
    path: '/sso/products',
    exact: true,
    component: props =>
      getScreen('SsoUserManagementPage', {
        ...props,
        ssoRoute: '/products'
      })
  },
  SSO_USER_MGMT_PAGE_APPLICATION_ROLES: {
    id: 'sso_user_mgmt_page_application_roles',
    path: '/sso/application-roles',
    exact: true,
    component: props =>
      getScreen('SsoUserManagementPage', {
        ...props,
        ssoRoute: '/application-roles'
      })
  },
  SSO_USER_MGMT_PAGE_PRODUCTS_REGISTRATIONTOKEN: {
    id: 'sso_user_mgmt_page_support',
    path: '/sso/products/registrationToken',
    exact: true,
    component: props =>
      getScreen('SsoUserManagementPage', {
        ...props,
        ssoRoute: '/products'
      })
  },   
  ISSUE_PAGE: {
    id: 'issue_page',
    path: '/partnersupport/issues',
    exact: true,
    component: props =>
      getScreen('IssuePage', {
        ...props,
        defaultTab: IssuePageTab.ISSUES
      }),
    showInMenuFooter: false,
    showInMenu:true,
    icon: 'Application_Issues',
    name: ()=> core.formatMessage(Locale.issues),
    allowedRoles: () => [
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER,
      POLICIES.HOME_STANDARD_USER,
      POLICIES.HOME_RESTRICTED_USER,
      POLICIES.HOME_BASIC_USER
    ],
    disallowedRoles: () => [
      POLICIES.NO_COMPANY,
      POLICIES.NO_COMPANY_CONTACT,
      POLICIES.UNDEFINED
    ]
  },
  PARTNER_SUPPORT_SALESFORCE_PAGE: {
    id: 'PartnerSupportRedirect',
    path: '/partnersupport',
    exact: true,
    component: props => getScreen('PartnerSupportRedirect', props),
    showInMenuFooter: false,
    showInMenu: true,
    icon: 'Action_Support',
    name: isMobileLayout =>
      core.formatMessage(
        isMobileLayout ? Locale.partner_support : Locale.support
      ),
    allowedRoles: () => [
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER,
      POLICIES.HOME_STANDARD_USER,
      POLICIES.HOME_RESTRICTED_USER,
      POLICIES.HOME_BASIC_USER
    ],
    disallowedRoles: () => [
      POLICIES.NO_COMPANY,
      POLICIES.NO_COMPANY_CONTACT,
      POLICIES.UNDEFINED
    ]
  },
  CREATE_TICKET: {
    id: 'create_ticket',
    path: '/partnersupport/createticket',
    regex: core.REGEX_NUMERIC_ID_MATCH,
    exact: true,
    showInMenu: false,
    showInMobileMenu: false,
    component: props =>
      getScreen('PartnerSupportPage', {
        ...props,
        showCreateTicketDrawer: true
      }),
    showInMenuFooter: false,
    icon: 'Action_Support',
    name: () => core.formatMessage(Locale.support),
    allowedRoles: () => [
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER,
      POLICIES.HOME_STANDARD_USER,
      POLICIES.HOME_BASIC_USER
    ],
    disallowedRoles: () => [
      POLICIES.NO_COMPANY,
      POLICIES.NO_COMPANY_CONTACT,
      POLICIES.UNDEFINED
    ]
  },
  TICKET_DRAWER: {
    id: 'ticket_drawer',
    path: '/partnersupport/ticket/:ticketId',
    regex: core.REGEX_NUMERIC_ID_MATCH,
    exact: true,
    showInMenu: false,
    showInMobileMenu: false,
    component: props =>
      getScreen('PartnerSupportPage', {
        ...props,
        defaultTab: PartnerSupportPageTab.ARCHIVED_TICKETS
      }),
    showInMenuFooter: false,
    icon: 'Action_Support',
    name: () => core.formatMessage(Locale.support),
    allowedRoles: () => [
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER,
      POLICIES.HOME_STANDARD_USER,
      POLICIES.HOME_RESTRICTED_USER,
      POLICIES.HOME_BASIC_USER
    ],
    disallowedRoles: () => [
      POLICIES.NO_COMPANY,
      POLICIES.NO_COMPANY_CONTACT,
      POLICIES.UNDEFINED
    ]
  },
  KNOWN_ISSUES_SITEMAP: {
    id: 'knownissues_sitemap',
    path: '/partnersupport/knownissues/sitemap.xml',
    exact: true,
    showInMenu: false,
    showInMobileMenu: false,
    showInMenuFooter: false,
    component: () => {
      const homeUrl = core.getModuleConfig(core.Store().getState().module, [
        'apiUrls',
        'home'
      ]);
      const sitemapUrl = homeUrl + '/knownissues/sitemap.xml';
      window.location.href = sitemapUrl;
      return null;
    }
  },
  KNOWN_ISSUE_PAGE: {
    id: 'known_issue_redirect',
    path: '/partnersupport/knownIssues',
    exact: true,
    component: () => {
      return <Redirect to="/partnersupport/issues" />;
    },
    allowedRoles: () => [
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER,
      POLICIES.HOME_STANDARD_USER,
      POLICIES.HOME_RESTRICTED_USER,
      POLICIES.HOME_BASIC_USER
    ],
    disallowedRoles: () => [
      POLICIES.NO_COMPANY,
      POLICIES.NO_COMPANY_CONTACT,
      POLICIES.UNDEFINED
    ]
  },
  HISTORICAL_CASES_PAGE: {
    id: 'historical_cases_page',
    path: '/partnersupport/historicalCases',
    exact: true,
    component: props =>
      getScreen('PartnerSupportPage', {
        ...props,
        defaultTab: PartnerSupportPageTab.ARCHIVED_TICKETS
      }),
    allowedRoles: () => [
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER,
      POLICIES.HOME_STANDARD_USER,
      POLICIES.HOME_RESTRICTED_USER,
      POLICIES.HOME_BASIC_USER
    ],
    disallowedRoles: () => [
      POLICIES.NO_COMPANY,
      POLICIES.NO_COMPANY_CONTACT,
      POLICIES.UNDEFINED
    ]
  },
  ISSUE_DRAWER: {
    id: 'issue_drawer',
    path: '/partnersupport/issues/:issueId',
    regex: core.REGEX_NUMERIC_ID_MATCH,
    exact: true,
    showInMenu: false,
    showInMobileMenu: false,
    component: props =>
      getScreen('IssuePage', {
        ...props,
        defaultTab: IssuePageTab.ISSUES
      }),
    showInMenuFooter: false,
    icon: 'Action_Support',
    name: () => core.formatMessage(Locale.support),
    allowedRoles: () => [
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER,
      POLICIES.HOME_STANDARD_USER,
      POLICIES.HOME_RESTRICTED_USER,
      POLICIES.HOME_BASIC_USER
    ],
    disallowedRoles: () => [
      POLICIES.NO_COMPANY,
      POLICIES.NO_COMPANY_CONTACT,
      POLICIES.UNDEFINED
    ]
  },
  KNOWN_ISSUE_DRAWER: {
    id: 'known_issue_drawer',
    path: '/partnersupport/knownIssues/:issueId',
    regex: core.REGEX_NUMERIC_ID_MATCH,
    exact: true,
    showInMenu: false,
    showInMobileMenu: false,
    component: props =>
      getScreen('IssuePage', {
        ...props,
        defaultTab: IssuePageTab.ISSUES
      }),
    showInMenuFooter: false,
    icon: 'Action_Support',
    name: () => core.formatMessage(Locale.support),
    allowedRoles: () => [
      POLICIES.HOME_ADMIN,
      POLICIES.HOME_ADVANCED_USER,
      POLICIES.HOME_STANDARD_USER,
      POLICIES.HOME_RESTRICTED_USER,
      POLICIES.HOME_BASIC_USER
    ],
    disallowedRoles: () => [
      POLICIES.NO_COMPANY,
      POLICIES.NO_COMPANY_CONTACT,
      POLICIES.UNDEFINED
    ]
  },
  USER_PAGE: {
    id: 'user_page',
    path: '/settings/user',
    component: props => getScreen('UserPage', props)
  },
  SHOPPING_CART: {
    id: 'shopping_cart',
    path: '/shoppingcart',
    component: props => getScreen('ShoppingCart', props),
    showInMenuFooter: false,
    showInMenu: false,
    showInMobileMenu: true,
    icon: 'Menu_Procurement',
    name: () => core.formatMessage(Locale.shopping_cart),
    allowedRoles: () => [POLICIES.BILLING_ADMIN, POLICIES.ADMIN]
  },
  COMPANY_ACCOUNT_SETTING: {
    id: 'company',
    path: '/company',
    exact: true,
    component: props => getScreen('HomePage', props)
  },
  GLOBAL_PREFERENCE_REDIRECT: {
    id: 'global_preference',
    path: '/globalPreference',
    exact: true,
    component: props => getScreen('HomePage', props)
  },
  LOGOUT: {
    id: 'logout',
    path: '/logout',
    component: props => getScreen('LogoutPage', props),
    showInMenuFooter: true,
    showInMenu: false,
    showInMobileMenu: true,
    icon: 'Action_Logout',
    name: () => core.formatMessage(Locale.logout)
  },
  IDENTIFY: {
    id: 'identify',
    path: '/identify',
    exact: true,
    component: props =>
      getScreen('HomePage', { ...props, showDrawer: 'identify' })
  },
  UNITE: {
    id: 'unite',
    path: '/unite',
    exact: true,
    component: props => getScreen('HomePage', { ...props, showDrawer: 'unite' })
  },
  PROFILE: {
    id: 'profile',
    path: '/profile',
    exact: true,
    showProfileDrawer: true,
    component: props => getScreen('HomePage', props)
  },
  LICENSCE_DRAWER: {
    id: 'licensce_drawer',
    path: '/addlicenses',
    exact: false,
    showInMenu: false,
    showInMobileMenu: false,
    component: props => getScreen('HomePage', props),
    showInMenuFooter: false,
    icon: 'Action_Support',
    allowedRoles: () => [POLICIES.BILLING_ADMIN, POLICIES.ADMIN]
  },
  NEWS_FEED: {
    id: 'news_page',
    path: '/admin/news',
    exact: true,
    component: props => getScreen('NewsAdminPage', props),
    showInMenuFooter: false,
    showInMenu: true,
    icon: 'Add_Note',
    name: () => 'News Admin',
    allowedRoles: () => [
      POLICIES.NEWS_FEED_ADMIN,
      POLICIES.NEWS_FEED_POSTER,
      POLICIES.SECURITY_BULLETINS_ADMIN
    ],
    mustHaveRoles: () => [POLICIES.CONNECTWISE_COLLEAGUE]
  }, 
  SECURITY_BULLETIN_DRAWER: {
    id: 'securitybulletin_drawer',
    path: '/securityBulletin/:securityBulletinId',
    exact: false,
    showInMenu: false,
    showInMobileMenu: false,
    component: props => getScreen('HomePage', props),
    showInMenuFooter: false
  },
  BANNER_DRAWER: {
    id: 'banner_drawer',
    path: '/banner/:bannerId',
    exact: false,
    showInMenu: false,
    showInMobileMenu: false,
    component: props => getScreen('HomePage', props),
    showInMenuFooter: false
  },
  BANNER_CONFIGURATION: {
    id: 'banner_configuration',
    path: '/',
    component: props => getScreen('BannerConfiguration', props),
    name: () => Locale.banner_name,
    exact: true
  }
};

export default Routes;
