import React from 'react';
import { Steps } from 'src/services/NewsAdmin/NewsAdminService';
import * as core from 'cw-ui-core';
import css from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsPostDrawer.scss';
import Locale from 'src/Locale';

const DrawerFooter = props => {
  return (
    <>
      <div className={css.buttonContainer}>
        {props.currentStep === Steps.Step_2 && (
          <core.ButtonStandard
            cwId="continueButton"
            automationName="continueButton"
            value={core.formatMessage(Locale.continue)}
            disabled={props.disabledContinueButton}
            onClick={() => {
              props.onTypeClick('', props.currentStep);
            }}
          ></core.ButtonStandard>
        )}
        {props.currentStep === Steps.Step_3 && !props.isPostEdit && (
          <core.ButtonStandard
            cwId="createPostButton"
            automationName="createPostButton"
            value={core.formatMessage(Locale.createPost)}
            disabled={props.disabledCreatePostButton}
            onClick={props.onCreatePost}
          ></core.ButtonStandard>
        )}
        {props.currentStep === Steps.Step_3 && props.isPostEdit && (
          <core.ButtonStandard
            cwId="editPostButton"
            automationName="editPostButton"
            value={core.formatMessage(Locale.save)}
            disabled={props.disabledCreatePostButton}
            onClick={props.onEditPost}
          ></core.ButtonStandard>
        )}
      </div>
    </>
  );
};

export default DrawerFooter;
