import React from 'react';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import css from './AccessDeniedPage.scss';
import Routes from 'src/services/Routes';
import { connect } from 'react-redux';

class AccessDeniedPage extends React.Component {
  componentDidMount() {
    this.props.onDidMount();
  }

  onClick = () => {
    this.props.history.push(Routes.HOME_PAGE.path);
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className={css.content}>
        <core.Image
          className={css.image}
          src="/images/accessdenied-image.png"
          isInlineSVG={false}
          alt={formatMessage(Locale.access_denied)}
        />
        <core.Label
          className={css.title}
          value={formatMessage(Locale.access_denied_title)}
        />
        <core.ButtonStandard
          automationName="take_me_home"
          className={css.btn}
          value={formatMessage(Locale.take_me_home)}
          onClick={this.onClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = () => {
  return {
    onDidMount: () => {
      core.runSaga(core.addNavigation, 'access_denied_page'); // log access denied page navigation
    }
  };
};

export default withRouter(
  connect(undefined, mapDispatchToProps)(injectIntl(AccessDeniedPage))
);
