import React from 'react';
import Autolinker from 'autolinker';
import { Label } from 'cw-ui-core';

const AutoLinkMessage = props => {
  const isUrlPatternMatch = match => {
    if (match.getType() === 'url') {
      var url = match.getUrl();

      /* Below regex would be true for below cases:
              1) Url with http | http://www.google.com
              2) Url with ftp  | ftp://test_boze:bl123@internet.address.edu/path/file.gz
              3) Url with //   | //www.google.com
      */
      const urlRegEx = /^(http|ftp){0,1}?:{0,1}\/\//;

      if (urlRegEx.test(url)) {
        return false;
      }
    }

    return true; // autolink everything else as usual
  };

  const config = {
    urls: true,
    stripPrefix: false
  };

  // If we use custom pattern to match, it will ignore match for all type of urls (urls: true)
  if (props.useCustomMatcher)
    config['replaceFn'] = match => isUrlPatternMatch(match);

  return (
    <Label
      automationName={props.automationName}
      className={props.className}
      isHTML
      value={Autolinker.link(props.content, config)}
    />
  );
};

AutoLinkMessage.defaultProps = {
  useCustomMatcher: true
};

export default AutoLinkMessage;
