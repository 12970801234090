import { connect } from 'react-redux';
import Routes from 'src/services/Routes';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import ManageProductDrawer from './ManageProductDrawer';
import {
  startTrial,
  getTileButtonTextLocaleKey,
  formTypes
} from 'src/services/Products/ProductsService';
import Locale from 'src/Locale';
import { POLICIES } from 'src/services/Authorization';
import HasAccess from 'src/services/RoleAccess';

export const MANAGE_PRODUCT_DRAWER = 'MANAGE_PRODUCT_DRAWER';

const mapStateToProps = state => {
  const productChk = core.getScreenData(
    state.screen,
    ['product'],
    MANAGE_PRODUCT_DRAWER
  );
  const product = productChk ? productChk.toJS() : undefined;

  const isBusyDemo =
    core.getScreenData(state.screen, ['isProcessingDemoButtonClick ']) === true;

  const isBusyQuote =
    core.getScreenData(state.screen, ['isProcessingQuoteButtonClick']) === true;

  const hasAccessToDemoOrQuote = HasAccess(
    [POLICIES.ADMIN, POLICIES.BILLING_ADMIN],
    null
  );

  return {
    isDemoOrQuoteEnable: hasAccessToDemoOrQuote,
    buttonTitle: hasAccessToDemoOrQuote
      ? ''
      : core.formatMessage(Locale.unauthorize_disable_button_title_text),
    productName: core.formatMessage(Locale.drawer_manage_title),
    productDescription: core.formatMessage(Locale.drawer_manage_description),
    productLearnMoreText: core.formatMessage(Locale.learn_more),
    bannerHeader: core.formatMessage(Locale.drawer_manage_banner_header),
    bannerDescription: core.formatMessage(
      Locale.drawer_manage_banner_description
    ),
    isBusyDemo,
    isBusyQuote,
    plans: product && [
      //Uncomment when Request a Demo api and interface is ready
      // {
      //   name: 'Demo',
      // buttonText: product.allowRequestForQuoteOrDemo
      //     ? core.formatMessage(Locale.request_demo_button)
      //     : core.formatMessage(Locale[getTileButtonTextLocaleKey(product)]),
      //   automationName: "manage_demo",
      // },
      {
        name: 'Quote',
        buttonText: product.allowRequestForQuoteOrDemo
          ? core.formatMessage(Locale.request_quote_button)
          : core.formatMessage(Locale[getTileButtonTextLocaleKey(product)]),
        automationName: 'manage_quote'
      }
    ],
    benefitHeader: core.formatMessage(Locale.drawer_manage_benefit_header),
    benefits: [
      {
        name: 'delivery',
        imageSrc: 'images/drawer/delivery.jpg',
        title: core.formatMessage(Locale.drawer_manage_benefit_delivery_title),
        description: core.formatMessage(
          Locale.drawer_manage_benefit_delivery_text
        )
      },
      {
        name: 'dashboard',
        imageSrc: 'images/drawer/dashboard.jpg',
        title: core.formatMessage(Locale.drawer_manage_benefit_dashboard_title),
        description: core.formatMessage(
          Locale.drawer_manage_benefit_dashboard_text
        )
      },
      {
        name: 'efficiency',
        imageSrc: 'images/drawer/efficiency.jpg',
        title: core.formatMessage(
          Locale.drawer_manage_benefit_efficiency_title
        ),
        description: core.formatMessage(
          Locale.drawer_manage_benefit_efficiency_text
        )
      },
      {
        name: 'billing',
        imageSrc: 'images/drawer/billing.jpg',
        title: core.formatMessage(Locale.drawer_manage_benefit_billing_title),
        description: core.formatMessage(
          Locale.drawer_manage_benefit_billing_text
        )
      }
    ]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(core.hideDrawer(MANAGE_PRODUCT_DRAWER));
      dispatch(core.setScreenData(['isProcessingDemoButtonClick'], false));
      dispatch(core.setScreenData(['isProcessingQuoteButtonClick'], false));
    },
    onUniversityLinkClick: () => {
      window.open(
        'https://www.connectwise.com/globalassets/media/assets/feature-sheet/managefeaturesheet.pdf'
      );
    },
    onConnectLinkClick: () => {
      window.open(
        'https://docs.connectwise.com/ConnectWise_Documentation/ConnectWise_Unified_Product/010/SSO_Setup_for_ConnectWise_Manage'
      );
    },
    onCardButtonClick: async plan => {
      const state = core.Store().getState();
      const productChk = core.getScreenData(
        state.screen,
        ['product'],
        MANAGE_PRODUCT_DRAWER
      );
      const product = productChk ? productChk.toJS() : undefined;
      dispatch(core.setScreenData(['isProcessingDemoButtonClick'], true));
      if (product.allowRequestForQuoteOrDemo) {
        if (plan.name === 'Demo') {
          dispatch(
            core.setScreenData(
              [
                Routes.HOME_PAGE.id,
                'trialSignup',
                product.productId,
                'formType'
              ],
              formTypes.Demo
            )
          );
        } else if (plan.name === 'Quote') {
          dispatch(core.setScreenData(['isProcessingQuoteButtonClick'], true));
          dispatch(
            core.setScreenData(
              [
                Routes.HOME_PAGE.id,
                'trialSignup',
                product.productId,
                'formType'
              ],
              formTypes.Quote
            )
          );
        }

        const result = await startTrial(product);
        dispatch(core.setScreenData(['isProcessingDemoButtonClick'], false));
        dispatch(core.setScreenData(['isProcessingQuoteButtonClick'], false));
        return result;
      }

      if (product.purchased) {
        const partnerId = core.getPartnerId(state.oidc);
        const user = core.getUser(state.oidc);
        const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
        const stsBaseUrl = core.getModuleConfig(state.module, ['authority']);
        if (isGuestUserLoggedIn) {
          return window.open(
            stsBaseUrl +
              '/account/switch?clientId=cwhome-backend&accountId=' +
              partnerId +
              '&redirectUri=' +
              product.launchUrl
          );
        } else {
          return window.open(product.launchUrl);
        }
      }
    }
  };
};

const ManageProductDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProductDrawer);

export default injectIntl(ManageProductDrawerContainer);
