import { connect } from 'react-redux';
import KnownIssuesFilterConditions from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesFilterConditions';
import * as core from 'cw-ui-core';
import { getCookie } from 'src/services/Utils';
import { getManageUserId } from 'src/services/Tickets/KnownIssuesService';
/** @typedef {import('src/components/PartnerSupportPage/KnownIssues/KnownIssuesFilterConditions').IssuesFilterRequest} IssuesFilterRequest */

export const myWatchedIssueFilters = [
  {
    id: 'MyWatchedIssues',
    name: 'My Watched Issues'
  }
];

class IssuesFilterState {
  partnerProducts;
  componentFilter;
  statusFilter;
  fixVersionFilter;
  myWatchedIssuesFilter;
}

const mapStateToProps = state => {
  let knownIssuesFilters = core.getScreenData(state.screen, [
    'knownIssueFilters'
  ]);
  knownIssuesFilters = knownIssuesFilters ? knownIssuesFilters.toJS() : {};

  knownIssuesFilters.myWatchedIssueFilters = myWatchedIssueFilters;

  const savedFilterState = new IssuesFilterState();

  const partnerProducts = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'partnerProducts'
  ]);

  const partnerStatuses = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'partnerStatuses'
  ]);

  savedFilterState.statusFilter = partnerStatuses ? partnerStatuses.toJS() : [];
  savedFilterState.partnerProducts = partnerProducts
    ? partnerProducts.toJS()
    : [];

  const partnerComponents = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'partnerComponents'
  ]);
  savedFilterState.componentFilter = partnerComponents
    ? partnerComponents.toJS()
    : [];

  const partnerFixVersions = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'partnerFixVersion'
  ]);
  savedFilterState.fixVersionFilter = partnerFixVersions
    ? partnerFixVersions.toJS()
    : [];

  const myWatchedIssuesFilter = core.getScreenData(state.screen, [
    'knownIssuesfilterSelection',
    'myWatchedIssues'
  ]);
  savedFilterState.myWatchedIssuesFilter = myWatchedIssuesFilter
    ? myWatchedIssuesFilter.toJS()
    : [];

  const userId = getManageUserId();
  const userDefaultFilterCookie = getCookie(
    'issues-user-default-filter' + userId
  );
  if (userDefaultFilterCookie) {
    /** @type {IssuesFilterRequest} **/
    const userDefaultFilter = JSON.parse(userDefaultFilterCookie);
    if (partnerProducts === undefined) {
      savedFilterState.partnerProducts = userDefaultFilter.products ?? [];
    }

    if (partnerComponents === undefined)
      savedFilterState.componentFilter = userDefaultFilter.components ?? [];

    if (partnerFixVersions === undefined)
      savedFilterState.fixVersionFilter = userDefaultFilter.fixVersions ?? [];

    if (partnerStatuses === undefined)
      savedFilterState.statusFilter = userDefaultFilter.statuses ?? [];

    if (myWatchedIssuesFilter === undefined)
      savedFilterState.myWatchedIssuesFilter =
        userDefaultFilter.myWatchedIssues ?? [];
  }

  return {
    savedFilterState,
    knownIssuesFilters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onProductSelectionChanged: selectedItems => {
      dispatch(
        core.setScreenData(
          ['knownIssuesfilterSelection', 'partnerComponents'],
          []
        )
      );
      dispatch(
        core.setScreenData(
          ['knownIssuesfilterSelection', 'partnerFixVersion'],
          []
        )
      );
      dispatch(
        core.setScreenData(
          ['knownIssuesfilterSelection', 'partnerProducts'],
          selectedItems
        )
      );
    },
    onStatusSelectionChanged: selectedItems => {
      dispatch(
        core.setScreenData(
          ['knownIssuesfilterSelection', 'partnerStatuses'],
          selectedItems
        )
      );
    },
    onComponentSelectionChanged: selectedItems => {
      dispatch(
        core.setScreenData(
          ['knownIssuesfilterSelection', 'partnerComponents'],
          selectedItems
        )
      );
    },
    onFixVersionSelectionChanged: selectedItems => {
      dispatch(
        core.setScreenData(
          ['knownIssuesfilterSelection', 'partnerFixVersion'],
          selectedItems
        )
      );
    },
    onMyWatchedIssuesSelectionChanged: selectedItems => {
      dispatch(
        core.setScreenData(
          ['knownIssuesfilterSelection', 'myWatchedIssues'],
          selectedItems
        )
      );
    },
    onSearchTextChange: searchText => {
      dispatch(
        core.setScreenData(
          ['knownIssuesfilterSelection', 'searchText'],
          searchText
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KnownIssuesFilterConditions);
