import React from 'react';
import { Image, Flexbox } from 'cw-ui-core';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import css from 'src/components/Drawer/SecurityBulletin/SecurityPageUnauthorizedError.scss';

const SecurityPageUnauthorizedError = () => {
  return (
    <Flexbox className={css.securityUnauthorizedContainer} column>
      <core.Label
        className={css.securityUnauthorizedText}
        grey7
        value={core.formatMessage(Locale.security_bulletin_access_error)}
      />
      <Image
        className={css.securityUnauthorizedImage}
        src="/images/unauthorized.svg"
        alt={core.formatMessage(Locale.unauthorized_text)}
      />
    </Flexbox>
  );
};

export default injectIntl(SecurityPageUnauthorizedError);
