import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsPostDrawer.scss';
import Locale from 'src/Locale';
import { NEWS_POST_DRAWER } from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsPostDrawerContainer';

const CreatePostSuccess = props => {
  return (
    <core.Flexbox column className={css.container}>
      <core.ScreenMessagePanelContainer screenKey={NEWS_POST_DRAWER} />
      <core.Label
        className={css.titleName}
        value={
          props.isPostEdit
            ? core.formatMessage(Locale.post_successfully_edited)
            : core.formatMessage(Locale.post_successfully_created)
        }
      />
      <core.Image
        src="/images/SupportTicket/ticket_created_message.svg"
        className={css.successImage}
      />
      <core.ButtonStandard
        cwId="createPostSuccess"
        automationName="createPostSuccess"
        className={css.viewPost}
        value={core.formatMessage(Locale.view_post_button)}
        onClick={() => props.onViewPostClick(props.postId)}
      ></core.ButtonStandard>
    </core.Flexbox>
  );
};

export default CreatePostSuccess;
