import { GET, getUrl, PUT, POST } from 'src/services/ApiService';

const UserService = () => {
  const apiUrl = () => getUrl('home', '/user');

  const fetchUserSecurity = async () => {
    return await GET(`${apiUrl()}/security`, true, '1.0');
  };

  const refreshUserSecurity = async () => {
    return await GET(`${apiUrl()}/security/refresh`, true, '1.0');
  };

  const getUserAsync = async () => {
    return await GET(`${apiUrl()}`, true, '1.0');
  };

  const closeAccount = async () => {
    return await GET(`${apiUrl()}/closeAccount`, true, '1.0');
  };

  const closeAccountValidation = async () => {
    return await GET(`${apiUrl()}/closeAccountValidation`, true, '1.0');
  };

  const getSearchToken = async () => {
    return await GET(`${apiUrl()}/searchToken`, true, '1.0');
  };

  const patch = async data => {
    return await PUT(`${apiUrl()}`, data);
  };

  const sendConfirmationEmail = async () => {
    return await POST(`${apiUrl()}/sendConfirmationEmail`, null);
  };

  return {
    fetchUserSecurity,
    refreshUserSecurity,
    getUserAsync,
    closeAccount,
    closeAccountValidation,
    getSearchToken,
    patch,
    sendConfirmationEmail
  };
};

export default UserService();
