import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import CompanyService from 'src/services/Api/HomeApi/CompanyService';

export const Steps = {
  Step_1: 'step1',
  Step_2: 'step2',
  Step_3: 'step3',
  Step_4: 'step4',
  Step_5: 'step5',
  Step_6: 'step6'
};

export const PSMType = {
  RecordType: 'ServiceTicket',
  Status: {
    New: 'New'
  },
  TicketType: 'PSM'
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const contactTimeFilter = [
  {
    id: 'Morning',
    name: core.formatMessage(Locale.morning)
  },
  {
    id: 'Afternoon',
    name: core.formatMessage(Locale.afternoon)
  },
  {
    id: 'Evening',
    name: core.formatMessage(Locale.evening)
  },
  {
    id: 'Anytime',
    name: core.formatMessage(Locale.anytime)
  }
];

export const contactMethod = [
  {
    id: 'Phone',
    name: core.formatMessage(Locale.phone)
  },
  {
    id: 'Email',
    name: core.formatMessage(Locale.profile_email)
  }
];

export const fetchSuccessManager = async () => {
  const result = await CompanyService.getSuccessManager();

  if (!result.isSuccess) {
    const error = 'No success Manager assigned to this company.';
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(core.setModuleCache(['successManager'], result.isSuccess));
    return result.isSuccess;
  }

  core
    .Store()
    .dispatch(core.setModuleCache(['successManager'], result.response));
  return result.response;
};

export const fetchSuccessManagerPhoto = async () => {
  var imageUrl = await CompanyService.getSuccessManagerPhoto();
  core
    .Store()
    .dispatch(core.setModuleCache(['successManagerPhoto'], imageUrl.response));
  return imageUrl.response;
};
