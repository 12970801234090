import React, { useRef } from 'react';
import useDismiss from 'src/state/hooks/dismiss';

const DismissContextHOC = (WrappedComponent, dismissCb) => {
  const ref = useRef(null);

  const contains = (ref, e) => {
    const rect = e.target.getBoundingClientRect();
    if (!ref.contains(e.target)) {
      // The element could have disappeared prior to this check.
      return rect.width === 0 && rect.height === 0;
    }

    return true;
  };

  const handleDismiss = e => {
    if (ref.current && !contains(ref.current, e)) {
      dismissCb();
    }
  };

  useDismiss(handleDismiss);

  return <span ref={ref}>{WrappedComponent}</span>;
};

export default DismissContextHOC;
