import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Flexbox, Image, IconPath } from 'cw-ui-core';
import cx from 'classnames';
import svgCss from 'src/styles/svg.scss';
import css from './SectionContentHOC.scss';
import animation from 'src/styles/animation.scss';

function SectionContentHOC(props) {
  const [isExpanded, setIsExpanded] = useState(props.isExpandable);
  const iconPath = isExpanded ? 'Action_CollapseUp' : 'Action_ExpandDown';

  const getCollapseIcon = () => {
    return props.isCollapsible ? (
      <Image
        src={IconPath.get(iconPath)}
        className={cx(svgCss.black)}
        isInlineSvg
      />
    ) : null;
  };

  return (
    <div className={css.container}>
      <Flexbox
        automationName="sectionCollapse"
        className={cx(css.header)}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h4 className={css.sectionTitle}>{props.title}</h4>
        {getCollapseIcon()}
      </Flexbox>
      <section
        className={cx(
          { [css.collapsed]: !isExpanded },
          animation.fast,
          animation.fadeInRight
        )}
      >
        {props.children}
      </section>
    </div>
  );
}

SectionContentHOC.propTypes = {
  title: PropTypes.string,
  isExpandable: PropTypes.bool
};

export default SectionContentHOC;
