import SecurityBulletinAdminApi from 'src/services/Api/HomeApi/SecurityBulletinAdminService';
import SecurityBulletinApi from 'src/services/Api/HomeApi/SecurityBulletinService';
import * as core from 'cw-ui-core';
import { SECURITY_BULLETIN_DRAWER } from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawerContainer';

export const Steps = {
  Step_1: 'step1',
  Step_2: 'step2',
  Step_3: 'step3'
};

const defaultPage = 1;
export const getSecurityBulletins = async (
  allBulletins,
  product,
  pageIndex
) => {
  const pageSize = 50;
  const pageName = pageIndex ? pageIndex : defaultPage;
  const result = await SecurityBulletinAdminApi.getSecurityBulletinsOverview(
    allBulletins,
    product,
    pageName,
    pageSize
  );
  if (!result.isSuccess) {
    const error = result.error;
    core.CwLog.error(error);
    return;
  }

  return result.response;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const loadSecurityBulletins = async pageIndex => {
  const dispatch = core.Store().dispatch;
  dispatch(core.setScreenData(['isSecurityBulletinReady'], false));
  const response = await getSecurityBulletins(true, '', pageIndex);
  dispatch(core.setScreenData(['securityBulletins'], response.bulletins));
  dispatch(
    core.setScreenData(
      'securityBulletinPagination',
      response.metadata.pagination
    )
  );
  dispatch(core.setScreenData(['isSecurityBulletinReady'], true));
};

export const getSecurityBulletinCriteria = async () => {
  const dispatch = core.Store().dispatch;
  const result = await SecurityBulletinApi.getSecurityBulletinCriteria();
  if (result.isSuccess) {
    dispatch(
      core.setModuleCache(['securityBulletinCriteria'], result.response)
    );
  } else {
    dispatch(
      core.setErrorScreenMessage(
        result.error,
        false,
        result.errorMessages,
        SECURITY_BULLETIN_DRAWER
      )
    );
  }
};

export const getSecurityBulletinById = async id => {
  const result = await SecurityBulletinApi.getSecurityBulletinById(id);
  let isUnauthorized = false;
  if (result && !result.isSuccess) {
    if (result.status === 401) {
      isUnauthorized = true;
    } else {
      const error = result.error;
      core.CwLog.error(error);
      return;
    }
  }

  const securityBulletinResponse = {
    result: result.response,
    isUnauthorized
  };

  return securityBulletinResponse;
};

export const addSecurityBulletin = async securityBulletinDetails => {
  const dispatch = core.Store().dispatch;
  dispatch(core.toggleMask(true));

  let content = securityBulletinDetails.content;
  if (content) {
    content = content.replace(/\n/g, '<br />');
  }

  const securityBulletinInfo = {
    originator: securityBulletinDetails.originator,
    status: 'published',
    title: securityBulletinDetails.title,
    description: content,
    product: securityBulletinDetails.product,
    priority: 'Low'
  };

  const result = await SecurityBulletinAdminApi.addSecurityBulletin(
    securityBulletinInfo
  );
  if (!result.isSuccess) {
    dispatch(
      core.setErrorScreenMessage(
        result.error,
        false,
        result.errorMessages,
        SECURITY_BULLETIN_DRAWER
      )
    );
    dispatch(core.toggleMask(false));
    return;
  } else {
    dispatch(core.toggleMask(false));
    return result.response;
  }
};

export const editSecurityBulletin = async (
  editBulletinId,
  securityBulletinDetails
) => {
  const dispatch = core.Store().dispatch;
  dispatch(core.toggleMask(true));

  let content = securityBulletinDetails.content;
  if (content) {
    content = content.replace(/\n/g, '<br />');
  }

  const securityBulletinInfo = {
    originator: securityBulletinDetails.originator,
    title: securityBulletinDetails.title,
    description: content,
    product: securityBulletinDetails.product,
    status: 'published',
    priority: 'Low'
  };

  const result = await SecurityBulletinAdminApi.editSecurityBulletin(
    editBulletinId,
    securityBulletinInfo
  );
  if (!result.isSuccess) {
    dispatch(
      core.setErrorScreenMessage(
        result.error,
        false,
        result.errorMessages,
        SECURITY_BULLETIN_DRAWER
      )
    );
    dispatch(core.toggleMask(false));
    return;
  } else {
    dispatch(core.toggleMask(false));
    return result.response;
  }
};
