import React, { useEffect, useRef } from 'react';
import {
  Drawer,
  Flexbox,
  Placeholder,
  ScreenMessagePanelContainer,
  Label,
  formatMessage,
  ButtonStandard,
  Image,
  ButtonLight,
  IconPath
} from 'cw-ui-core';
import css from 'src/components/PartnerSupportPage/KnownIssues/KnownIssues.scss';
import Locale from 'src/Locale';
import { getStatusClass } from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesListviewColumns';
import cx from 'classnames';
import Routes from 'src/services/Routes';
import { FormattedDate } from 'react-intl';
import { POLICIES } from 'src/services/Authorization';
import HasAccess from 'src/services/RoleAccess';
import { CLOSED_STATUS } from 'src/services/Tickets/KnownIssuesService';
import { NoteLabel } from '@cwnexus/cw-markdown-to-html';

export const VIEW_KNOWN_ISSUE_DRAWER = 'VIEW_KNOWN_ISSUE_DRAWER';

const KnownIssuesDrawer = props => {
  const isConnectwiseColleague = HasAccess(
    [POLICIES.CONNECTWISE_COLLEAGUE],
    null
  );
  const {
    isReady,
    isUnauthorized,
    knownIssue,
    onFollowKnownIssueInDrawerClicked
  } = props;
  const statusClass = knownIssue && getStatusClass(knownIssue.status);
  const previewLoader = <Placeholder className={css.previewLoading} />;

  useEffect(() => {
    if (props.knownIssue) {
      const url = Routes.ISSUE_DRAWER.path.replace(
        ':issueId',
        props.knownIssue.jiraId
      );
      window.history.pushState(null, 'View issue', url);
    }
  });

  const drawerTitle = useRef(null);
  const drawerContent = useRef(null);
  const drawerBtns = useRef(null);
  useEffect(() => {
    let titleHeight;
    let buttonsHeight;
    titleHeight = drawerTitle.current
      ? drawerTitle.current.getBoundingClientRect().height
      : 0;
    buttonsHeight = drawerBtns.current
      ? drawerBtns.current.getBoundingClientRect().height
      : 0;
    let heightToApply = window.innerHeight - (buttonsHeight + titleHeight + 48);
    drawerContent.current &&
      (drawerContent.current.style.height = `${heightToApply}px`);
  });

  return (
    <Drawer onCloseIconClicked={props.onCloseIconClicked}>
      <ScreenMessagePanelContainer screenKey={VIEW_KNOWN_ISSUE_DRAWER} />
      {!isReady ? (
        <div>{previewLoader}</div>
      ) : (
        <Flexbox className={css.knownIssueDrawer} column>
          {!isUnauthorized ? (
            <>
              <div ref={drawerTitle}>
                <Flexbox className={css.titleContainer} column>
                  <Label
                    value={formatMessage(Locale.issue_id, {
                      issueId: knownIssue && knownIssue.key
                    })}
                    className={css.title}
                  />
                </Flexbox>
                <Flexbox className={css.summaryContainer} column>
                  <Label
                    value={knownIssue.summary}
                    className={css.summaryContent}
                  />
                  <Flexbox className={css.issueHighlights}>
                    <Flexbox column>
                      <div className={css.labelContainer}>
                        <Label value={`${formatMessage(Locale.product)}: `} />
                        <Label
                          automationName="product"
                          grey7
                          value={knownIssue.product}
                          className={css.label}
                        />
                      </div>
                      <div className={css.labelContainer}>
                        <Label
                          value={`${formatMessage(Locale.last_updated)}: `}
                        />
                        <Label
                          automationName="last_updated"
                          grey7
                          value={
                            <FormattedDate
                              value={new Date(knownIssue.updated)}
                            />
                          }
                          className={css.label}
                        />
                      </div>
                      <div className={css.labelContainer}>
                        <Label value={`${formatMessage(Locale.status)}: `} />
                        <Label
                          automationName="status"
                          className={cx(css.knownIssueStatus, statusClass)}
                          value={knownIssue.status}
                          grey7
                        />
                      </div>

                      {!isConnectwiseColleague ? (
                        knownIssue.isFollowedByUser ? (
                          <ButtonStandard
                            automationName="followed_by_user"
                            value={`+ ${knownIssue.follows}`}
                            className={css.issueButton}
                            onClick={onFollowKnownIssueInDrawerClicked}
                            disabled={isConnectwiseColleague}
                          />
                        ) : (
                          <ButtonLight
                            automationName="follow_button"
                            value={`+ ${knownIssue.follows}`}
                            className={css.issueButton}
                            onClick={onFollowKnownIssueInDrawerClicked}
                            disabled={
                              isConnectwiseColleague ||
                              knownIssue.status === CLOSED_STATUS
                            }
                          />
                        )
                      ) : (
                        <div className={css.labelContainer}>
                          <Label
                            value={`${formatMessage(Locale.reported)}: `}
                          />
                          <Label
                            automationName="reported"
                            grey7
                            value={knownIssue.follows}
                            className={css.label}
                          />
                        </div>
                      )}

                      <div className={css.labelContainer}>
                        <Label
                          value={`${formatMessage(Locale.resolution)}: `}
                        />
                        <Label
                          automationName="resolution"
                          grey7
                          value={knownIssue.resolution || 'Unresolved'}
                          className={css.label}
                        />
                      </div>
                      <div className={css.labelContainer}>
                        <Label
                          value={`${formatMessage(Locale.resolved_date)}: `}
                        />
                        <Label
                          automationName="resolved_date"
                          grey7
                          value={
                            knownIssue.resolutionDate ? (
                              <FormattedDate value={new Date(knownIssue.resolutionDate)} />
                            ) : (
                              'N/A'
                            )
                          }
                          className={css.label}
                        />
                      </div>                      
                      {knownIssue.releaseNotes && knownIssue.releaseNotes.startsWith('https://docs.connectwise.com/') && (
                      <div className={css.labelContainer}>                        
                        <button
                          className={css.textButton}
                          onClick={() =>                            
                            window.open(knownIssue.releaseNotes)
                          }
                        >
                        <img src="/images/releasenotes.svg" alt='Release Notes'/>
                        <Label value={formatMessage(Locale.release_notes)} />
                        </button>
                      </div>
                     )}
                    </Flexbox>
                    <Flexbox column>
                      <div className={css.labelContainer}>
                        <Label
                          value={`${formatMessage(Locale.components)}: `}
                        />
                        <Label
                          automationName="components"
                          grey7
                          value={knownIssue.component || 'N/A'}
                          className={css.label}
                        />
                      </div>
                      <div className={cx(css.labelContainer, css.inlineFlex)}>
                        <Label
                          value={`${formatMessage(
                            Locale.known_issues_defect_priority
                          )}: `}
                        />
                        <Label
                          automationName="defect_priority"
                          grey7
                          value={knownIssue.defectPriority}
                          className={css.defectprioritylabel}
                        />
                        <Image
                          cwId="knownIssueHelpIcon"
                          alt={formatMessage(Locale.help)}
                          title={formatMessage(Locale.help)}
                          automationName="knownIssueHelpIcon"
                          className={css.helpIcon}
                          onClick={props.onHelpIconClick}
                          src={IconPath.get('Application_Help')}
                        />
                      </div>
                      <div className={css.labelContainer}>
                        <Label
                          value={`${formatMessage(Locale.patch_number)}: `}
                        />
                        <Label
                          automationName="patch_number"
                          grey7
                          value={knownIssue.patchVersion || 'N/A'}
                          className={css.label}
                        />
                      </div>
                      <div className={css.labelContainer}>
                        <Label
                          value={`${formatMessage(Locale.version_number)}: `}
                        />
                        <Label
                          automationName="version_number"
                          grey7
                          value={knownIssue.fixVersion || 'N/A'}
                          className={css.label}
                        />
                      </div>
                      <div className={css.labelContainer}>
                        <Label
                          value={`${formatMessage(Locale.releaseETA)}: `}
                        />
                        <Label
                          automationName="ReleaseETA"
                          grey7
                          value={knownIssue.releaseEta || 'N/A'}
                          className={css.label}
                        />
                      </div>
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
              </div>

              <div className={css.scrollableArea} ref={drawerContent}>
               <NoteLabel
                  fullNoteClass={css.inlineFlexDisplay}
                  value={knownIssue.description}
                  automationName="task_description"
                  noteLabelCss={css.notesText}
                />
              </div>

              {knownIssue.kcsArticleLink && (
                <div ref={drawerBtns} className={css.footerContainer}>
                  <ButtonStandard
                    automationName="learn_more"
                    value={formatMessage(Locale.learn_more)}
                    onClick={() => window.open(knownIssue.kcsArticleLink)}
                  />
                </div>
              )}
            </>
          ) : (
            <Flexbox className={css.unauthorizedContentContainer} column>
              <Label
                className={css.knownIssueUnauthorizedText}
                grey7
                value={formatMessage(Locale.known_issue_access_error)}
              />
              <Image
                className={css.knownIssueUnauthorizedImage}
                src="/images/unauthorized.svg"
                alt={formatMessage(Locale.unauthorized_text)}
              />
            </Flexbox>
          )}
        </Flexbox>
      )}
    </Drawer>
  );
};

export default KnownIssuesDrawer;
