import { connect } from 'react-redux';
import {
  fieldEditUpdate,
  getScreenData,
  Store,
  setScreenData
} from 'cw-ui-core';
import MultiCheckBox from './MultiCheckBox';

export const mapStateToProps = (state, props) => {
  const currentSelection = getScreenData(
    state.screen,
    props.pathKey,
    props.screenKey
  );
  let items = [];
  if (props.subItemsField) {
    props.items.forEach(item => {
      items = items.concat(item[props.subItemsField]);
    });
  } else {
    items = props.items;
  }

  return {
    values: currentSelection ? currentSelection.toJS() : [],
    selectedAll:
      currentSelection && items ? currentSelection.size === items.length : false
  };
};

export const mapDispatchToProps = (dispatch, props) => {
  return {
    onChange: item => {
      const itemId = props.customId ? props.customId(item) : item.id;
      let currentSelection = getScreenData(
        Store().getState().screen,
        props.pathKey,
        props.screenKey
      );
      if (currentSelection) {
        const index = currentSelection.findIndex(item => {
          return item === itemId;
        });
        currentSelection =
          index === -1
            ? currentSelection.push(itemId)
            : currentSelection.splice(index, 1);
      } else {
        currentSelection = [itemId];
      }

      if (props.ignoreDirty) {
        dispatch(
          setScreenData(props.pathKey, currentSelection, props.screenKey)
        );
      } else {
        dispatch(
          fieldEditUpdate(props.pathKey, currentSelection, props.screenKey)
        );
      }

      if (props.onAfterChange) {
        props.onAfterChange(currentSelection);
      }
    },
    onSelectAll: selectedAll => {
      let items = [];
      if (props.subItemsField) {
        props.items.forEach(item => {
          items = items.concat(item[props.subItemsField]);
        });
      } else {
        items = props.items;
      }

      const currentSelection = selectedAll
        ? []
        : items.reduce((accumulator, item) => {
            const itemId = props.customId ? props.customId(item) : item.id;
            accumulator.push(itemId);
            return accumulator;
          }, []);

      if (props.ignoreDirty) {
        dispatch(
          setScreenData(props.pathKey, currentSelection, props.screenKey)
        );
      } else {
        dispatch(
          fieldEditUpdate(props.pathKey, currentSelection, props.screenKey)
        );
      }

      if (props.onAfterChange) {
        props.onAfterChange(currentSelection);
      }
    }
  };
};

const MultiCheckBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiCheckBox);

export default MultiCheckBoxContainer;
