import { connect } from 'react-redux';
import {
  getScreenData,
  Store,
  setScreenData,
  removeScreenData,
  removeModuleCache
} from 'cw-ui-core';
import EditSupportTicketDrawerHeader from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawerHeader/EditSupportTicketDrawerHeader';
import { EDIT_VIEW_TICKET_DRAWER_ID } from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawer';
import { loadData } from 'src/components/PartnerSupportPage/ArchivedTickets/Paging';

const mapStateToProps = (state, ownProps) => {
  const ticketNumber = getScreenData(
    state.screen,
    ['ticket', 'id'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );

  let isReady = ownProps.ticketIdFromUrl ? true : false;
  let isNavigationReady = false;
  if (ticketNumber > 0) {
    isReady = true;
    const notes = getScreenData(
      state.screen,
      ['notes'],
      EDIT_VIEW_TICKET_DRAWER_ID
    );
    if (notes) isNavigationReady = true;
  }

  return {
    ticketNumber,
    isReady,
    canPreviousPage: canPreviousPage() && isNavigationReady,
    canNextPage: canNextPage() && isNavigationReady
  };
};

const canNextPage = () => {
  const state = Store().getState();
  const lastPageIndex = getScreenData(state.screen, 'pageCount');
  const pageIndex = getScreenData(state.screen, ['params', 'pageIndex']);
  const currentPageTickets = getScreenData(state.screen, [
    'tickets',
    pageIndex
  ]);
  const currentPageSize = currentPageTickets ? currentPageTickets.length : 0;
  const ticketIndex = getScreenData(
    state.screen,
    ['ticketIndex'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );

  return !(
    ticketIndex === currentPageSize - 1 && pageIndex === lastPageIndex - 1
  );
};

const canPreviousPage = () => {
  const state = Store().getState();
  const pageIndex = getScreenData(state.screen, ['params', 'pageIndex']);
  const ticketIndex = getScreenData(
    state.screen,
    ['ticketIndex'],
    EDIT_VIEW_TICKET_DRAWER_ID
  );

  return pageIndex > 0 || ticketIndex > 0;
};

const mapDispatchToProps = dispatch => {
  return {
    onRightNavigation: async () => {
      let state = Store().getState();
      dispatch(removeScreenData([], EDIT_VIEW_TICKET_DRAWER_ID));
      let ticketIndex = getScreenData(
        state.screen,
        'ticketIndex',
        EDIT_VIEW_TICKET_DRAWER_ID
      );
      const pageSize = getScreenData(state.screen, ['params', 'pageSize']);
      let pageIndex = getScreenData(state.screen, ['params', 'pageIndex']);
      if (ticketIndex === pageSize - 1) {
        await loadData({ pageIndex: ++pageIndex });
        setScreenData(state.screen, ['params', 'pageIndex'], pageIndex);
        state = Store().getState();
        ticketIndex = 0;
      } else {
        ticketIndex++;
      }

      const tickets = getScreenData(state.screen, 'tickets');
      const ticketsJs = tickets.toJS();
      const newTicket = ticketsJs[pageIndex][ticketIndex];
      dispatch(setScreenData('activeTicket', newTicket));
      dispatch(removeModuleCache(['comboBoxTypeAhead', 'status']));
    },
    onLeftNavigation: async () => {
      let state = Store().getState();
      dispatch(removeScreenData([], EDIT_VIEW_TICKET_DRAWER_ID));
      let ticketIndex = getScreenData(
        state.screen,
        ['ticketIndex'],
        EDIT_VIEW_TICKET_DRAWER_ID
      );
      let pageIndex = getScreenData(state.screen, ['params', 'pageIndex']);
      if (ticketIndex === 0) {
        await loadData({ pageIndex: --pageIndex });
        setScreenData(state.screen, ['params', 'pageIndex'], pageIndex);
        state = Store().getState();
        ticketIndex = getScreenData(state.screen, ['params', 'pageSize']) - 1;
      } else {
        ticketIndex--;
      }

      const tickets = getScreenData(state.screen, 'tickets');
      const ticketsJs = tickets.toJS();
      const newTicket = ticketsJs[pageIndex][ticketIndex];
      dispatch(setScreenData('activeTicket', newTicket));
      dispatch(removeModuleCache(['comboBoxTypeAhead', 'status']));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSupportTicketDrawerHeader);
