import { DELETE, GET, getUrl, POST, POSTFORMDATA, PUT } from 'src/services/ApiService';

const BannerService = () => {
  const apiUrl = () => getUrl('home', '/banner');

  return {
    getPublishedBanners: async () => {
      return await GET(`${apiUrl()}/publishedBanner`);
    },
    
    addBanner: async bannerDetails => {
      return await POST(`${apiUrl()}`, bannerDetails);
    },
    deleteBanner: async bannerId => {
      return await DELETE(`${apiUrl()}/${bannerId}`);
    },
    getAllBanners: async () => {
      return await GET(`${apiUrl()}/allbanner`);
    },
    getBannerById: async id => {
      return await GET(`${apiUrl()}/${id}`);
    },
    updateBanner: async (bannerId, bannerDetails) => {
      return await PUT(`${apiUrl()}/updatebanner/${bannerId}`, bannerDetails);
    },
    addUpdateFirstBanner: async (bannerId, bannerImage) => {
      return await POSTFORMDATA(`${apiUrl()}/${bannerId}/bannerImage1`, bannerImage);
    },
    addUpdateSecondBanner: async (bannerId, bannerImage) => {
      return await POSTFORMDATA(`${apiUrl()}/${bannerId}/bannerImage2`, bannerImage);
    },
    addUpdateThirdBanner: async (bannerId, bannerImage) => {
      return await POSTFORMDATA(`${apiUrl()}/${bannerId}/bannerImage3`, bannerImage);
    },
    addUpdateFourthBanner: async (bannerId, bannerImage) => {
      return await POSTFORMDATA(`${apiUrl()}/${bannerId}/bannerImage4`, bannerImage);
    },
    publishBanner: async (bannerId, postInfo) => {
      return await PUT(`${apiUrl()}/${bannerId}`, postInfo);
    }
  };
};

export default BannerService();