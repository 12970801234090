import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import ResourceCard from 'src/components/Drawer/PartnerSuccessManagerDrawer/ResourceCard';
import Locale from 'src/Locale';
import {
  PARTNER_SUCCESS_MANAGER_DRAWER,
  PARTNER_SUCCESS_MANAGER_TITLE,
  PathValues
} from './PartnerSuccessDrawer/PartnerSuccessManagerDrawerContainer';
import { Steps } from 'src/services/SuccessManagerService';
import {
  FEATURES,
  isFeatureEnabled
} from 'src/services/Features/FeaturesService';

const mapStateToProps = state => {
  const partnerSuccessManager = core.getModuleCache(state.module, [
    'successManager'
  ]);
  const partnerSuccessManagerPhoto = core.getModuleCache(state.module, [
    'successManagerPhoto'
  ]);
  const showFullPSMBio = core.getScreenData(
    state.screen,
    'showFullPSMBio',
    PARTNER_SUCCESS_MANAGER_DRAWER
  );
  function constructSupportLink() {
    const baseUrl = core.getModuleConfig(state.module, ['SalesForceServiceUrlBase']);
    const email = core.getUserEmail(state.oidc);
    return baseUrl + "?startURL=" + encodeURIComponent("/s/#step2?supportArea=PSM&owner=" + email);
  }

  return {
    name: partnerSuccessManager ? partnerSuccessManager.get('fullName'): undefined,
    description: core.formatMessage(Locale.success_manager_description),
    initials: partnerSuccessManager ? 
      partnerSuccessManager && partnerSuccessManager.get('fullName')
        ? core.getInitials(partnerSuccessManager.get('fullName'))
        : null : '',
    phone: partnerSuccessManager ? partnerSuccessManager.get('phone'): undefined,
    photo: partnerSuccessManager ? partnerSuccessManagerPhoto : "/images/deafult_avatar.png",
    photoUrl: partnerSuccessManagerPhoto
      ? URL.createObjectURL(partnerSuccessManagerPhoto)
      : "/images/deafult_avatar.png",
    aboutMe: partnerSuccessManager ? partnerSuccessManager.get('notes'): undefined,
    title: core.formatMessage(Locale.success_manager),
    email: partnerSuccessManager ? partnerSuccessManager.get('defaultEmail'): 'partnersuccess@connectwise.com',
    showFullBio: showFullPSMBio,
    isSalesForceCaseManagement: isFeatureEnabled(FEATURES.HOME_CASE_MANAGEMENT),
    supportLinkText: core.formatMessage(Locale.create_case_with_psm),
    supportLink: constructSupportLink()
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClick: () => {
      const state = core.Store().getState();
      // Clear form values when changing card
      Object.keys(PathValues).forEach(path => {
        dispatch(
          core.setScreenData(
            PathValues[path],
            undefined,
            PARTNER_SUCCESS_MANAGER_DRAWER
          )
        );
      });

      const partnerSuccessManagerPhoto = core.getModuleCache(state.module, [
        'successManagerPhoto'
      ]);
      const partnerSuccessManagerPhotoUrl = partnerSuccessManagerPhoto
        ? URL.createObjectURL(partnerSuccessManagerPhoto)
        : undefined;
      const resourceSelected = {
        type: PARTNER_SUCCESS_MANAGER_TITLE,
        member: core.getModuleCache(state.module, ['successManager']).toJS(),
        photoUrl: partnerSuccessManagerPhotoUrl,
        photoAltText:
          core.formatMessage(Locale.my_success_manager) +
          ' ' +
          core.formatMessage(Locale.photo),
        tooltip: core.formatMessage(Locale.my_success_manager)
      };
      dispatch(
        core.setScreenData(
          'resourceSelected',
          resourceSelected,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
      dispatch(
        core.setScreenData('step', Steps.Step_2, PARTNER_SUCCESS_MANAGER_DRAWER)
      );
    },
    onShowMoreClick: () => {
      dispatch(
        core.setScreenData(
          'showFullPSMBio',
          true,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
    },
    onShowLessClick: () => {
      dispatch(
        core.setScreenData(
          'showFullPSMBio',
          false,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
    }
  };
};

const PartnerSuccessManagerResourceCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceCard);

export default PartnerSuccessManagerResourceCardContainer;
