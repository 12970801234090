import React from 'react';
import * as PropTypes from 'prop-types';
import css from 'src/components/UserAccount/UserProfileBriefing.scss';
import cx from 'classnames';
import Selection from 'src/components/UserAccount/Selection';
import { formatMessage } from 'cw-ui-core/lib/services/IntlService';
import Locale from 'src/Locale';

function UserProfileBriefing(props) {
  return (
    <article className={css.container}>
      <label className={css.title}>
        {formatMessage(Locale.user_profile_my_profile)}
      </label>
      <div className={css.briefing}>
        <props.Avatar />
        <div className={css.detail}>
          <ul className={css.whoAmI}>
            <li className={cx(css.name, css.companyName)}>
              {props.companyName}
            </li>
            <li className={css.name}>{props.name}</li>
            <li className={css.securityRole}>{props.role}</li>
            <li>
              <a className={css.email} href={`mailto:${props.email}`}>
                {props.email}
              </a>
            </li>
          </ul>
          {props.items.map(item => (
            <Selection
              automationName="menuItem"
              key={item.name}
              msg={item.name}
              onClick={() => props.onMenuItemClicked(item.id)}
            />
          ))}
        </div>
      </div>
    </article>
  );
}

UserProfileBriefing.propTypes = {
  Avatar: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string,
  items: PropTypes.array,
  companyName: PropTypes.string,
  onMenuItemClicked: PropTypes.func,
  role: PropTypes.string
};

export default UserProfileBriefing;
