import React from 'react';
import css from './Background.scss';

const Background = props => {

  return (
    <div className={css.wrap}>
      <div className={css.inner}>
        {props.children}
      </div>
    </div >
  );
};

export default Background;
