import React, { useEffect } from 'react';
import {
  Label,
  formatMessage,
  MobileLayoutHOC,
  Toggle,
  Flexbox,
  Drawer
} from 'cw-ui-core';
import Locale from 'src/Locale';
import css from 'src/components/Drawer/UserProfileDrawer/CompanyAccountSetting/CompanyAccountSetting.scss';
import SectionContentHOC from 'src/components/Drawer/SectionContentHOC';

const CompanyAccountSetting = props => {
  const onDidMount = props.onDidMount;

  useEffect(() => {
    onDidMount();
  }, [onDidMount]);

  const Header = (
    <Flexbox className={css.header}>
      <Label
        className={css.mainTitle}
        value={formatMessage(Locale.company_account_text)}
      ></Label>
    </Flexbox>
  );

  return (
    <Drawer
      automationName="listItemClose"
      fadeDirection="left"
      onCloseIconClicked={props.onCloseIconClicked}
      closeOnBlur
    >
      <>
        {Header}
        <Flexbox className={css.container}>
          <SectionContentHOC
            title={formatMessage(Locale.company_account_support_text)}
            isCollapsible
            isExpandable
          >
            <div className={css.area}>
              <Label value={`${formatMessage(Locale.profile_partner_id)} :`} />
              <Label value={props.partnerId} className={css.label} />
            </div>

            <Label
              value={formatMessage(Locale.company_account_support_description)}
            />
            <Toggle
              className={css.supportaccess}
              cwId="toggleCompanyAccountSetting"
              automationName="toggleCompanyAccountSetting"
              onChange={props.onChange}
              value={props.isSupportEnabled}
              text={formatMessage(Locale.support_access)}
            />
          </SectionContentHOC>
        </Flexbox>
      </>
    </Drawer>
  );
};

export default MobileLayoutHOC(CompanyAccountSetting);
