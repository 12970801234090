import BaseCartItem from './BaseCartItem';

class MarketplaceCartItem extends BaseCartItem {
  get productName() {
    return this._buyerCartItem.productName || 'Marketplace';
  }

  get isUsageBased() {
    return this._buyerCartItem.chargeType === 'usage';
  }
  get productImageUrl() {
    return (
      this._buyerCartItem.productImageUrl ||
      '/images/ShoppingCart/productPlaceholder.png'
    );
  }
}

export default MarketplaceCartItem;
