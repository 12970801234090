import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import LandingPage from './LandingPage';

const mapStateToProps = state => {
  return {
    featureImageUrl: core.getModuleCache(state.module, ['home_configuration', 'featureImageUrl']),
    logoUrl: core.getModuleCache(state.module, ['home_configuration', 'logoUrl']),
    titleText: core.getModuleCache(state.module, ['home_configuration', 'titleText']),
    titleSubtext: core.getModuleCache(state.module, ['home_configuration', 'titleSubtext'])
  };
};

const mapDispatchToProps = () => {
  return {
    onSignInClicked: () => {
      //core.signinRedirect({ acr_values: `idp:${idp}` });
      core.signinRedirect({});
    },
    onAboutClick: () => {
      window.open('https://www.connectwise.com/company/about-us');
    },
    onPrivacyClick: () => {
      window.open('https://www.connectwise.com/privacy-policy');
    },
    onContactUsClick: () => {
      window.open('https://www.connectwise.com/contact-us');
    }
  };
};

const LandingPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage);

export default LandingPageContainer;
