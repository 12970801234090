import React from 'react';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import css from 'src/components/Drawer/AddLicenseDrawer/DrawerContent/DrawerContent.scss';
import { Steps } from 'src/services/Licenses/LicensesService';
import cx from 'classnames';

const DrawerContent = props => {
  const {
    provisionLicensingInfo,
    proratePriceToDisplay,
    getFormattedPriceWithCurrency,
    currentStep,
    checkBoxValue,
    setCheckBoxValue,
    onTncClick,
    activationDays,
    licenseName,
    currentMonthName,
    productName,
    isBusy,
    onSelectQuantity,
    quantityValue
  } = props;
  const pricePerLicense = provisionLicensingInfo.priceChargedPerLicense;
  const totalPrice = provisionLicensingInfo.additionalMonthlyCharge;
  const currency = provisionLicensingInfo.currencyIsoCode;
  const noProrate = proratePriceToDisplay <= 0;
  const oneMonthProrate = proratePriceToDisplay > 0;
  const price = getFormattedPriceWithCurrency(currency, proratePriceToDisplay);
  const pricePerLicenseDisplay = getFormattedPriceWithCurrency(
    currency,
    pricePerLicense
  );
  const totalPriceDisplay = getFormattedPriceWithCurrency(currency, totalPrice);
  const licenseNameTitle = licenseName
    ? licenseName.slice(0, licenseName.length - 1)
    : '';
  const licenseNameToShow = quantityValue < 2 ? licenseNameTitle : licenseName;
  let licenseNameLowercase = licenseNameToShow
    ? licenseNameToShow.toLowerCase()
    : 'licenses';

  return (
    <core.Flexbox
      column
      className={cx(
        css.licenseInfo,
        {
          [css.licenseStep2]: currentStep === Steps.Step_2
        },
        { [css.licenseStep3]: currentStep === Steps.Step_3 }
      )}
    >
      {currentStep === Steps.Step_1 && (
        <>
          <core.Flexbox column>
            <core.Label
              value={core.formatMessage(Locale.license_name, {
                productName,
                licenseName: licenseNameTitle
              })}
              className={css.labelDark}
            />
            <core.Flexbox className={css.licensePrice}>
              <span className={css.licensePriceTag}>
                {pricePerLicenseDisplay}
              </span>
              <span>{`/${core.formatMessage(Locale.license_per_month)}`}</span>
            </core.Flexbox>
          </core.Flexbox>

          <core.Flexbox column className={css.productLicenseQuantity}>
            <core.Label
              value={core.formatMessage(Locale.add_license_quantity, {
                licenseName
              })}
              className={css.labelStandard}
              grey7
            />
            <input
              automationName="licenseQuantity"
              className={css.licenseQuantityDropDown}
              type="number"
              autoFocus="true"
              name="numberQuantity"
              value={quantityValue}
              onChange={e => {
                onSelectQuantity(e.target.value);
              }}
              onFocus={e => {
                e.target.select();
              }}
              min={1}
              max={9999}
            />
          </core.Flexbox>

          <core.Flexbox column>
            <core.Label
              value={core.formatMessage(Locale.total)}
              className={css.labelLight}
              grey1
            />
            <core.Label
              value={core.formatMessage(Locale.additional_monthly_charge)}
              className={css.labelDark}
            />
            <core.Label
              value={totalPriceDisplay}
              className={css.licensePriceTag}
            />
            {oneMonthProrate && (
              <core.Label
                value={core.formatMessage(Locale.license_puchase_summary, {
                  amount: <span>{price}</span>
                })}
                className={css.labelText}
              />
            )}
          </core.Flexbox>
        </>
      )}

      {currentStep === Steps.Step_2 && (
        <>
          <core.Label
            value={`${quantityValue} ${productName} ${licenseNameToShow}`}
            className={css.labelLight}
          />
          <core.Label
            value={core.formatMessage(Locale.additional_charges)}
            className={css.labelDark}
          />
          <span className={css.licensePriceTag}>{totalPriceDisplay}</span>
          {oneMonthProrate && (
            <core.Label
              value={core.formatMessage(Locale.license_puchase_summary, {
                amount: <span>{price}</span>
              })}
              className={css.labelText}
            />
          )}
          <core.Label
            value={core.formatMessage(Locale.license_terms_condition)}
            className={css.labelText}
          />
          <core.Flexbox className={css.tncBox}>
            <core.CheckBox
              cwId="tncCheckbox"
              automationName="tncCheckbox"
              value={checkBoxValue}
              onChange={() => !isBusy && setCheckBoxValue()}
            />
            <core.Label
              value={`${core.formatMessage(
                Locale.license_agreement_statement
              )} ${core.formatMessage(Locale.license_agreement)}`}
              className={css.labelTnc}
              onClick={() => onTncClick()}
              automationName="tncLabel"
            />
          </core.Flexbox>
        </>
      )}

      {currentStep === Steps.Step_3 && (
        <>
          <core.Flexbox className={css.licenseSuccessimage}>
            <core.Image
              src="/images/HomePage/undraw-festivities-tvvj.png"
              isInlineSVG={false}
              alt={core.formatMessage(Locale.license_success)}
            />
          </core.Flexbox>
          <core.Label
            value={core.formatMessage(Locale.license_purchase_response)}
            className={css.labelDark}
          />
          <core.Label
            value={core.formatMessage(Locale.license_activation_duration, {
              days: activationDays
            })}
            className={cx(css.labelText, css.lastStepLabel)}
          />
          {noProrate && (
            <core.Label
              value={core.formatMessage(
                Locale.license_charge_summary_no_prorate,
                {
                  totalAmount: <span>{totalPriceDisplay}</span>,
                  licenseName: licenseNameLowercase
                }
              )}
              className={cx(css.labelText, css.lastStepLabel)}
            />
          )}
          {oneMonthProrate && (
            <core.Label
              value={core.formatMessage(
                Locale.license_charge_summary_one_month_prorate,
                {
                  prorateAmount: <span>{price}</span>,
                  totalAmount: <span>{totalPriceDisplay}</span>,
                  licenseName: licenseNameLowercase,
                  currentMonthName
                }
              )}
              className={cx(css.labelText, css.lastStepLabel)}
            />
          )}
          <core.Label
            value={core.formatMessage(Locale.license_terms_condition)}
            className={cx(css.labelText, css.lastStepLabel)}
          />
        </>
      )}
    </core.Flexbox>
  );
};

export default core.MobileLayoutHOC(DrawerContent);
