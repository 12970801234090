import { GET, getUrl } from 'src/services/ApiService';

const LocationService = () => {
  const apiUrl = () => getUrl('home', '/location');

  const getCountries = async () => {
    return await GET(`${apiUrl()}/countries`, true, '1.0');
  };

  const getCountryCurrency = async country => {
    return GET(`${apiUrl()}/currency/${country}`);
  };

  return {
    getCountries,
    getCountryCurrency
  };
};

export default LocationService();
