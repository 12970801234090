import * as core from 'cw-ui-core';
import PartnerBillingService from 'src/services/Api/BillingApi/PartnerBillingService';
import Routes from 'src/services/Routes';
import HasAccess from 'src/services/RoleAccess';
import { POLICIES } from 'src/services/Authorization';

const TAX_IDENTIFIER_REGIONS = ['United Kingdom', 'Canada', 'Australia'];

export const checkUserPermission = async () => {
  const userBillingAccessResult = await PartnerBillingService.getUserBillingAccess();
  const hasCartPermissions = HasAccess(
    [POLICIES.BILLING_ADMIN, POLICIES.ADMIN],
    null
  );

  if (!userBillingAccessResult.isSuccess) {
    const errorMessage = 'Failed to get user billing permission. ';
    const error =
      errorMessage + userBillingAccessResult.error;
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(
        core.setErrorScreenMessage(
          errorMessage,
          false,
          userBillingAccessResult.errorMessages
        )
      );
    return;
  }

  const billingPermissions = JSON.parse(
    userBillingAccessResult.response.msg.Billing
  );
  const hasBillingPermissions =
    billingPermissions.read && billingPermissions.add;

  const hasPermissions = hasBillingPermissions && hasCartPermissions;
  if (!hasPermissions) {
    core
      .Store()
      .dispatch(
        core.setErrorScreenMessage('No cart or billing permissions.', false, [])
      );
  }

  core
    .Store()
    .dispatch(
      core.setScreenData(
        [Routes.SHOPPING_CART.id, 'userHasPermission'],
        hasPermissions
      )
    );
  return hasPermissions;
};

export const getPartnerBillingInfo = async () => {
  const result = await PartnerBillingService.getPartnerBillingInfo();
  if (!result.isSuccess) {
    const errorMessage = 'Failed to get partner billing information. ';
    const error = errorMessage + result.error;
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(core.setErrorScreenMessage(errorMessage, false, result.errorMessages));
    return;
  }

  const partnerBillingInfo = result.response;

  const taxIdentifier =
    partnerBillingInfo.msg.cwCompany &&
    partnerBillingInfo.msg.cwCompany.taxIdentifier;
  core
    .Store()
    .dispatch(
      core.setScreenData(
        [Routes.SHOPPING_CART.id, 'billingInfo'],
        partnerBillingInfo.msg
      )
    );
  taxIdentifier &&
    core
      .Store()
      .dispatch(
        core.setScreenData(
          [Routes.SHOPPING_CART.id, 'taxIdentifier'],
          taxIdentifier
        )
      );

  return partnerBillingInfo;
};

export const navigateToRedirectUrl = (status, message) => {
  const cartReferenceKey = core.getQueryParameter('cartReferenceKey');
  const returnUrl = decodeURIComponent(core.getQueryParameter('redirectUri'));
  let redirectUrl = `${returnUrl}?transactionstatus=${status}&uuid=${cartReferenceKey}`;
  if (message) {
    redirectUrl += `&reason=${message}`;
  }

  window.location = redirectUrl;
};

export const navigateToHome = () => {
  window.location = '/';
};

export const navigateBack = (status, message) => {
  const cartReferenceKey = core.getQueryParameter('cartReferenceKey');
  if (cartReferenceKey) {
    navigateToRedirectUrl(status, message);
    return;
  }

  navigateToHome();
};

export const checkIfPartnerCanHaveTaxIdentifier = () => {
  const state = core.Store().getState();
  const country = core.getModuleCache(state.module, ['partner', 'country']);
  if (!country) {
    return false;
  }

  const inTaxIdentifierRegion = TAX_IDENTIFIER_REGIONS.includes(country);
  core
    .Store()
    .dispatch(
      core.setScreenData(
        [Routes.SHOPPING_CART.id, 'showTaxIdentifier'],
        inTaxIdentifierRegion
      )
    );
  return inTaxIdentifierRegion;
};

export const getHeaderText = () => {
  const state = core.Store().getState();
  const activePage = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'activePage'
  ]);
  switch (activePage) {
    case 'shopping_cart':
      return 'shopping_cart';
    case 'review_order':
      return 'review_your_order';
    case 'select_payment':
      return 'select_payment';
    default:
      return 'shopping_cart';
  }
};

export const getBackButtonText = () => {
  const state = core.Store().getState();
  const activePage = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'activePage'
  ]);
  const cartReferenceKey = core.getQueryParameter('cartReferenceKey');
  const fromMarketplace = cartReferenceKey ? true : false;
  switch (activePage) {
    case 'shopping_cart':
      return fromMarketplace ? 'back_to_marketplace' : 'back';
    case 'review_order':
      return 'back_to_select_payment';
    case 'select_payment':
      return 'back_to_shopping_cart';
    default:
      return 'back';
  }
};

export const onNavigateBack = () => {
  const state = core.Store().getState();
  const dispatch = core.Store().dispatch;
  const activePage = core.getScreenData(state.screen, [
    Routes.SHOPPING_CART.id,
    'activePage'
  ]);
  switch (activePage) {
    case 'shopping_cart':
      navigateBack('canceled');
      break;
    case 'review_order':
      dispatch(
        core.setScreenData(
          [Routes.SHOPPING_CART.id, 'activePage'],
          'select_payment'
        )
      );
      break;
    case 'select_payment':
      dispatch(
        core.setScreenData(
          [Routes.SHOPPING_CART.id, 'activePage'],
          'shopping_cart'
        )
      );
      break;
    default:
      return;
  }
};
