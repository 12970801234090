import BaseCartItem from './BaseCartItem';

class ControlCartItem extends BaseCartItem {
  constructor(cartItem, controlAccountData) {
    super(cartItem);
    this._controlAccountData = controlAccountData;
    const instanceData = controlAccountData.instances.find(c => c.instanceID === cartItem.instanceId);
    this._instanceDescription = instanceData && instanceData.webAbsoluteDomainName;
  }

  get productName() {
    return 'Control';
  }

  get isControl() {
    return true;
  }

  get instanceDescription() {
    return this._instanceDescription;
  }

  get description() {
    return this._buyerCartItem.description.split(' ').slice(0, 3).join(' ');
  }

  get sku() {
    return this._buyerCartItem.productPlan;
  }

  get allowedQuantities() {
    if (this._buyerCartItem.allowedQuantities) {
      return this._buyerCartItem.allowedQuantities;
    }

    return [
      { id: 25, name: '25' },
      { id: 100, name: '100' },
      { id: 250, name: '250' },
      { id: 500, name: '500' },
      { id: 750, name: '1000' }
    ];
  }

  get productImageUrl() {
    return '/images/ShoppingCart/control.png';
  }
}

export default ControlCartItem;