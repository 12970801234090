import * as core from 'cw-ui-core';
import { connect } from 'react-redux';
import License from 'src/components/Drawer/AddLicenseDrawer/License';
import { Steps, TimePeriod } from 'src/services/Licenses/LicensesService';
import Locale from 'src/Locale';
import {
  getProratePriceDisplay,
  jumpOnToStep,
  createQuoteTicket,
  validateQuantity,
  createLicenseProvision,
  getNextMonth,
  calculateLicensePrice
} from 'src/services/Licenses/LicensesService.js';
import { injectIntl } from 'react-intl';
import { ADD_LICENSE_DRAWER } from 'src/components/Drawer/AddLicenseDrawer/LicenseDrawerContainer';

let activationDays = '';

const mapStateToProps = state => {
  let licenseInfo = core.getScreenData(
    state.screen,
    ['licenseInfo'],
    ADD_LICENSE_DRAWER
  );
  licenseInfo = licenseInfo ? licenseInfo.toJS() : [];
  const {
    step: currentStep,
    licenseError,
    errorCode,
    isUnAuthorized
  } = licenseInfo;
  let { licenseType: title, productName } = licenseInfo;

  const processingError = core.getScreenData(
    state.screen,
    'processingError',
    ADD_LICENSE_DRAWER
  );
  const quoteRequested = core.getScreenData(
    state.screen,
    'quoteRequested',
    ADD_LICENSE_DRAWER
  );
  const ticketProcessingError = core.getScreenData(
    state.screen,
    'ticketProcessingError',
    ADD_LICENSE_DRAWER
  );
  const ticketId = core.getScreenData(
    state.screen,
    'ticketId',
    ADD_LICENSE_DRAWER
  );

  const isLoading = core.getScreenData(
    state.screen,
    'showLoadingComponent',
    ADD_LICENSE_DRAWER
  );
  let quantityValue = core.getScreenData(
    state.screen,
    'totalQuantity',
    ADD_LICENSE_DRAWER
  );
  quantityValue = quantityValue === undefined ? 1 : quantityValue;
  let licenseName = title ? title.split(' ') : null;
  licenseName = licenseName ? licenseName[1] : 'licenses';

  if (productName) {
    for (let duration in TimePeriod) {
      duration.toLowerCase() === productName &&
        (activationDays = TimePeriod[duration]);
    }
  }

  currentStep === Steps.Step_2 &&
    (title = core.formatMessage(Locale.add_license_confirm));
  currentStep === Steps.Step_3 &&
    (title = core.formatMessage(Locale.add_license_success));

  licenseError &&
    (title = core.formatMessage(Locale.add_license_error, {
      licenseType: licenseName
    }));

  (processingError || ticketProcessingError) &&
    (title = core.formatMessage(Locale.license_something_wrong));
  productName =
    productName && productName.charAt(0).toUpperCase() + productName.slice(1);
  const [currentMonthName, nextMonthName] = getNextMonth();

  return {
    currentStep,
    title,
    activationDays,
    licenseName,
    licenseError,
    errorCode,
    processingError,
    quoteRequested,
    ticketProcessingError,
    ticketId,
    provisionLicensingInfo: calculateLicensePrice(),
    contactEmail: 'accountmanager@connectwise.com',
    currentMonthName,
    nextMonthName,
    proratePriceToDisplay: getProratePriceDisplay(),
    productName,
    isLoading,
    isUnAuthorized,
    quantityValue
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onViewTicket: id => {
      window.open(`/partnersupport/ticket/${id}`, '_self');
    },
    onClickBack: () => {
      const stateScreen = core.Store().getState().screen;
      const currentStep = core.getScreenData(
        stateScreen,
        ['licenseInfo', 'step'],
        ADD_LICENSE_DRAWER
      );
      const currentTitle = core.getScreenData(
        stateScreen,
        ['licenseInfo', 'licenseType'],
        ADD_LICENSE_DRAWER
      );
      jumpOnToStep(currentStep, currentTitle);
    },
    getFormattedPriceWithCurrency: (currency, price) => {
      const currencyFormat = {
        style: 'currency',
        currency,
        minimumFractionDigits: 0
      };
      return ownProps.intl.formatNumber(price, currencyFormat);
    },
    onTncClick: () => {
      window.open(
        'https://www.connectwise.com/globalassets/media/documents/legal/connectwise-master-agreement-software--saas-and-education-and-services-addendum--7-23-21-clean.pdf'
      );
    },
    onClickQuote: () => {
      createQuoteTicket();
    },
    onClickContinue: () => {
      dispatch(core.setScreenData('isDrawerUpdated', true, ADD_LICENSE_DRAWER));
      createLicenseProvision();
    },
    onSelectQuantity: value => {
      dispatch(core.setScreenData('isDrawerUpdated', true, ADD_LICENSE_DRAWER));
      const quantityValue = validateQuantity(value);
      dispatch(
        core.setScreenData('totalQuantity', quantityValue, ADD_LICENSE_DRAWER)
      );
    },
    onLicenseQuoteCommentChange: value => {
      if (value) {
        dispatch(
          core.setScreenData('isDrawerUpdated', true, ADD_LICENSE_DRAWER)
        );
      }
    }
  };
};

const LicenseContainer = connect(mapStateToProps, mapDispatchToProps)(License);
export default injectIntl(LicenseContainer);
