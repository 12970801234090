import UserService from 'src/services/Api/HomeApi/UserService';
import UserProfileService from 'src/services/Api/UserManagementApi/UserProfileService';
import * as core from 'cw-ui-core';
import Routes from 'src/services/Routes';
import Locale from 'src/Locale';
import { POLICIES } from '../Authorization';

export const NO_COMPANY = 'No Company';
export const NO_COMPANY_CONTACT = 'No Company Contact';
export const NO_ACCESS = 'No Access';

const getSecurityPoliciesFromCache = () => {
  const state = core.Store().getState();
  const securityPoliciesChk = core.getModuleCache(state.module, [
    'userSecurity',
    'securityPolicies'
  ]);
  return securityPoliciesChk ? securityPoliciesChk.toJS() : [];
};

const dispatchError = async (
  result,
  methodName,
  screenKey = core.ACTIVE_SCREEN_KEY
) => {
  const error = `${methodName}:Failed to get user service data.${
    result.error || result.response.msg
  }`;
  core.CwLog.error(error);
  dispatch(
    core.setErrorScreenMessage(
      result.error,
      false,
      result.errorMessages,
      screenKey
    )
  );
};

const dispatch = core.Store().dispatch;

export const getUserSecurity = async () => {
  const dispatch = core.Store().dispatch;
  if (UserService) {
    const oldSecurityPolicies = getSecurityPoliciesFromCache();
    await UserService.refreshUserSecurity();
    const result = await UserService.fetchUserSecurity();
    if (result.isSuccess) {
      const userSecurity = result.response;
      const state = core.Store().getState();
      const user = core.getUser(state.oidc);
      const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
      const originAppRoles = user.toJS()?.profile?.origin_app_role_assignments;
      const childAppRoles = user.toJS()?.profile?.app_role_assignments;
      let originAppRolesArray = [];
      let childAppRolesArray = [];
      for (let key in originAppRoles) {
        originAppRolesArray.push(originAppRoles[key]);
      }

      for (let key in childAppRoles) {
        childAppRolesArray.push(childAppRoles[key]);
      }

      if (isGuestUserLoggedIn) {
        const isAdminOrUserManagement = childAppRolesArray?.some(
          role =>
            role.appRoleId === 'Admin' || role.appRoleId === 'User Management'
        );
        const isOriginAdmin = originAppRolesArray?.some(
          role => role.appRoleId === 'Admin'
        );

        if (isAdminOrUserManagement || isOriginAdmin) {
          if (!userSecurity.securityPolicies.includes('User Management')) {
            userSecurity.securityPolicies.push('User Management');
          }
        }
      }

      dispatch(core.setModuleCache(['userSecurity'], userSecurity));
      if (userSecurity.securityPolicies.includes(NO_ACCESS)) {
        dispatch(core.showDialog('APP_ACCESS_DENIED'));
        return;
      }

      if (
        !oldSecurityPolicies.includes(NO_COMPANY_CONTACT) &&
        userSecurity.securityPolicies.includes(NO_COMPANY_CONTACT)
      ) {
        dispatch(
          core.setInformationalScreenMessage(
            core.formatMessage(Locale.sso_email_association_error),
            true,
            null,
            Routes.HOME_PAGE.id
          )
        );
      }

      return userSecurity;
    }
  }

  dispatch(core.showDialog('SERVER_NOT_AVAILABLE'));
  return;
};

export const refreshUserSecurity = async () => {
  await UserService.refreshUserSecurity();
  await getUserSecurity();
};

export const userHasNoCompany = () => {
  const securityPolicies = getSecurityPoliciesFromCache();
  return securityPolicies.includes('No Company');
};

export const userHasNoAccess = () => {
  const securityPolicies = getSecurityPoliciesFromCache();
  return securityPolicies.includes('No Access');
};

export const userHasNoRoles = () => {
  const securityPolicies = getSecurityPoliciesFromCache();
  return securityPolicies.length === 0;
};

export const userHasNoCompanyContact = () => {
  const securityPolicies = getSecurityPoliciesFromCache();
  return securityPolicies.includes('No Company Contact');
};

export const getMfaStatus = async () => {
  const result = await UserProfileService.getMfaStatus();
  if (!result.isSuccess || result.response.status === 'error') {
    dispatchError(result, 'getMfaStatus');
    return result.response;
  }

  return result.response;
};

export const getMfaAuthUrl = async () => {
  const result = await UserProfileService.getMfaAuthUrl();
  if (!result.isSuccess || result.response.status === 'error') {
    dispatchError(result, 'getMfaAuthUrl');
    return result.response;
  }

  return JSON.stringify(result.response);
};

export const validateMFAKey = async code => {
  dispatch(core.toggleMask(true));
  const result = await UserProfileService.validateMFAKey(code);
  if (!result.isSuccess || result.response.status === 'error') {
    dispatch(core.toggleMask(false));
    return result.response;
  }

  dispatch(core.toggleMask(false));
  return result.response;
};

export const getValidMfaProviders = async screenKey => {
  let result = await UserProfileService.getValidMfaProviders();
  if (!result.isSuccess || result.response.error === 'error') {
    dispatchError(result, 'getValidMfaProviders', screenKey);
    return false;
  }

  return result.response.validProviders;
};

export const turnOnEmailMfa = async () => {
  const result = await UserProfileService.turnOnEmailMfa();
  if (!result.isSuccess || result.response.status === 'error') {
    dispatchError(result, 'turnOnEmailMfa');
    return false;
  }

  return result.response;
};

export const turnOffEmailMfa = async screenKey => {
  const result = await UserProfileService.turnOffEmailMfa();
  if (!result.isSuccess || result.response.status === 'error') {
    dispatchError(result, 'turnOffEmailMfa', screenKey);
    return false;
  }

  return result.response;
};

export const disableEmailMfa = async verificationCode => {
  dispatch(core.toggleMask(true));
  const result = await UserProfileService.disableEmailMfa(verificationCode);
  if (!result.isSuccess || result.response.status === 'error') {
    dispatch(core.toggleMask(false));
    return false;
  }

  dispatch(core.toggleMask(false));
  return result.response;
};

export const disableMFAKey = async code => {
  dispatch(core.toggleMask(true));
  const result = await UserProfileService.disableMFAKey(code);
  if (!result.isSuccess || result.response.status === 'error') {
    dispatch(core.toggleMask(false));
    return false;
  }

  dispatch(core.toggleMask(false));
  return result.response;
};

export const updatePassword = async code => {
  dispatch(core.toggleMask(true));
  const result = await UserProfileService.updatePassword(code);
  if (!result.isSuccess || result.response.status === 'error') {
    dispatch(core.toggleMask(false));
    return false;
  } else {
    dispatch(core.toggleMask(false));
    return true;
  }
};

export const setWalkMeVariables = () => {
  const state = core.Store().getState();
  const firstName = core.getUserFirstName(state.oidc);
  const partnerModule = core.getModuleCache(state.module, ['partner']);
  const partner = partnerModule ? partnerModule.toJS() : undefined;
  const company = partner ? partner.companyName : undefined;
  const email = core.getUserEmail(state.oidc);
  const ssoUserID = core.getUserId(state.oidc);
  const partnerCountry = partner ? partner.country : undefined;
  const partnerState = partner ? partner.state : undefined;
  const zip = partner ? partner.zip : undefined;

  const securityPoliciesChk = core.getModuleCache(state.module, [
    'userSecurity',
    'securityPolicies'
  ]);
  const hasSuccessManager = core.getModuleCache(state.module, [
    'successManager'
  ])
    ? true
    : false;

  const roles = securityPoliciesChk ? securityPoliciesChk.toJS() : [];
  let isAdmin = false;
  let isUserManagement = false;
  let isBillingAdmin = false;
  let noSSORole = false;
  let manageSecurityRole = '';
  if (roles) {
    isAdmin = roles.includes(POLICIES.ADMIN);
    isUserManagement = roles.includes(POLICIES.USER_MANAGEMENT);
    isBillingAdmin = roles.includes(POLICIES.BILLING_ADMIN);
    noSSORole = !(isAdmin || isUserManagement || isBillingAdmin);
    manageSecurityRole = roles.filter(role => {
      if (
        !(
          role === POLICIES.ADMIN ||
          role === POLICIES.USER_MANAGEMENT ||
          role === POLICIES.BILLING_ADMIN
        )
      )
        return role;
      else return null;
    });
  }

  const productsCheck = core.getModuleCache(state.module, ['products']);
  const products = productsCheck ? productsCheck.toJS() : null;
  let accessToManage = false;
  let accessToAutomate = false;
  let accessToSell = false;
  let accessToControl = false;
  let accessToUnite = false;
  let accessToIdentify = false;
  if (products) {
    const productIdentifiers = products.map(product => {
      return product.productIdentifier;
    });
    accessToManage = productIdentifiers.includes('manage');
    accessToAutomate = productIdentifiers.includes('automate');
    accessToSell = productIdentifiers.includes('sell');
    accessToControl = productIdentifiers.includes('control');
    accessToUnite = productIdentifiers.includes('unite');
    accessToIdentify = productIdentifiers.includes('identify');
  }

  var walkMeVariables = {
    ManageSecurityRole: manageSecurityRole.toString(),
    IsSSOAdmin: isAdmin,
    IsSSOUserManagement: isUserManagement,
    IsSSOBillingAdmin: isBillingAdmin,
    NoSSORole: noSSORole,
    SSOUserID: ssoUserID,
    FirstName: firstName,
    CompanyName: company,
    AccessToManage: accessToManage,
    AccessToAutomate: accessToAutomate,
    AccessToSell: accessToSell,
    AccessToControl: accessToControl,
    AccessToUnite: accessToUnite,
    AccessToIdentify: accessToIdentify,
    HasSuccessManager: hasSuccessManager,
    Email: email,
    Country: partnerCountry,
    State: partnerState,
    Zip: zip
  };

  return walkMeVariables;
};

export const getSearchToken = async () => {
  const dispatch = core.Store().dispatch;
  const result = await UserService.getSearchToken();
  if (result.isSuccess) {
    dispatch(core.setModuleCache(['coveo'], result.response));
    const token = result.response.token;
    return token;
  }

  return null;
};

export const getEmailDomain = email => {
  if (email) {
    const pos = email.indexOf('@');
    return email.slice(pos + 1, email.length);
  }
};

export const isSupportEnabled = async () => {
  const result = await UserProfileService.isSupportEnabled();
  if (!result.isSuccess || result.response.status === 'error') {
    dispatchError(result, 'isSupportEnabled');
    return;
  }

  return result.response;
};

export const enableSupport = async () => {
  const result = await UserProfileService.enableSupport();
  if (!result.isSuccess || result.response.status === 'error') {
    dispatchError(result, 'enableSupport');
    return;
  }

  return result.response;
};
export const disableSupport = async () => {
  const result = await UserProfileService.disableSupport();
  if (!result.isSuccess || result.response.status === 'error') {
    dispatchError(result, 'disableSupport');
    return;
  }

  return result.response;
};
