import { getModuleCache, Store } from 'cw-ui-core';

const HasAccess = (allowedRoles, disallowedRoles) => {
  if (!allowedRoles && !disallowedRoles) {
    return true;
  }

  const state = Store().getState();
  const userRoles = getModuleCache(state.module, [
    'userSecurity',
    'securityPolicies'
  ]);
  const userRolesJs = userRoles ? userRoles.toJS() : null;
  if (!userRolesJs || userRolesJs.length <= 0) {
    return false;
  }

  if (disallowedRoles && disallowedRoles.length > 0) {
    const disallowCheck = userRolesJs.some(val =>
      disallowedRoles.includes(val)
    );
    const allowCheck = allowedRoles
      ? userRolesJs.some(val => allowedRoles.includes(val))
      : false;
    if (disallowCheck) {
      return !disallowCheck;
    } else if (allowCheck) {
      return allowCheck;
    } else if (allowedRoles && !allowCheck) {
      return false;
    } else {
      return true;
    }
  }

  return userRolesJs.some(val => allowedRoles.includes(val));
};

export default HasAccess;
