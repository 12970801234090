import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/ProductDrawer/UniteProductDrawer.scss';
import cssCommon from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon.scss';
import cx from 'classnames';
import Locale from 'src/Locale';
import {
  PlanCardButtonWithLoadingIndicator,
  ProductHeader
} from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon';

const UniteProductDrawer = props => {
  const plan = props.plan;

  return (
    <core.Drawer
      automationName="uniteDrawer"
      onCloseIconClicked={props.onClose}
      closeOnBlur
    >
      <core.Flexbox className={cx(css.container, css.scrollable)} column>
        <ProductHeader
          {...props}
          productIconClassName={css.productIconHeader}
          descriptionClassName={css.productDescription}
          dividerLineClassName={css.dividerLineHeader}
        />
        <core.Label className={cssCommon.productLabel}>
          {core.formatMessage(Locale.service_categories)}
        </core.Label>
        <core.Label value={props.planDescription} />
        <core.Label value={props.planBillingDescription} isHTML />
        <core.Flexbox key={plan.name} className={css.plan} column={false}>
          <core.Flexbox className={css.planCard} column>
            <core.Label
              className={cx(css.planCardLabel, css.planCardName)}
              value={plan.name}
            />
            {plan.status && (
              <core.Label
                className={cx(css.planCardStatus, {
                  [css.planCardStatusGray]: plan.statusKey === 'cancelled',
                  [css.productStatusRed]:
                    plan.statusKey === 'payment_past_due' ||
                    plan.statusKey === 'provisioning_failed',
                  [css.productStatusGreen]: plan.statusKey === 'provisioning'
                })}
                value={plan.status}
              />
            )}
            <span className={css.planCardLabel}>
              <core.Label className={css.planCardPrice} value={plan.price} />
              <core.Label
                className={css.planCardPriceInterval}
                value={plan.priceInterval}
              />
            </span>
            <core.Label
              className={css.planBillingType}
              value={plan.billingType}
            />
            <core.Label
              className={cx(css.planCardLabel, css.categoryComment)}
              value={plan.comment}
            />
            {plan.showButton && (
              <PlanCardButtonWithLoadingIndicator
                automationName={`planCardLoaderButton_${plan.automationName}`}
                buttonText={plan.buttonText}
                onCardButtonClick={props.onCardButtonClick}
                isBusy={props.isBusy}
                wrapperStyle={{
                  position: 'absolute',
                  bottom: '0',
                  marginBottom: '30px',
                  width: '188px',
                  alignSelf: 'center'
                }}
                planCardButtonClassName={css.planCardButton}
              />
            )}
          </core.Flexbox>
          <core.Flexbox className={css.categoryCard} column>
            {plan.billedCategories.map(c => (
              <core.Flexbox
                key={c.name}
                className={cx(css.categoryContainer, {
                  [css.categoryDisabled]: !c.enabled
                })}
                column
              >
                <core.Label className={css.category} value={c.name} />
                <core.Label
                  className={cx(css.planCardLabel, css.categoryDescription)}
                  value={c.description}
                />
              </core.Flexbox>
            ))}
          </core.Flexbox>
        </core.Flexbox>
      </core.Flexbox>
    </core.Drawer>
  );
};

export default UniteProductDrawer;
