import React from 'react';
import {
  Dialog,
  ButtonStandard,
  ButtonLight,
  Label,
  MobileLayoutHOC,
  ScreenMessagePanelContainer
} from 'cw-ui-core';
import Locale from 'src/Locale';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
import css from 'src/components/HomePage/ToolsPod/Resources/ResourcesDialogs/AddEditResourceDialog.scss';

export const DELETE_RESOURCE_DIALOG = 'DELETE_RESOURCE_DIALOG';

const DeleteConfirmResourceDialog = props => {
  const {
    intl: { formatMessage },
    isMobileLayout,
    onCloseDialog,
    onDeleteClick,
    isDeleting
  } = props;

  const SaveAndCancelButton = () => {
    const button = () => (
      <ButtonStandard
        automationName="deleteResourceButton"
        value={formatMessage(Locale.delete)}
        isSmall
        onClick={onDeleteClick}
      />
    );
    const LoadingButton = WithButtonLoadingIndicatorHOC(button);
    return [
      <ButtonLight
        automationName="cancelDialogButton"
        key="buttonCancel"
        value={formatMessage(Locale.cancel)}
        isSmall
        onClick={onCloseDialog}
      />,
      <LoadingButton
        key="buttonDelete"
        isBusy={isDeleting}
        wrapperStyle={{ height: '36px', width: '78px' }}
      />
    ];
  };

  return (
    <Dialog
      automationName="confirmDeleteResourceDialog"
      buttons={SaveAndCancelButton()}
      includeCloseIcon={!isMobileLayout}
      includeMobileCloseIcon={isMobileLayout}
      onCloseIconClicked={onCloseDialog}
    >
      <ScreenMessagePanelContainer
        screenKey={DELETE_RESOURCE_DIALOG}
        className={isMobileLayout ? css.screenMessageMobile : css.screenMessage}
      />
      <Label
        className={css.headerTitle}
        value={formatMessage(Locale.delete_resource_header_text)}
      />

      <Label
        className={
          isMobileLayout ? css.contentMessageMobile : css.contentMessageFull
        }
        value={formatMessage(Locale.confirm_delete_resource)}
      />
    </Dialog>
  );
};

export default MobileLayoutHOC(DeleteConfirmResourceDialog);
