import React, { useEffect, useRef } from 'react';
import css from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawer.scss';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import cx from 'classnames';
import Routes from 'src/services/Routes';
import { FieldWithLabel, TextAreaWithLabel } from 'src/components/FieldWithLabel/FieldWithLabel';

const BannerDrawerContent = props => {

  const drawerContent = useRef(null);
  useEffect(() => {
    const heightToApply = window.innerHeight - 250;
    drawerContent.current &&
      (drawerContent.current.style.maxHeight = `${heightToApply}px`);
  });

  const getFirstBannerItemPreview = () => {
    return (
      <core.Image
        isInlineSVG={false}
        src={props.firstBannerUrl}
        className={css.colorPreview}
      />
    );
  };

  const getSecondBannerItemPreview = () => {
    return (
      <core.Image
        isInlineSVG={false}
        src={props.secondBannerUrl}
        className={css.colorPreview}
      />
    );
  };

  const getThirdBannerItemPreview = () => {
    return (
      <core.Image
        isInlineSVG={false}
        src={props.thirdBannerUrl}
        className={css.colorPreview}
      />
    );
  };

  const getForthBannerItemPreview = () => {
    return (
      <core.Image
        isInlineSVG={false}
        src={props.fourthBannerUrl}
        className={css.colorPreview}
      />
    );
  };

  const getColorItemPreview = item => {
    return (
      <div
        className={css.colorPreview}
        style={{
          backgroundColor: item.name,
        }}
      />
    );
  };

  const customizeUploader = uploader => {
    return uploader;
  };

  const flyoutMenu = () => {
    return (
      <core.FlyoutComposer
        automationName={`AboutMe-${props.name}`}
        flyoutClassName={css.bannerImageFlyout}
        positionFixed
        widget={
          <core.Image
            automationName="aboutMeIcon"
            src={core.IconPath.get('Application_Information')}
            className={css.toolTipIcon}
          />
        }
        flyout={
          <core.Flexbox className={css.aboutMeContent} column>
            <core.Label
              automationName="aboutMeTitleText"
              isHTML
              value={core.formatMessage(Locale.image_info_message)}
              className={css.aboutMeTitle}
            />
          </core.Flexbox>
        }
      />
    );
  };

  return (
    <>
      <core.Flexbox className={css.detailsAndPreviewContainer}>
        <core.Flexbox column className={cx(css.details, css.bannerDetails)}>
          <core.FormFieldContainer
            path={[Routes.BANNER_CONFIGURATION.id, 'bannerName']}
            className={cx(css.textField, css.displayAsRequired)}
            label={core.formatMessage(Locale.banner_name)}
            required
          >
            <core.TextField
              className={cx(css.textField)}
              placeholder={core.formatMessage(Locale.banner_name_info)}
              maxLength={100}
              style={{ backgroundColor: '#ffffff' }}
            />
          </core.FormFieldContainer>
          <core.Flexbox column className={css.bannerImageAttachments}>
            <core.Flexbox column>
              <core.Flexbox>
                <core.Label
                  automationName="firstBannerName"
                  className={cx(css.displayAsRequired, css.labels)}
                  value={core.formatMessage(Locale.banner_upload_label_one)}
                  required
                />
                {flyoutMenu()}
              </core.Flexbox>
              <core.FormFieldContainer
                path={[Routes.BANNER_CONFIGURATION.id, 'firstBannerFileName']}
                className={css.displayAsRequired}
                required
              >
                {props.firstBannerUrl ? (
                  <core.Uploader
                    value={[
                      {
                        name: props.firstBannerFileName || props.firstBannerUrl
                      }
                    ]}
                    uploadText={core.formatMessage(Locale.attach)}
                    onChange={props.onFirstBannerChanged}
                    customizeUploader={customizeUploader}
                    getItemPreview={getFirstBannerItemPreview}
                  />
                ) : (
                  <core.AttachmentUpload
                    value={props.firstBanner || []}
                    uploadText={core.formatMessage(Locale.attach)}
                    onChange={props.onFirstBannerChanged}
                    accept=".svg"
                  />
                )}
              </core.FormFieldContainer>
            </core.Flexbox>
            <core.Flexbox column>
              <core.Flexbox>
                <core.Label
                  automationName="secondBannerName"
                  className={cx(css.displayAsRequired, css.labels)}
                  value={core.formatMessage(Locale.banner_upload_label_two)}
                  required
                />
                {flyoutMenu()}
              </core.Flexbox>
              <core.FormFieldContainer
                path={[Routes.BANNER_CONFIGURATION.id, 'secondBannerFileName']}
                className={css.displayAsRequired}
                required
              >
                {props.secondBannerUrl ? (
                  <core.Uploader
                    value={[
                      {
                        name:
                          props.secondBannerFileName || props.secondBannerUrl
                      }
                    ]}
                    uploadText={core.formatMessage(Locale.attach)}
                    onChange={props.onSecondBannerChanged}
                    customizeUploader={customizeUploader}
                    getItemPreview={getSecondBannerItemPreview}
                  />
                ) : (
                  <core.AttachmentUpload
                    value={props.secondBanner || []}
                    uploadText={core.formatMessage(Locale.attach)}
                    onChange={props.onSecondBannerChanged}
                    accept=".svg"
                  />
                )}
              </core.FormFieldContainer>
            </core.Flexbox>
            <core.Flexbox column>
              <core.Flexbox>
                <core.Label
                  automationName="thirdBannerName"
                  className={cx(css.displayAsRequired, css.labels)}
                  value={core.formatMessage(Locale.banner_upload_label_three)}
                  required
                />
                {flyoutMenu()}
              </core.Flexbox>
              <core.FormFieldContainer
                path={[Routes.BANNER_CONFIGURATION.id, 'thirdBannerFileName']}
                className={css.displayAsRequired}
                required
              >
                {props.thirdBannerUrl ? (
                  <core.Uploader
                    value={[
                      {
                        name: props.thirdBannerFileName || props.thirdBannerUrl
                      }
                    ]}
                    uploadText={core.formatMessage(Locale.attach)}
                    onChange={props.onThirdBannerChanged}
                    customizeUploader={customizeUploader}
                    getItemPreview={getThirdBannerItemPreview}
                  />
                ) : (
                  <core.AttachmentUpload
                    value={props.thirdBanner || []}
                    uploadText={core.formatMessage(Locale.attach)}
                    onChange={props.onThirdBannerChanged}
                    accept=".svg"
                  />
                )}
              </core.FormFieldContainer>
            </core.Flexbox>
            <core.Flexbox column>
              <core.Flexbox>
                <core.Label
                  automationName="fourthBannerName"
                  className={cx(css.displayAsRequired, css.labels)}
                  value={core.formatMessage(Locale.banner_upload_label_four)}
                  required
                />
                {flyoutMenu()}
              </core.Flexbox>
              <core.FormFieldContainer
                path={[Routes.BANNER_CONFIGURATION.id, 'fourthBannerFileName']}
                className={css.displayAsRequired}
                required
              >
                {props.fourthBannerUrl ? (
                  <core.Uploader
                    value={[
                      {
                        name:
                          props.fourthBannerFileName || props.fourthBannerUrl
                      }
                    ]}
                    uploadText={core.formatMessage(Locale.attach)}
                    onChange={props.onForthBannerChanged}
                    customizeUploader={customizeUploader}
                    getItemPreview={getForthBannerItemPreview}
                  />
                ) : (
                  <core.AttachmentUpload
                    value={props.fourthBanner || []}
                    uploadText={core.formatMessage(Locale.attach)}
                    onChange={props.onForthBannerChanged}
                    accept=".svg"
                  />
                )}
              </core.FormFieldContainer>
            </core.Flexbox>
          </core.Flexbox>
          <core.FormFieldContainer
            path={[Routes.BANNER_CONFIGURATION.id, 'bannerCopy']}
            label={core.formatMessage(Locale.copy)}
            maxLength={100}
            className={cx(css.logoField, css.displayAsRequired)}
            required
          >
            <FieldWithLabel
              className={cx(css.textField, css.fieldWithCount)}
              maxLength={50}
              labelProps={{ isCount: true }}
              style={{ backgroundColor: '#ffffff' }}
            />
          </core.FormFieldContainer>
          <core.FormFieldContainer
            path={[Routes.BANNER_CONFIGURATION.id, 'bannerSubHeader']}
            className={css.logoField}
            label={core.formatMessage(Locale.banner_sub_header)}
          >
            <TextAreaWithLabel
              className={css.textArea}
              rows={2}
              maxLength={200}
              labelProps={{ isCount: true }}
            />
          </core.FormFieldContainer>
          <core.FormFieldContainer
            path={['isToggleEnabled']}
            screenKey="BANNER_DRAWER"
          >
            <core.Toggle
              cwId="toggleCTAButton"
              automationName="toggleCTAButton"
              text={core.formatMessage(Locale.banner_CTA_button_enable)}
              className={css.bannerCTAButtonToggle}
            />
          </core.FormFieldContainer>
          {props.toggle && (
            <>
              <core.FormFieldContainer
                path={[Routes.BANNER_CONFIGURATION.id, 'bannerCTAButtonText']}
                className={cx(css.textField, css.displayAsRequired)}
                label={core.formatMessage(Locale.banner_CTA_button_text)}
                required
              >
                <core.TextField
                    className={cx(css.textField)}
                    maxLength={100}
                    style={{ backgroundColor: '#ffffff' }}
                />
              </core.FormFieldContainer>
              <core.Flexbox column className={css.bannerImageAttachments}>
                <core.FormFieldContainer
                  path={[Routes.BANNER_CONFIGURATION.id, 'primaryColor']}
                  className={cx(css.displayAsRequired, css.logoField)}
                  label={core.formatMessage(
                    Locale.banner_CTA_button_primary_color
                  )}
                  required
                >
                  <core.Uploader
                    value={props.primaryColor}
                    uploadText={core.formatMessage(Locale.pick_color)}
                    onChange={props.onPrimaryColorChanged}
                    customizeUploader={customizeUploader}
                    getItemPreview={getColorItemPreview}
                    onUploadClicked={props.onPrimaryColorUploadClicked}
                  />
                </core.FormFieldContainer>
                <core.FormFieldContainer
                  path={[
                    Routes.BANNER_CONFIGURATION.id,
                    'primaryButtonTextColor'
                  ]}
                  className={cx(css.displayAsRequired, css.logoField)}
                  label={core.formatMessage(
                    Locale.banner_CTA_button_text_primary_color
                  )}
                  required
                >
                  <core.Uploader
                    value={props.primaryButtonTextColor}
                    uploadText={core.formatMessage(Locale.pick_color)}
                    onChange={props.onPrimaryButtonTextColorChanged}
                    customizeUploader={customizeUploader}
                    getItemPreview={getColorItemPreview}
                    onUploadClicked={
                      props.onPrimaryButtonTextColorUploadClicked
                    }
                  />
                </core.FormFieldContainer>
              </core.Flexbox>
              <core.Flexbox>
                <core.Label
                  automationName="bannerCTAButtonURL"
                  className={cx(css.displayAsRequired, css.labels)}
                  value={core.formatMessage(Locale.banner_CTA_button_url)}
                  required
                />
                <core.FlyoutComposer
                  automationName={`AboutMe-${props.name}`}
                  flyoutClassName={css.toolTipContainer}
                  positionFixed
                  widget={
                    <core.Image
                      automationName="aboutMeIcon"
                      src={core.IconPath.get('Application_Information')}
                      className={css.toolTipIcon}
                    />
                  }
                  flyout={
                    <core.Flexbox className={css.aboutMeContent} column>
                      <core.Label
                        automationName="aboutMeTitleText"
                        isHTML
                        value={core.formatMessage(Locale.banner_demo_url)}
                        className={css.aboutMeTitle}
                      />
                    </core.Flexbox>
                  }
                />
              </core.Flexbox>
              <core.FormFieldContainer
                    path={[Routes.BANNER_CONFIGURATION.id, 'bannerCTAButtonUrl']}
                    className={cx(css.textField, css.displayAsRequired)}
                    onAfterChange={props.onCTAUrlChange}
                    required
              >
                    <core.TextField
                        className={cx(css.textField)}
                        maxLength={300}
                        style={{ backgroundColor: '#ffffff' }}
                    />
              </core.FormFieldContainer>
            </>
          )}
        </core.Flexbox>
      </core.Flexbox>
    </>
  );
};

export default BannerDrawerContent;