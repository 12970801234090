import React, { useEffect, useRef } from 'react';
import { Drawer, Placeholder, ScreenMessagePanelContainer } from 'cw-ui-core';
import css from 'src/components/Drawer/SecurityBulletin/SecurityPageDrawer.scss';
import SecurityBulletinItem from 'src/components/Drawer/SecurityBulletin/SecurityBulletinItemContainer';
import SecurityPageUnauthorizedError from 'src/components/Drawer/SecurityBulletin/SecurityPageUnauthorizedError';
export const VIEW_SECURITY_BULLETIN_DRAWER = 'VIEW_SECURITY_BULLETIN_DRAWER';

const SecurityPageDrawer = props => {
  const { isReady, isUnauthorized } = props;

  const drawerContent = useRef(null);

  useEffect(() => {
    const heightToApply = window.innerHeight - 113;
    drawerContent.current &&
      (drawerContent.current.style.maxHeight = `${heightToApply}px`);
  });

  const previewLoader = <Placeholder className={css.previewLoading} />;
  return (
    <Drawer
      automationName="listItemClose"
      fadeDirection="left"
      onCloseIconClicked={props.onCloseIconClicked}
    >
      <ScreenMessagePanelContainer screenKey={VIEW_SECURITY_BULLETIN_DRAWER} />
      {!isReady ? (
        <div>{previewLoader}</div>
      ) : (
        <>
          {!isUnauthorized ? (
            <div
              className={css.securityPageContentContainer}
              ref={drawerContent}
            >
              <SecurityBulletinItem />
            </div>
          ) : (
            <div className={css.unauthorizedContentContainer}>
              <SecurityPageUnauthorizedError />
            </div>
          )}
        </>
      )}
    </Drawer>
  );
};

export default SecurityPageDrawer;
