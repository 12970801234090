import { connect } from 'react-redux';
import DrawerHeader from './DrawerHeader';
import { Steps } from 'src/services/NewsAdmin/NewsAdminService';
import Locale from 'src/Locale';
import * as core from 'cw-ui-core';
import { NEWS_POST_DRAWER } from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsPostDrawerContainer';

const mapStateToProps = state => {
  const step = core.getScreenData(state.screen, 'step', NEWS_POST_DRAWER);
  let criteriaName;
  let criteriaDescription;
  if (step === Steps.Step_1) {
    criteriaName = core.formatMessage(Locale.postType);
    criteriaDescription = core.formatMessage(Locale.post_type_description);
  }

  if (step === Steps.Step_2) {
    criteriaName = core.formatMessage(Locale.post_audience);
    criteriaDescription = core.formatMessage(Locale.post_audience_description);
  }

  if (step === Steps.Step_3) {
    criteriaName = core.formatMessage(Locale.post_details);
    criteriaDescription = core.formatMessage(Locale.post_details_description);
  }

  return { criteriaName, criteriaDescription };
};

const DrawerHeaderContainer = connect(mapStateToProps, null)(DrawerHeader);

export default DrawerHeaderContainer;
