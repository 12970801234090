import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/PartnerSupportPage/KnownIssues/KnownIssues.scss';
import { injectIntl } from 'react-intl';
import Locale from 'src/Locale';

function KnownIssuesOptionColumn(props) {
  return (
    <core.FlyoutComposer
      automationName="sendEmailLinkFlyout"
      widget={
        <core.Image
          automationName="options"
          src={core.IconPath.get('Application_More')}
        />
      }
      flyout={
        <core.Flexbox column>
          <core.Label
            automationName="optionHeader"
            className={css.menuItem}
            onClick={props.onSendEmailLinkClick}
            value={core.formatMessage(Locale.known_issues_send_email_link)}
          />
        </core.Flexbox>
      }
      positionFixed
      flyoutClassName={css.menu}
    />
  );
}

export default injectIntl(KnownIssuesOptionColumn);
