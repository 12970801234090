import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import {
  Flexbox,
  Label,
  formatMessage,
  TextField,
  Image,
  IconPath,
  FlyoutComposer,
  FormFieldContainer
} from 'cw-ui-core';
import cx from 'classnames';
import svgCss from 'src/styles/svg.scss';
import css from 'src/components/Drawer/UserProfileDrawer/MultiFactor/MfaEmail.scss';
import Locale from 'src/Locale';

const MfaEmail = props => {
  const {
    onDidMount,
    isEmailMfaEnabled,
    isMobileLayout,
    isShowTurnOffVerification,
    onEmailMfaDisableCancelClick,
    emailMfaTurnOffCode,
    onDisableEmailMfaClick,
    email,
    onTurnOffEmailMfaClick,
    onTurnOnEmailMfaClick,
    isDisableEmailMfaVerificationFailed
  } = props;

  useEffect(() => {
    onDidMount();
  }, [onDidMount]);

  const [isShowTurnOnEmailMfaVerify, setIsShowTurnOnEmailMfaVerify] = useState(
    false
  );
  const [isShowTurnOnFlyout, setIsShowTurnOnFlyout] = useState(false);
  return (
    <Flexbox column className={css.mfaContainer}>
      <Label
        className={css.mfaEmailHeader}
        value={
          isMobileLayout
            ? formatMessage(Locale.mfa_email_setup_header_mobile)
            : formatMessage(Locale.mfa_email_setup_header)
        }
      />
      <Label
        className={css.subHeaderDescription}
        value={formatMessage(Locale.mfa_email_header_detailtext)}
      />
      {isEmailMfaEnabled ? (
        <>
          <Flexbox className={css.emailMfaContainer}>
            <Flexbox verticalAlign className={css.emailContainer}>
              <Image
                src={IconPath.get('Status_GoodProgram')}
                className={svgCss.green}
              />
              <Label
                className={{ [css.wrappedEmail]: isMobileLayout }}
                value={email}
              />
            </Flexbox>
          </Flexbox>
          {(isShowTurnOffVerification ||
            isDisableEmailMfaVerificationFailed) && (
            <Flexbox column className={css.mfaWarning}>
              <Image
                automationName="emailMfaDisableCancel"
                className={css.warningCancelIcon}
                src={IconPath.get('Action_Close')}
                onClick={onEmailMfaDisableCancelClick}
              />
              <Image
                className={cx(css.warningIcon, {
                  [css.warningIconRed]: isDisableEmailMfaVerificationFailed
                })}
                src={IconPath.get('Status_Unacknowledged')}
              />
              <Label
                value={
                  isDisableEmailMfaVerificationFailed
                    ? formatMessage(Locale.mfa_email_turn_off_verify_code_error)
                    : formatMessage(Locale.mfa_email_turn_off_verify)
                }
                grey7
                className={css.mfaWarningText}
              />
              <FormFieldContainer path={['emailMfaTurnOffCode']}>
                <TextField
                  className={css.textfield}
                  cwId="emailMfaTurnOffCode"
                  automationName="emailMfaTurnOffCode"
                  maxLength={6}
                  placeholder={formatMessage(
                    Locale.mfa_verify_token_placeholder
                  )}
                />
              </FormFieldContainer>
              <Flexbox className={css.emailMfaConfirmation}>
                <Label
                  cwId="resendEmailMfaVerificationCode"
                  automationName="resendEmailMfaVerificationCode"
                  className={cx(css.mfaButton, {
                    [css.mfaButtonRed]: isDisableEmailMfaVerificationFailed
                  })}
                  value={formatMessage(Locale.mfa_email_resend_code)}
                  onClick={onTurnOffEmailMfaClick}
                  isLink
                />
                <Label
                  automationName="disableEmailMfaConfirm"
                  className={cx(css.mfaButton, {
                    [css.mfaButtonRed]: isDisableEmailMfaVerificationFailed
                  })}
                  value={formatMessage(Locale.mfa_turn_off)}
                  disabled={!emailMfaTurnOffCode}
                  onClick={onDisableEmailMfaClick}
                  isLink
                />
              </Flexbox>
            </Flexbox>
          )}
        </>
      ) : (
        <>
          <Flexbox className={css.emailMfaContainer}>
            <Flexbox verticalAlign className={css.emailContainer}>
              <Image
                src={IconPath.get('Action_BlackList')}
                className={css.mfaDisabled}
              />
              <Label
                className={{ [css.wrappedEmail]: isMobileLayout }}
                value={email}
              />
            </Flexbox>
            <FlyoutComposer
              className={cx(css.flyoutContainer, {
                [css.hideFlyoutArrow]: !isShowTurnOnFlyout
              })}
              widget={
                <Image
                  automationName="turnOnImage"
                  src={IconPath.get('Application_More')}
                  onClick={() => setIsShowTurnOnFlyout(true)}
                />
              }
              flyout={
                isShowTurnOnFlyout && (
                  <Label
                    automationName="turnOffEmailMfaClick"
                    value={formatMessage(Locale.mfa_email_turn_on)}
                    onClick={() => {
                      setIsShowTurnOnEmailMfaVerify(true);
                      setIsShowTurnOnFlyout(false);
                    }}
                  />
                )
              }
            />
          </Flexbox>
          {isShowTurnOnEmailMfaVerify && (
            <Flexbox column className={css.mfaWarning}>
              <Image
                automationName="emailMfaEnableCancel"
                className={css.warningCancelIcon}
                src={IconPath.get('Action_Close')}
                onClick={() => setIsShowTurnOnEmailMfaVerify(false)}
              />
              <Image
                className={css.warningIcon}
                src={IconPath.get('Status_Unacknowledged')}
              />
              <Label
                value={formatMessage(Locale.mfa_email_turn_on_verify, {
                  emailaddress: email
                })}
                grey7
                className={css.mfaWarningText}
              />
              <Flexbox className={css.emailMfaConfirmation}>
                <Label
                  cwId="enableEmailMfa"
                  automationName="enableEmailMfa"
                  className={css.mfaButton}
                  value={formatMessage(Locale.mfa_email_turn_on)}
                  onClick={() => {
                    onTurnOnEmailMfaClick();
                    setIsShowTurnOnEmailMfaVerify(false);
                  }}
                  isLink
                />
              </Flexbox>
            </Flexbox>
          )}
        </>
      )}
    </Flexbox>
  );
};

MfaEmail.propTypes = {
  isEmailMfaEnabled: PropTypes.bool,
  isMobileLayout: PropTypes.bool,
  isShowTurnOffVerification: PropTypes.bool,
  onEmailMfaDisableCancelClick: PropTypes.bool,
  emailMfaTurnOffCode: PropTypes.string,
  onDisableEmailMfaClick: PropTypes.func,
  email: PropTypes.string,
  onTurnOffEmailMfaClick: PropTypes.func,
  onTurnOnEmailMfaClick: PropTypes.func
};

export default MfaEmail;
