import * as core from 'cw-ui-core';
import { connect } from 'react-redux';
import WalletDrawer from 'src/components/Drawer/PartnerWalletDrawer/WalletDrawer';
import { getCustomerWallet } from 'src/components/Wallet/CustomerWalletService';
import Routes from 'src/services/Routes';
import { getPartnerBillingInfo } from 'src/services/ShoppingCart/ShoppingCartService';
import { CUSTOMER_WALLET_NOT_FOUND } from 'src/components/Wallet/WalletNotFound';
import { showNotificationBeforeClose } from 'src/services/DrawerNotification/DrawerNotification';

export const WALLET_DRAWER = 'WALLET_DRAWER';

const mapStateToProps = state => {
  return {
    isLoading: core.getScreenData(state.screen, [
      Routes.BILLING_PAGE.id,
      'showLoadingComponent'
    ]),
    failedInvoices: core.getScreenData(state.screen, ['failedInvoices']),
    wallet: core.getScreenData(state.screen, ['shopping_cart']),
    isShowCVV: state.drawer.get(WALLET_DRAWER).isShowCVV
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseIconClicked: () => {
      dispatch(core.hideDrawer(WALLET_DRAWER));
    },
    onBeforeClose: result => {
      const state = core.Store().getState();
      const isCardEdited = core.getScreenData(state.screen, [
        Routes.SHOPPING_CART.id,
        'editedCard'
      ]);
      if (isCardEdited) {
        showNotificationBeforeClose(WALLET_DRAWER);
        result.isCancel = true;
      }
    },
    onDidMount: async () => {
      core
        .Store()
        .dispatch(
          core.removeScreenData([Routes.SHOPPING_CART.id, 'editedCard'])
        );
      dispatch(
        core.setScreenData(
          [Routes.BILLING_PAGE.id, 'showLoadingComponent'],
          true
        )
      );
      const billingInfoResponse = await getPartnerBillingInfo();
      if (
        billingInfoResponse.status === 'error' &&
        billingInfoResponse.msg &&
        billingInfoResponse.msg ===
          'Your account has not been setup for purchasing. Please contact us to complete the setup.'
      ) {
        dispatch(core.hideDrawer(WALLET_DRAWER));
        dispatch(core.showDialog(CUSTOMER_WALLET_NOT_FOUND));
      } else {
        await getCustomerWallet();
      }

      dispatch(
        core.setScreenData(
          [Routes.BILLING_PAGE.id, 'showLoadingComponent'],
          false
        )
      );
    }
  };
};

const WalletDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletDrawer);

export default WalletDrawerContainer;
