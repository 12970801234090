import React, { useRef, useEffect } from 'react';
import { Router, NavLink } from 'react-router-dom';
import history from 'src/services/History';
import Routes from 'src/services/Routes';
import * as core from 'cw-ui-core';
import HasRoleAccess from 'src/services/RoleAccess';
import AccessDeniedPage from 'src/components/AccessDeniedPage/AccessDeniedPage';
import UserAccountContainer from 'src/components/UserAccount/UserAccountContainer';
import Locale from 'src/Locale';
import css from './TopNavigationMenuContent.scss';
import cx from 'classnames';

const TopNavigationMenuContent = props => {
  const getRouteList = () => {
    let routeList = [];

    for (const key in Routes) {
      if (Routes.hasOwnProperty(key)) {
        const route = Routes[key];
        if (
          HasRoleAccess(
            route.allowedRoles && route.allowedRoles(),
            route.disallowedRoles && route.disallowedRoles()
          )
        ) {
          route.showInMenu && routeList.push(route);
          if (route.showInMobileMenu) {
            let menuCopy = Object.assign({}, route);
            menuCopy.showInMenu = true;
            routeList.push(menuCopy);
          }
        } else {
          routeList.push({
            ...route,
            component: AccessDeniedPage,
            showInMenu: false
          });
        }
      }
    }

    const state = core.Store().getState();
    const user = core.getUser(state.oidc);
    const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
    if (isGuestUserLoggedIn) {
        routeList = routeList.filter(
        item => item.id !== 'PartnerSupportRedirect' && item.id !== 'university_page'
      );
    }

    return routeList.filter(x => x.showInMenu);
  };

  const menuList = useRef(null);
  const menuUpperList = useRef(null);
  const mobileMenuRoutes = getRouteList();
  const mobileMenuUpperList = [];
  const mobileMenuLowerList = [];
  for (let i = 0; i < mobileMenuRoutes.length; i++) {
    const mobileMenuListItem = (
      <NavLink
        automationName="listItem"
        className={css.mobileNavLink}
        exact={mobileMenuRoutes[i].exact}
        to={mobileMenuRoutes[i].path}
        activeClassName={css.selectedLink}
        key={mobileMenuRoutes[i].index}
        onClick={props.onCloseIconClicked}
      >
        <core.Image
          title={mobileMenuRoutes[i].id}
          className={css.navItem}
          src={core.IconPath.get(mobileMenuRoutes[i].icon)}
        />
        {mobileMenuRoutes[i].name(props.isMobileLayout)}
      </NavLink>
    );
    mobileMenuRoutes[i].showInMenuFooter
      ? mobileMenuLowerList.push(mobileMenuListItem)
      : mobileMenuUpperList.push(mobileMenuListItem);
  }

  useEffect(() => {
    const scrollSpace = mobileMenuLowerList.length * 64 + 16;
    const appliedHeight =
      menuList.current.getBoundingClientRect().height - (scrollSpace + 48);
    menuUpperList.current.style.height = appliedHeight + 'px';
  });

  return (
    <Router history={history()}>
      <core.Drawer
        automationName="listItemClose"
        customClass={css.mobileNavClose}
        fadeDirection="left"
        onCloseIconClicked={props.onCloseIconClicked}
      >
        <div className={css.menuContainer} ref={menuList}>
          <core.Flexbox column className={css.mobileMenuList}>
            <div
              ref={menuUpperList}
              className={cx(css.mobileMenuUpperContainer, css.scrollable)}
            >
              <core.Flexbox column className={css.mobileMenuUpperList}>
                {mobileMenuUpperList}
                <span
                  title={core.formatMessage(Locale.user_profile)}
                  className={css.mobileNavLink}
                >
                  <UserAccountContainer
                    hasPopup={false}
                    items={[
                      {
                        id: 'updateProfile',
                        name: core.formatMessage(Locale.updateHomeProfile)
                      }
                    ]}
                    label={core.formatMessage(Locale.user_profile_my_profile)}
                  />
                </span>
              </core.Flexbox>
            </div>
            <core.Flexbox column className={css.mobileMenulowerList}>
              {mobileMenuLowerList}
            </core.Flexbox>
          </core.Flexbox>
        </div>
      </core.Drawer>
    </Router>
  );
};

export default core.MobileLayoutHOC(TopNavigationMenuContent);
