import { injectIntl } from 'react-intl';
import {
  Dialog,
  ScreenMessagePanelContainer,
  Label,
  ButtonLight,
  ButtonStandard,
  formatMessage,
  Flexbox
} from 'cw-ui-core';
import Locale from 'src/Locale';
import React from 'react';
import css from 'src/components/PartnerSupportPage/KnownIssues/FollowKnownIssuesDialog.scss';

export const FOLLOW_KNOWN_ISSUES_DIALOG = 'FOLLOW_KNOWN_ISSUES_DIALOG';

function FollowKnownIssuesDialog(props) {
  const dialogButtons = () => {
    return [
      <ButtonLight
        key="btnNo"
        automationName="btnNo"
        className={css.noButton}
        value={formatMessage(Locale.no)}
        onClick={props.onNoClick}
        isSmall
      />,
      <ButtonStandard
        key="btnYes"
        automationName="btnYes"
        className={css.yesButton}
        value={formatMessage(Locale.yes)}
        onClick={props.onYesClick}
        isSmall
      />
    ];
  };

  return (
    <Dialog
      dialogId={FOLLOW_KNOWN_ISSUES_DIALOG}
      header={formatMessage(Locale.yes_no)}
      buttons={dialogButtons()}
    >
      <Flexbox className={css.wrap}>
        <ScreenMessagePanelContainer
          className={css.screenMessage}
          screenKey={FOLLOW_KNOWN_ISSUES_DIALOG}
        />
        <Label
          value={formatMessage(Locale.known_issues_follow_dialog_message)}
        />
      </Flexbox>
    </Dialog>
  );
}

export default injectIntl(FollowKnownIssuesDialog);
