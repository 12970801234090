import BaseService from '../BaseApiService';

class BillingEventService extends BaseService {
  getBaseUrl() {
    return `${this.getBaseUrlFromConfig('billing')}/api/billing_event`;
  }

  getBillingEventsByStatus(eventStatus, failedInvoiceNumbers) {
    const body = {
      eventStatus,
      invoice_number: failedInvoiceNumbers
    };
    return this.post(`${this.apiUrl()}/dummy/getBillingEventsByStatus`, body);
  }

  sendSuccessTaskToken(eventId, token, taskOutput) {
    const body = {
      eventId,
      taskToken: token ? token : '',
      taskOutput
    };
    return this.post(`${this.apiUrl()}/dummy/sendSuccessTaskToken`, body);
  }
}

export default new BillingEventService();
