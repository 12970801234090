import BaseService from '../BaseApiService';

class TokenService extends BaseService {
    getBaseUrl() {
        return `${this.getBaseUrlFromConfig('billing')}/api/token`;
    }

    create() {
        return this.post(`${this.apiUrl()}/dummy/Create`);
    }
}

export default new TokenService();
