import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import ResourceCard from 'src/components/Drawer/PartnerSuccessManagerDrawer/ResourceCard';
import Locale from 'src/Locale';
import {
  PARTNER_SUCCESS_MANAGER_DRAWER,
  ACCOUNT_MANAGER_TITLE,
  PathValues
} from './PartnerSuccessDrawer/PartnerSuccessManagerDrawerContainer';
import { Steps } from 'src/services/SuccessManagerService';
import {
  FEATURES,
  isFeatureEnabled
} from 'src/services/Features/FeaturesService';

const mapStateToProps = state => {
  const accountManager = core.getModuleCache(state.module, ['accountManager']);
  const accountManagerPhoto = core.getModuleCache(state.module, [
    'accountManagerPhoto'
  ]);
  const showFullAMBio = core.getScreenData(
    state.screen,
    'showFullAMBio',
    PARTNER_SUCCESS_MANAGER_DRAWER
  );
  function constructSupportLink() {
    const baseUrl = core.getModuleConfig(state.module, ['SalesForceServiceUrlBase']);
    const email = core.getUserEmail(state.oidc);
    return baseUrl + "?startURL=" + encodeURIComponent("/s/#step2?supportArea=AM&owner=" + email);
  }

  return {
    name: accountManager.get('fullName'),
    description: core.formatMessage(Locale.account_manager_description),
    initials:
      accountManager && accountManager.get('fullName')
        ? core.getInitials(accountManager.get('fullName'))
        : null,
    phone: accountManager.get('phone'),
    photo: accountManagerPhoto,
    photoUrl: accountManagerPhoto
      ? URL.createObjectURL(accountManagerPhoto)
      : undefined,
    aboutMe: accountManager.get('notes'),
    title: core.formatMessage(Locale.account_manager),
    email: accountManager.get('defaultEmail'),
    showFullBio: showFullAMBio,
    isSalesForceCaseManagement: isFeatureEnabled(FEATURES.HOME_CASE_MANAGEMENT),
    supportLinkText: core.formatMessage(Locale.create_case_with_am),
    supportLink: constructSupportLink()
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClick: () => {
      const state = core.Store().getState();
      const accountManagerPhoto = core.getModuleCache(state.module, [
        'accountManagerPhoto'
      ]);

      // Clear form values when changing card
      Object.keys(PathValues).forEach(path => {
        dispatch(
          core.setScreenData(
            PathValues[path],
            undefined,
            PARTNER_SUCCESS_MANAGER_DRAWER
          )
        );
      });

      const accountManagerPhotoUrl = accountManagerPhoto
        ? URL.createObjectURL(accountManagerPhoto)
        : undefined;
      const resourceSelected = {
        type: ACCOUNT_MANAGER_TITLE,
        member: core.getModuleCache(state.module, ['accountManager']).toJS(),
        photoUrl: accountManagerPhotoUrl,
        photoAltText:
          core.formatMessage(Locale.my_account_manager) +
          ' ' +
          core.formatMessage(Locale.photo),
        tooltip: core.formatMessage(Locale.my_account_manager)
      };
      dispatch(
        core.setScreenData(
          'resourceSelected',
          resourceSelected,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
      dispatch(
        core.setScreenData('step', Steps.Step_2, PARTNER_SUCCESS_MANAGER_DRAWER)
      );
    },
    onShowMoreClick: () => {
      dispatch(
        core.setScreenData(
          'showFullAMBio',
          true,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
    },
    onShowLessClick: () => {
      dispatch(
        core.setScreenData(
          'showFullAMBio',
          false,
          PARTNER_SUCCESS_MANAGER_DRAWER
        )
      );
    }
  };
};

const AccountManagerResourceCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceCard);

export default AccountManagerResourceCardContainer;
