import React, { useState } from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/ProductDrawer/IdentifyProductDrawer.scss';
import cssCommon from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon.scss';
import cx from 'classnames';
import {
  PlanCardButtonWithLoadingIndicator,
  PlanCardButton,
  ProductHeader
} from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon';

const IdentifyProductDrawer = props => {
  const [isBusy, setIsBusy] = useState(false);

  const onCardButtonClick = async plan => {
    plan.useButtonLoader && setIsBusy(true);
    await props.onCardButtonClick(plan);
    plan.useButtonLoader && setIsBusy(false);
  };

  return (
    <core.Drawer
      automationName="identifyDrawer"
      onCloseIconClicked={props.onClose}
      closeOnBlur
    >
      <core.Flexbox
        className={cx(css.scrollable, cssCommon.scrollable, css.container)}
        column
      >
        <ProductHeader
          {...props}
          productIconClassName={css.productIconHeader}
          descriptionClassName={css.productDescription}
          dividerLineClassName={css.dividerLineHeader}
        />
        <core.Label className={cssCommon.productLabel}>
          {props.productLabel}
        </core.Label>
        <core.Label value={props.planDescription} />
        {props.plans.map(plan => (
          <core.Flexbox key={plan.name} className={css.plan} column>
            <core.Flexbox
              className={cx(css.planCard, {
                [css.basic]: plan.name === 'Basic'
              })}
              column
            >
              <core.Flexbox className={css.planCardName}>
                <core.Label className={css.planCardLabel} value={plan.name} />
                {plan.status && (
                  <core.Label
                    className={cx(css.planCardStatus, {
                      [css.productStatusRed]:
                        plan.statusKey === 'payment_past_due'
                    })}
                    value={plan.status}
                  />
                )}
              </core.Flexbox>
              {plan.description && (
                <core.Label
                  className={cx(css.planCardLabel, css.planCardDescription)}
                  value={plan.description}
                />
              )}
              <core.Flexbox className={css.planPrice}>
                <core.Label
                  className={cx(css.planCardLabel, css.planCardPrice)}
                  value={plan.price}
                />
                <core.Label
                  className={css.planCardPriceInterval}
                  value={plan.priceInterval}
                />
              </core.Flexbox>
              <core.Label
                className={cx(css.planCardLabelBilling, css.planBillingType)}
                value={plan.billingType}
              />
              {plan.useButtonLoader ? (
                <PlanCardButtonWithLoadingIndicator
                  planCardButtonClassName={css.planCardButton}
                  automationName={`planCardLoaderButton_${plan.automationName}`}
                  buttonText={plan.buttonText}
                  onCardButtonClick={() => onCardButtonClick(plan)}
                  isBusy={isBusy}
                  wrapperStyle={{
                    position: 'absolute',
                    bottom: '0',
                    marginBottom: '30px',
                    width: '188px',
                    alignSelf: 'center',
                    fontSize: '14px'
                  }}
                />
              ) : (
                <PlanCardButton
                  planCardButtonClassName={css.planCardButton}
                  automationName={`cardButton_${plan.automationName}`}
                  buttonText={plan.buttonText}
                  onCardButtonClick={() => onCardButtonClick(plan)}
                />
              )}
            </core.Flexbox>
            <core.Flexbox className={css.planCard} column>
              {plan.features.map(f => (
                <core.Flexbox key={f} className={css.feature}>
                  <core.Image
                    className={css.featureCheckIcon}
                    src={core.IconPath.get('Status_Success')}
                  />
                  <core.Label className={css.featureLabel} value={f} />
                </core.Flexbox>
              ))}
            </core.Flexbox>
          </core.Flexbox>
        ))}
      </core.Flexbox>
    </core.Drawer>
  );
};

export default core.MobileLayoutHOC(IdentifyProductDrawer);
