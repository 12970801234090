import React, { useEffect, useRef } from 'react';
import {
  MobileLayoutHOC,
  Flexbox,
  Label,
  formatMessage,
  Placeholder,
  Drawer
} from 'cw-ui-core';
import css from 'src/components/Admin/NewsPage/NewsFeed/NewsPageDrawer/NewsPageDrawer.scss';
import Locale from 'src/Locale';
import NewsFeedItem from './NewsFeedItemContainer';
export const VIEW_ADMIN_NEWS_DRAWER = 'VIEW_ADMIN_NEWS_DRAWER';

const NewsPageDrawer = props => {
  const { onDidMount, tags, isReady } = props;
  const drawerContent = useRef(null);
  useEffect(() => {
    const heightToApply = window.innerHeight - 223;
    drawerContent.current &&
      (drawerContent.current.style.maxHeight = `${heightToApply}px`);
  });

  useEffect(() => {
    onDidMount();
  }, [onDidMount]);

  const Header = (
    <Label
      className={css.mainTitle}
      value={formatMessage(Locale.post_details)}
      grey7
    />
  );
  const previewLoader = <Placeholder className={css.previewLoading} />;
  return (
    <Drawer
      automationName="listItemClose"
      fadeDirection="left"
      onCloseIconClicked={props.onCloseIconClicked}
      closeOnBlur
    >
      {Header}
      {!isReady ? (
        <div>{previewLoader}</div>
      ) : (
        <Flexbox className={css.container} column>
          <div className={css.previewContainer} ref={drawerContent}>
            <NewsFeedItem />
          </div>
          <Label grey2 className={css.tag} value={tags}></Label>
        </Flexbox>
      )}
    </Drawer>
  );
};

export default MobileLayoutHOC(NewsPageDrawer);
