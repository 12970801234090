import { injectIntl } from 'react-intl';
import Locale from 'src/Locale';
import * as core from 'cw-ui-core';
import React, { useState } from 'react';
import css from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesSendEmailLinkDialog.scss';
import WithButtonLoadingIndicatorHOC from 'src/components/HoC/WithButtonLoadingIndicatorHOC';
export const KNOWN_ISSUES_SEND_EMAIL_DIALOG = 'KNOWN_ISSUES_SEND_EMAIL_DIALOG';

function KnownIssuesSendEmailLinkDialog(props) {
  const [isBusy, setIsBusy] = useState(false);
  const onSendEmailClick = async () => {
    setIsBusy(true);
    await props.onSendClick();
    setIsBusy(false);
  };

  const SendEmailButton = ({ onSendEmailClick, isEmailValid }) => {
    return (
      <core.ButtonStandard
        automationName="btnSend"
        className={css.button}
        value={core.formatMessage(Locale.send)}
        onClick={onSendEmailClick}
        disabled={!isEmailValid}
        isSmall
      />
    );
  };

  const SendEmailButtonWithLoadingIndicator = WithButtonLoadingIndicatorHOC(
    SendEmailButton
  );

  return (
    <core.Dialog
      includeCloseIcon
      dialogId={KNOWN_ISSUES_SEND_EMAIL_DIALOG}
      onCloseIconClicked={props.onCancelClick}
      className={css.dialog}
      includeMobileCloseIcon={props.isMobileLayout ? true : false}
    >
      <div className={css.wrap}>
        <core.Label
          className={css.sendlink}
          value={core.formatMessage(Locale.known_issues_send_email_link)}
        />
        <core.Label
          className={css.emailhelptext}
          value={core.formatMessage(Locale.known_issues_send_email_text)}
        />
        <div className={css.emailContainer}>
          <core.TextField
            automationName="email"
            className={css.emailField}
            value={props.email}
            placeholder={core.formatMessage(
              Locale.known_issues_email_placeholder
            )}
            onChange={props.onEmailTextChange}
          />
          <core.Image
            src={core.IconPath.get('Action_email')}
            className={css.emailIconKnownIssue}
          />
        </div>
        <div className={props.isEmailValid ? css.validEmail : css.invalidEmail}>
          <core.Image
            automationName="options"
            src={core.IconPath.get('Action_Apply')}
            className={
              props.isEmailValid ? css.validEmailIcon : css.invalidEmailIcon
            }
          />
          <core.Label
            className={css.validateEmailLabel}
            value={core.formatMessage(Locale.known_issues_email_validate_label)}
          ></core.Label>
        </div>
        <div className={css.dialogbuttons}>
          <core.ButtonLight
            automationName="Cancel"
            className={css.button}
            value={core.formatMessage(Locale.cancel)}
            onClick={props.onCancelClick}
            isSmall
          />
          <SendEmailButtonWithLoadingIndicator
            isBusy={isBusy}
            onSendEmailClick={onSendEmailClick}
            isEmailValid={props.isEmailValid}
            wrapperStyle={{ width: '110px', height: '36px' }}
          />
        </div>
      </div>
    </core.Dialog>
  );
}

export default core.MobileLayoutHOC(injectIntl(KnownIssuesSendEmailLinkDialog));
