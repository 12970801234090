import * as core from 'cw-ui-core';
import React from 'react';
import { BANNER_DRAWER } from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawerContainer';
import { VIEW_ADMIN_NEWS_DRAWER } from 'src/components/Admin/NewsPage/NewsFeed/NewsPageDrawer/NewsPageDrawer';
import { NEWS_POST_DRAWER } from 'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsPostDrawerContainer';
import { SECURITY_BULLETIN_DRAWER } from 'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawerContainer';
import { ADD_LICENSE_DRAWER } from 'src/components/Drawer/AddLicenseDrawer/LicenseDrawerContainer';
import { PARTNER_SUCCESS_MANAGER_DRAWER } from 'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerSuccessDrawer/PartnerSuccessManagerDrawerContainer';
import { WALLET_DRAWER } from 'src/components/Drawer/PartnerWalletDrawer/WalletDrawerContainer';
import { AUTOMATE_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/AutomateProductDrawerContainer';
import { CONTROL_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/ControlProductDrawerContainer';
import { IDENTIFY_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/IdentifyProductDrawerContainer';
import { MANAGE_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/ManageProductDrawerContainer';
import { SELL_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/SellProductDrawerContainer';
import { UNITE_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/UniteProductDrawerContainer';
import { VIEW_SECURITY_BULLETIN_DRAWER } from 'src/components/Drawer/SecurityBulletin/SecurityPageDrawer';
import { COMPANY_ACCOUNT_SETTING_DRAWER } from 'src/components/Drawer/UserProfileDrawer/CompanyAccountSetting/CompanyAccountSettingContainer';
import { UPDATE_PROFILE_DRAWER } from 'src/components/Drawer/UserProfileDrawer/UserProfileDrawer';
import { MOBILE_NAVIGATION_MENU_DRAWER } from 'src/components/Navigation/TopNavigationContentContainer';
import { VIEW_KNOWN_ISSUE_DRAWER } from 'src/components/PartnerSupportPage/KnownIssues/KnownIssuesDrawer';
import { EDIT_VIEW_TICKET_DRAWER_ID } from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawer';
import UserAccountContainer from 'src/components/UserAccount/UserAccountContainer';

const getDrawer = drawerId => {
  switch (drawerId) {
    case UPDATE_PROFILE_DRAWER:
      return (
        <core.AsyncComponent
          key={UPDATE_PROFILE_DRAWER}
          avatar={() => (
            <UserAccountContainer
              avatarStyle={{ cursor: 'auto' }}
              hasPopup={false}
            />
          )}
          loader={cb => {
            import(
              'src/components/Drawer/UserProfileDrawer/UserProfileDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case IDENTIFY_PRODUCT_DRAWER:
      return (
        <core.AsyncComponent
          key={IDENTIFY_PRODUCT_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/ProductDrawer/IdentifyProductDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case UNITE_PRODUCT_DRAWER:
      return (
        <core.AsyncComponent
          key={UNITE_PRODUCT_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/ProductDrawer/UniteProductDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case CONTROL_PRODUCT_DRAWER:
      return (
        <core.AsyncComponent
          key={CONTROL_PRODUCT_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/ProductDrawer/ControlProductDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case MANAGE_PRODUCT_DRAWER:
      return (
        <core.AsyncComponent
          key={MANAGE_PRODUCT_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/ProductDrawer/ManageProductDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case SELL_PRODUCT_DRAWER:
      return (
        <core.AsyncComponent
          key={SELL_PRODUCT_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/ProductDrawer/SellProductDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case PARTNER_SUCCESS_MANAGER_DRAWER:
      return (
        <core.AsyncComponent
          key={PARTNER_SUCCESS_MANAGER_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerSuccessDrawer/PartnerSuccessManagerDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );

    case NEWS_POST_DRAWER:
      return (
        <core.AsyncComponent
          key={NEWS_POST_DRAWER}
          loader={cb => {
            import(
              'src/components/Admin/NewsPage/NewsFeed/NewsPostDrawer/NewsPostDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case EDIT_VIEW_TICKET_DRAWER_ID:
      return (
        <core.AsyncComponent
          key={EDIT_VIEW_TICKET_DRAWER_ID}
          loader={cb => {
            import(
              'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case MOBILE_NAVIGATION_MENU_DRAWER:
      return (
        <core.AsyncComponent
          key={MOBILE_NAVIGATION_MENU_DRAWER}
          loader={cb => {
            import(
              'src/components/Navigation/TopNavigationContentContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case WALLET_DRAWER:
      return (
        <core.AsyncComponent
          key={WALLET_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/PartnerWalletDrawer/WalletDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case ADD_LICENSE_DRAWER:
      return (
        <core.AsyncComponent
          key={ADD_LICENSE_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/AddLicenseDrawer/LicenseDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case COMPANY_ACCOUNT_SETTING_DRAWER:
      return (
        <core.AsyncComponent
          key={COMPANY_ACCOUNT_SETTING_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/UserProfileDrawer/CompanyAccountSetting/CompanyAccountSettingContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case VIEW_ADMIN_NEWS_DRAWER:
      return (
        <core.AsyncComponent
          key={VIEW_ADMIN_NEWS_DRAWER}
          loader={cb => {
            import(
              'src/components/Admin/NewsPage/NewsFeed/NewsPageDrawer/NewsPageDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case VIEW_SECURITY_BULLETIN_DRAWER:
      return (
        <core.AsyncComponent
          key={VIEW_SECURITY_BULLETIN_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/SecurityBulletin/SecurityPageDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case VIEW_KNOWN_ISSUE_DRAWER:
      return (
        <core.AsyncComponent
          key={VIEW_KNOWN_ISSUE_DRAWER}
          loader={cb => {
            import(
              'src/components/PartnerSupportPage/KnownIssues/KnownIssuesDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case SECURITY_BULLETIN_DRAWER:
      return (
        <core.AsyncComponent
          key={SECURITY_BULLETIN_DRAWER}
          loader={cb => {
            import(
              'src/components/Admin/NewsPage/SecurityBulletin/SecurityBulletinDrawer/SecurityBulletinDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case AUTOMATE_PRODUCT_DRAWER:
      return (
        <core.AsyncComponent
          key={AUTOMATE_PRODUCT_DRAWER}
          loader={cb => {
            import(
              'src/components/Drawer/ProductDrawer/AutomateProductDrawerContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case BANNER_DRAWER:
      return (
      <core.AsyncComponent
        key={BANNER_DRAWER}
        loader={cb => {
          import(
            'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawerContainer'
          ).then(component => {
            cb(component);
          });
        }}
      />
      );
    default:
      // TODO: is there a cwerror logger we can use?
      console.error('Unknown drawer: ', drawerId);
      return null;
  }
};

export default getDrawer;
