import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/ProductDrawer/SellProductDrawer.scss';
import cssCommon from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon.scss';
import cx from 'classnames';
import {
  ProductHeader,
  PlanCardButtonWithLoadingIndicator
} from 'src/components/Drawer/ProductDrawer/ProductDrawerCommon';
import Locale from 'src/Locale';

const SellProductDrawer = props => {
  return (
    <core.Drawer automationName="sellDrawer" onCloseIconClicked={props.onClose}>
      <core.Flexbox
        className={cx(css.scrollable, cssCommon.scrollable, css.container)}
        column
      >
        <ProductHeader
          {...props}
          productIconClassName={css.productIconHeader}
          descriptionClassName={css.productDescription}
          dividerLineClassName={css.dividerLineHeader}
          learnMoreClassName={css.learnMoreClassName}
        />
        <core.Flexbox className={css.bannerHeader} column>
          <core.Flexbox column className={css.banner}>
            <core.Label
              value={core.formatMessage(Locale.drawer_sell_banner_header)}
              className={css.bannerHeaderText}
            />
            <core.Label
              value={core.formatMessage(
                Locale.drawer_sell_banner_description_first
              )}
              className={css.bannerDescription}
            />
            <core.Label
              value={core.formatMessage(
                Locale.drawer_sell_banner_description_second
              )}
              className={css.bannerDescription}
            />
          </core.Flexbox>
          <core.Flexbox
            column={props.isMobileLayout}
            className={css.planContainer}
          >
            {props.plans.map(plan => (
              <div title={props.buttonTitle}>
                <PlanCardButtonWithLoadingIndicator
                  automationName={`planCardLoaderButton_${plan.automationName}`}
                  buttonText={plan.buttonText}
                  onCardButtonClick={() => props.onCardButtonClick(plan)}
                  isBusy={
                    plan.name === 'Demo' ? props.isBusyDemo : props.isBusyQuote
                  }
                  wrapperStyle={{
                    bottom: '0',
                    width: '188px',
                    alignSelf: 'center',
                    fontSize: '14px'
                  }}
                  disabled={!props.isDemoOrQuoteEnable}
                  planCardButtonClassName={css.planCardButton}
                />
              </div>
            ))}
          </core.Flexbox>
        </core.Flexbox>
        <core.Flexbox column>
          <core.Label
            value={core.formatMessage(Locale.drawer_sell_benefit_header)}
            className={css.benefitHeader}
          ></core.Label>
          <core.Flexbox column className={css.benefits}>
            {props.benefits.map(benefit => (
              <core.Flexbox
                key={benefit.name}
                className={css.benefit}
                column={props.isMobileLayout}
              >
                <core.Flexbox>
                  <span>
                    <core.Image
                      cwId="benefitImage"
                      isInlineSVG={false}
                      src={benefit.imageSrc}
                      className={css.benefitImage}
                    ></core.Image>
                  </span>
                  <core.Flexbox column className={css.benefitMessage}>
                    <core.Label
                      value={benefit.title}
                      className={css.textBold}
                    ></core.Label>
                    <core.Label value={benefit.description}></core.Label>
                  </core.Flexbox>
                </core.Flexbox>
              </core.Flexbox>
            ))}
          </core.Flexbox>
        </core.Flexbox>
        <core.Label
          value={core.formatMessage(Locale.drawer_sell_product_owned)}
          className={cx(css.ownedProductText, css.textBold)}
        />
        <core.Label
          automationName="sellConnectAccount"
          isLink
          className={css.connectPlan}
          value={core.formatMessage(Locale.drawer_product_owned_text)}
          onClick={props.onConnectLinkClick}
        />
      </core.Flexbox>
    </core.Drawer>
  );
};

export default core.MobileLayoutHOC(SellProductDrawer);
