import React, { useEffect } from 'react';
import css from './SuccessTeamBanner.scss';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import { injectIntl } from 'react-intl';
import cx from 'classnames';

const SuccessTeamBanner = props => {
  const {
    successManager,
    successManagerPhotoUrl,
    getSuccessTeam,
    accountManager,
    accountManagerPhotoUrl,
    partnerSalesExecutive,
    partnerDevelopmentManager
  } = props;
  const { formatMessage } = props.intl;
  const successTeamElement = (
    <>
      <core.Label
        className={css.textLabel}
        value={formatMessage(Locale.contact_my_connectwise_team)}
      />
      {' '}
    </>
  );

  function Avatar() {
    if (
      successManager != null &&
      accountManager != null &&
      partnerSalesExecutive != null &&
      partnerDevelopmentManager != null &&
      ((accountManager ||
        successManager ||
        partnerSalesExecutive ||
        partnerDevelopmentManager) ||
      (!accountManager &&
        !successManager &&
        !partnerSalesExecutive &&
        !partnerDevelopmentManager))
    )
     {
      let successManagerContent;
      let dynStyleSM = {
        CwThemeBackgroundColor: props.successManagerInitials !== undefined
      };
      if (props.successManagerInitials) {
        successManagerContent = (
          <core.Label
            className={css.initials}
            value={props.successManagerInitials}
          />
        );
      }
      else if (successManagerPhotoUrl || !successManager) {
        successManagerContent = (
          <core.Image
            src={successManager ? successManagerPhotoUrl : "/images/deafult_avatar.png"}
            isInlineSVG={false}
            alt={formatMessage(Locale.success_manager_photo)}
          />
        );
      }

      let accountManagerContent;
      let dynStyleAM = {
        CwThemeBackgroundColor: props.accountManagerInitials !== undefined
      };
      if (props.accountManagerInitials) {
        accountManagerContent = (
          <core.Label
            className={css.initials}
            value={props.accountManagerInitials}
          />
        );
      } else if (accountManagerPhotoUrl) {
        accountManagerContent = (
          <core.Image
            src={accountManagerPhotoUrl}
            isInlineSVG={false}
            alt={formatMessage(Locale.account_manager_photo)}
          />
        );
      }

      return (
        <div
          className={props.className}
          onClick={() => {
            !props.disabled && props.showPartnerSuccessManagerDrawer();
          }}
          title={formatMessage(Locale.contact_my_connectwise_team)}
        >
          {successManager ? (
            <core.Flexbox
              className={cx(dynStyleSM, css.image)}
              style={props.style}
            >
              <div
                title={formatMessage(Locale.success_manager_name, {
                  fullName: successManager.get('fullName')
                })}
              >
                {successManagerContent}
              </div>
            </core.Flexbox>
          ) : (
            <core.Flexbox
              className={cx(dynStyleSM, css.image)}
              style={props.style}
            >
              <div title={formatMessage(Locale.partner_success_team)}>
                {successManagerContent}
              </div>
            </core.Flexbox>
          )}
          {accountManager && (
            <core.Flexbox
              className={cx(dynStyleAM, css.image, css.secondImage)}
              style={props.style}
            >
              <div
                title={formatMessage(Locale.account_manager_name, {
                  fullName: accountManager.get('fullName')
                })}
              >
                {accountManagerContent}
              </div>
            </core.Flexbox>
          )}
          <core.Flexbox
            className={css.profileContainer}
            column={props.isMobileLayout}
          >
            {successTeamElement}
          </core.Flexbox>
        </div>
      );
    } else if (
      successManager == null ||
      successManager ||
      accountManager == null ||
      accountManager ||
      partnerSalesExecutive == null ||
      partnerSalesExecutive ||
      partnerDevelopmentManager == null ||
      partnerDevelopmentManager
    ) {
      return (
        <div className={props.className}>
          <core.Flexbox className={css.bannerLoading}>
            <core.Placeholder className={css.imageLoading} />
            <core.Placeholder className={css.nameLabelLoading} />
            <core.Placeholder className={css.textLabelLoading} />
          </core.Flexbox>
        </div>
      );
    } else {
      return null;
    }
  }

  useEffect(() => {
    getSuccessTeam();
  }, [getSuccessTeam]);

  return <Avatar />;
};

export default injectIntl(core.MobileLayoutHOC(SuccessTeamBanner));
