import React from 'react';
import * as PropTypes from 'prop-types';
import css from 'src/components/Drawer/UserProfileDrawer/UserProfileHeader.scss';
import { Label, Notification, formatMessage } from 'cw-ui-core';
import Locale from 'src/Locale';

function UserProfileHeader(props) {
  if (props.notifications && props.notifications.length > 0) {
    setTimeout(() => {
      props.dismissNotification(props.notifications[0].cwId);
    }, 5000);
  }

  return (
    <>
      <Label
        className={css.mainTitle}
        value={formatMessage(Locale.profile_edit_my_profile)}
      />
      {props.notifications.map(notification => (
        <Notification
          key={notification.message}
          onClose={() => props.dismissNotification(notification.cwId)}
          {...notification}
        />
      ))}
    </>
  );
}

UserProfileHeader.propTypes = {
  onClose: PropTypes.func,
  closeIconPath: PropTypes.string,
  mainTitle: PropTypes.string,
  saveButton: PropTypes.func,
  notifications: PropTypes.array,
  dismissNotification: PropTypes.func
};

export default UserProfileHeader;
