import * as core from 'cw-ui-core';
import React from 'react';
import AccountManagerResourceCardContainer from 'src/components/Drawer/PartnerSuccessManagerDrawer/AccountManagerResourceCardContainer';
import css from 'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerSuccessDrawer/DrawerContent.scss';
import PartnerSuccessManagerResourceCardContainer from 'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerSuccessManagerResourceCardContainer';
import { Steps } from 'src/services/SuccessManagerService';
import PartnerSalesExecutiveResourceCardContainer from 'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerSalesExecutiveResourceCardContainer';
import PartnerDevelopmentManagerResourceCardContainer from 'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerDevelopmentManagerResourceCardContainer';

const DrawerContent = props => {
  const {
    currentStep,
    accountManager,
    successManager,
    partnerSalesExecutive,
    partnerDevelopmentManager
  } = props;

  return (
    <div>
      {currentStep === Steps.Step_1 && (
        <core.Flexbox column className={css.details}>
          {accountManager && <AccountManagerResourceCardContainer />}
          {(successManager || !successManager) && <PartnerSuccessManagerResourceCardContainer />}
          {partnerSalesExecutive && <PartnerSalesExecutiveResourceCardContainer />}
          {partnerDevelopmentManager && <PartnerDevelopmentManagerResourceCardContainer />}
        </core.Flexbox>
      )}
    </div>
  );
};

export default DrawerContent;
