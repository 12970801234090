import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/AddLicenseDrawer/DrawerTitle/DrawerTitle.scss';
import cx from 'classnames';

const DrawerTitle = props => {
  return (
    <core.Flexbox column className={css.licenseTitle}>
      <core.Label
        className={cx(css.title, {
          [css.onlyTitle]: !props.showSubTitle && !props.showProgressBar
        })}
        value={props.title}
      />
      {props.showSubTitle && (
        <core.Label className={css.subTitle} value={props.subTitle} />
      )}
      {props.showProgressBar && (
        <core.ProgressBar
          className={css.stepStageConatiner}
          currentStep={props.currentStep}
          totalSteps={props.totalSteps}
        />
      )}
    </core.Flexbox>
  );
};

export default DrawerTitle;
