import * as core from 'cw-ui-core';
import { connect } from 'react-redux';
import {
  hideNotificationBeforeClose,
  showNotificationBeforeClose
} from 'src/services/DrawerNotification/DrawerNotification';
import {
  contactTimeFilter,
  Steps
} from 'src/services/SuccessManagerService';
import PartnerSuccessManagerDrawer from './PartnerSuccessManagerDrawer';

export const PARTNER_SUCCESS_MANAGER_DRAWER = 'PARTNER_SUCCESS_MANAGER_DRAWER';
export const PARTNER_SUCCESS_MANAGER_TITLE = 'Partner Success Manager';
export const ACCOUNT_MANAGER_TITLE = 'Account Manager';
export const PARTNER_SALES_EXECUTIVE_TITLE = 'Partner Sales Executive';
export const PARTNER_DEVELOPMENT_MANAGER_TITLE = 'Partner Development Manager';

export const PathValues = {
  Category: 'mainCategorySelected',
  ContactTime: 'contactTimeCombo',
  ContactMethod: 'contactMethodCombo',
  DetailText: 'additionalDetailText',
  Topic: 'product',
  SubCategory: 'subCategory',
  SubCategoryItem: 'item'
};

const disableSubmitHandler = (ticketInfo, isAcccountManager) => {
  let hasValidFields = true;

  if (isAcccountManager) {
    hasValidFields =
      hasValidFields &&
      Boolean(ticketInfo.mainCategorySelected) &&
      Boolean(ticketInfo.product) &&
      Boolean(ticketInfo.additionalDetailText) &&
      Boolean(ticketInfo.contactMethodCombo) &&
      Boolean(ticketInfo.contactTimeCombo);
  } else {
    hasValidFields =
      ticketInfo &&
      Boolean(ticketInfo.additionalDetailText) &&
      Boolean(ticketInfo.contactMethodCombo) &&
      Boolean(ticketInfo.contactTimeCombo);
  }

  return !hasValidFields;
};

const mapStateToProps = state => {
  const successManager = core.getModuleCache(state.module, ['successManager']);
  const accountManager = core.getModuleCache(state.module, ['accountManager']);
  const partnerSalesExecutive = core.getModuleCache(state.module, ['partnerSalesExecutive']);
  const partnerDevelopmentManager = core.getModuleCache(state.module, ['partnerDevelopmentManager']);

  const currentStep = core.getScreenData(
    state.screen,
    'step',
    PARTNER_SUCCESS_MANAGER_DRAWER
  );

  let mappingsReady = false;

  const resourceSelected = core.getScreenData(
    state.screen,
    ['resourceSelected', 'type'],
    PARTNER_SUCCESS_MANAGER_DRAWER
  );
  const isAccountManager = resourceSelected === ACCOUNT_MANAGER_TITLE;

  const createdTicketId = core.getScreenData(
    state.screen,
    ['setTicketId'],
    PARTNER_SUCCESS_MANAGER_DRAWER
  );

  let isTicketCreated = false;
  if (createdTicketId) {
    isTicketCreated = true;
  }

  const ticketDetails = core.getScreenData(
    state.screen,
    [],
    PARTNER_SUCCESS_MANAGER_DRAWER
  );
  const ticketInfo = ticketDetails ? ticketDetails.toJS() : null;

  const disabled = disableSubmitHandler(ticketInfo, isAccountManager);

  const showNotificationBeforeClose = core.getScreenData(
    state.screen,
    'showNotificationBeforeClose',
    PARTNER_SUCCESS_MANAGER_DRAWER
  );

  const drawerScreenKey = PARTNER_SUCCESS_MANAGER_DRAWER;

  return {
    isAccountManager,
    successManager,
    accountManager,
    step: currentStep,
    currentStep,
    showBreadCrumbs: currentStep !== Steps.Step_1,
    createdTicketId,
    isTicketCreated,
    disabled,
    drawerScreenKey,
    contactTimeFilter,
    mappingsReady,
    showNotificationBeforeClose,
    partnerSalesExecutive,
    partnerDevelopmentManager
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseIconClicked: () => {
      dispatch(core.hideDrawer(PARTNER_SUCCESS_MANAGER_DRAWER));
      dispatch(core.removeScreenData('psmNextStepMapping'));
    },
    onBeforeClose: result => {
      const state = core.Store().getState();
      const step = Steps.Step_1;
      const currentStep = core.getScreenData(
        state.screen,
        'step',
        PARTNER_SUCCESS_MANAGER_DRAWER
      );
      const createdTicketId = core.getScreenData(
        state.screen,
        ['setTicketId'],
        PARTNER_SUCCESS_MANAGER_DRAWER
      );
      if (!createdTicketId ? step !== currentStep : false) {
        showNotificationBeforeClose(PARTNER_SUCCESS_MANAGER_DRAWER);

        result.isCancel = true;
      }
    },
    onNotificationStayClick: drawerScreenKey => {
      hideNotificationBeforeClose(drawerScreenKey);
    },
    onNotificationCloseClick: drawerScreenKey => {
      hideNotificationBeforeClose(drawerScreenKey);
      dispatch(core.hideDrawer(drawerScreenKey));
      dispatch(core.removeScreenData('psmNextStepMapping'));
    },
    onCreateTicketClick: () => {
      dispatch(core.hideDrawer(PARTNER_SUCCESS_MANAGER_DRAWER));
      const baseUrl = core.getModuleConfig(core.Store().getState().module, [
        'SalesForceServiceUrlBase'
      ]);
      const url =
        baseUrl + '?startURL=' + encodeURIComponent('/s/#step2?supportArea=PS');
      window.open(url, '_self');
    },
    setValueOnPath: (path, value) => {
      dispatch(core.setScreenData(path, value, PARTNER_SUCCESS_MANAGER_DRAWER));
    },
    onViewTicket: id => {
      window.open(`/partnersupport/ticket/${id}`, '_self');
    },
  };
};

const PartnerSuccessManagerDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerSuccessManagerDrawer);

export default PartnerSuccessManagerDrawerContainer;
