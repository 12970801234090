import React, { useEffect } from 'react';
import { Flexbox, MobileLayoutHOC, Label } from 'cw-ui-core';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import css from './SupportTicketNotesSection.scss';
import SupportTicketNotesLoading from './SupportTicketNotesLoading';
import Locale from 'src/Locale';
import SupportTicketNotesMemberImageContainer from './SupportTicketNotesMemberImageContainer';
import { NoteLabel } from '@cwnexus/cw-markdown-to-html';

const SupportTicketNotesSection = ({ loadNotes, ...props }) => {
  const formatDate = date => {
    const { formatDate } = props.intl;
    return formatDate(date, {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    });
  };

  const { formatTime, formatMessage } = props.intl;

  const getIcon = note => {
    return <SupportTicketNotesMemberImageContainer note={note} />;
  };

  const getMobileLayout = () => {
    return props.notes
      ? props.notes.map((note, index) => {
          return (
            <div
              key={index}
              className={cx(css.mainContainerMobile, {
                [css.isMeBackground]: note.memberId > 0 && !note.contactId
              })}
            >
              <Flexbox className={css.headerContainerMobile}>
                {getIcon(note)}
                <Flexbox className={css.nameDateContainerMobile} column>
                  <Label className={css.nameMobile}>
                    {note.isAttachment
                      ? formatMessage(Locale.attachment)
                      : note.name}
                  </Label>
                  <Label className={css.dateMobile}>
{`${formatDate(
                    note.date,
                    {}
                  )} at ${formatTime(note.date)}`}
</Label>
                </Flexbox>
              </Flexbox>
              <NoteLabel
                fullNoteClass={css.inlineFlexDisplay}
                noteLabelCss={cx(css.notesTextMobile, {
                  CwThemeColor: note.isAttachment,
                  [css.link]: note.isAttachment
                })}
                value={note.text}
                automationName="download_attachment_mobile"
                onClick={
                  note.isAttachment
                    ? () => {
                        props.downloadDocumentOnClick(note.id, note.text);
                      }
                    : undefined
                }
              />
            </div>
          );
        })
      : null;
  };

  const getNormalLayout = () => {
    return props.notes
      ? props.notes.map((note, index) => {
          return (
            <Flexbox
              key={index}
              className={cx(css.notesContainer, {
                [css.isMeBackground]: note.memberId > 0 && !note.contactId
              })}
            >
              <Flexbox className={css.notesTopContainer}>
                {getIcon(note)}
                <Label
                  className={css.name}
                  value={
                    note.isAttachment
                      ? formatMessage(Locale.attachment)
                      : note.name
                  }
                />
                <Label
                  className={css.date}
                  value={`| ${formatDate(note.date, {})} at ${formatTime(
                    note.date
                  )}`}
                />
              </Flexbox>
              <NoteLabel
              fullNoteClass={css.inlineFlexDisplay}
                noteLabelCss={cx(css.notesText, {
                  CwThemeColor: note.isAttachment,
                  [css.link]: note.isAttachment
                })}
                value={note.text}
                automationName="download_attachment"
                onClick={
                  note.isAttachment
                    ? () => {
                        props.downloadDocumentOnClick(note.id, note.text);
                      }
                    : undefined
                }
              />
            </Flexbox>
          );
        })
      : null;
  };

  useEffect(() => {
    const doWork = async () => {
      loadNotes();
    };

    doWork().then();
  }, [loadNotes, props.ticketId]);

  return (
    <section className={css.sectionContainer}>
      {props.notesReady && props.contactReady ? (
        props.isMobileLayout ? (
          getMobileLayout()
        ) : (
          getNormalLayout()
        )
      ) : (
        <SupportTicketNotesLoading />
      )}
    </section>
  );
};

export default injectIntl(MobileLayoutHOC(SupportTicketNotesSection));
