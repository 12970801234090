import * as core from 'cw-ui-core';
import React, { useEffect, useRef, useState } from 'react';
import NotificationBeforeClose from 'src/components/Drawer/NotificationBeforeClose';
import CreateTicketSuccess from 'src/components/CreateTicketSuccess/CreateTicketSuccess';
import DrawerContent from 'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerSuccessDrawer/DrawerContent';
import DrawerFooter from 'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerSuccessDrawer/DrawerFooter';
import { Steps } from 'src/services/SuccessManagerService';
import { useWindowResized } from 'src/state/hooks/windowResize';
import css from './PartnerSuccessManagerDrawer.scss';
import PartnerSuccessManagerDrawerHeaderContainer from './PartnerSuccessManagerDrawerHeaderContainer';

const PartnerSuccessManagerDrawer = props => {
  const {
    accountManager,
    contactTimeFilter,
    createdTicketId,
    currentStep,
    mappingQueries,
    drawerScreenKey,
    disabled,
    isAccountManager,
    isTicketCreated,
    mappingsReady,
    onCloseIconClicked,
    onBeforeClose,
    onCreateTicketClick,
    onNotificationStayClick,
    onNotificationCloseClick,
    onSubmit,
    onViewTicket,
    setValueOnPath,
    showBreadCrumbs,
    showNotificationBeforeClose,
    step,
    successManager,
    partnerSalesExecutive,
    partnerDevelopmentManager
  } = props;

  const [disablePSM, setDisablePSM] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const drawerTitle = useRef(null);
  const drawerContent = useRef(null);
  const drawerBtns = useRef(null);
  const windowDim = useWindowResized();

  const calculateContentHeight = function () {
    let titleHeight;
    let buttonsHeight;
    titleHeight = drawerTitle.current
      ? drawerTitle.current.getBoundingClientRect().height
      : 0;
    buttonsHeight = drawerBtns.current
      ? drawerBtns.current.getBoundingClientRect().height
      : 0;
    let heightToApply = windowDim.height - (buttonsHeight + titleHeight + 48);
    drawerContent.current &&
      (drawerContent.current.style.height = `${heightToApply}px`);
  };

  useEffect(() => {
    setDisableSubmit(isAccountManager ? disabled : disablePSM || disabled);
  }, [disabled, disablePSM, isAccountManager]);

  useEffect(calculateContentHeight, [showNotificationBeforeClose, windowDim]);

  return (
    <core.Drawer
      onCloseIconClicked={onCloseIconClicked}
      onBeforeClose={onBeforeClose}
      closeOnBlur
    >
      {!isTicketCreated ? (
        <core.Flexbox className={css.partnerSuccessManagerDrawer} column>
          <div ref={drawerTitle} className={css.managerHeaderDrawerContainer}>
            <PartnerSuccessManagerDrawerHeaderContainer />
          </div>
          <NotificationBeforeClose
            showNotificationBeforeClose={showNotificationBeforeClose}
            onNotificationStayClick={onNotificationStayClick}
            onNotificationCloseClick={onNotificationCloseClick}
            drawerScreenKey={drawerScreenKey}
          ></NotificationBeforeClose>
          <div className={css.scrollableArea} ref={drawerContent}>
            <core.ScreenMessagePanelContainer screenKey={drawerScreenKey} />
            <DrawerContent
              isAccountManager={isAccountManager}
              currentStep={currentStep}
              setDynamicFieldsAreValid={setDisablePSM}
              mappingQueries={mappingQueries}
              setValueOnPath={setValueOnPath}
              step={step}
              mappingsReady={mappingsReady}
              showBreadCrumbs={showBreadCrumbs}
              contactTimeFilter={contactTimeFilter}
              accountManager={accountManager}
              successManager={successManager}
              partnerSalesExecutive={partnerSalesExecutive}
              partnerDevelopmentManager={partnerDevelopmentManager}
            />
          </div>
          {(currentStep === Steps.Step_1 || currentStep === Steps.Step_2) && (
            <div ref={drawerBtns} className={css.footerContainer}>
              <DrawerFooter
                currentStep={currentStep}
                onCreateTicketClick={onCreateTicketClick}
                onSubmit={onSubmit}
                disabled={disableSubmit}
              />
            </div>
          )}
        </core.Flexbox>
      ) : (
        <CreateTicketSuccess
          ticketId={createdTicketId}
          onViewTicket={onViewTicket}
          drawerId={props.drawerScreenKey}
        ></CreateTicketSuccess>
      )}
    </core.Drawer>
  );
};

export default React.memo(PartnerSuccessManagerDrawer);
