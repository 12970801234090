import React from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Drawer/PartnerSuccessManagerDrawer/PartnerSuccessDrawer/PartnerSuccessManagerDrawer.scss';
import Locale from 'src/Locale';
import cx from 'classnames';

const Avatar = (photoUrl, initials, photoAltText) => {
  let managerContent;
  if (initials && !photoUrl) {
    managerContent = <core.Label className={css.initials} value={initials} />;
  } else if (photoUrl) {
    managerContent = (
      <core.Image src={photoUrl} isInlineSVG={false} alt={photoAltText} />
    );
  }

  return managerContent;
};

const PartnerSuccessManagerDrawerHeader = props => {
  return (
    <core.Flexbox
      className={cx(css.header, { [css.tranformHeader]: props.title })}
      column
    >
      <core.Flexbox className={css.nameContainer}>
        {props.fullName && (
          <div className={css.profileImage}>
            {Avatar(props.photoUrl, props.initials, props.photoAltText)}
          </div>
        )}
        {props.fullName && (
          <core.Flexbox column className={css.nameLabelContainer}>
            <core.Label
              value={props.fullName}
              automationName="manager"
              className={css.name}
            />
            <core.Label
              className={css.successManagerLabel}
              value={
                props.title
                  ? props.title
                  : core.formatMessage(Locale.step1_title)
              }
            />
            {!props.title && (
              <core.Label
                className={css.successManagerLabel}
                value={core.formatMessage(Locale.select_an_option)}
              />
            )}
          </core.Flexbox>
        )}
        {!props.fullName && (
          <core.Flexbox column className={css.nameLabelContainer}>
            <core.Label
              value={core.formatMessage(Locale.my_connectwise_team)}
              automationName="manager"
              className={css.myTeamHeaderTitle}
            />
            <core.Label
              className={css.myTeamHeaderDescription}
              value={
                props.title
                  ? props.title
                  : core.formatMessage(Locale.step1_title)
              }
            />
            {!props.title && (
              <core.Label
                className={css.myTeamHeaderDescription}
                value={core.formatMessage(Locale.select_an_option)}
              />
            )}
          </core.Flexbox>
        )}
      </core.Flexbox>
    </core.Flexbox>
  );
};

export default PartnerSuccessManagerDrawerHeader;
