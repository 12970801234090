import React, { useEffect, useRef } from 'react';
import * as core from 'cw-ui-core';
import css from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawer.scss';
import { BANNER_DRAWER } from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawerContainer';
import BannerDrawerContent from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawerContent';
import BannerDrawerHeaderContainer from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawerHeaderContainer';
import BannerDrawerFooter from 'src/components/Admin/NewsPage/Banner/BannerDrawer/BannerDrawerFooter';
import NotificationBeforeCloseContainer from 'src/components/Drawer/NotificationBeforeCloseContainer';

const BannerDrawer = props => {
  const drawerTitle = useRef(null);
  const drawerContent = useRef(null);
  const drawerBtns = useRef(null);

  useEffect(() => {
    let titleHeight;
    let buttonsHeight;
    titleHeight = drawerTitle.current
      ? drawerTitle.current.getBoundingClientRect().height
      : 0;
    buttonsHeight = drawerBtns.current
      ? drawerBtns.current.getBoundingClientRect().height
      : 0;
    let heightToApply = window.innerHeight - (buttonsHeight + titleHeight + 48);
    drawerContent.current &&
      (drawerContent.current.style.height = `${heightToApply}px`);
  });

  const {
    createdBannerId,
    onCreateBannerClick,
    isBannerEdit,
    firstBanner,
    firstBannerUrl,
    firstBannerFileName,
    onFirstBannerChanged,
    secondBanner,
    secondBannerUrl,
    secondBannerFileName,
    onSecondBannerChanged,
    thirdBanner,
    thirdBannerUrl,
    thirdBannerFileName,
    onThirdBannerChanged,
    fourthBanner,
    fourthBannerUrl,
    fourthBannerFileName,
    onForthBannerChanged,
    onPrimaryColorUploadClicked,
    onPrimaryButtonTextColorUploadClicked,
    onPrimaryColorChanged,
    onPrimaryButtonTextColorChanged,
    primaryColor,
    primaryButtonTextColor,
    onCancelClick,
    onPreviewClick,
    toggle,
    onCTAUrlChange
  } = props;

  const onClose = () => {
    setTimeout(props.onCloseIconClicked, 500);
  };

  useEffect(() => {
    drawerContent.current && (drawerContent.current.scrollTop = 0);
  }, []);

  return (
    <core.Drawer
      automationName="bannerDrawer"
      onCloseIconClicked={onClose}
      customClass={css.bannerDrawer}
    >
      <>
        <div ref={drawerTitle}>
          <BannerDrawerHeaderContainer />
        </div>
        <div className={css.scrollableArea} ref={drawerContent}>
          <div className={css.position}>
          <core.ScreenMessagePanelContainer screenKey={BANNER_DRAWER} />
            <NotificationBeforeCloseContainer
              drawerScreenKey={BANNER_DRAWER}
            ></NotificationBeforeCloseContainer>
          </div>
          <BannerDrawerContent
            createdBannerId={createdBannerId}
            isBannerEdit={isBannerEdit}
            firstBanner={firstBanner}
            firstBannerUrl={firstBannerUrl}
            firstBannerFileName={firstBannerFileName}
            onFirstBannerChanged={onFirstBannerChanged}
            secondBanner={secondBanner}
            secondBannerUrl={secondBannerUrl}
            secondBannerFileName={secondBannerFileName}
            onSecondBannerChanged={onSecondBannerChanged}
            thirdBanner={thirdBanner}
            thirdBannerUrl={thirdBannerUrl}
            thirdBannerFileName={thirdBannerFileName}
            onThirdBannerChanged={onThirdBannerChanged}
            fourthBanner={fourthBanner}
            fourthBannerUrl={fourthBannerUrl}
            fourthBannerFileName={fourthBannerFileName}
            onForthBannerChanged={onForthBannerChanged}
            onPrimaryColorUploadClicked={onPrimaryColorUploadClicked}
            onPrimaryButtonTextColorUploadClicked={
              onPrimaryButtonTextColorUploadClicked
            }
            onPrimaryColorChanged={onPrimaryColorChanged}
            onPrimaryButtonTextColorChanged={onPrimaryButtonTextColorChanged}
            primaryColor={primaryColor}
            primaryButtonTextColor={primaryButtonTextColor}
            toggle={toggle}
            onCTAUrlChange={onCTAUrlChange}
          />
        </div>

        <div ref={drawerBtns} className={css.footerContainer}>
          <BannerDrawerFooter
            onCreateBannerClick={onCreateBannerClick}
            onCancelClick={onCancelClick}
            onPreviewClick={onPreviewClick}
            isBannerEdit={isBannerEdit}
          />
        </div>
      </>
    </core.Drawer>
  );
};

export default BannerDrawer;
