import React from 'react';
import * as core from 'cw-ui-core';
import css from './TicketAttachment.scss';
import cx from 'classnames';

const TicketAttachment = props => {
  const getPreview = file => {
    if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/bmp' || file.type === 'image/gif' || file.type === 'image/svg+xml') {
      return <core.FileImage className={css.imageIcon} file={file} />;
    } else {
      return <core.Image className={cx(css.imageIcon, css.attachmentIcon)} src={core.IconPath.get('Action_Attach')} />;
    }
  };

  return (
    <div className={cx(props.className, css.wrap)}>
      {getPreview(props.file)}
      <core.Label className={css.name} value={props.file.name} />
      <core.Image automationName="attachment_close" className={css.closeIcon} src={core.IconPath.get('Action_Close')} onClick={props.onCloseClicked} />
    </div>
  );
};

export default TicketAttachment;