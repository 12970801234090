import * as core from 'cw-ui-core';
import FeaturesService from 'src/services/Api/HomeApi/FeaturesService';

export const getFeatures = async () => {
  const result = await FeaturesService.getFeatures();
  if (result.isSuccess) {
    return result.response;
  }

  return null;
};

export const isFeatureEnabled = featureId => {
  const state = core.Store().getState();
  const featureEnabled = core.getModuleCache(state.module, [
    'features',
    featureId,
    'enabled'
  ]);

  return featureEnabled === undefined ? false : featureEnabled;
};

export const FEATURES = {
  SWITCH_TO_NO_COMPANY: 'switchtonocompany',
};
