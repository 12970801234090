import React from 'react';
import css from './NewsPostDrawer.scss';
import * as core from 'cw-ui-core';

const DrawerHeader = props => {
  return (
    <>
      <core.Flexbox column className={css.labelContainer}>
        <core.Label
          value={props.criteriaName}
          className={css.largeLabel}
          grey7
        />
        <core.Label
          className={css.smallLabel}
          value={props.criteriaDescription}
        />
      </core.Flexbox>
    </>
  );
};

export default DrawerHeader;
