import React from 'react';
import {
  Image,
  IconPath,
  Flexbox,
  Placeholder,
  MobileLayoutHOC,
  Label
} from 'cw-ui-core';
import { injectIntl } from 'react-intl';
import Locale from 'src/Locale';
import css from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/EditSupportTicketDrawerHeader/EditSupportTicketDrawerHeader.scss';

const SupportTicketDrawerHeader = ({ isReady, ticketNumber, ...props }) => {
  const { formatMessage } = props.intl;

  return (
    <Flexbox className={css.header} horizontalAlign verticalAlign>
      <Image
        src={IconPath.get('Action_Back')}
        cwId="previousTicket_ViewSupportTicketDrawer"
        automationName="previousTicket_ViewSupportTicketDrawer"
        isInlineSvg
        alt="Previous Ticket"
        className={
          props.canPreviousPage ? css.headerArrow : css.headerArrowDisabled
        }
        onClick={props.canPreviousPage ? props.onLeftNavigation : undefined}
      />
      {isReady || props.ticketIdFromUrl ? (
        <Label
          className={css.mainTitle}
          value={formatMessage(Locale.ticket_id, {
            ticketId: props.ticketIdFromUrl || ticketNumber
          })}
        />
      ) : (
        <Placeholder className={css.placeholder} />
      )}
      <Image
        src={IconPath.get('Action_Forward')}
        cwId="nextTicket_ViewSupportTicketDrawer"
        automationName="nextTicket_ViewSupportTicketDrawer"
        isInlineSvg
        alt="Next Ticket"
        onClick={
          props.canNextPage
            ? async () => await props.onRightNavigation()
            : undefined
        }
        className={
          props.canNextPage ? css.headerArrow : css.headerArrowDisabled
        }
      />
    </Flexbox>
  );
};

export default injectIntl(MobileLayoutHOC(SupportTicketDrawerHeader));
