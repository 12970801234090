import { Store, getScreenData, setScreenData, CwLog } from 'cw-ui-core';
import linkHeaderParser from 'parse-link-header';

import TicketApi from 'src/services/Api/HomeApi/TicketService';

export const defaultPageSize = 10;
export const defaultParams = {
  pageIndex: 0,
  conditions: '',
  orderBy: 'id desc',
  pageSize: defaultPageSize,
  isArchivedList: true
};

export const loadData = async (params = {}) => {
  const dispatch = Store().dispatch;
  const state = Store().getState();
  let existingParams =
    getScreenData(state.screen, ['params']) || Map(defaultParams);
  existingParams = existingParams.toJS();
  const { conditions: oldConditions } = existingParams;
  const mergedParams = { ...existingParams, ...params };
  const { pageIndex, pageSize, conditions, orderBy, ticketType, isArchivedList } = mergedParams;
  dispatch(setScreenData(['isReady'], false));
  let isCleanData = false;
  if (oldConditions !== conditions) {
    isCleanData = true;
  }

  let tickets = getScreenData(state.screen, ['tickets']);
  tickets = isCleanData ? tickets.clear() : tickets;
  try {
    const result = await TicketApi.archivedList(
      conditions,
      orderBy,
      pageIndex + 1,
      pageSize,
      ticketType,
      isArchivedList
    );
    const link = result.headers.get('Link');
    const count = result.headers.get('X-Total-Count');
    const linkParsed = linkHeaderParser(link);
    let updatedTickets = tickets.set(pageIndex, result.response);
    let pageCount = 1;
    if (linkParsed) {
      if (linkParsed.last) {
        pageCount = parseInt(linkParsed.last.page);
      } else if (linkParsed.prev) {
        pageCount = pageIndex + 1;
      }
    }

    dispatch(setScreenData(['tickets'], updatedTickets));
    dispatch(setScreenData(['totalCount'], parseInt(count)));
    dispatch(setScreenData(['pageCount'], pageCount));

    dispatch(setScreenData(['params'], mergedParams));
  } catch (e) {
    CwLog.error('Unable to get tickets for pageIndex ' + pageIndex);
  }

  dispatch(setScreenData(['isReady'], true));
};
