import * as Coveo from 'coveo-search-ui';

class Deflection extends Coveo.Component {
  static ID = 'Deflection';
  constructor(element, options, bindings, result) {
    super(element, Deflection.ID, bindings);
    this.HIDDEN_CLASS = 'coveo-hidden';
    this.result = result;
    this.options = Coveo.ComponentOptions.initComponentOptions(
      element,
      Deflection,
      options
    );
    // Initialization Events
    this.bind.onRootElement(
      Coveo.InitializationEvents.afterComponentsInitialization,
      function () {
        return this.handleAfterComponentsInit();
      }
    );
    // Query events
    this.bind.onRootElement(Coveo.QueryEvents.querySuccess, function (args) {
      return this.handleQuerySucess(args);
    });
    this.bind.onRootElement(Coveo.QueryEvents.buildingQuery, function (args) {
      return this.handleBuildingQuery(args);
    });
  }

  handleCreateTicketButton = function (el) {
    var cause;
    if (this.options.type === 'case') {
      cause = { name: 'submitButton', type: 'caseCreation' };
    } else {
      cause = this.chatSubmitButton;
    }

    this.searchInterface.usageAnalytics.logCustomEvent(cause, {}, el);
  };

  handleBackButton = function (el) {
    var cause;
    if (this.options.type === 'case') {
      cause = { name: 'cancelButton', type: 'caseCreation' };
    } else {
      cause = this.chatCancelButton;
    }

    this.searchInterface.usageAnalytics.logCustomEvent(cause, {}, el);
  };

  handleFieldChange = function (el) {
    var cause;
    if (this.options.type === 'case') {
      cause = { name: 'inputChange', type: 'caseCreation' };
    } else {
      cause = this.chatInputChange;
    }

    var that = this;
    el.onkeyup = function () {
      that.cancelAnyPendingSearchTimeout();
      that.searchTimeout = setTimeout(function () {
        that.executeQuery(cause);
      }, that.options.refreshDelay);
    };
  };

  cancelAnyPendingSearchTimeout = function () {
    if (Coveo.Utils.exists(this.searchTimeout)) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = undefined;
    }
  };

  handleAfterComponentsInit = function () {
    // eslint-disable-next-line no-underscore-dangle
    var _this = this;
    Coveo.$$(document.body)
      .findClass(this.options.issueSummaryFieldSelector)
      .forEach(function (el) {
        return Coveo.$$(el).on('input', function () {
          return _this.handleFieldChange(el);
        });
      });
    Coveo.$$(document.body)
      .findClass(this.options.createTicketButtonSelector)
      .forEach(function (el) {
        return Coveo.$$(el).on('click', function () {
          return _this.handleCreateTicketButton(el);
        });
      });
    Coveo.$$(document.body)
      .findClass(this.options.backButtonSelector)
      .forEach(function (el) {
        return Coveo.$$(el).on('click', function () {
          return _this.handleBackButton(el);
        });
      });
  };

  executeQuery = function (cause) {
    // eslint-disable-next-line no-underscore-dangle
    var _this = this;
    this.searchInterface.queryController.deferExecuteQuery({
      beforeExecuteQuery: function () {
        _this.searchInterface.usageAnalytics.logSearchEvent(cause, {});
      }
    });
  };

  handleQuerySucess = function (args) {
    if (
      args.results.totalCount === 0 ||
      args.query.context['issuesummary'] === ''
    ) {
      Coveo.$$(this.root).addClass(this.HIDDEN_CLASS);
      Coveo.$$(document.body)
        .findClass(this.options.noResultFieldSelector)
        .forEach(function (el) {
          if (el instanceof HTMLDivElement) {
            var container = el;
            Coveo.$$(container).show();
          }
        });
    } else {
      Coveo.$$(this.root).removeClass(this.HIDDEN_CLASS);
      Coveo.$$(document.body)
        .findClass(this.options.noResultFieldSelector)
        .forEach(function (el) {
          if (el instanceof HTMLDivElement) {
            var container = el;
            Coveo.$$(container).hide();
          }
        });
    }
  };

  handleBuildingQuery = function (args) {
    args.queryBuilder.context = {
      userDomain: this.options.userDomain
    };
    Coveo.$$(document.body)
      .findClass(this.options.issueSummaryFieldSelector)
      .forEach(function (el) {
        if (el instanceof HTMLInputElement) {
          var txtArea = el;
          if (txtArea.value.toLocaleLowerCase() !== 'none') {
            args.queryBuilder.context['issuesummary'] = txtArea.value;
          }
        }
      });
    Coveo.$$(document.body)
      .findClass(this.options.issueCategoryFieldSelector)
      .forEach(function (el) {
        if (el instanceof HTMLLabelElement) {
          var labelEl = el;
          if (labelEl.textContent.toLowerCase() !== 'none') {
            args.queryBuilder.context['issuecategory'] = labelEl.innerHTML;
          }
        }
      });
    Coveo.$$(document.body)
      .findClass(this.options.issueNameFieldSelector)
      .forEach(function (el) {
        if (el instanceof HTMLLabelElement) {
          var labelEl = el;
          if (labelEl.textContent.toLowerCase() !== 'none') {
            args.queryBuilder.context['issuename'] = labelEl.innerHTML;
          }
        }
      });
    for (var val in this.options.context) {
      args.queryBuilder.context[val] = this.options.context[val];
    }
  };
}

Deflection.options = {
  context: Coveo.ComponentOptions.buildJsonObjectOption(),
  type: Coveo.ComponentOptions.buildStringOption({ defaultValue: 'case' }),
  refreshDelay: Coveo.ComponentOptions.buildNumberOption({
    defaultValue: 1500
  }),
  issueCategoryFieldSelector: Coveo.ComponentOptions.buildStringOption({
    defaultValue: 'coveo-query-category'
  }),
  issueNameFieldSelector: Coveo.ComponentOptions.buildStringOption({
    defaultValue: 'coveo-query-subcategory'
  }),
  issueSummaryFieldSelector: Coveo.ComponentOptions.buildStringOption({
    defaultValue: 'coveo-query-summary'
  }),
  createTicketButtonSelector: Coveo.ComponentOptions.buildStringOption({
    defaultValue: 'coveo-submit'
  }),
  backButtonSelector: Coveo.ComponentOptions.buildStringOption({
    defaultValue: 'coveo-cancel'
  }),
  noResultFieldSelector: Coveo.ComponentOptions.buildStringOption({
    defaultValue: 'coveo-query-noResult'
  }),
  userDomain: Coveo.ComponentOptions.buildStringOption()
};

export default Deflection;
